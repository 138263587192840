var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"elevation-1 boxed seriescard is-hover-grow",class:{
    'is-active': _vm.isActive
  },on:{"click":_vm.onSeriesCardClick}},[_c('DateSquare',{staticClass:"mr-3",attrs:{"d":_vm.toLocalDate(_vm.event.starts_at || _vm.event.scheduled_starts_at),"is-featured":_vm.event.series.featured_at !== null,"size":"medium"}}),_c('div',{staticClass:"event-info"},[_c('div',{staticClass:"event-title"},[_c('h2',[_vm._v(" "+_vm._s(_vm._f("truncate_render")(_vm._f("format_title")(_vm.event)))+" "),(_vm.event.series.featured_at !== null)?_c('badge',{attrs:{"data-type":"featured","icon":"flag","label":"Featured"}}):_vm._e(),(_vm.durationName === 'extended')?_c('badge',{attrs:{"data-type":"extended","icon":"arrows-alt-h","label":"Extended"}}):_vm._e(),(_vm.durationName === 'marathon')?_c('badge',{attrs:{"data-type":"marathon","icon":"running","label":"Marathon"}}):_vm._e(),(_vm.seriesIsUpdated)?_c('badge',{attrs:{"data-type":"updated","label":"Updated"}}):_vm._e()],1),_c('div',{staticClass:"is-flex is-align-items-center"},[_c('div',{staticClass:"is-size-7 has-text-grey"},[_vm._v("Hosted by")]),(_vm.hostUser)?_c('UserPill',{staticClass:"ml-1",attrs:{"size":"small","user":_vm.hostUser,"link-to-profile":false}}):_vm._e()],1)]),_c('div',{staticClass:"details"},[(_vm.event.series.venue)?_c('div',{staticClass:"is-flex is-flex-wrap-wrap is-align-items-center is-flex-grow-0"},[_c('Pill',{staticClass:"mr-2 is-dark",attrs:{"icon":"clock","icon-type":"is-info"}},[_vm._v(" "+_vm._s(_vm._f("format")((_vm.event.starts_at || _vm.event.scheduled_starts_at),"h:mmaaa"))+" - "+_vm._s(_vm._f("format")(_vm.event.ends_at,"h:mmaaa"))+" "),_c('span',{staticClass:"tz"},[_vm._v(" "+_vm._s(_vm._f("format")((_vm.event.starts_at || _vm.event.scheduled_starts_at),"zzz"))+" ")])]),_c('Pill',{staticClass:"mr-2",class:{
            'is-success':
              _vm.event.series.venue.is_virtual && !_vm.event.series.original_venue_was_virtual
          },attrs:{"icon":_vm.event.series.venue.is_virtual ? 'laptop' : 'map-marker-alt',"icon-pack":_vm.event.series.venue.is_virtual ? 'fas' : 'far'}},[(!_vm.event.series.original_venue_was_virtual)?_c('span',[(_vm.event.series.venue.is_virtual)?_c('span',[_vm._v(" Currently Meets Online ")]):_c('span',[_vm._v(" "+_vm._s(_vm.venueCityState)+" ")])]):_c('span',[_vm._v(" Online")])]),_c('Pill',{staticClass:"mr-2",class:{
            'is-success':
              _vm.event.series.venue.is_virtual && !_vm.event.series.original_venue_was_virtual
          },attrs:{"icon":"globe"}},[_vm._v(" English ")])],1):_vm._e(),_c('b-tag',{attrs:{"type":"is-success is-light"}},[_c('b-icon',{attrs:{"icon":"user-check"}}),_c('span',[_vm._v(_vm._s(_vm.event.series.members_aggregate.aggregate.count || "0"))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }