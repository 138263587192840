<template>
  <div>
    <SubmissionCardEditDesktop 
      v-if="suwDesktop"
      :submission="submission"
      :prompt="prompt" 
      @created="onCreated"
      @updated="onUpdated"
      @cancelled="onCancelled"
    />
    <SubmissionCardEditMobile 
      v-if="!suwDesktop"
      :submission="submission"
      :prompt="prompt" 
      @created="onCreated"
      @updated="onUpdated"
      @cancelled="onCancelled"
    />
  </div>
</template>

<script>
import SubmissionCardEditDesktop from "@/components/submissionCard/SubmissionCardEditDesktop.vue";
import SubmissionCardEditMobile from "@/components/submissionCard/SubmissionCardEditMobile.vue";

export default {
  name: "SubmissionCardEdit",
  components: {
    SubmissionCardEditDesktop,
    SubmissionCardEditMobile
  },
  props: {
    submission: {
      type: Object,
      default: null
    },
    prompt: {
      type: Object,
      default: null
    }
  },
  methods: {
    onCreated(result) {
      this.$emit("created", result);
    },
    onUpdated(result) {
      this.$emit("updated", result);
    },
    onCancelled() {
      this.$emit("cancelled");
    }
  }
};
</script>
