<template>
  <section
    class="empty-state has-background-light mt-2"
    :class="{
      'full-width': isFullWidth
    }"
  >
    <slot>Empty State</slot>
  </section>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    isFullWidth: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  border-radius: 4px;
  width: 100%;
}
</style>
