import { CREATE_CHALLENGE, UPDATE_CHALLENGE, GET_CHALLENGE_BY_DRAFT } from "@/models/challenges/operations.gql";
import { add } from "date-fns";
import { required } from "vuelidate/lib/validators";

const challengeCardEdit = {
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      body: {
        required
      }
    }
  },
  data() {
    return {
      form: {
        id: null,
        title: "",
        body: null,
        available_after: new Date(add(new Date(), { hours: 24 })),
        featured_at: null
      },
      isFeatured: false,
      draft: null
    };
  },
  mounted() {
    if (this.challenge !== null) {
      this.isFeatured = this.challenge.featured_at !== null ? true : false;
      this.form = {
        ...this.challenge,
        available_after: new Date(this.challenge.available_after)
      };
    }
  },
  methods: {
    onCancel() {
      this.$emit("cancelled");
    },
    onDraft(body) {
      if (!this.challenge) {
        //only save NEW challenges as drafts
        Object.assign(this.form, { body });
        if (this.form.id) {
          this.onUpdate(true);
        } else {
          this.onCreate(true);
        }
      }
    },
    onConfirm(body) {
      Object.assign(this.form, { body });
      if (this.form.id) {
        this.onUpdate(false);
      } else {
        this.onCreate(false);
      }
    },
    onCreate(isDraft) {
      this.form.featured_at = this.isFeatured ? new Date() : null;
      const { title, body, available_after, featured_at } = this.form;
      this.$apollo
        .mutate({
          mutation: CREATE_CHALLENGE,
          variables: {
            challenge: {
              title,
              body,
              available_after,
              featured_at,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
            if (isDraft) {
              this.form.id = result.id;
            }
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            this.$emit("created", result);
            this.$buefy.snackbar.open({
              message: result.title ? `Challenge ${result.title} created` : "Challenge created",
              type: "is-success"
            });
            this.$router.push({
              name: "challenges_detail",
              params: {
                slug: result.slug
              }
            });
          }
        })
        .catch(e => {
          let msg = "Error creating challenge.";
          if (e.message.includes("Uniqueness violation")) {
            msg = "A challenge with this title -- deleted or not -- already exists. Rename one or the other.";
          }
          this.$buefy.snackbar.open({
            message: msg,
            type: "is-danger",
            position: "is-top"
          });
        });
    },
    onUpdate(isDraft) {
      this.form.featured_at = this.isFeatured
        ? this.challenge?.featured_at ?? new Date()
        : null;
      const wasDraft = this.challenge ? this.challenge.is_draft : true;
      const { id, title, body, available_after, featured_at } = this.form;
      this.$apollo
        .mutate({
          mutation: UPDATE_CHALLENGE,
          variables: {
            challenge_id: id,
            changes: {
              title,
              body,
              available_after,
              featured_at,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            if (wasDraft) {
              this.$emit("created", result);
            } else {
              this.$emit("updated", result);
            }
            this.$buefy.snackbar.open({
              message: result.title ? `Challenge ${result.title} updated` : "Challenge updated",
              type: "is-success"
            });
          }
        })
        .catch(e => {
          let msg = "Error updating challenge.";
          if (e.message.includes("Uniqueness violation")) {
            msg = "A challenge with this title -- deleted or not -- already exists. Rename one or the other.";
          }
          this.$buefy.snackbar.open({
            message: msg,
            type: "is-danger",
            position: "is-top"
          });
        });
    }
  },
  apollo: {
    draft: {
      query: GET_CHALLENGE_BY_DRAFT,
      variables() {
        return {
          author_id: this.$auth.user.id
        };
      },
      update({ drafts }) {
        const draft = drafts ? drafts[0] || null : null;
        if (draft && !this.form.id) {
          this.form.id = draft.id;
          this.form.title = draft.title;
          this.form.body = Object.assign({}, draft.body);
        }
        return draft;
      }
    }
  }
};

export default challengeCardEdit;
