<template>
  <b-taginput
    :value="value"
    type="is-outlined"
    :data="searchResults"
    :clearable="true"
    :keep-first="true"
    autocomplete
    expanded
    :allow-new="false"
    :open-on-focus="false"
    icon="user-friends"
    placeholder="Find a user..."
    max-height="400px"
    field="username"
    :loading="$apollo.queries.searchResults.loading"
    :before-adding="beforeAdd"
    @typing="onTyping"
    @input="onInput"
  >
    <template #tag="{ tag }">
      <b-tooltip type="is-dark" :label="`@${tag.username}`">
        {{ tag | user_name }}
      </b-tooltip>
    </template>

    <template #default="{ option }">
      <div class="is-flex is-justify-content-flex-start is-align-items-center mb-1">
        <!-- eslint-disable -->
        <Avatar size="small" :seed-string="option | user_name" :color="option.avatar_color" />
        <strong v-html="highlight(user_name(option), query)"></strong>
        @
        <span v-html="highlight(option.username, query)" class="has-text-weight-light is-italic">
        </span>
        <!-- eslint-enable -->
      </div>
    </template>

    <template #empty>
      <p class="has-text-weight-bold">
        No users found.
      </p>
    </template>
  </b-taginput>
</template>

<script>
/* eslint-disable vue/no-v-html */
import Avatar from "@/components/common/Avatar.vue";
import { USERS_LIST } from "@/models/users/operations.gql";
import { whereUserMatches, roleIn, whereIsNotUser } from "@/models/users/predicates.js";
import { user_name } from "@/filters.js";

export default {
  name: "UserSearch",
  components: {
    Avatar
  },
  props: {
    allowedRoles: {
      type: Array,
      default: () => ["owner", "organizer", "app_admin"]
    },
    value: {
      type: Array, // list of user objects
      default: () => []
    },
    hideCurrentUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchResults: [], // users who match the query but are not in the "in set"
      query: "", // what the user has typed so far
      isDumping: false
    };
  },
  apollo: {
    searchResults: {
      query: USERS_LIST,
      fetchPolicy: "network-only",
      debounce: 250, // in milliseconds
      variables() {
        let where = {
          ...whereUserMatches({ query: this.query }),
          ...roleIn(this.allowedRoles),
          ...(this.hideCurrentUser ? whereIsNotUser(this.$auth.user) : {})
        };
        return { where };
      },
      update: ({ users }) => users,
      skip() {
        return !this || this.query === "";
      }
    }
  },
  methods: {
    user_name,
    highlight(haystack, needle) {
      let out = ""; // the highlighted output
      // since we put this result back in the DOM with HTML marks in it and it's user-generated
      // we need to strip tags that may be in the data to prevent XSS attacks
      haystack = haystack
        .replaceAll("&", "&amp;")
        .replaceAll("<", "&lt;")
        .replaceAll('"', "&quot;")
        .replaceAll("'", "&#39;");
      const start = haystack.toLowerCase().indexOf(needle.toLowerCase());
      if (start === -1) {
        return haystack;
      }
      out += haystack.substring(0, start);
      out += "<mark>";
      out += haystack.substring(start, start + needle.length);
      out += "</mark>";
      out += haystack.substring(start + needle.length);
      //this.$log.debug("highlight", needle, "in", haystack, "=>", out);
      return out;
    },
    onTyping(query) {
      this.query = query;
      if (query.length < 1) {
        return;
      }
    },
    beforeAdd(userToBeAdded) {
      // this method just ensures the id's aren't duplicated before allowing an insert
      this.$log.debug("beforeAdd", userToBeAdded);
      return !this.value.find(u => u.id == userToBeAdded.id);
    },
    onInput(users) {
      this.$emit("input", users);
    }
  }
};
</script>
