<template>
  <div class="breadcrumbs">
    <nav class="breadcrumb has-succeeds-separator is-display-block">
      <ul>
        <li v-for="(crumb, index) in breadcrumbs" :key="index">
          <router-link active-class="is-active" :to="crumb.to">
            {{ crumb.label }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
  .breadcrumb ol,
  .breadcrumb ul {
    align-items: center;
  }

</style>
