<template>
  <div class="expanded-card is-flex">
    <div class="is-center is-flex is-flex-direction-column is-flex-grow-1">
      <div class="is-flex is-flex-direction-column p-1">
        <div>
          <div class="is-flex is-align-items-center">
            <div>
              <UserPill :user="submission.author" class="mr-1" />
            </div>
            <span class="has-text-grey">
              <InlineDate :d="submission.created_at" :add-suffix="true" />
            </span>
          </div>
        </div>
        <p class="my-2">
          <TipTap v-model="form.body" :is-preview="false" :is-dangerous="isReported && !isAllowed" />
        </p>
        <div class="is-flex">
          <div class="is-flex is-flex-grow-1"></div>
          <b-taglist class="is-flex is-align-items-center">
            <ClickableTag
              class="mb-0"
              colorClass="yellow"
              type="is-warning is-light"
              icon-left="thumbs-up"
              :is-enabled="isUpvoted"
              @click="onUpvoteClicked"
            >
              <span>
                {{ `${submission.upvotes.aggregate.count}` }}
                {{ "Like" | pluralize(submission.upvotes.aggregate.count) }}
              </span>
            </ClickableTag>
            <ClickableTag 
              class="mb-0" 
              colorClass="blue"
              type="is-info is-light" 
              is-disabled
              is-enabled
            >
              <span>
                {{ `${submission.topic.posts_aggregate.aggregate.count}` }}
                {{ "Comment" | pluralize(submission.topic.posts_aggregate.aggregate.count) }}
              </span>
            </ClickableTag>
            <b-dropdown v-if="isEllipsesEnabled" ref="dropdown" aria-role="list" position="is-top-left">
              <template #trigger>
                <b-button type="is-ghost is-small has-text-grey-dark" icon-left="ellipsis-h" />
              </template>
              <!-- allow -->
              <b-dropdown-item v-if="canShowEllipsesAllow" class="p-0 has-text-centered">
                <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllowClicked" />
                <b-button v-else type="is-ghost" label="Allowed" disabled />
              </b-dropdown-item>
              <!-- delete -->
              <b-dropdown-item v-if="canShowEllipsesDelete" class="p-0 has-text-centered">
                <b-button type="is-ghost" label="Delete" @click="onDeleteClicked" />
              </b-dropdown-item>
              <!-- edit -->
              <b-dropdown-item v-if="canShowEllipsesEdit" class="p-0 has-text-centered">
                <b-button type="is-ghost" label="Edit" @click="onEditClicked" />
              </b-dropdown-item>
              <!-- report -->
              <b-dropdown-item v-if="canShowEllipsesReport" class="p-0 has-text-centered">
                <b-button type="is-ghost" label="Report" @click="onReportClicked" />
              </b-dropdown-item>
            </b-dropdown>
          </b-taglist>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import submissionCardViewHeader from "@/components/submissionCard/SubmissionCardViewHeader.js";
import TipTap from "@/components/forms/TipTap.vue";
import UserPill from "@/components/common/UserPill.vue";
import InlineDate from "@/components/common/InlineDate.vue";
import ClickableTag from "@/components/common/ClickableTag.vue";

export default {
  name: "SubmissionCardViewHeaderDesktop",
  mixins: [submissionCardViewHeader],
  components: {
    TipTap,
    UserPill,
    InlineDate,
    ClickableTag
  }
};
</script>
