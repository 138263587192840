import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  split
  //ApolloError
} from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";

import { getInstance as getAuthInstance } from "./auth";
import build_info from "../build-info.json";
import { Logger, DEBUG } from "@/plugins/logging.js";

const $log = new Logger("Apollo", { level: DEBUG });

// HTTP connection to the API (use absolute URL including graphql endpoint)
const httpLink = new HttpLink({
  uri: window.config.GRAPHQL_URL
});

const wsLink = new WebSocketLink({
  uri: window.config.GRAPHQL_WS_ENDPOINT,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const accessToken = await getAuthInstance().getTokenSilently();
      return {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : ""
        }
      };
    }
  }
});

const cmsHttpLink = new HttpLink({
  uri: window.config.GRAPHCMS_URL
});

const debugLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime();

  return forward(operation).map(result => {
    const ellapsed = new Date().getTime() - startTime;
    $log.apollo(operation, ellapsed, result);
    return result;
  });
});

const errLink = onError(err => {
  $log.error(err);
});

const authLink = setContext((req, { headers }) => {
  // $log.info("graphqlrequest", req);
  const inst = getAuthInstance();
  if (!inst.loading && inst.isAuthenticated) {
    if (req.operationName === "LoginByToken" || req.operationName === "LoginByPassword") {
      return { headers: { headers } };
    } else {
      return inst
        .getTokenSilently()
        .then(accessToken => {
          if (!accessToken) {
            return { headers: { headers } };
          }
          return {
            headers: {
              ...headers,
              authorization: accessToken ? `Bearer ${accessToken}` : ""
            }
          };
        })
        .catch(e => {
          $log.error("couldn't get accesstoken", e, "logging you out");
          inst.logout();
        });
    }
  }
});

const splitter = split(
  ({ query }) => {
    const def = getMainDefinition(query);
    return def.kind === "OperationDefinition" && def.operation === "subscription";
  },
  wsLink,
  httpLink
);

import queryTypePolicy from "@/models/queryTypePolicy.js";

const cache = new InMemoryCache({
  typePolicies: {
    Query: queryTypePolicy
  }
});

export const hasuraClient = new ApolloClient({
  link: debugLink
    .concat(errLink)
    .concat(authLink)
    .concat(splitter),
  cache,
  name: "vue-app",
  version: build_info ? build_info.BUILD_ID : "DEV"
});

export const graphcmsClient = new ApolloClient({
  link: cmsHttpLink,
  cache: new InMemoryCache(),
  name: "graphcms-client",
  version: build_info ? build_info.BUILD_ID : "DEV"
});
