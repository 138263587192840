import { required } from "vuelidate/lib/validators";
import { CREATE_PROMPT, UPDATE_PROMPT, GET_PROMPT_BY_DRAFT } from "@/models/challenges/operations.gql";

const nakedPromptCardEdit = {
  props: {
    prompt: {
      type: Object,
      default: null
    }
  },
  validations: {
    form: {
      // title: {
      //   required
      // },
      body: {
        required
      }
    }
  },
  data() {
    return {
      form: {
        id: null,
        title: "",
        body: null
      },
      draft: null
    };
  },
  created() {
    if (this.prompt) {
      this.form.id = this.prompt.id;
      this.form.title = this.prompt.title;
      this.form.body = Object.assign({}, this.prompt.body);
    }
  },
  methods: {
    onCancel() {
      if (this.prompt) {
        this.form = {
          ...this.prompt
        };
      }
      this.$emit("cancelled");
    },
    onDraft(body) {
      if (!this.prompt) {
        //only save NEW prompts as drafts
        Object.assign(this.form, { body });

        if (this.form.id) {
          this.onUpdate(true);
        } else {
          this.onCreate(true);
        }
      }
    },
    onConfirm(body) {
      Object.assign(this.form, { body });

      if (this.form.id) {
        this.onUpdate(false);
      } else {
        this.onCreate(false);
      }
    },
    onCreate(isDraft) {
      const { title, body } = this.form;

      this.$apollo
        .mutate({
          mutation: CREATE_PROMPT,
          variables: {
            prompt: {
              title,
              body,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
            if (isDraft) {
              this.form.id = result.id;
            }          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            this.$emit("created", result);
            this.isEditing = false;
            this.$buefy.snackbar.open({
              message: result.title ? `Prompt ${result.title} created` : "Prompt created",
              type: "is-success"
            });
          }
        });
    },
    onUpdate(isDraft) {
      const { id, title, body } = this.form;
      const wasDraft = this.prompt ? this.prompt.is_draft : true;
      this.$apollo
        .mutate({
          mutation: UPDATE_PROMPT,
          variables: {
            prompt_id: this.form.id,
            changes: {
              title,
              body,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            if (wasDraft) {
              this.$emit("created", result);
            } else {
              this.$emit("updated", result);
            }
            this.isEditing = false;
            this.$buefy.snackbar.open({
              message: result.title ? `Prompt ${result.title} updated` : "Prompt updated",
              type: "is-success"
            });
          }
        });
    }
  },
  apollo: {
    draft: {
      query: GET_PROMPT_BY_DRAFT,
      variables() {
        return {
          author_id: this.$auth.user.id
        };
      },
      update({ drafts }) {
        const draft = drafts ? drafts[0] || null : null;
        if (draft && !this.form.id) {
          this.form.id = draft.id;
          this.form.title = draft.title;
          this.form.body = Object.assign({}, draft.body);
        }
        return draft;
      }
    }
  }
}

export default nakedPromptCardEdit;