<template>
  <div class="event-listing">
    <DateSquare
      :d="toLocalDate(event.starts_at || event.scheduled_starts_at)"
      :is-featured="event.template.featured_at !== null"
      :is-cancelled="event.template.cancelled_by_user_at !== null"
    />
    <div class="event-info"
      :class="{
        cancelled: event.template.cancelled_by_user_at !== null
      }"
    >
      <div class="event-title">
        <h2 :class="{ 'featured': event.template.featured_at !== null }">
          <span>{{ event.template | format_title | truncate_render }}</span>
          <badge
            data-type="featured"
            v-if="event.template.featured_at !== null"
            icon="flag"
            label="Featured"
          />
          <badge
            v-if="durationName === 'extended'"
            data-type="extended"
            icon="arrows-alt-h"
            label="Extended"
          />
          <badge
            v-if="durationName === 'marathon'"
            data-type="marathon"
            icon="running"
            label="Marathon"
          />
        </h2>
      </div>
      <h6 class="has-text-grey600">
        Hosted By
        <UserPill
          v-for="subscription in event.template.organizers"
          :key="subscription.user.id"
          :user="subscription.user"
          class="pill"
        />
      </h6>
    </div>

    <!-- right side -->
    <div class="actions">
      <div class="social buttons">
        <div class="share-button">
          <CopyUrl
            v-if="event.template.cancelled_by_user_at === null"
            class="mr-1"
            size="10px"
            type="has-text-info"
            :sharable-url="seriesUrl"
            :title="event.template | format_title | truncate_render"
          />
        </div>
        <b-tag v-if="event.template.cancelled_by_user_at" class="is-danger">
          CANCELLED
        </b-tag>
        <Pill
          v-else-if="event.venue.is_virtual &&
            !event.template.original_venue_was_virtual"
          class="is-success"
          icon="laptop"
          icon-pack="fas"
        >
          Currently Meets Online
        </Pill>
        <b-tag type="is-success is-light has-text-grey-dark">
          <b-icon icon="user-check" />
          <span>
            {{ event.template.members_aggregate.aggregate.count }}
          </span>
        </b-tag>
      </div>
      
      <div class="admin buttons">
        <FlyoutButton
          v-if="
            $auth.isAdmin &&
              event.template.original_venue_was_virtual &&
              event.template.cancelled_by_user_at === null
          "
          ref="featured_flyout"
          position="is-bottom-left"
          trigger-button-size="10px"
          trigger-button-type="is-success is-outlined"
          :trigger-button-icon="null"
          :trigger-button-text="
            event.template.featured_at ? 'unFeature' : 'Feature'
          "
        >
          <template #default>
            <!-- Featured Status -->
            <FeaturedControl
              :event-template="event.template"
              @undo="closeFlyout('featured_flyout')"
              @confirm="closeFlyout('featured_flyout')"
            />
          </template>
        </FlyoutButton>
        <SeriesJoinLeave
          v-if="event.template.cancelled_by_user_at === null"
          v-model="event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventSeriesHeaderView from "@/components/eventSeries/EventSeriesHeaderView.js";
import DateSquare from "@/components/dates/DateSquare.vue";
import FeaturedControl from "@/components/events/FeaturedControl.vue";
import FlyoutButton from "@/components/common/FlyoutButton.vue";
import Pill from "@/components/common/Pill.vue";
import SeriesJoinLeave from "@/components/events/SeriesJoinLeave.vue";
import UserPill from "@/components/common/UserPill.vue";
import Badge from "@/components/common/Badge.vue";
import CopyUrl from "@/components/common/CopyUrl.vue";

export default {
  name: "EventSeriesHeaderViewDesktop",
  mixins: [eventSeriesHeaderView],
  components: {
    DateSquare,
    FeaturedControl,
    FlyoutButton,
    Pill,
    SeriesJoinLeave,
    UserPill,
    Badge,
    CopyUrl
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.event-listing {
  @include touch {
    align-items: stretch;
  }

  h6 {
    @include tablet {
      margin-bottom: .5rem;
    }
    @include desktop {
      font-size: .875rem;
    }
    .pill {
      top: 8px;
    }
  }

  .actions {
    @include tablet {
      .buttons {
        gap: 0.5rem;
        justify-content: flex-end;
        &.social {
          margin-bottom: 1.25rem;
        }
        &.admin {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "../../scss/mixins";
.event-listing {
  .actions {
    @include tablet {
      .buttons {
        &.admin {
          flex-wrap: nowrap;
          .button {
            @include tablet {
              height: 40px;
              margin: 0;
              font-size: 16px;
              width: 100%;
            }
          }
        }
      }
      .dropdown {
        margin: 0;
      }
    }
  }
}
</style>