<template>
  <div v-if="post" class="is-flex is-align-items-center is-justify-content-flex-end">
    <b-taglist class="is-justify-content-flex-end">
      <!-- Like -->
      <b-tooltip :active="!$auth.isAuthenticated" label="You must sign up to upvote">
        <ClickableTag
          class="mb-0 mr-1"
          colorClass="yellow"
          type="is-warning is-light"
          icon-left="thumbs-up"
          :is-disabled="!$auth.isAuthenticated || isDeleted"
          :is-enabled="state.upvoted"
          @click="onUpvote"
        >
          <span v-show="post.upvotes.aggregate.count > 0">
            {{ post.upvotes.aggregate.count }}
          </span>
        </ClickableTag>
      </b-tooltip>

      <!-- Show Replies -->
      <b-tooltip v-if="post.children_aggregate.aggregate.count > 0" :active="!$auth.isAuthenticated" label="You must sign up to see replies">
        <ClickableTag
          v-if="depth < maxDepth"
          class="mb-0 mr-1"
          colorClass="blue"
          type="is-info is-light"
          icon-left="comment-alt"
          :is-disabled="!$auth.isAuthenticated || post.children_aggregate.aggregate.count < 1"
          :is-enabled="state.isShowingReplies"
          @click="toggleShowReplies"
        >
          <span>
            {{ post.children_aggregate.aggregate.count }}
          </span>
        </ClickableTag>
      </b-tooltip>

      <!-- Reply -->
      <b-tooltip :active="!$auth.isAuthenticated" label="You must sign up to reply">
        <ClickableTag
          v-if="depth < maxDepth"
          class="mb-0 mr-1"
          colorClass="green"
          type="is-success is-light"
          icon-left="comment-alt-plus"
          :is-disabled="!$auth.isAuthenticated || isDeleted"
          :is-enabled="state.isShowingReplyForm"
          @click="toggleReplyForm"
        >
        </ClickableTag>
      </b-tooltip>

      <!-- Context Menu -->
      <b-dropdown ref="dropdown" aria-role="list" position="is-top-left">
        <template #trigger>
          <b-button
            v-if="!state.isEditing"
            style="border: none;"
            type="is-ghost is-small has-text-grey-dark"
            icon-left="ellipsis-h"
          />
        </template>
        <!-- allow -->
          <b-dropdown-item v-if="$auth.isAdmin && isReported" class="p-0 has-text-centered">
            <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllow" />
            <b-button v-else type="is-ghost" label="Allowed" disabled />
          </b-dropdown-item>
        <!-- delete -->
        <b-dropdown-item v-if="userCanDelete" class="p-0 mb-1 has-text-centered">
          <b-button type="is-ghost" label="Delete" @click="confirmDelete"/>
        </b-dropdown-item>
        <!-- edit -->
        <b-dropdown-item v-if="userCanEdit" class="p-0 mb-1 has-text-centered">
          <b-button type="is-ghost" label="Edit" @click="toggleEditing" />
        </b-dropdown-item>
        <!-- restore -->
        <b-dropdown-item v-if="userCanRestore" class="p-0 has-text-centered">
          <b-button type="is-ghost" label="Restore" @click="onRestore" />
        </b-dropdown-item>
        <!-- report -->
        <b-dropdown-item v-if="!isDeleted" class="p-0 mb-1 has-text-centered">
          <b-button type="is-ghost" label="Report" @click="confirmReport" />
        </b-dropdown-item>
      </b-dropdown>
    </b-taglist>
  </div>
</template>

<script>
import {
  FORUMS_POST_EDIT,
  CREATE_POST_REACTION,
  DELETE_POST_REACTION,
  REPORT_POST
} from "@/models/forums/operations.gql";
import ClickableTag from "@/components/common/ClickableTag.vue";

export default {
  name: "PostCardTags",
  components: {
    ClickableTag
  },
  props: {
    post: {
      // for showing/editing existing posts
      type: Object,
      default: null
    },
    expandReplies: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number,
      default: 0
    },
    maxDepth: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      state: {
        upvoted: false,
        isShowingReplies: this.expandReplies,
        isShowingReplyForm: false,
        isEditing: false
      },
      form: {
        id: null
      }
    };
  },
  computed: {
    isDeleted() {
      if (this.post && this.post.deleted_at) {
        return true;
      }
      return false;
    },
    userCanEdit() {
      return (this.post &&
          !this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.post.author.id) ||
        (!this.isDeleted && (this.$auth.isAdmin || this.$auth.isOrganizer));
    },
    userCanDelete() {
     return (this.post &&
          !this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.post.author.id) ||
        (!this.isDeleted && this.$auth.isAdmin);
    },
    userCanRestore() {
      return (this.post &&
          this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.post.author.id) ||
          (this.isDeleted && this.$auth.isAdmin);
    },
    isReported() {
      if (this.post !== null && this.post.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.post !== null && this.post.allowed_at !== null) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.post) {
      this.state.upvoted = this.post.current_user_has_reacted;
      this.form.id = this.post.id;
    } else {
      this.state.isEditing = true;
    }
  },
  methods: {
    toggleEditing() {
      this.state.isEditing = !this.state.isEditing;
      this.$emit("edit");
    },
    toggleReplyForm() {
      this.state.isShowingReplyForm = !this.state.isShowingReplyForm;
      this.$emit("reply");
    },
    toggleShowReplies() {
      this.state.isShowingReplies = !this.state.isShowingReplies;
      this.$emit("replies");
    },
    onUpvote() {
      //reaction types (string) 'upvote', 'funny'
      if (this.state.upvoted === false) {
        this.$apollo
          .mutate({
            mutation: CREATE_POST_REACTION,
            variables: {
              reaction: {
                post_id: this.post.id,
                reaction_type: "upvote" // TODO: get these from the db
              }
            },
            update: cache => {
              cache.evict(cache.identify(this.post));
              cache.gc();
            }
          })
          .then(() => {
            this.state.upvoted = true;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: DELETE_POST_REACTION,
            variables: {
              post_id: this.post.id,
              reaction_type: "upvote",
              author_id: this.$auth.user.id
            },
            update: cache => {
              cache.evict(cache.identify(this.post));
              cache.gc();
            }
          })
          .then(() => {
            this.state.upvoted = false;
          });
      }
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to delete this post?",
        onConfirm: () => {
          this.onDelete();
        }
      });
    },
    onDelete() {
      const { id } = this.form;

      this.$apollo
        .mutate({
          mutation: FORUMS_POST_EDIT,
          variables: {
            post_id: id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$emit("deleted", result);
          this.$buefy.snackbar.open({
            message: `Post deleted`,
            type: "is-success"
          });
        });
    },
    onRestore() {
      const { id } = this.form;

      this.$log.debug("Restore Post", this.form);
      this.$apollo
        .mutate({
          mutation: FORUMS_POST_EDIT,
          variables: {
            post_id: id,
            changes: {
              deleted_at: null
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$emit("restored", result);
          this.form = {
            ...result
          };
          this.$buefy.snackbar.open({
            message: `Post restored`,
            type: "is-success"
          });
        });
    },
    confirmReport() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to report this post?",
        onConfirm: () => {
          this.onReport();
        }
      });
    },
    onReport() {
      this.$log.debug("Report Post");
      //posts don't have their own routes, so the reported route is the thread they originated from
      //we throw the post_id into the querystring as a hack for now since our unique constraint is on the reported_route
      //this is necessary (good enough, rather) until we upgrade to postgres 15, at which time our constraint can cover nullable columns
      this.$apollo
        .mutate({
          mutation: REPORT_POST,
          variables: {
            post_id: this.post.id,
            reporting_user_id: this.$auth.user.id,
            reported_route: this.$route.fullPath + '?post_id=' + this.post.id
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$emit("reported", result);
          this.$buefy.snackbar.open({
            message: `Post Reported`,
            type: "is-success"
          });
        });
    },
    onAllow() {
      this.$log.debug("Allow Post");
      this.$apollo
        .mutate({
          mutation: FORUMS_POST_EDIT,
          variables: {
            post_id: this.post.id,
            changes: {
              allowed_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$emit("allowed", result);
          this.$buefy.snackbar.open({
            message: `Post allowed`,
            type: "is-success"
          });
        });
    }
  }
};
</script>
