<template>
  <div class="card">
    <div v-if="prompt !== null" class="challenge-order">
      <span v-if="challengeOrder !== null">
        {{ challengeOrder }}
      </span>
    </div>
    <div class="card-info">
      <div class="card-details">
        <b-field
          :type="{ 'is-danger': $v.form.title.$invalid }"
          :message="{ 'Title is required': !$v.form.title.required }"
          label="Prompt Title (required, be creative)"
        >
          <b-input v-model.trim="form.title" placeholder="My Amazing Prompt" />
        </b-field>
        <TipTap
          v-model="form.body"
          :show-edit-controls="true"
          :editable="true"
          :confirm-enabled="() => !$v.form.$invalid"
          :type="$v.form.body.required ? '' : 'is-danger'"
          :message="$v.form.body.required ? '' : 'Prompt body is required.'"
          @edit-cancelled="onCancel"
          @edit-confirmed="onConfirm"
          @input="onDraft"
        >
          <template #confirm>
            <span v-if="prompt !== null">
              Confirm Edit
            </span>
            <span v-else>
              Create Prompt
            </span>
          </template>
        </TipTap>
      </div>
    </div>
  </div>
</template>

<script>
import challengePromptCardEdit from "@/components/challengePromptCard/ChallengePromptCardEdit.js";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "ChallengePromptCardEdit",
  components: {
    TipTap
  },
  mixins: [challengePromptCardEdit],
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    // this should only be set when prompt is null (create prompt inside a challenge)
    challenge: {
      type: Object,
      default: null
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    qaIsAvailable: {
      type: String,
      default: ""
    },
    qaIsAccepted: {
      type: String,
      default: ""
    },
    qaHasSubmitted: {
      type: String,
      default: ""
    }
  },
  methods: {
    onCreated(result) {
      this.$emit("created", result);
    },
    onUpdated(result) {
      this.$emit("updated", result);
    },
    onCancelled() {
      this.$emit("cancelled");
    }    
  }
};
</script>