import { getInstance } from "./index";
import { Logger, INFO } from "@/plugins/logging.js";

const $log = new Logger("GUARD>ACCOUNT_FINISHED", { level: INFO });

export default (to, _, next) => {
  $log.debug("checking for finished account");

  if (to.name == "logout") {
    return next();
  }

  if (to.name == "complete_account") {
    return next();
  }

  if (to.name == "dashboard_profile") {
    return next();
  }

  if (to.name == "privacy_policy") {
    return next();
  }

  if (to.name == "legal") {
    return next();
  }

  if (to.name == "code_of_conduct") {
    return next();
  }

  if (to.name == "tos") {
    return next();
  }

  const auth = getInstance();

  const fn = () => {
    if (auth.isAuthenticated && !auth.user.username) {
      next({ name: "complete_account", query: { targetUrl: to.fullPath } });
      return;
    } else {
      return next();
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!auth.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  auth.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
