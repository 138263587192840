var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Section',[_c('EventSeriesCoordinator',{attrs:{"loading":_vm.loading,"event":_vm.event,"is-editing":_vm.isEditingSeries},on:{"updated":_vm.onSeriesUpdated,"edit-cancelled":_vm.onSeriesEditCancelled}}),(_vm.event)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEditingSeries),expression:"!isEditingSeries"}],staticClass:"event-details"},[_c('div',{staticClass:"events-container"},[(_vm.event.template.cancelled_by_user_at === null)?_c('SeriesJoinLeave',{staticClass:"is-hidden-tablet",attrs:{"value":_vm.event}}):_vm._e(),_c('div',{staticClass:"button-group mt-2 mb-2"},[_c('EventNavLink',{staticClass:"nav-button prev-button",class:{
            'elevation-1': _vm.suwMobile,
            hidden: !_vm.hasPrevEvent
          },attrs:{"event":_vm.event,"is-prev":true}}),(_vm.canDisplayMiddleButtons)?_c('div',{staticClass:"is-flex edit-cancel-buttons"},[_c('b-button',{staticClass:"button has-background-white has-text-info elevation-1",attrs:{"expanded":_vm.suwMobile},on:{"click":_vm.startEditing}},[_vm._v(" Edit Series ")]),(_vm.canDisplayMiddleButtons)?_c('CancelCard',{staticClass:"cancel-button",attrs:{"event":_vm.event,"cancel-type":"series","expanded":_vm.suwMobile}}):_vm._e()],1):_vm._e(),_c('EventNavLink',{staticClass:"nav-button next-button",class:{
            'elevation-1': _vm.suwMobile,
            hidden: !_vm.hasNextEvent
          },attrs:{"event":_vm.event,"is-prev":false}})],1)],1),_c('EventBody',{class:{
        'p-3': !_vm.suwMobile,
        'elevation-1': !_vm.suwMobile,
        'boxed': !_vm.suwMobile
      },attrs:{"event":_vm.event},on:{"updated":_vm.onEventUpdated}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }