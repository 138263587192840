<template>
  <b-tooltip type="is-primary" position="is-bottom">
    <template #content class="hover-info">
      @{{ user.username }} joined {{ user.created_at | formatFromNow }}
    </template>

    <div
      class="has-text-weight-semibold user-pill is-flex is-align-items-center has-text-grey700"
      :class="{ [`elevation-${elevation}`]: elevation }"
      @click="onUserPillClick"
    >
      <Avatar :size="size" :seed-string="user | user_name" :color="user.avatar_color" />
      <span>{{ user | user_name }}</span>
    </div>
  </b-tooltip>
</template>

<script>
import Avatar from "@/components/common/Avatar.vue";

export default {
  name: "UserPill",
  components: {
    Avatar
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      // gets passed through to Avatar
      type: String,
      default: "small",
      validator: v => ["small", "medium", "large", "huge"].includes(v)
    },
    elevation: {
      type: Number,
      default: 1
    },
    linkToProfile: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onUserPillClick() {
      if (this.linkToProfile) {
        this.$router.push({
          name: "user_profile",
          params: {
            username: this.user.username
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.user-pill {
  border-radius: 16px;
  padding: 0.25rem;
  padding-right: 0.75rem;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  background-color: #f5f5f5;
}
</style>
