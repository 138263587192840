<template>
  <div>
    <div>
      <TipTap v-model="form.body" class="is-size-5" :is-dangerous="isReported && !isAllowed" />
    </div>
    <div class="mt-2 is-center is-flex is-justify-content-space-between">
      <div class="pill is-flex is-flex-direction-column is-justify-content-space-between is-align-items-start">
        <UserPill :user="prompt.author" />
        <InlineDate :d="prompt.created_at" class="ml-1 has-text-grey400" />
      </div>
      <div class="is-align-self-flex-end">
        <b-taglist>
          <b-dropdown v-if="isEllipsesEnabled" ref="dropdown" aria-role="list" position="is-top-left">
            <template #trigger>
              <b-button type="is-ghost is-small has-text-grey-dark" icon-left="ellipsis-h" />
            </template>
            <!-- allow -->
            <b-dropdown-item v-if="canShowEllipsesAllow" class="p-0 has-text-centered">
              <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllowClick" />
              <b-button v-else type="is-ghost" label="Allowed" disabled />
            </b-dropdown-item>
            <!-- delete -->
            <b-dropdown-item v-if="canShowEllipsesDelete" class="p-0 has-text-centered">
              <b-button type="is-ghost" label="Delete" @click="onDeleteClick" />
            </b-dropdown-item>
          </b-dropdown>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
import nakedPromptCardViewHeader from "@/components/nakedPromptCard/NakedPromptCardViewHeader.js";
import UserPill from "@/components/common/UserPill.vue";
import InlineDate from "@/components/common/InlineDate.vue";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "NakedPromptCardViewHeaderMobile",
  mixins: [nakedPromptCardViewHeader],
  components: {
    UserPill,
    InlineDate,
    TipTap
  },
  props: {
    prompt: {
      type: Object,
      default: null
    }
  }
};
</script>
