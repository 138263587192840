<template>
  <span>
    <span v-if="kind === 'members'">
      <Accordian v-if="members.length > 0" class="mt-2">
        <template #question>
          <figure>
            <Avatar
              v-for="record in members"
              :key="record.user_id"
              :seed-string="record.user | user_name"
              :color="record.user.avatar_color"
              class="stacked"
              size="large"
            />
          </figure>
        </template>
        <template #answer>
          <Participant
            v-for="record in members"
            :key="record.user_id"
            :subscription="record"
            :event="event"
            :kind="kind"
          />
        </template>
      </Accordian>
      <EmptyState v-else>
        No Attendees yet
      </EmptyState>
    </span>

    <span v-else class="mt-2">
      <Accordian v-if="attendees.length > 0">
        <template #question>
          <figure>
            <Avatar
              v-for="record in attendees"
              :key="record.user_id"
              :seed-string="record.user | user_name"
              :color="record.user.avatar_color"
              class="stacked"
              size="large"
            />
          </figure>
        </template>
        <template #answer>
          <div>
            <Participant
              v-for="record in attendees"
              :key="record.user_id"
              :subscription="record"
              :event="event"
              :kind="kind"
            />
          </div>
        </template>
      </Accordian>
      <EmptyState v-else>
        No Attendees yet
      </EmptyState>
    </span>
  </span>
</template>

<script>
import Accordian from "@/components/resources/Accordian.vue";
import Participant from "@/components/events/Participant.vue";
import { MEMBER_LIST } from "@/models/series/operations.gql";
import { ATTENDEE_LIST } from "@/models/events/operations.gql";
import Avatar from "@/components/common/Avatar.vue";
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "Participants",
  components: {
    Accordian,
    Participant,
    Avatar,
    EmptyState
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    kind: {
      type: String,
      default: "members"
    }
  },
  data() {
    return {
      members: [],
      attendees: []
    };
  },
  apollo: {
    members: {
      deep: true,
      query: MEMBER_LIST,
      variables() {
        return {
          event_template_id: parseInt(this.event.template.id),
          offset: this.offset,
          limit: this.limit
        };
      },
      skip() {
        return !this.event || !this.event.template;
      }
    },
    attendees: {
      query: ATTENDEE_LIST,
      variables() {
        return {
          event_id: parseInt(this.event.id),
          offset: this.offset,
          limit: this.limit
        };
      },
      skip() {
        return !this.event || !this.event.template;
      }
    }
  },
  methods: {
    refetch() {
      this.$log.info("refetching");
      this.members = [];
      this.$apollo.queries.members.refresh();
    }
  }
};
</script>
