<template>
  <div>
    <div class="events-container">
      <div
        class="map-container"
        :class="{
          'elevation-2': !suwMobile
        }"
      >
        <GooglePlaces
          v-if="map"
          cities-only
          :class="{
            'mb-2': !suwMobile
          }"
          @updated:place="onPlaceUpdated"
          @recenter="onRecenter"
        />
        <div class="map">
          <gmaps-map
            :options="mapOptions"
            @mounted="onMapCreated"
            @idle="onMapIdle"
            @click="activeSeriesId = null"
          >
            <gmaps-cluster
              v-if="clusterItems"
              :options="clusterOptions"
              :items="clusterItems"
              @click="onPinClicked"
            />
          </gmaps-map>
        </div>
      </div>
      <slot name="underMap" :clear="clearSelection" :active-series-id="activeSeriesId" />
    </div>

    <div>
      <slot v-if="fakeLoading || $apollo.queries.physicalEventList.loading" name="Loading">
        <div v-for="i in maxEvents" :key="i" class="is-flex line-item">
          <div style="flex-grow: 0;" class="my-2 ml-2">
            <b-skeleton width="120px" height="120px" />
          </div>
          <div style="width: 100%;" class="pl-2 pt-4">
            <b-skeleton size="is-large" :width="rand(40, 70)" />
            <b-skeleton :width="rand(60, 80)" />
            <b-skeleton :width="rand(20, 35)" />
          </div>
        </div>
      </slot>

      <slot v-else-if="totalPhysicalEvents > 0">
        <EventSeriesCard
          v-for="event in displayedPhysicalEvents"
          :key="event.id"
          :is-active="activeSeriesId === event.series.id"
          :event="event"
          class="mt-2"
        />
        <p
          v-if="
            apportionedViewable > 0 && currentlyShowing < totalPhysicalEvents && !activeSeriesId
          "
          class="is-flex is-justify-content-space-around m-3"
        >
          <a
            class="has-text-grey-dark has-text-weight-bold is-size-5"
            @click="currentlyShowing += apportionedViewable"
          >
            Load More
          </a>
        </p>
      </slot>

      <slot v-else name="empty">
        <EmptyState>
          No events found.
        </EmptyState>
      </slot>
    </div>
  </div>
</template>

<script>
/* global google */
import { EVENT_SERIES_LIST_PHYSICAL } from "@/models/series/operations.gql";
import { formatUTC } from "@/dates.js";
import GooglePlaces from "@/components/forms/GooglePlaces.vue";
import EventSeriesCard from "@/components/eventSeries/EventSeriesCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import { format_title } from "@/filters.js";
import { DEFAULT_LOCATION, getGPS } from "@/location.js";
import { gmapsMap, gmapsCluster } from "x5-gmaps";

export default {
  name: "EventSeriesListPhysical",
  components: {
    GooglePlaces,
    EmptyState,
    EventSeriesCard,
    gmapsMap,
    gmapsCluster
  },
  props: {
    where: {
      type: Object,
      default: null
    },
    orderBy: {
      type: Array,
      default: () => [{ starts_at: "asc" }]
    },
    maxEvents: {
      type: Number,
      default: 100
    },
    //Fetch all of the events (up to maxEvents), but only render this many at a time
    apportionedViewable: {
      type: Number,
      default: 0
    },
    lastSearched: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      physicalEventList: [],
      totalPhysicalEvents: 0,
      offset: 0,
      userLocation: null,
      searchBoundsPolygon: null,
      currentlyShowing: this.apportionedViewable,
      fakeLoading: false,
      map: null, // will be the google.maps instance on mount
      activeSeriesId: null,
      // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
      mapOptions: {
        center: DEFAULT_LOCATION,
        zoom: 2,
        mapId: "8742e9a476aaca4b"
      },
      mapHasIdled: false
    };
  },
  computed: {
    shouldSkipBoundsSearch() {
      if (!this.mapHasIdled) {
        return true;
      }
      if (!this.searchBoundsPolygon) {
        return true;
      }
      return false;
    },
    clusterOptions() {
      return {
        minZoom: -1,
        maxZoom: -1
      };
    },
    clusterItems() {
      let items = [];
      if (this.physicalEventList.length > 0) {
        this.physicalEventList.forEach(event => {
          //this.$log.info("making cluster item based on series", s);
          items.push({
            id: String(event.series.id), // must be a string
            icon: this.getVenueIcon(event.series),
            title: `${format_title(event)}` || `series-${event.series.id}`,
            // label: {
            //   text: formatUTC(s.upcoming_events[0].starts_at, "MMM do h:ma"),
            //   className: "elevation-3",
            //   color: "#353535",
            //   fontWeight: "700",
            //   fontSize: "24px",
            //   fontFamily: "Roboto",
            // },
            lat: Number(event.series.venue.location.coordinates[1]),
            lng: Number(event.series.venue.location.coordinates[0])
          });
        });
      }
      return items;
    },
    since() {
      return this.where.starts_at ? this.where.starts_at._gte : "now";
    },
    displayedPhysicalEvents() {
      let filteredEvents =
        this.activeSeriesId !== null
          ? this.physicalEventList.filter(event => event.series.id === this.activeSeriesId)
          : this.apportionedViewable > 0
          ? this.physicalEventList.slice(0, this.currentlyShowing)
          : this.physicalEventList;
      return filteredEvents;
    }
  },
  apollo: {
    physicalEventList: {
      query: EVENT_SERIES_LIST_PHYSICAL,
      variables() {
        let where = {
          ...this.where, // let the composing view control most of the predicates
          template: {
            ...this.where.template,
            // but since we own the map, we add the polygon predicate
            original_venue_location: {
              _cast: {
                geometry: { _st_within: this.gisPolygon() }
              }
            }
          }
        };
        return {
          where: where,
          orderBy: this.orderBy,
          since: this.since
        };
      },
      update({ events }) {
        const physicalEvents = events.map(queryResult => queryResult.event);
        // using physicalEventsCount rather than events_aggregate count because we want
        // the series count, not count of all upcoming events
        const physicalEventsCount = physicalEvents.length;
        this.totalPhysicalEvents = physicalEventsCount;
        this.$emit("results:updated", physicalEvents, physicalEventsCount);
        return physicalEvents;
      },
      skip() {
        return this.shouldSkipBoundsSearch;
      }
    }
  },
  mounted() {
    getGPS(position => {
      this.$log.info("user location known", position);
      if (position !== null) {
        this.userLocation = position;
        this.mapOptions.center = this.userLocation;
        this.mapOptions.zoom = 10;
        if (this.lastSearched !== null && this.lastSearched.lat) {
          this.mapOptions.center = {
            lat: parseFloat(this.lastSearched.lat),
            lng: parseFloat(this.lastSearched.lng)
          };
        }
      }
    });
  },
  methods: {
    formatUTC,
    rand(minimum, maximum) {
      const val = minimum + Math.random() * (minimum - maximum);
      return `${val}%`;
    },
    gisPolygon() {
      let sbp = (this.searchBoundsPolygon || []).map(coord => [coord.lng, coord.lat]);
      sbp = sbp.concat([sbp[0]]);
      return {
        type: "Polygon",
        crs: { type: "name", properties: { name: "EPSG:4326" } },
        coordinates: [sbp]
      };
    },
    onMapCreated(map) {
      this.map = map;
    },
    onMapIdle() {
      const rawBounds = this.map.getBounds();
      const bounds = {
        sw: rawBounds.getSouthWest(),
        ne: rawBounds.getNorthEast()
      };
      if (bounds) {
        const sw = { lat: bounds.sw.lat(), lng: bounds.sw.lng() };
        const nw = { lat: bounds.ne.lat(), lng: bounds.sw.lng() };
        const se = { lat: bounds.sw.lat(), lng: bounds.ne.lng() };
        const ne = { lat: bounds.ne.lat(), lng: bounds.ne.lng() };
        this.searchBoundsPolygon = [sw, nw, ne, se];
      }
      this.mapHasIdled = true;
    },
    onPinClicked(event_template_id) {
      this.activeSeriesId = parseInt(event_template_id);
    },
    clearSelection() {
      // this needs to be a method for use in the undermap slot prop
      this.activeSeriesId = null;
    },
    onPlaceUpdated(place) {
      if (!place.name) {
        return;
      }
      // user chose a diff place in the autocomplete, pretend it's their userLocation
      this.mapOptions.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.$emit("place:updated", place);
    },
    onRecenter(coords) {
      if (this.map && coords) {
        this.map.panTo(coords);
      }
    },
    getVenueIcon(series) {
      const aspectRatio = 69 / 52; // All svgs in the /img/map folder follow this
      const iconWidth = 26;
      const iconHeight = iconWidth * aspectRatio;
      let iconUrl = "/img/map/SUAW_Yellow1.svg";

      if (this.activeSeriesId && this.activeSeriesId !== series.id) {
        iconUrl = "/img/map/SUAW_Gray3.svg";
      }

      return {
        url: iconUrl,
        fillOpacity: 1.0,
        strokeWeight: 0,
        rotation: 0,
        scaledSize: new google.maps.Size(iconWidth, iconHeight),
        //scale: 0.05,
        anchor: new google.maps.Point(iconWidth / 2, iconHeight)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  height: 500px;
  max-height: 500px;
  @include mobile {
    height: 300px;
  }
  .gmaps-cluster-center {
    $aspect-ratio: 69 / 52;
    $icon-width: 52px;
    $icon-height: $aspect-ratio * $icon-width;

    // @extend %elevation-3;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    height: $icon-height;
    width: $icon-width;
    font-size: 18px;
    font-weight: 700;
    color: $grey100;
    background-image: url("/img/map/SUAW_Yellow1.svg");

    > span {
      line-height: 2.3; // Beware magic number
    }
  }
}
.map-container {
  background-color: white;
  padding: 1.5rem;
  @include mobile {
    margin: -1px -0.5em 0 -0.5em;
    padding: 10px 0.75em 0.75em 0.75em;
    box-shadow: 0 6px 10px 0 rgba(11, 13, 22, 0.05);
  }
  @include tablet {
    border-radius: 8px;
    border: solid 1px $gold;
  }
}
</style>
