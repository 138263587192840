<template>
  <div>
    <h2>Review Your Single Event Changes...</h2>

    <h4 class="mt-2">Starts At</h4>
    <s v-if="eventStartsAtToBeUpdated">
      {{ event.starts_at || event.scheduled_starts_at | format("MMM do hh:mma z") }}
    </s>
    <p>{{ form.starts_at | format("MMM do hh:mma z") }}</p>

    <h4 class="mt-2">Description</h4>
    <TipTap :value="form.description" />

    <h4 class="mt-2">Host City</h4>
    <s>{{ venueStrikeThru }}</s>
    <p>{{ form.venue.street_address }}</p>

    <div v-if="form.venue.url">
      <h4 class="mt-2">Meeting URL</h4>
      <p>{{ form.venue.url }}</p>
    </div>

    <div v-if="form.venue.meeting_password">
      <h4 class="mt-2">Meeting password or PIN</h4>
      <p>{{ form.venue.meeting_password }}</p>
    </div>

  </div>
</template>

<script>
import { isEqual } from "lodash";
import { toLocalDate } from "@/dates";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "EventReviewVirtual",
  components: {
    TipTap
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    venueStrikeThru() {
      return this.event.venue.street_address !== this.form.venue.street_address
        ? this.event.venue.street_address
        : "";
    },
    eventStartsAtToBeUpdated() {
      // Only update events.starts_at if these conditions apply
      return (this.event.starts_at === null && !isEqual(toLocalDate(this.form.starts_at), toLocalDate(this.event.scheduled_starts_at)))
        || this.event.starts_at !== null && !isEqual(toLocalDate(this.event.starts_at), toLocalDate(this.form.starts_at));
    }
  }
};
</script>
