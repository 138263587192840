<template>
  <div>
    <SubmissionCardViewExpandedDesktop 
      v-if="suwDesktop"
      :submission="submission"
      :prompt="prompt" 
      @upvote="onUpvote"
      @delete="onDelete"
      @report="onReport"
      @allow="onAllow"
      @edit="onEdit"
    />
    <SubmissionCardViewExpandedMobile 
      v-if="!suwDesktop"
      :submission="submission"
      :prompt="prompt" 
      @upvote="onUpvote"
      @delete="onDelete"
      @report="onReport"
      @allow="onAllow"
      @edit="onEdit"
    />
  </div>
</template>

<script>
import SubmissionCardViewExpandedDesktop from "@/components/submissionCard/SubmissionCardViewExpandedDesktop.vue";
import SubmissionCardViewExpandedMobile from "@/components/submissionCard/SubmissionCardViewExpandedMobile.vue";

export default {
  name: "SubmissionCardViewExpanded",
  components: {
    SubmissionCardViewExpandedDesktop,
    SubmissionCardViewExpandedMobile
  },
  props: {
    submission: {
      type: Object,
      default: null
    },
    prompt: {
      type: Object,
      default: null
    }
  },
  methods: {
    onUpvote() {
      this.$emit("upvote");
    },
    onDelete() {
      this.$emit("delete");
    },
    onReport() {
      this.$emit("report");
    },
    onAllow() {
      this.$emit("allow");
    },
    onEdit() {
      this.$emit("edit");
    }
  }
};
</script>
