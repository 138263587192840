import { getInstance } from "./index";
import { Logger, INFO } from "@/plugins/logging.js";
import router from "../router";

const $log = new Logger("GUARD>AUTH", { level: INFO });

export const authGuard = (to, _, next) => {
  $log.debug("protected route: checking that user is authenticated");
  const authService = getInstance();

  const fn = () => {
    $log.debug("user authenticated", authService.isAuthenticated);
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    if (to.name === "login") {
      return next();
    }
    if (to.name === "logout") {
      return next();
    }
    if (to.name === "reset_password_token") {
      return next();
    }
    if (to.name === "complete_account_token") {
      return next();
    }

    // Otherwise, log in
    router.push({
      name: "login",
      query: {
        redirectUrl: to.fullPath
      }
    });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
