<template>
  <div
    class="is-hover-grow"
    :class="{
      'is-active': isActive
    }"
    @click="onSeriesCardClick"
  >
    <div class="events-container">
      <div class="event-listing" :class="{ 'featured': event.series.featured_at !== null }">
        <DateSquare
          :d="toLocalDate(event.starts_at || event.scheduled_starts_at)"
          :is-featured="event.series.featured_at !== null"
          :is-cancelled="event.series.cancelled_by_user_at !== null"
          size="medium"
        />
        <div class="event-info">
          <h2>
            {{ event | format_title | truncate_render }}
            <badge
              v-if="event.series.featured_at !== null && !suwMobile"
              data-type="featured"
              icon="flag"
              label="Featured"
            />
            <badge
              v-if="durationName === 'extended'"
              data-type="extended"
              icon="arrows-alt-h"
              label="Extended"
            />
            <badge
              v-if="durationName === 'marathon'"
              data-type="marathon"
              icon="running"
              label="Marathon"
            />
            <badge
              v-if="seriesIsUpdated"
              data-type="updated"
              label="Updated"
            />
          </h2>
          <h6>
            Hosted by {{ hostUser | user_name }}
          </h6>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import eventSeriesCard from "@/components/eventSeries/EventSeriesCard.js";
import DateSquare from "@/components/dates/DateSquare.vue";
import Badge from "@/components/common/Badge.vue";

export default {
  name: "EventSeriesCardMobile",
  components: {
    DateSquare,
    Badge
  },
  mixins: [eventSeriesCard]
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.event-listing {
  display: flex;
  flex-direction: row;
  background: white;
  border: 2px solid $border;
  border-radius: 0.5em;
  @extend %elevation-1;
  @include mobile {
    padding: vwl(8px);
  }
  @include portrait {
    padding: vwp(8px);
  }
  @include tablet {
    padding: 1.25rem 1.5rem;
  }
  &.is-active {
    background: $warning-light;
  }
  &.featured {
    @include mobile {
      border: solid $gold 2px;
    }
  }

  h6 {
    color: #7a7a7a;
    font-size: .75rem;
    @include mobile {
      font-size: vwl(10px);
      margin-bottom: vwl(4px);
    }
    @include portrait {
      font-size: vwp(10px);
      margin-bottom: vwp(4px);
    }
    .pill {
      display: inline-flex;
      position: relative;
      @include mobile {
        top: vwl(2px);
      }
      @include portrait {
        top: vwp(2px);
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    h6 {
      white-space: nowrap;
    }
  }
}
.date {
  width: 120px;
  height: 120px;
  border-radius: 2px;
  background: $background;
  border: 1px solid $border;
  flex-grow: 0;
  flex-shrink: 0;
}
.tz {
  padding: 2px;
  font-size: 11px;
  background-color: white;
  border-radius: 5px;
  margin-left: 5px;
}
a {
  color: inherit;
}
.badge {
  font-size: 0.5em;
}
</style>
