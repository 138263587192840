import { LIST_TOPIC_POSTS } from "@/models/forums/operations.gql";

const submissionCardViewExpanded = {
  props: {
    submission: {
      type: Object,
      default: null
    },
    prompt: {
      type: Object,
      default: null
    }
  },
  apollo: {
    posts: {
      query: LIST_TOPIC_POSTS,
      variables() {
        return {
          topic_id: this.submission.topic_id,
          where: {
            parent_id: { _is_null: true } // Get only root posts because this is top level
          },
          limit: this.postsPerPage
        };
      },
      skip() {
        return !this.showPosts;
      }
    }
  },
  data() {
    return {
      showPosts: false,
      showAddComment: false,
      postsPage: 0,
      postsPerPage: 5
    };
  },
  computed: {
    canShowEllipsesAllow() {
      return this.$auth.isAdmin && this.isReported;
    },
    canShowEllipsesDelete() {
      return this.submission.author.id === this.$auth.user.id || this.$auth.isAdmin;
    },
    canShowEllipsesEdit() {
      return this.$auth.user.id === this.submission.author.id || this.$auth.isAdmin;
    },
    canShowEllipsesReport() {
      return this.$auth.isAuthenticated;
    },
    isEllipsesEnabled() {
      return this.$auth.isAuthenticated;
    },
    isReported() {
      if (this.submission.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.submission.allowed_at !== null) {
        return true;
      }
      return false;
    },
    isUpvoted() {
      return this.submission.current_user_has_reacted;
    }
  },
  methods: {
    onUpvoteClicked() {
      this.$emit("upvote");
    },
    onDeleteClicked() {
      this.$emit("delete");
    },
    onReportClicked() {
      this.$emit("report");
    },
    onAllowClicked() {
      this.$emit("allow");
    },
    onEditClicked() {
      this.$emit("edit");
    },
    onPostCreated() {
      this.showPosts = true;
      this.showAddComment = false;
    },
    showMore() {
      this.postsPage++;

      this.$apollo.queries.posts.fetchMore({
        variables: {
          offset: this.postsPage * this.postsPerPage,
          limit: this.postsPageSize
        },
        updateQuery(previousResult, { fetchMoreResult }) {
          const newPosts = fetchMoreResult.posts;
          const result = [...previousResult.posts, ...newPosts];

          return {
            posts: result
          };
        }
      });
    },
    onSubmissionCardClick() {
      this.$router.push({
        name: this.prompt.challenge === null ? "submission_detail" : "challenge_submission_detail",
        params: {
          challenge_slug: this.prompt.challenge ? this.prompt.challenge.slug : "",
          prompt_slug: this.prompt.challenge ? this.prompt.slug : "",
          submission_id: this.submission.id
        }
      });
    }
  }
}

export default submissionCardViewExpanded;