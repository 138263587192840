<template>
  <span :class="cssOuterClass">
    Starts
    <span v-if="isBefore(new Date(availableDate), addDays(nowTimer, 2))">
      in {{ isHappeningNow() !== true ? formatDurationClock(nowTimer, availableDate) : "00:00:00" }}
    </span>
    <span v-else>
      on {{ format(new Date(availableDate), "EEEE MM/dd") }}
    </span>
    <b-icon v-show="showStopwatch" class="has-text-primary-gradient" icon="stopwatch" />
  </span>
</template>

<script>
import { format, isBefore, addDays } from "date-fns";
import { formatDurationClock } from "@/dates.js";

export default {
  name: "ChallengeStartsOn",
  props: {
    cssOuterClass: {
      type: String
    },
    availableDate: {
      type: Date,
      default: null
    },
    showStopwatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatDurationClock,
      format,
      isBefore,
      addDays
    };
  },
  methods: {
    isHappeningNow() {
      return this.availableDate <= this.nowTimer;
    }
  }
};
</script>
