<template>
  <router-link
    :to="`/challenges/${challenge.slug}/${challenge.id}`"
    class="challenge-progress"
  >
    <ProgressRing
      :class="ringType"
      :radius="54"
      :stroke="8"
      :progress="progress === 100 ? 0 : progress"
      :steps="progress === 100 ? 0 : challenge.prompt_ids.length"
      :dash-spacing="8"
    />
    <span v-if="!isHappening">
      <b-icon icon="lock" size="is-large" />
    </span>
    <span
      v-else-if="challenge.current_user_accepted_at === null"
      class="challenge-start has-text-weight-semibold p-0"
      >START</span
    >
    <span v-else-if="progress === 100">
      <b-icon icon="check" type="is-success" size="is-large" />
    </span>
    <span v-else class="progress-percent">
      {{ Math.round(progress) }}%
    </span>
  </router-link>
</template>

<script>
import ProgressRing from "@/components/common/ProgressRing.vue";
import { isPast } from "date-fns";

export default {
  name: "ChallengeProgress",
  components: {
    ProgressRing
  },
  props: {
    challenge: {
      type: Object,
      required: true
    }
  },
  computed: {
    isHappening() {
      return isPast(new Date(this.challenge.available_after));
    },
    progress() {
      if (
        !this.challenge.prompt_ids.length ||
        this.challenge.current_user_completed_prompts === null ||
        this.challenge.current_user_accepted_at === null
      ) {
        return 0;
      }

      return (
        (this.challenge.current_user_completed_prompts /
          this.challenge.prompt_ids.length) *
        100
      );
    },
    ringType() {
      if (!this.isHappening) {
        return null;
      }

      if (!this.challenge.prompt_ids.length) {
        return "is-info";
      }

      if (this.progress === 100) {
        return "is-success";
      }

      if (this.challenge.current_user_accepted_at === null) {
        return "is-info";
      }

      return "is-danger";
    }
  },
  methods: {
    onAccept() {
      this.$emit("accepted", this.form);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.challenge-progress,
.challenge-order {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 80px;
  flex-shrink: 0;
  @include mobile {
    flex-basis: 11%;
  }
  @include portrait {
    flex-basis: 16%;
  }
}
.challenge-progress {
  max-height: 110px;
  @include mobile {
    max-height: vwl(60px);
  }
  @include portrait {
    max-height: vwp(60px);
  }
  svg {
    width: 100%;
  }

  &:hover span.has-text-info {
    color: $white !important;
  }

  > span {
    pointer-events: none;
    position: absolute;
    &.challenge-start {
      color: $blue;
      @include mobile {
        font-size: vwl(10px);
      }
      @include portrait {
        font-size: vwp(10px);
      }
    }
    &.progress-percent {
      font-weight: 400;
      font-size: 1.5rem;
      color: #4a4a4a;
      @include mobile {
        font-size: vwl(16px);
      }
      @include portrait {
        font-size: vwp(14px);
      }
    }

    &:focus {
      box-shadow: none !important;
    }
  }
  &:hover,
  &:focus {
    .challenge-start {
      color: shade($blue, 20%);
    }
    .progress-ring {
      circle {
        stroke: tint($blue, 50%);
        &.progress-steps:not(.not-started) {
          stroke: tint($pink, 50%);
        }
        &.progress-completed {
          stroke: $pink;
        }
      }
    }
  }
}
.challenge-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.75rem;
  flex-grow: 1;
  @include tablet {
    column-gap: 1rem;
  }
  @include desktop {
    flex-grow: 1;
    padding-right: 2rem;
  }
  > div {
    flex-grow: 1;
  }
}
</style>
