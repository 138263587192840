<template>
  <header class="home">
    <div class="hero">
      <h1>
        You want to <span>write.</span><br />
        We're here to help.
      </h1>
      <h2>
        Everyone has a story to tell.<br />
        This is your sign to Shut Up & Write!
      </h2>
      <b-button
        class="hero-cta"
        type="is-primary has-text-weight-semibold"
        label="Find an Event Near You"
        @click="$router.push('/events')"
      />
    </div>
    <div class="subhero">
      <p>
        Join our thriving community and get your writing <br />
        done with free in-person and online writing groups.
      </p>
      <b-button
        class="subhero-cta"
        type="is-warning is-inverted"
        label="Start Today!"
        @click="onStartTodayClick"
      />
    </div>
  </header>
</template>

<script>

export default {
  name: "HeroMobile",
  methods: {
    onStartTodayClick() {
      this.$emit("startTodayClicked");
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.hero {
  color: white;
  height: 0;
  padding: 2.5rem 2rem 66.66666667% 2rem;
  background-image: url('/img/banners/Homepage_Mobile2.jpg');
  background-repeat: no-repeat;
  background-size: 172%;
  background-position: 50% 50%;
  display: block;
  @include mobile {
    padding: vwl(35px) vwl(28px) 40% vwl(42px);
    background-size: cover;
  }
  @include portrait {
    padding: vwp(28px) 0 66.66666667% vwp(28px);
    background-size: 172%;
    background-position: 36% 50%;
  }

  
  .hero-cta {
    @include tablet {
      min-width: 13em;
    }
  }
  h1 {
    color: white;
    font-weight: 300;
    font-size: 36px;
    line-height: 1.2;
    @include mobile {
      font-size: vwl(26px);
    }
    @include portrait {
      font-size: vwp(26px);
    }
    span {
      color: $gold;
    }
  }
  h2 {
    line-height: 1.5em;
  }
  h2 {
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin: 10px 0 20px 0;
    line-height: 1.3;
    @include mobile {
      font-size: vwl(12px);
    }
    @include portrait {
      font-size: vwp(12px);
    }
  }
}

.subhero {
  background: #FFCB37;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  text-align: center;
  @include mobile {
    padding: vwl(15px) 0;
  }
  @include portrait {
    padding: vwp(15px) 0 vwp(20px) 0;
  }
  p {
    color: #414141;
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 10px 0;
    @include mobile {
      font-size: vwl(14px);
    }
    @include portrait {
      font-size: vwp(12px);
      margin: vwp(5px) 0 vwp(15px) 0;
    }
  }

  .subhero-cta {
    min-width: 9em;
    font-weight: 500;
  }
}
</style>
