import { render, staticRenderFns } from "./ChallengePromptCardViewHeaderUnlocked.vue?vue&type=template&id=35469632&%3Aprompt=prompt&%3Achallenge-order=challengeOrder&%3Aqa-has-submitted=qaHasSubmitted&%3Aqa-has-submitted-when=qaHasSubmittedWhen&"
import script from "./ChallengePromptCardViewHeaderUnlocked.vue?vue&type=script&lang=js&"
export * from "./ChallengePromptCardViewHeaderUnlocked.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports