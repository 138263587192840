<template>
  <div class="columns is-variable is-5" :class="{ 'is-flex-direction-column': !suwDesktop }">
    <div class="column is-7-desktop is-full-mobile">
      <!-- <pre>{{form.api_result | dump}}</pre> -->
      <div class="map-container">
        <gmaps-map
          v-if="form || userLocation"
          class="map"
          :options="mapOptions"
          @mounted="map = $event"
        >
          <gmaps-marker
            v-if="form.api_result"
            :position="form.api_result.geometry.location"
            :icon="venueIcon"
          />
        </gmaps-map>
        <div v-if="value" class="event-location">
          <div class="map-location">
            <h5>{{ value.title }}</h5>
            <p>{{ value.host_city || value.street_address }}</p>
          </div>
          <div class="map-actions">
            <b-button size="is-small" type="is-ghost" @click="onRecenter()">
              Center on map
            </b-button>
            <b-button
              v-if="showVenueLocation"
              class="is-warning is-inverted has-text-weight-bold"
              tag="a"
              target="_blank"
              type="button is-link is-small"
              style="border-radius: 5px;"
              icon-left="link"
              icon-pack="fas"
              :href="directionsURL"
              >Directions</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="event-details column">
      <slot name="top-corner"></slot>
      <transition v-if="!hideForm" name="slide">
        <keep-alive>
          <!-- PHYSICAL EVENT FORM -->
          <div v-if="form">
            <div v-if="isEditing">
              <b-field
                label="Location / Address"
                :type="{ 'is-danger': $v.form.api_result.$invalid }"
                :message="{
                  'Choosing a venue is required!': form.api_result === null
                }"
              >
                <GooglePlaces
                  :key="`ac-${autocompleteKey}`"
                  :value="form.api_result"
                  @updated:place="onPlaceChanged"
                />
              </b-field>

              <b-field label="How can attendees find you?">
                <b-input
                  v-model="form.find_me"
                  placeholder="How can attendees find you at the event?"
                />
              </b-field>

              <b-field label="Parking/Transit Info">
                <b-input
                  v-model="form.parking_info"
                  placeholder="Parking / Public Transit Info"
                />
              </b-field>

              <b-field label="WiFi Info">
                <b-input
                  v-model="form.wifi_info"
                  placeholder="Wifi network name and password..."
                />
              </b-field>
            </div>
            <div v-else-if="value">
              <b-field v-if="value.find_me" label="How to Find us">
                <p>{{ value.find_me }}</p>
              </b-field>

              <b-field v-if="showVenueLocation && value.parking_info" label="Parking / Transit Info">
                <p>{{ value.parking_info }}</p>
              </b-field>

              <b-field v-if="showVenueLocation && value.wifi_info" label="Wifi Name/Password">
                <p>{{ value.wifi_info }}</p>
              </b-field>
            </div>
          </div>
          </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import venueCardPhysical from "@/components/venueCard/VenueCardPhysical.js";
/* global google */
import GooglePlaces from "@/components/forms/GooglePlaces.vue";
import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  name: "VenueCardPhysicalDesktop",
  mixins: [venueCardPhysical],
  components: {
    GooglePlaces,
    gmapsMap,
    gmapsMarker
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.map-container {
  @extend %elevation-2;
  height: 100%;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  .map {
    height: 100%;
    &.gmaps-map {
      min-height: 400px;
      > div {
        border-radius: 0.5em 0.5em 0 0;
      }
    }
  }
}
.map-location {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.map-actions {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.event-details {
  @include tablet {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @include desktop {
    padding-bottom: 4rem;
  }
}
</style>
