<template>
  <router-link
    class="is-flex is-align-items-center has-text-grey-dark"
    :enabled="navEvent"
    :to="routedata"
  >
    <b-icon 
      v-if="isPrev"
      icon="chevron-circle-left"
      size="is-medium" 
    />
    <span class="link-title">{{ nextPrev }}</span>
    <b-icon 
      v-if="!isPrev"
      icon="chevron-circle-right" 
      size="is-medium" 
    />
  </router-link>
</template>
    
<script>
import { slugify } from "@/filters";

export default {
  name: "EventNavLink",
  props: {
    event: {
      type: Object,
      default: () => null
    },
    isPrev: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navEvent() {
      if (this.event && this.event.other_events) {
        let ourIndex = this.event.other_events.findIndex(
          e => e.id === this.event.id
        );
        // this.$log.debug("our index", ourIndex);
        if (this.isPrev) {
          return this.prevEvent(ourIndex);
        }
        else {
          return this.nextEvent(ourIndex);
        }
      }
      return null;
    },
    routedata() {
      return this.navEvent
        ? {
            name: 'event_detail',
            params: {
              slug: slugify(this.event),
              event_id: this.navEvent.id
            }
          } 
        : {}
    },
    nextPrev() {
      return this.isPrev ? "Previous Event" : "Next Event";
    }
  },
  methods: {
    prevEvent(idx) {
      if (idx > 0) {
        return this.event.other_events[idx - 1];
      }
      return null;
    },
    nextEvent(idx) {
      if (idx < this.event.other_events.length) {
        return this.event.other_events[idx + 1];
      }
      return null;
    },
    slugify
  }
};
</script>
<style lang="scss">
.mobile-button {
  padding: 0.1rem !important;
}
</style>
