import { CREATE_SUBMISSION, UPDATE_SUBMISSION, GET_SUBMISSION_BY_DRAFT } from "@/models/challenges/operations.gql";
import { required } from "vuelidate/lib/validators";

const submissionCardEdit = {
  props: {
    submission: {
      type: Object,
      default: null
    },
    prompt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        id: null,
        title: null,
        body: "",
        body_text: "",
        prompt_id: null
      },
      draft: null
    };
  },
  validations: {
    form: {
      body: {
        required
      }
    }
  },
  created() {
    this.form.prompt_id = this.prompt.id;

    if (this.submission) {
      this.form.id = this.submission.id;
      this.form.title = this.submission.title;
      this.form.body = Object.assign({}, this.submission.body);
    }
  },
  methods: {
    onCancel() {
      if (this.form.id) {
        this.form = {
          ...this.submission
        };
      }
      this.$emit("cancelled");
    },
    onDraft(body) {
      if (!this.submission) {
        //only save NEW submissions as drafts
        Object.assign(this.form, { body });

        if (this.form.id) {
          this.onUpdate(true);
        } else {
          this.onCreate(true);
        }
      }
    },
    onConfirm(body) {
      Object.assign(this.form, { body });

      if (this.form.id) {
        this.onUpdate(false);
      } else {
        this.onCreate(false);
      }
    },
    onCreate(isDraft) {
      //this.form.title = this.form.prompt_id + new Date().toString();//for breadcrumbs
      this.form.title = "Submission"; //for breadcrumbs
      const { prompt_id, title, body } = this.form;
      //this.$log.debug("Create submission", this.form);
      this.$apollo
        .mutate({
          mutation: CREATE_SUBMISSION,
          variables: {
            submission: {
              prompt_id,
              title,
              body,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
            if (isDraft) {
              this.form.id = result.id;
            }
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            this.$emit("created", result);

            this.$buefy.snackbar.open({
              message: `Submission created`,
              type: "is-success"
            });
          }
        });
    },
    onUpdate(isDraft) {
      const { id, body } = this.form;
      //this.$log.debug("Update Submission", this.form);
      const wasDraft = this.submission ? this.submission.is_draft : true;
      this.$apollo
        .mutate({
          mutation: UPDATE_SUBMISSION,
          variables: {
            submission_id: id,
            changes: {
              body,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            if (wasDraft) {
              this.$emit("created", result);
            } else {
              this.$emit("updated", result);
            }

            this.$buefy.snackbar.open({
              message: `Submission updated`,
              type: "is-success"
            });
          }
        });
    }
  },
  apollo: {
    draft: {
      query: GET_SUBMISSION_BY_DRAFT,
      variables() {
        return {
          author_id: this.$auth.user.id,
          prompt_id: this.prompt.id
        };
      },
      update({ drafts }) {
        const draft = drafts ? drafts[0] || null : null;
        if (draft && !this.form.id) {
          this.form.id = draft.id;
          this.form.title = draft.title;
          this.form.body = Object.assign({}, draft.body);
        }
        return draft;
      }
    }
  }
}

export default submissionCardEdit;