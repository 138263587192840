<template>
  <div class="card">
    <ChallengeProgress :challenge="challenge" />
    <div class="card-info">
      <div class="card-details">
        <h2>
          {{ challenge.title }}
          <b-tag v-if="challenge.current_user_accepted_at" class="accepted" type="is-success is-light">
            <b-icon icon="check" />
            <span class="has-text-weight-semibold">Accepted</span>
          </b-tag>
        </h2>
        <p><TipTap v-model="form.body" /></p>
      </div>
      <div class="card-actions">
        <h6 v-if="wasHappeningNow">Created {{ availableDate | formatFromNow }}</h6>
        <ChallengeStartsOn
          v-else
          css-outer-class="has-text-weight-semibold"
          :available-date="availableDate"
          :show-stopwatch="true"
        />
        <div class="is-flex mt-1">
          <b-button
            v-if="!$auth.isAuthenticated"
            type="is-warning is-small"
            icon-left="user-check"
            @click="loginAndComeBackWithFlyOpen"
          >
            Login to Accept
          </b-button>
          <b-button
            v-if="$auth.isAuthenticated && challenge.current_user_accepted_at === null"
            type="button-sm is-success"
            icon-left="check"
            @click="onAccept"
          >
            Accept Challenge
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import challengeCardViewHeader from "@/components/challengeCard/ChallengeCardViewHeader.js";
import { format, isBefore, addDays } from "date-fns";
import TipTap from "@/components/forms/TipTap.vue";
import ChallengeProgress from "@/components/challengeCard/ChallengeProgress.vue";

export default {
  name: "ChallengeCardViewHeader",
  components: {
    TipTap,
    ChallengeProgress
  },
  mixins: [challengeCardViewHeader],
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      format,
      isBefore,
      addDays
    };
  },
  methods: {
    onAccepted(challenge_user) {
      this.$emit("accepted", challenge_user);
    },
  }
};
</script>