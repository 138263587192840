<template>
  <div class="event-description">
    <h4 class="is-flex mb-2">
      <b-icon icon="calendar" type="is-info" class="mr-1"/>
      {{ startsAt | format("MMMM dd, yyyy") }}
      <b-taglist v-if="event.starts_at || nestedEvent.venue_id">
        <b-tag class="is-pink ml-1">
          Updated
        </b-tag>
      </b-taglist>
    </h4>
    <h4 class="is-flex mb-2">
      <b-icon icon="clock" type="is-info" class="mr-1"/>
      {{ startsAt | format("h:mmaaa") }} - {{ event.ends_at | format("h:mmaaa") }}
      <span class="tz mx-1">
        {{ startsAt | format("zzz") }}
      </span>
    </h4>
    <div class="rsvp" v-if="!event.current_user_is_organizer && !isJoinTime">
      <RSVPCard v-if="isVirtual" :event="event" :expanded="suwMobile" />
      <p v-if="isNotRSVPed" class="has-text-grey500">
        Secures your spot for this specific event.
      </p>
    </div>
    <div class="rsvp" v-else-if="!event.current_user_is_organizer && isJoinTime && isNotRSVPed && !requestedEventAccess">
      <EventAccessCard v-if="isVirtual" :event="event" :expanded="suwMobile" :disabled="requestedEventAccess" @eventAccessRequested="handleEventAccess" />
      <p class="is-full is-flex has-text-grey500">
        Join an ongoing event without RSVPing.
      </p>
    </div>
    <div v-if="isVirtual" class="is-flex is-flex-wrap-wrap is-align-items-center is-flex-grow-0">
      <Pill class="mr-1 mb-2" icon="laptop" icon-pack="fas">
        <span> Online</span>
      </Pill>
      <Pill class="mr-1 mb-2" icon="globe">
        English
      </Pill>
    </div>
    <b-field v-if="showMeetingUrl" label="Meeting URL">
      <p>
        <a :href="meetingUrl" target="_blank" rel="noopener noreferrer" class="stupid-super-special-anchor">{{ meetingUrl }}</a>
      </p>
    </b-field>
    <b-field v-if="showMeetingPassword" label="Meeting Password">
      <p>{{ event.venue.meeting_password }}</p>
    </b-field>
    <b-field v-if="!showMeetingUrl && isVirtual">
      <p>Your meeting link will appear 5 minutes before the event.</p>
    </b-field>
    <b-field label="Event Details">
      <TipTap v-if="event.description" v-model="event.description" />
      <TipTap v-else :value="isVirtual || event.template.is_virtual ? defaultEventDescriptionVirtual : defaultEventDescriptionPhysical" />
    </b-field>    
  </div>
</template>

<script>
import eventCornerView from "@/components/eventBody/EventCornerView.js";
import RSVPCard from "@/components/eventBody/RSVPCard.vue";
import EventAccessCard from "@/components/eventBody/EventAccessCard.vue";
import TipTap from "@/components/forms/TipTap.vue";
import Pill from "@/components/common/Pill.vue";

export default {
  name: "EventCornerViewMobile",
  components: {
    RSVPCard,
    EventAccessCard,
    TipTap,
    Pill
  },
  mixins: [eventCornerView],
  methods: {
    handleEventAccess() {
      this.requestedEventAccess = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.tz {
  @extend %elevation-2;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  font-size: 0.75em;
  background-color: white;
  color: $grey600;
  border-radius: 5px;
}
.icon {
  position: relative;
  top: -2px;
}
</style>
