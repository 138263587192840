const submissionCardViewHeader = {
  props: {
    submission: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        body: ""
      }
    };
  },
  computed: {
    canShowEllipsesAllow() {
      return this.$auth.isAdmin && this.isReported;
    },
    canShowEllipsesDelete() {
      return this.submission.author.id === this.$auth.user.id || this.$auth.isAdmin;
    },
    canShowEllipsesEdit() {
      return this.$auth.user.id === this.submission.author.id || this.$auth.isAdmin;
    },
    canShowEllipsesReport() {
      return this.$auth.isAuthenticated;
    },
    isEllipsesEnabled() {
      return this.$auth.isAuthenticated;
    },
    isReported() {
      if (this.submission.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.submission.allowed_at !== null) {
        return true;
      }
      return false;
    },
    isUpvoted() {
      return this.submission.current_user_has_reacted;
    }
  },
  created() {
    this.form.body = Object.assign({}, this.submission.body);
  },
  methods: {
    onUpvoteClicked() {
      this.$emit("upvote");
    },
    onDeleteClicked() {
      this.$emit("delete");
    },
    onReportClicked() {
      this.$emit("report");
    },
    onAllowClicked() {
      this.$emit("allow");
    },
    onEditClicked() {
      this.$emit("edit");
    }
  }
}

export default submissionCardViewHeader;