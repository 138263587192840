export const DEFAULT_LOCATION = { lat: 36.77175, lng: 21.705028 }; // Sapientza, Greece

export const getGPS = callback => {
  //first try browser GPS, then try reverse lookup
  return navigator.geolocation.getCurrentPosition(
    position => {
      callback({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    }, 
    err => { //(0) unknown; (1) permission denied; (2) position unavailable; or (3) timeout
      console.warn(err);
      return fetch("https://get.geojs.io/v1/ip/geo.json", {
        mode: "cors"
      })
      .then(d => {
        if (d.ok) {
          return d.json();
        } else {
          throw new Error("unable to get user geoip" + JSON.stringify(d));
        }
      })
      .then(j => {
        callback({
          lat: parseFloat(j.latitude),
          lng: parseFloat(j.longitude)
        });
      })
      .catch(e => {
        console.warn("failed to fetch geoip", e);
        callback(null);
      });
    });
};
