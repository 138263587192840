<template>
  <div v-if="event.has_occurred || event.cancelled_by_user_at || event.cancelled_by_materializer_at" class="disabled-overlay">
    <!-- Event Status notifications -->
    <div class="px-6 py-3 m-4 boxed has-background-white is-flex is-flex-direction-column is-align-items-center elevation-3">
      <div v-if="event.has_occurred">
        <h4>
          The event on <strong>{{
            event.starts_at || event.scheduled_starts_at | format("MMM do")
          }}</strong> has already occurred.
        </h4>
        <div class="is-flex mt-2 is-justify-content-space-around">
          <b-button class="is-info is-outlined elevation-1 mr-2" @click="goToNextEvent">
            Next Available
          </b-button>
        </div>
      </div>

      <div v-else-if="event.template.cancelled_by_user_at">
        <h4>
          This series has been
          <span class="has-text-danger has-text-weight-semibold">cancelled</span>.
        </h4>
        <div class="is-flex mt-2 is-justify-content-space-around">
          <b-button tag="router-link" class="is-info is-outlined elevation-1 mr-2" to="/events">
            Find Another Event
          </b-button>
          <CancelCard
            v-if="event.template.cancelled_by_user_at && ($auth.isAdmin || event.current_user_is_organizer)"
            :event="event"
            cancel-type="series"
          />
        </div>
      </div>
      <div v-else-if="event.cancelled_by_user_at">
        <h4>
          The event on
          <strong>{{ event.starts_at || event.scheduled_starts_at | format("MMM do") }}</strong>
          has been
          <span class="has-text-danger has-text-weight-semibold">cancelled</span>.
        </h4>
        <div class="is-flex mt-2 is-justify-content-space-around">
          <b-button class="is-info is-outlined elevation-1 mr-2" @click="goToNextEvent">
            Next Available
          </b-button>
          <CancelCard
            v-if="event.cancelled_by_user_at && ($auth.isAdmin || event.current_user_is_organizer)"
            :event="event"
            cancel-type="event"
          />
        </div>
      </div>
      <div v-else-if="event.cancelled_by_materializer_at">
        <h4>
          The event on
          <strong>{{ event.starts_at || event.scheduled_starts_at | format("MMM do") }}</strong>
          has been
          <span class="has-text-danger has-text-weight-semibold">rescheduled</span>.
        </h4>
        <div class="is-flex mt-2 is-justify-content-space-around">
          <b-button class="is-info is-outlined elevation-1 mr-2" @click="goToNextEvent">
            Next Available
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { slugify } from "@/filters";
import CancelCard from "@/components/events/CancelCard.vue";

export default {
  name: "EventNavigator",
  components: {
    CancelCard
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToNextEvent() {
      this.$router.push({
        name: "series",
        params: {
          slug: slugify(this.event),
          event_template_id: this.event.template.id
        }
      });
    }
  }
};
</script>
