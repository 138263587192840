<template>
  <div
    class="date-square"
    :class="{
      'is-featured': isFeatured,
      'is-cancelled': isCancelled,
      'show-day': showDay
    }"
  >
    <div class="square">
      <div class="cal">
        <div class="time">{{ d | format("h:mma") }}</div>
        <div class="day">
          {{ d | format(showDay ? "M/d" : "ccc") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateSquare",
  props: {
    d: {
      type: Date,
      required: true
    },
    isFeatured: {
      type: Boolean,
      default: false
    },
    isCancelled: {
      type: Boolean,
      default: false
    },
    showDay: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "mixins";
$b: 2px solid $grey900;
$radius: 0.5rem;

.date-square {
  background: transparent;
  flex-basis: 60px;
  color: $grey900;
  margin-right: .75rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  @include mobile {
    flex-basis: 11%;
  }
  @include portrait {
    flex-basis: 16%;
  }
  @include tablet {
    flex-basis: 80px;
    margin-right: 1.5rem;
  }

  .square {
    width: 100%;
    padding-bottom: 108%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .cal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .time {
    height: 33.333333%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gold;
    color: $grey900;
    font-weight: 600;
    text-transform: lowercase;
    border-left: $b;
    border-top: $b;
    border-right: $b;
    border-radius: $radius;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 16px;
    @include mobile {
      font-size: vwl(10px);
    }
    @include portrait {
      font-size: vwp(10px);
    }
  }
  .day {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 66.6666667%;
    background: $white;
    font-weight: 500;
    font-size: 2rem;
    text-transform: uppercase;
    //padding: 5px;
    border: $b;
    border-radius: $radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    @include mobile {
      font-size: vwl(22px);
    }
    @include portrait {
      font-size: vwp(18px);
    }
  }

  &.is-featured {
    .day {
      border-color: $grey900;
    }
    .time {
      color: $grey900;
      //border-top: 0;
      border-color: $grey900;
      background: $gold;
    }
  }

  &.is-cancelled {
    .day {
      border-color: $grey500;
      background: white;
      color: $grey500;
    }
    .time {
      color: white;
      border-top: 0;
      border-color: transparent;
      background: $grey500;
    }
  }

  &.is-past {
    .time {
      background-color: $grey300;
    }
  }
  &.show-day {
    .day {
      @include desktop {
        letter-spacing: -1px;
        font-size: 1.7rem;
      }
    }
  }
}
.next-event {
  .date-square {
    .time,
    .day {
      font-size: 0;
    }
  }
}
</style>
