/**
 * common rrule modifications
 */
import { toUTCDate } from "@/dates";
import { RRule, RRuleSet } from "rrule";
import { Logger, DEBUG } from "@/plugins/logging.js";
import { start } from "pretty-error";

const $log = new Logger("rrule.js", { level: DEBUG });

// date-fn & postgres' "days" are 0-6 (Sunday-Saturday)
const DAYS_OF_WEEK = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA
];

export default {
  set_from_rule(rrule) {
    let ruleset = new RRuleSet();
    ruleset.rrule(rrule);
    return ruleset;
  },
  create_one_off_set(startDateLocal) {
    const r = new RRule({
      dtstart: toUTCDate(startDateLocal), // TODO check this
      freq: RRule.DAILY,
      count: 1
    });
    return this.set_from_rule(r);
  },
  create_weekly_set(startDateLocal) {
    var utcnow = new Date(
      Date.UTC(
        startDateLocal.getUTCFullYear(),
        startDateLocal.getUTCMonth(),
        startDateLocal.getUTCDate(),
        startDateLocal.getUTCHours(),
        startDateLocal.getUTCMinutes()
      )
    );
    var dayOfWeek = utcnow.getUTCDay();
    $log.info("creating weekly recurring rrule on day", dayOfWeek);
    const r = new RRule({
      dtstart: utcnow,
      freq: RRule.WEEKLY,
      byweekday: DAYS_OF_WEEK[dayOfWeek],
      interval: 1,
      count: 50000
    });
    $log.info("RRule", r.toString());
    return this.set_from_rule(r);
  },
  recurrenceTypeFromSet(rruleset) {
    // HACK FIXME TODO: this is a hack as we're just looking at the rrule json coming
    // from hasura and not an actual rruleset (it's not the rrule lib)
    let out = "one_time"; // assume there's no recurrence unless we can figure it out
    if (rruleset && rruleset.options.freq == RRule.WEEKLY) {
      // TODO look for a non-1 interval for bi-weekly etc...
      out = "weekly_on_start_day";
    }
    return out;
  },
  dayOfWeek(rruleset) {
    if (!rruleset || !rruleset.options.byweekday) {
      return null;
    }
    //database _rrule.DAY starts on Monday but DAYS_OF_WEEK starts on Sunday
    if (rruleset.options.byweekday[0] == 6) {
      return DAYS_OF_WEEK[0];
    } else {
      return DAYS_OF_WEEK[rruleset.options.byweekday[0] + 1];
    }
  },
  dayOfWeekLong(rruleset) {
    let dow = this.dayOfWeek(rruleset);
    if (dow == "SU") return "Sunday";
    if (dow == "MO") return "Monday";
    if (dow == "TU") return "Tuesday";
    if (dow == "WE") return "Wednesday";
    if (dow == "TH") return "Thursday";
    if (dow == "FR") return "Friday";
    if (dow == "SA") return "Saturday";
    else return "Someday";
  }
};
