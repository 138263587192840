import { Logger, INFO } from "@/plugins/logging.js";
//import { offsetLimitPagination } from "@apollo/client/utilities";

const $log = new Logger("InMemoryCache", { level: INFO });

const offsetLimitPagination = (keyArgs = false) => ({
  keyArgs,
  read(existing, { args, storeFieldName }) {
    $log.debug("--READ-->", storeFieldName, existing, args);
    const page =
      existing && existing.slice(args.offset, args.offset + args.limit);
    if (page && page.length > 0) {
      return page;
    }
  },
  merge(existing, incoming, { args, storeFieldName }) {
    $log.debug("<-MERGE-", storeFieldName, existing, incoming, args);
    const merged = existing ? existing.slice(0) : [];
    const end = args.offset + Math.min(args.limit, incoming.length);
    for (let i = args.offset; i < end; ++i) {
      merged[i] = incoming[i - args.offset];
    }
    return merged;
  }
});

// Export the typePolicy as default
export default {
  fields: {
    users: offsetLimitPagination(["where", "order_by"]),
    events: offsetLimitPagination(["where", "order_by"]),
    events_next: offsetLimitPagination(["where", "order_by"]),
    event_calendars: offsetLimitPagination(["where"]),
    challenges_challenges: offsetLimitPagination(["where", "order_by"]),
    challenges_challenges_with_computed_fields: offsetLimitPagination([
      "where",
      "order_by"
    ]),
    challenges_prompts: offsetLimitPagination(["where", "order_by"]),
    challenges_prompts_with_computed_fields: offsetLimitPagination([
      "where",
      "order_by"
    ]),
    challenges_submissions: offsetLimitPagination(["where", "order_by"])
  }
};
