<template>
  <Page class="main">
    <EventDetailSectionMain 
      :event="event" 
      :loading="$apollo.queries.event.loading" 
      @seriesUpdated="onSeriesUpdated"
      @eventUpdated="onEventUpdated"
      @startEditingSeries="onStartEditingSeries"
      @stopEditingSeries="onStopEditingSeries"
    />
    <EventDetailSectionMembers v-show="!isEditingSeries" :event="event" ref="members" />
    <EventDetailSectionDiscussion v-show="!isEditingSeries" :event="event" />
  </Page>
</template>

<script>
import { GET_BY_PK } from "@/models/events/operations.gql";
import Page from "@/components/common/Page.vue";
import EventDetailSectionMain from "@/components/events/EventDetailSectionMain.vue";
import EventDetailSectionMembers from "@/components/events/EventDetailSectionMembers.vue";
import EventDetailSectionDiscussion from "@/components/events/EventDetailSectionDiscussion.vue";

export default {
  name: "EventDetail",
  metaInfo: {
    title: "Writing Groups - "
  },
  components: {
    Page,
    EventDetailSectionMain,
    EventDetailSectionMembers,
    EventDetailSectionDiscussion
  },
  props: {
    eventId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      event: null,
      isEditingSeries: false
    };
  },
  apollo: {
    event: {
      query: GET_BY_PK,
      variables() {
        return {
          event_id: this.eventId
        };
      },
      update({ events }) {
        const event = events[0] || null;
        return event || this.display404Page();
      }
    }
  },
  methods: {
    onSeriesUpdated() {
      this.$refs.members.refetch();
    },
    onEventUpdated() {
      this.$apollo.queries.event.refetch();
    },
    onStartEditingSeries() {
      this.isEditingSeries = true;
    },
    onStopEditingSeries() {
      this.isEditingSeries = false;
    }
  }
};
</script>
