export const DEBUG = 10,
  INFO = 20,
  WARN = 30,
  ERROR = 40;

export class Logger {
  constructor(name, options) {
    this.name = name || "ROOT";
    this.options = options;
    this.common_style = `
      padding: .3em;
      border-radius: 2px;
      font-size: 1.25em;
      font-weight: bold;
    `;
    this.debug_style = [
      this.common_style,
      "color: #666",
      "background-color: #f6f6f6"
    ].join(";");
    this.info_style = [
      this.common_style,
      "color: #fff",
      "background-color: #0087BD"
    ].join(";");
    this.warn_style = [
      this.common_style,
      "font-size: 1.5em",
      "background-color: #FFD300",
      "color: #333"
    ].join(";");
    this.error_style = [
      this.common_style,
      "background-color: #C40233",
      "color: #fff",
      "font-size: 1.75em"
    ].join(";");
  }

  debug(...args) {
    if (DEBUG >= this.options.level) {
      console.log(`%c🙂DEBUG>${this.name}`, this.debug_style, ...args);
    }
  }
  info(...args) {
    if (INFO >= this.options.level) {
      console.log(`%c🤔INFO>${this.name}`, this.info_style, ...args);
    }
  }
  warn(...args) {
    if (WARN >= this.options.level) {
      console.log(`%c🤕WARN > ${this.name}`, this.warn_style, ...args);
    }
  }
  error(...args) {
    if (ERROR >= this.options.level) {
      console.log(`%c🤮ERROR > ${this.name}`, this.error_style, ...args);
    }
  }
  apollo(operation, ellapsedMS, result) {
    const operationType = operation.query.definitions[0].operation;
    let label = "";
    let styles = [];
    let hasErrors = result.errors !== undefined;
    if (operationType === "query") {
      label = `%c↲ ${operation.operationName} %c${ellapsedMS} ms`;
      styles = [
        "color: #343488; font-style: oblique; font-weight: lighter;",
        "font-weight: normal; color: blue; font-size: 0.8em;"
      ];
    } else {
      label = `%c↱ ${operation.operationName} %c${ellapsedMS} ms`;
      styles = [
        "color: #B93445; font-size: 0.95em; font-style: oblique; font-weight: lighter;",
        "font-weight: normal; color: blue; font-size: 0.8em;"
      ];
    }
    if (hasErrors) {
      console.group(label, ...styles);
    } else {
      console.groupCollapsed(label, ...styles);
    }

    if (result.errors !== undefined) {
      console.log("variables", operation.variables);
      result.errors.forEach(err => {
        if (err.extensions && err.extensions.internal) {
          console.log(
            `%c${err.extensions.internal.error.exec_status || "ERROR"}:`,
            "color: darkred; font-weight: bold",
            err.message,
            err.extensions.internal.error.message,
            err.extensions.internal.error.status_code,
            err.code
          );
        }
      });
    } else {
      console.log("variables", operation.variables);
      console.log(
        "%cresult",
        "color: darkgreen; font-weight: bold",
        result.data
      );
    }
    console.groupEnd(label);
  }
}

export default {
  installed: false,
  install(Vue, options) {
    // install guard
    if (this.installed) return;
    this.installed = true;
    Vue.log = new Logger("Vue.root", options);

    // $log object for all instances
    Vue.prototype.$log = {
      debug(...args) {
        console.error(`Cannot log without a bound instance`, ...args);
      },
      info(...args) {
        console.error(`Cannot log without a bound instance`, ...args);
      },
      warn(...args) {
        console.error(`Cannot log without a bound instance`, ...args);
      },
      error(...args) {
        console.error(`Cannot log without a bound instance`, ...args);
      }
    };

    Vue.mixin({
      beforeCreate: function() {
        let name = this.$options.name || "ROOT";
        this.$log = new Logger(name, { ...options });
      }
      // very noisy but good test
      // created() {
      //   this.$log.debug("CREATED");
      // }
    });
  }
};
