<template>
  <Page class="main">
    <Section>
      <!-- Make into a specific header component? -->
      <div v-if="suwDesktop">
        <h1>
          Find an
          <strong class="nowrap">Online</strong>
          Event
        </h1>
        <p>
          Join our global community of writers at an online Shut Up & Write! almost
          any time of day, morning or night.
        </p>
        <p class="mt-2">
          Our online event format uses our proven formula: brief introductions, an hour of focused writing, and a final check-in to share the hour's progress. 
          Featured Events are online events that we've selected as a great choice for new Shut Up & Write! members.
        </p>
        <p class="mt-2 has-text-grey-dark">
          Events are listed for your local time, which is {{ formatUTC(nowTimer, "h:mm a") }}. Event listings hosted on Meetup.com are updated every 24 hours.
        </p>
      </div>

      <div class="events-container" :class="{
        'mt-5': suwDesktop,
        'mt-2': !suwDesktop
        }"
      >
        <div class="page-heading is-flex is-justify-content-space-between is-align-items-center">
          <h4>This Week's Online Events</h4>
          <div v-if="suwMobile" class="event-type-dropdown is-hidden-tablet">
            <b-dropdown v-if="isChapterMember" ref="dropdown" aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-button type="has-text-grey-dark" icon-right="chevron-down">
                  <span v-if="onlyShowChapters">Mine</span>
                  <span v-else>None</span>
                </b-button>
              </template>
              <b-dropdown-item @click="selectMyChapters">
                Mine
              </b-dropdown-item>
              <b-dropdown-item @click="selectNoChapters">
                None
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-button type="has-text-grey-dark" icon-right="chevron-down">
                  <span v-if="onlyShowFeatured">Featured</span>
                  <span v-else-if="onlyShowExtended">Extended</span>
                  <span v-else-if="onlyShowMarathon">Marathon</span>
                  <span v-else>Event Type</span>
                </b-button>
              </template>
              <b-dropdown-item @click="selectAny">
                Any
              </b-dropdown-item>
              <b-dropdown-item @click="selectFeatured">
                Featured
              </b-dropdown-item>
              <b-dropdown-item @click="selectExtended">
                Extended
              </b-dropdown-item>
              <b-dropdown-item @click="selectMarathon">
                Marathon
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div class="events-nav mt-2">
          <b-tabs v-model="activeTab" class="is-danger day-tabs">
            <b-tab-item :label="format(addDays(new Date(), 0), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 1), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 2), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 3), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 4), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 5), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 6), 'eeee')" />
            <b-tab-item :label="format(addDays(new Date(), 7), 'eeee')" />
          </b-tabs>

          <div v-if="!suwMobile && isChapterMember" class="event-type-dropdown is-hidden-mobile">
            <span class="is-hidden-touch">Chapter</span>
            <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-button type="has-text-grey-dark" icon-right="chevron-down">
                  <span v-if="onlyShowChapters">Mine</span>
                  <span v-else>None</span>
                </b-button>
              </template>
              <b-dropdown-item @click="selectMyChapters">
                Mine
              </b-dropdown-item>
              <b-dropdown-item @click="selectNoChapters">
                None
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-if="!suwMobile" class="event-type-dropdown is-hidden-mobile">
            <span class="is-hidden-touch">Event Type</span>
            <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-button type="has-text-grey-dark" icon-right="chevron-down">
                  <span v-if="onlyShowFeatured">Featured</span>
                  <span v-else-if="onlyShowExtended">Extended</span>
                  <span v-else-if="onlyShowMarathon">Marathon</span>
                  <span v-else-if="suwMobile">Event Type</span>
                  <span v-else>Select</span>
                </b-button>
              </template>
              <b-dropdown-item @click="selectAny">
                Any
              </b-dropdown-item>
              <b-dropdown-item @click="selectFeatured">
                Featured
              </b-dropdown-item>
              <b-dropdown-item @click="selectExtended">
                Extended
              </b-dropdown-item>
              <b-dropdown-item @click="selectMarathon">
                Marathon
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <EventSeriesListVirtual
        :where="whereClause"
        :max-events="maxEvents"
        :apportioned-viewable="showingAtATime"
      />
    </Section>

    <div class="events-container">
      <div class="boxed mb-4 cantfind">
        <h4 class="has-text-centered has-text-weight-medium">
          Can't find an event that <br class="is-hidden-tablet" />
          works for you?
        </h4>
        <p class="has-text-centered has-text-grey">
          Search for an in-person event <br class="is-hidden-tablet" />
          or host your own.
        </p>
        <div class="button-row">
          <router-link
            tag="a"
            class="button is-info has-text-weight-bold"
            :to="{ name: 'events_physical' }"
            exact
          >
            Search In-Person Events
          </router-link>
          <router-link
            tag="a"
            class="button is-pink has-text-weight-bold px-4"
            :to="{ name: 'host' }"
            exact
          >
          Host an Event
          </router-link>
        </div>
        
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/common/Page.vue";
import Section from "@/components/common/Section.vue";
import EventSeriesListVirtual from "@/components/eventSeries/EventSeriesListVirtual.vue";
import { add, addDays, startOfDay, endOfDay } from "date-fns";
import { format } from "date-fns-tz";
import { whereIsFeatured, whereIsExtended, whereIsMarathon, whereIsChapter, whereIsNotChapterOnly } from "@/models/series/predicates.js";
import { formatUTC } from "@/dates";

export default {
  name: "EventsVirtual",
  metaInfo: {
    title: "Writing Groups - "
  },
  components: {
    Page,
    Section,
    EventSeriesListVirtual
  },
  data() {
    return {
      format,
      onlyShowFeatured: false,
      onlyShowExtended: false,
      onlyShowMarathon: false,
      maxEvents: 100,
      showingAtATime: 10,
      day: new Date(),
      addDays,
      activeTab: 0,
      formatUTC,
      onlyShowChapters: true
    };
  },
  computed: {
    isChapterMember() {
      return this.$auth.user ? this.$auth.user.chapter_members.length > 0 : false;
    },
    whereClause() {
      const day = addDays(new Date(), this.activeTab);
      return {
        template: {
          ...(this.onlyShowFeatured ? whereIsFeatured() : {}),
          ...(this.onlyShowExtended ? whereIsExtended() : {}),
          ...(this.onlyShowMarathon ? whereIsMarathon() : {}),
          ...(this.onlyShowChapters && this.isChapterMember ? whereIsChapter(this.$auth.user.chapter_members[0]) : {}), //only works for one chapter
          ...(!this.onlyShowChapters || !this.isChapterMember ? whereIsNotChapterOnly() : {})
        },
        starts_at: {
          _gte: add(startOfDay(day), { hours: 5 }),
          _lte: add(endOfDay(day), { hours: 5 })
        }
      };
    }
  },
  mounted() {
    this.onlyShowChapters = this.isChapterMember;
  },
  methods: {
    selectAny() {
      this.onlyShowFeatured = false;
      this.onlyShowExtended = false;
      this.onlyShowMarathon = false;
    },
    selectFeatured() {
      this.onlyShowFeatured = true;
      this.onlyShowExtended = false;
      this.onlyShowMarathon = false;
    },
    selectExtended() {
      this.onlyShowFeatured = false;
      this.onlyShowExtended = true;
      this.onlyShowMarathon = false;
    },
    selectMarathon() {
      this.onlyShowFeatured = false;
      this.onlyShowExtended = false;
      this.onlyShowMarathon = true;
    },
    selectMyChapters() {
      this.onlyShowChapters = true;
    },
    selectNoChapters() {
      this.onlyShowChapters = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.events-nav {
  @include tablet {
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: solid 1px #dfdfe2;
    padding-bottom: 5px;
  }
}
.event-type-dropdown {
  @include tablet {
    margin-top: 3px;
  }
  @include desktop {
    margin-top: 2px;
  }
}
</style>
<style lang="scss">
.b-tabs.day-tabs {
  .tab-content {
    display: none !important; // we're just using tabs for the UI
  }
  a {
    @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  ul {
    border-bottom: none;
  }
}
</style>
