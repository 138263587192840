<template>
  <Page>
    <Section>
      <Breadcrumbs :breadcrumbs="breadcrumbs" />

      <!-- Skeleton View -->
      <div v-if="$apollo.loading">
        <div v-for="n in 6" :key="n" class="expanded-card is-flex boxed mb-2 p-2">
          <div class="is-flex is-flex-direction-column is-justify-content-space-center">
            <div>
              <div class="m-2">
                <b-skeleton circle width="96px" height="96px"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="is-flex-grow-1">
            <div>
              <div class="mb-2">
                <b-skeleton width="30%" height="32px"></b-skeleton>
              </div>
              <b-skeleton height="96px"></b-skeleton>
            </div>
          </div>
          <div class="is-right">
            <div class="is-align-self-flex-end">
              <b-skeleton width="120px"></b-skeleton>
            </div>
            <div>
              <slot name="right"></slot>
            </div>
            <div class="is-align-self-flex-end">
              <div class="is-flex">
                <span class="icon is-small mr-2">
                  <b-skeleton></b-skeleton>
                </span>
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Content Loaded -->
      <div v-else>
        <ChallengePromptCardCoordinator
          v-if="prompt.challenge"
          class="mb-3"
          :prompt="prompt"
          is-header
        />
        <NakedPromptCardCoordinator v-else class="boxed elevation-1 mb-3" :prompt="prompt" is-header />
        <div class="is-flex is-align-items-center mb-1 is-justify-content-space-between">
          <!-- List Controls -->
          <div class="tabs is-danger is-medium mb-0">
            <ul>
              <li key="Latest" :class="{ 'is-active': selectedSortOption === 'Latest' }">
                <a class="button discussions-button" @click="setSortOption('Latest')">
                  <b-icon icon="exchange-alt" custom-class="fa-rotate-90" />
                  <span class="button-label">Latest</span>
                </a>
              </li>
              <li key="Popularity" :class="{ 'is-active': selectedSortOption === 'Popularity' }">
                <a class="button discussions-button" @click="setSortOption('Popularity')">
                  <b-icon icon="star" />
                  <span class="button-label">Top Submissions</span>
                </a>
              </li>
            </ul>
          </div>
          <AuthButton
            class="is-right"
            type="button discussions-button elevation-1 has-text-weight-semibold has-text-info ml-1"
            label="Create New Submission"
            icon-left="plus-circle"
            :disabled="isShowingSubmissionForm"
            @click="isShowingSubmissionForm = !isShowingSubmissionForm"
          >
            <template #dropdown="{ login }">
              <div class="is-flex is-justify-content-center is-align-items-center p-2">
                <p class="mr-2">
                  Login Required
                </p>
                <b-button label="Sign Up / Log in" type="is-primary" @click="login" />
              </div>
            </template>
          </AuthButton>
        </div>

        <!-- New Submission Form -->
        <SubmissionCardCoordinator
          v-if="isShowingSubmissionForm"
          class="boxed my-2"
          :submission="null"
          :prompt="prompt"
          @created="isShowingSubmissionForm = false"
          @updated="isShowingSubmissionForm = false"
          @cancelled="isShowingSubmissionForm = false"
        />

        <div v-if="submissions.length">
          <SubmissionCardCoordinator
            v-for="s in orderedSubmissions"
            :key="s.id"
            class="boxed mb-2"
            :submission="s"
            :prompt="prompt"
            @updated="onUpdateSubmission"
          />
        </div>
        <div v-else>
          <EmptyState>
            No submissions yet
          </EmptyState>
        </div>
      </div>
    </Section>
  </Page>
</template>

<script>
import { isNotDeleted, isNotDraft } from "@/models/challenges/predicates.js";
import {
  GET_PROMPT_BY_PK,
  LIST_PROMPT_SUBMISSIONS
} from "@/models/challenges/operations.gql";

import Page from "@/components/common/Page.vue";
import Section from "@/components/common/Section.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import SubmissionCardCoordinator from "@/components/submissionCard/SubmissionCardCoordinator.vue";
import ChallengePromptCardCoordinator from "@/components/challengePromptCard/ChallengePromptCardCoordinator.vue";
import NakedPromptCardCoordinator from "@/components/nakedPromptCard/NakedPromptCardCoordinator.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import AuthButton from "@/components/common/AuthButton.vue";

export default {
  name: "PromptDetail",
  components: {
    Page,
    Section,
    Breadcrumbs,
    SubmissionCardCoordinator,
    ChallengePromptCardCoordinator,
    NakedPromptCardCoordinator,
    EmptyState,
    AuthButton
  },
  data() {
    return {
      isShowingSubmissionForm: false,
      prompt: null,
      submissions: [],
      selectedSortOption: "Latest",
      onlyShowMine: false,
      popularSubmissions: []
    };
  },
  computed: {
    breadcrumbs() {
      const result = [];

      if (this.prompt) {
        if (this.prompt.challenge) {
          result.push({
            to: { name: "challenges" },
            label: "Challenges"
          });

          result.push({
            to: {
              name: "challenges_detail",
              params: {
                slug: this.prompt.challenge.slug
              }
            },
            label: this.prompt.challenge.title
          });
        } else {
          result.push({
            to: { name: "prompts" },
            label: "Prompts"
          });
        }
        result.push({
          to: {
            name: this.prompt.challenge ? "challenges_prompt" : "prompt_detail_naked",
            params: {
              challenge_slug: this.prompt.challenge ? this.prompt.challenge.slug : "",
              prompt_slug: this.prompt.challenge ? this.prompt.slug : "",
              prompt_id: this.prompt.id
            }
          },
          label: this.prompt.challenge ? this.prompt.title : ""
        });
      }

      return result;
    },
    whereClause() {
      return {
        prompt_id: { _eq: this.$route.params.prompt_id },
        ...isNotDeleted()
      };
    },
    canAddSubmissions() {
      return true; //this.prompt.challenge === null; // TODO: find the acceptance record
    },
    orderedSubmissions() {
      if (this.selectedSortOption === "Popularity") {
        return this.popularSubmissions;
      }
      return this.submissions;
    }
  },
  apollo: {
    prompt: {
      query: GET_PROMPT_BY_PK,
      variables() {
        return {
          prompt_id: this.$route.params.prompt_id
        };
      },
      error() {
        this.display404Page();
      },
      update({ prompt }) {
        //this.$log.debug("in update", prompt);
        const p = prompt[0] || null;
        return p || this.display404Page();
      }
    },
    submissions: {
      query: LIST_PROMPT_SUBMISSIONS,
      variables() {
        if (this.prompt) {
          return {
            prompt_id: this.prompt.id,
            where: {
              ...isNotDeleted(),
              ...isNotDraft()
            },
            orderBy: { created_at: "desc" }
          };
        }

        return null;
      },
      skip() {
        // Skip fetching submissions if there is no prompt loaded
        return !this.prompt;
      },
      update({ submissions, submissions_aggregate: { aggregate } }) {
        //this.$log.debug("in update", submissions, aggregate);
        this.totalSubmissions = aggregate.count;

        if (this.popularSubmissions.length === 0) {
          this.popularSubmissions = submissions.map(p => p);
          this.popularSubmissions.sort((a, b) => {
            return b.upvotes.aggregate.count - a.upvotes.aggregate.count;
          });
        } else {
          //preserve original sort; append new submissions to the end of the list
          for (var i = 0; i < this.popularSubmissions.length; i++) {
            for (var j = 0; j < submissions.length; j++) {
              if (submissions[j].id === this.popularSubmissions[i].id) {
                this.popularSubmissions[i] = submissions[j];
              }
            }
          }
          var newSubmissions = submissions.filter(x => !this.popularSubmissions.includes(x));
          for (var k = 0; k < newSubmissions.length; k++) {
            this.popularSubmissions.push(newSubmissions[k]);
          }
        }
        //this.$log.debug("in update", this.popularSubmissions, aggregate);

        return submissions;
      }
    }
  },
  methods: {
    setSortOption(o) {
      this.selectedSortOption = o;
    },
    onCreateSubmission(submission) {
      this.$log.debug("Submission Created", submission);
      this.isShowingSubmissionForm = false;
    },
    onUpdateSubmission(submission) {
      this.$log.info("Submission Updated", submission);
    }
  }
};
</script>
