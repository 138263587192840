<template>
  <span class="pill">
    <b-icon
      v-if="icon !== null"
      :icon="icon"
      :type="iconType"
      :pack="iconPack"
      class="pill-icon"
      size="is-small"
    />
    <span class="pill-content">
      <slot>DEFAULT PILL CONTENT</slot>
    </span>
  </span>
</template>

<script>
export default {
name: "Pill",
props: {
  icon: {
    type: String,
    default: null
  },
  iconType: {
    type: String,
    default: null // use b-icon's default
  },
  iconPack: {
    type: String,
    default: ""
  }
}
};
</script>

<style lang="scss" scoped>
@import "mixins";
$font-size: 0.9em;
$padding: .15em .5em 0.3em 0.5em;
$border-radius: 1em;

.pill {
  display: flex;
  align-items: center;
  font-size: $font-size;
  font-weight: 500;
  padding: $padding;
  border-radius: $border-radius;
  color: $grey600;
  background: $grey100;
  border: 1px solid transparent;
  @include mobile {
    font-size: vwl(9px);
  }
  @include portrait {
    font-size: vwp(9px);
  }

  &.is-success {
    border: 1px solid $success;
    color: $success;
  }

  &.is-dark {
    background: $grey300;
    color: $grey700;
  }

  &.strike {
    text-decoration: line-through;
  }

  .pill-icon {
    width: 0.9rem; // TODO: HACK: this only works on thin icons like the map-marker
    font-size: 0.9rem;
    margin-right: 0.2rem;
    @include mobile {
      $size: vwl(14px);
      width: $size;
      height: $size;
    }
    @include portrait {
      $size: vwp(12px);
      width: $size;
      height: $size;
    }
  }
  .pill-content {
    @include mobile {
      padding-top: vwl(4px);
    }
    @include portrait {
      padding-top: vwp(4px);
    }
  }
  &.author {
    padding: 2px 0.75rem 2px 2px;
    border-radius: 18px;
    .pill-content {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
