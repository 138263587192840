<template>
  <FlyoutButton
    v-if="event && !isConfirming"
    ref="seriescancel_flyout"
    trigger-button-size=""
    :trigger-button-type="
      event.template.cancelled_by_user_at
        ? 'has-text-success  elevation-1'
        : 'has-text-danger elevation-1'
    "
    :trigger-button-text="triggerButtonText"
    trigger-button-icon=""
    query-string-toggle="cancel"
    :position="position"
    :login-required="true"
    :close-on-click="false"
    :can-close="false"
    :expanded="expanded"
  >
    <template #default>
      <div v-if="showReinstate">
        <div v-if="cancelType === 'series'">
          <h4>Reinstate this Series?</h4>
          <p class="has-text-dark">
            You're about to reinstate the series.
          </p>
        </div>
        <div v-else>
          <h4>Reinstate this Event?</h4>
          <p class="has-text-dark">
            You're about to reinstate this event.
          </p>
        </div>
      </div>
      <div v-else>
        <!-- cancel event; give them a radio button choice. -->
        <h4>Cancel this {{cancelType}}</h4>
        <p v-if="cancelType === 'series'" class="has-text-dark">
          All currently scheduled events will also be cancelled and the system
          will stop creating new events for this series. Your discussion topics
          will remain active. You can reinstate a cancelled series. Do you want
          to proceed?
        </p>
        <p v-else>
          This event will be cancelled and all those RSVPd will me notified.
          Your discussion topics will remain active. You can reinstate a
          cancelled event. Do you want to proceed?
        </p>
      </div>

      <UndoConfirmButtons
        :confirm-enabled="true"
        :confirm-button-type="showReinstate ? 'is-success' : 'is-danger'"
        @confirm="onConfirm"
        @undo="closeFlyout"
      >
        <template #undo>
          No, leave it as-is
        </template>
        <template #confirm>
          Yes, continue
        </template>
      </UndoConfirmButtons>
    </template>
  </FlyoutButton>
</template>

<script>
import FlyoutButton from "@/components/common/FlyoutButton.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import { UPDATE_SERIES } from "@/models/series/operations.gql";
import { UPDATE_SINGLE_EVENT } from "@/models/events/operations.gql";

export default {
  name: "CancelCard",
  components: {
    FlyoutButton,
    UndoConfirmButtons
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    position: {
      // same as b-dialog
      type: String,
      default: "is-bottom-right"
    },
    cancelType: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isConfirming: false
    };
  },
  computed: {
    triggerButtonText() {
      if (this.cancelType === "series") {
        return this.event && this.event.template.cancelled_by_user_at
          ? "Reinstate"
          : "Cancel Series";
      } else {
        return this.event && this.event.cancelled_by_user_at
          ? "Reinstate"
          : "Cancel Event";
      }
    },
    showReinstate() {
      // events can be cancelled 2 ways (EXDATE on the template's RRuleSet)
      return (
        this.event.template.cancelled_by_user_at !== null ||
        this.event.cancelled_by_user_at
      );
    }
  },
  methods: {
    closeFlyout() {
      this.$refs.seriescancel_flyout.close();
    },
    onConfirm() {
      if (!this.showReinstate) {
        this.isConfirming = true;
      }
      return this.showReinstate ? this.mutateSeries() : this.askToConfirm();
    },
    askToConfirm() {
      this.$buefy.dialog.prompt({
        title: "Confirm " + this.cancelType + " cancellation",
        type: "is-danger",
        message:
          "To cancel this " + this.cancelType + " type <strong>CANCEL</strong> in the box below",
        inputAttrs: {
          class: "input is-danger"
        },
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (v, dialog) => {
          if (v.trim().toLowerCase() === "cancel") {
            this.isConfirming = false;
            dialog.close();
            this.mutateSeries();
          } // TODO else wiggle it or something?
        },
        onCancel: () => {
          this.isConfirming = false;
        }
      });
    },
    mutateSeries() {
      const variables =
        this.cancelType === "series"
          ? {
              id: this.event.template.id,
              venue_id: this.event.template.venue.id,
              series_changes: {
                cancelled_by_user_at: this.showReinstate ? null : "now()"
              },
              organizers: []
            }
          : {
              event_id: this.event.id,
              event_changes: {
                cancelled_by_user_at: this.showReinstate ? null : "now()"
              }
            }
      this.$apollo
        .mutate({
          mutation:
            this.cancelType === "series" ? UPDATE_SERIES : UPDATE_SINGLE_EVENT,
          variables,
          update:
            this.cancelType === "series"
              ? (cache, { data: { template_result } }) => {
                  cache.evict(cache.identify(template_result));
                  cache.evict(cache.identify(this.event));
                  cache.gc();
                  return template_result;
                }
              : (cache, { data: { event_result } }) => {
                  cache.evict(cache.identify(event_result));
                  cache.evict(cache.identify(this.event));
                  cache.gc();
                  return event_result;
                }
        })
        .then(() => {
          const message = this.showReinstate
            ? this.cancelType + " Reinstated"
            : this.cancelType + " Cancelled";
          this.$buefy.snackbar.open({
            message,
            type: "is-success"
          });
          this.$emit("confirm");
        });
    }
  }
};
</script>
