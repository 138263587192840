<template>
  <div :class="`${suwDesktop ? 'section container' : 'p-1'}`">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />

    <!-- Skeleton View -->
    <div v-if="$apollo.loading">
      <div v-for="n in 6" :key="n" class="expanded-card is-flex boxed mb-2 p-2">
        <div class="is-flex is-flex-direction-column is-justify-content-space-center">
          <div>
            <div class="m-2">
              <b-skeleton circle width="96px" height="96px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="is-flex-grow-1">
          <div>
            <div class="mb-2">
              <b-skeleton width="30%" height="32px"></b-skeleton>
            </div>
            <b-skeleton height="96px"></b-skeleton>
          </div>
        </div>
        <div class="is-right">
          <div class="is-align-self-flex-end">
            <b-skeleton width="120px"></b-skeleton>
          </div>
          <div>
            <slot name="right"></slot>
          </div>
          <div class="is-align-self-flex-end">
            <div class="is-flex">
              <span class="icon is-small mr-2">
                <b-skeleton></b-skeleton>
              </span>
              <span class="icon is-small">
                <b-skeleton></b-skeleton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SubmissionCardCoordinator
      v-if="submission"
      class="post-card-view boxed p-2"
      :class="`${suwDesktop ? 'mb-4' : 'mb-2'}`"
      :submission="submission"
      :prompt="submission.prompt"
      is-header
    />

    <div class="is-flex is-justify-content-space-between is-align-items-center mb-1">
      <div class="is-flex is-align-items-center">
        <p class="has-text-grey600" :class="`${suwDesktop ? 'is-size-3' : 'is-size-4'}`">Comments</p>
      </div>
      <!-- List Controls - Desktop -->
      <div class="is-flex is-hidden-mobile">
        <b-button
          v-if="$auth.isAuthenticated"
          type="elevation-1 has-text-weight-semibold has-text-info ml-1"
          label="Create New Comment"
          icon-left="plus-circle"
          :disabled="showPostForm"
          @click="showPostForm = !showPostForm"
        />
      </div>

      <!-- List Controls - Mobile -->
      <div class="is-flex is-hidden-tablet">
        <b-button
          v-if="$auth.isAuthenticated"
          type="is-danger is-outlined"
          icon-left="plus"
          :disabled="showPostForm"
          @click="showPostForm = true"
        />
      </div>
    </div>

    <PostCard
      v-if="showPostForm"
      class="boxed p-2 mb-2"
      :post="null"
      :topic="{ id: submission.topic_id }"
      @created="showPostForm = false"
      @updated="showPostForm = false"
      @cancel="showPostForm = false"
    />

    <div class="boxed" v-show="posts && posts.length > 0">
      <PostCard v-for="p in posts" :depth="0" :id="p.id" :key="p.id" :post="p" :topic="{ id: submission.topic_id }" />
    </div>
  </div>
</template>

<script>
import { isNotDraft } from "@/models/challenges/predicates.js";
import { GET_SUBMISSION_BY_PK } from "@/models/challenges/operations.gql";
import { LIST_TOPIC_POSTS } from "@/models/forums/operations.gql";

import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import SubmissionCardCoordinator from "@/components/submissionCard/SubmissionCardCoordinator.vue";
import PostCard from "@/components/forums/PostCard.vue";

export default {
  name: "SubmissionDetail",
  components: {
    Breadcrumbs,
    SubmissionCardCoordinator,
    PostCard
},
  data() {
    return {
      submission: null,
      showPostForm: false
    };
  },
  computed: {
    breadcrumbs() {
      const result = [];

      if (this.$route.params.challenge_slug) {
        result.push({
          to: { name: "challenges" },
          label: "Challenges"
        });

        if (this.submission) {
          result.push({
            to: {
              name: "challenges_detail",
              params: {
                slug: this.$route.params.challenge_slug
              }
            },
            label: this.submission.prompt.challenge.title
          });
        }
      } else {
        result.push({
          to: { name: "prompts" },
          label: "Prompts"
        });
      }

      if (this.submission) {
        if (this.$route.params.challenge_slug) {
          result.push({
            to: {
              name: "challenges_prompt",
              params: {
                challenge_slug: this.$route.params.challenge_slug,
                prompt_slug: this.submission.prompt.slug,
                prompt_id: this.submission.prompt.id
              }
            },
            label: this.submission.prompt.title
          });
        } else {
          result.push({
            to: {
              name: "prompt_detail_naked",
              params: {
                prompt_id: this.submission.prompt.id
              }
            },
            label: "Prompt"
          });
        }

        result.push({
          to: {
            name:
              this.submission.prompt.challenge === null ? "submission_detail" : "challenge_submission_detail",
            params: {
              challenge_slug: this.submission.prompt.challenge ? this.submission.prompt.challenge.slug : "",
              prompt_slug: this.submission.prompt.challenge ? this.submission.prompt.slug : "",
              submission_id: this.submission.id
            }
          },
          label: this.submission.title
        });
      }

      return result;
    }
  },
  apollo: {
    submission: {
      query: GET_SUBMISSION_BY_PK,
      variables() {
        return {
          submission_id: this.$route.params.submission_id
        };
      },
      error() {
        this.display404Page();
      },
      update({ submissions }) {
        const submission = submissions[0] || null;
        return submission || this.display404Page();
      }
    },
    posts: {
      query: LIST_TOPIC_POSTS,
      variables() {
        if (this.submission) {
          return {
            topic_id: this.submission.topic_id,
            where: {
              ...isNotDraft(),
              parent_id: { _is_null: true } // Get only root posts
            },
            orderBy: { created_at: "asc" }
          };
        }

        return null;
      },
      skip() {
        return !this.submission;
      }
    }
  }
};
</script>
