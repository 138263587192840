<template>
  <div :class="!$route.meta.hideNav ? 'main-col' : ''">
    <GlobalSubscriptions v-if="$auth.isAuthenticated && !$route.meta.hideNav" />
    <MainNavigation v-if="!$route.meta.hideNav" class="no-grow" />
    <NotFoundPage v-if="notFound" />
    <router-view
      v-else
      class="page-content grow"
      :class="$router.currentRoute.path.replace(/\//, '').split('/')[0]"
      @not-found="notFound = true"
    />
    <MainFooterCoordinator v-if="!$route.meta.hideNav" class="no-grow" />
  </div>
</template>

<script>
import MainNavigation from "@/components/common/MainNavigation.vue";
import MainFooterCoordinator from "@/components/common/MainFooterCoordinator.vue";
import GlobalSubscriptions from "@/components/common/GlobalSubscriptions.vue";
import NotFoundPage from "@/views/home/NotFoundPage.vue";

export default {
  name: "App",
  metaInfo: {
    titleTemplate: "%s Shut Up & Write"
  },
  components: {
    GlobalSubscriptions,
    MainNavigation,
    MainFooterCoordinator,
    NotFoundPage
  },
  data() {
    return {
      notFound: false
    };
  },
  watch: {
    $route() {
      this.notFound = false;
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
@import "index.scss";

.main-col {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .grow {
    flex: 1 0 auto;
    @include desktop {
      width: 100%;
    }
  }
  .no-grow {
    flex-grow: 0;
    flex-shrink: 0;
    @include touch {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
