<template>
  <div v-if="eventTemplate">
    <div v-if="eventTemplate.featured_at">
      <h5 class="mb-2"><strong>Unfeature Series?</strong></h5>
      <p>This will remove this series from the global featured items</p>
      <p>Featured: <InlineDate :d="eventTemplate.featured_at" /></p>
      <UndoConfirmButtons
        :confirm-enabled="!$apollo.loading"
        confirm-button-type="is-danger"
        @undo="$emit('undo')"
        @confirm="setFeatured(false)"
      >
        <template #confirm>
          Unfeature This Series
        </template>
      </UndoConfirmButtons>
    </div>

    <div v-else>
      <h5 class="mb-2">Feature Series?</h5>
      <p>
        Confirming will set this series as featured, making it more easily
        discovered on the main event pages.
      </p>
      <UndoConfirmButtons
        :confirm-enabled="!$apollo.loading"
        confirm-button-type="is-success"
        @undo="$emit('undo')"
        @confirm="setFeatured(true)"
      >
        <template #confirm>
          Feature This Series
        </template>
      </UndoConfirmButtons>
    </div>
  </div>
</template>

<script>
import { UPDATE_SERIES } from "@/models/series/operations.gql";

import InlineDate from "@/components/common/InlineDate.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";

export default {
  name: "FeaturedControl",
  components: {
    InlineDate,
    UndoConfirmButtons
  },
  props: {
    eventTemplate: {
      type: Object,
      required: true
    }
  },
  methods: {
    setFeatured(isFeatured = true) {
      this.$log.info("will set featured_at to", isFeatured);
      this.$emit("confirm"); // pass through from UndoConfirmButtons

      this.$apollo
        .mutate({
          mutation: UPDATE_SERIES,
          variables: {
            id: this.eventTemplate.id,
            venue_id: this.eventTemplate.venue.id,
            organizers: [],
            series_changes: {
              featured_at: isFeatured ? "now()" : null
            }
          }
        })
        .then(result => {
          this.$log.debug("result", result);
          this.$buefy.snackbar.open({
            message: isFeatured ? "Series Featured" : "Series Unfeatured",
            type: "is-success"
          });
        });
    }
  }
};
</script>
