<template>
  <div>
    <div class="event-location elevation-2" v-if="value">
      <div>
        <h5>{{ value.title }}</h5>
        <p>{{ value.host_city || value.street_address }}</p>
      </div>
      <div class="directions-button">
        <b-button
          v-if="showVenueLocation"
          class="is-warning is-inverted has-text-weight-bold"
          tag="a"
          target="_blank"
          type="button"
          icon-left="link"
          icon-pack="fas"
          :href="directionsURL"
          >Directions</b-button
        >
      </div>
    </div>

    <div class="events-container pt-1">
      <slot name="top-corner"></slot>
      <transition v-if="!hideForm" name="slide">
        <keep-alive>
          <!-- PHYSICAL EVENT FORM -->
          <div v-if="form">
            <div v-if="isEditing">
              <b-field
                label="Location / Address"
                :type="{ 'is-danger': $v.form.api_result.$invalid }"
                :message="{
                  'Choosing a venue is required!': form.api_result === null
                }"
              >
                <GooglePlaces
                  :key="`ac-${autocompleteKey}`"
                  :value="form.api_result"
                  @updated:place="onPlaceChanged"
                />
              </b-field>

              <b-field label="How can attendees find you?">
                <b-input
                  v-model="form.find_me"
                  placeholder="How can attendees find you at the event?"
                />
              </b-field>

              <b-field label="Parking/Transit Info">
                <b-input
                  v-model="form.parking_info"
                  placeholder="Parking / Public Transit Info"
                />
              </b-field>

              <b-field label="WiFi Info">
                <b-input
                  v-model="form.wifi_info"
                  placeholder="Wifi network name and password..."
                />
              </b-field>
            </div>
            <div v-else-if="value">
              <b-field v-if="value.find_me" label="How to Find us">
                <p>{{ value.find_me }}</p>
              </b-field>

              <b-field v-if="showVenueLocation && value.parking_info" label="Parking / Transit Info">
                <p>{{ value.parking_info }}</p>
              </b-field>

              <b-field v-if="showVenueLocation && value.wifi_info" label="Wifi Name/Password">
                <p>{{ value.wifi_info }}</p>
              </b-field>
            </div>
          </div>
          </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import venueCardPhysical from "@/components/venueCard/VenueCardPhysical.js";
/* global google */
import GooglePlaces from "@/components/forms/GooglePlaces.vue";
import { gmapsMap, gmapsMarker } from "x5-gmaps";

export default {
  name: "VenueCardPhysicalMobile",
  mixins: [venueCardPhysical],
  components: {
    GooglePlaces,
    gmapsMap,
    gmapsMarker
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.directions-button {
  text-align: right;
  margin-top: 1rem;
  a {
    @include mobile {
      padding-top: vwl(6px);
      padding-bottom: vwl(4px);
    }
  }
}
</style>
