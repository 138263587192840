<template>
  <div class="action-card elevation-2">
    <img v-if="iconUrl" :src="iconUrl" />
    <div class="question">
      <h3>
        <slot name="question">Default Question Slot</slot>
      </h3>
      <slot name="answer">Default Answer Slot</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  props: {
    iconUrl: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.faq.container {
  h3 {
    line-height: 1;
  }
  p {
    font-weight: 300;
  }
}
</style>

<style lang="scss" scoped>
@import "../../scss/mixins";
.action-card {
  align-items: flex-start;
  @include mobile {
    padding: vwl(12px) vwl(12px) vwl(20px) vwl(12px);
  }
  @include portrait {
    padding: vwp(12px) vwp(12px) vwp(20px) vwp(12px);
  }
  img {
    margin: 0 0.75rem 0 0;
    $size: 48px;
    width: $size;
    height: $size;
    @include mobile {
      $size: vwl(32px);
      width: $size;
      height: $size;
      margin: 0 vwl(12px) 0 0;
    }
    @include portrait {
      $size: vwp(32px);
      width: $size;
      height: $size;
      margin: 0 vwp(12px) 0 0;
    }
    @include desktop {
      $size: 58px;
      width: $size;
      height: $size;
    }
  }
  .question {
    flex-grow: 1;
    @include tablet {
      padding: 0.75rem 3rem 0.5rem 0;
    }
    @include desktop {
      padding: 1rem 6rem 0.5rem 0;
    }
    @include widescreen {
      padding: 1rem 8rem 0.5rem 0;
    }
  }
  h3 {
    @include mobile {
      margin-top: vwl(8px);
    }
    @include portrait {
      margin-top: vwp(8px);
    }
  }
  p {
    margin-bottom: 0;
  }
}
</style>
