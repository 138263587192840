<template>
  <footer>
    <div class="container">
      <div class="center-on-touch my-4">
        <router-link to="/"><BrandCircle /></router-link>
      </div>
      <div class="has-text-centered mt-2 mb-3">
        <div class="has-text-weight-bold mb-2">
          <router-link :to="{ name: 'contact' }">
            Contact Us
          </router-link>
        </div>
        <ul>
          <li class="mb-2">
            <p>
              2261 Market Street #4978
              <br />
              San Francisco, CA 94114
            </p>
          </li>
          <li class="ml-1">
            <a href="https://www.facebook.com/shutupnwrite/" target="_blank" rel="noopener noreferrer">
              <img src="/img/facebook.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/shutupwrite/" target="_blank" rel="noopener noreferrer">
              <img src="/img/instagram.svg" alt="" class="mx-2" />
            </a>
            <a href="https://twitter.com/shutupwrite" target="_blank" rel="noopener noreferrer">
              <img src="/img/twitter.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>

      <div class="is-flex has-text-centered is-justify-content-center mb-2">
        <table>
          <tr>
            <td class="has-text-weight-bold"><div class="mb-2 mx-5">Resources</div></td>
            <td class="has-text-weight-bold"><div class="mb-2 mx-5">Support</div></td>
          </tr>
          <tr>
            <td>
              <ul class="site-links">
                <li><router-link :to="{ name: 'organizer' }">Become an Organizer</router-link></li>
                <li><router-link :to="{ name: 'downloads' }">Downloads</router-link></li>
                <li><a href="https://store.shutupwrite.com/" target="_blank" rel="noopener noreferrer">Store</a></li>
                <li><router-link :to="{ name: 'about' }">About Us</router-link></li>
              </ul>
            </td>
            <td>
              <ul class="site-links">
                <li><router-link :to="{ path: '/faq' }">FAQ</router-link></li>
                <li><router-link :to="{ path: '/organizer-handbook' }">Organizer Handbook</router-link></li>
                <li><router-link :to="{ name: 'organizer-faq'}">Organizer FAQ</router-link></li>
                <li><router-link :to="{ name: 'code_of_conduct' }">Code of Conduct</router-link></li>
              </ul>
            </td>
          </tr>         
        </table>
      </div>
    </div>

    <div class="copyright has-text-centered">
      <div>
        &copy; {{ new Date().getFullYear() }} Shut Up & Write! All rights reserved.
      </div>
      <div class="site-links mt-1">
        <router-link :to="{ name: 'tos' }">Terms of Use</router-link>
        <router-link :to="{ name: 'legal' }">Legal</router-link>
        <router-link :to="{ name: 'privacy_policy' }">Your Privacy</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import BrandCircle from "@/components/common/BrandCircle.vue";
export default {
  name: "MainFooterMobile",
  components: {BrandCircle}
};
</script>

<style lang="scss">
footer {
  color: $grey900;
  background-color: $gold;

  li {
    padding: .15em 0;
  }

  .copyright {
    border-top: 1px solid $grey900;
    padding: 2em;
    font-size: .9em;
  }

  a {
    display: inline-block;
    color: $grey900;
    transition: all 0.2s;

    img {
      max-width: 2.65em;
    }

    &:hover,
    &:focus {
      color: $blue;
    }
  }
  .site-links {
    a {
      border-bottom: solid 1px transparent;
      &:hover,
      &:focus {
        color: #000;
        border-bottom: solid 1px #000;
      }
    }
  }
}
</style>
