<template>
  <b-dropdown
    ref="dropdown"
    :position="position"
    :expanded="expanded"
    :disabled="triggerButtonDisabled"
    :close-on-click="closeOnClick"
    :can-close="canClose"
    @active-change="activeChange($event)"
  >
    <b-button
      slot="trigger"
      :disabled="disabled"
      :size="triggerButtonSize"
      :type="triggerButtonType"
      :icon-left="triggerButtonIcon"
      :expanded="expanded"
    >
      {{ triggerButtonText }}
    </b-button>
    <template slot="default">
      <section class="has-text-left px-2 py-2">
        <div v-if="loginRequired && !$auth.isAuthenticated">
          <slot name="login-required">
            <p class="is-size-4 has-text-weight-semibold">
              Login Required
            </p>
          </slot>
          <UndoConfirmButtons
            :confirm-enabled="true"
            @undo="toggle"
            @confirm="loginAndComeBackWithFlyOpen"
          >
            <template #confirm>
              Log In / Sign Up
            </template>
          </UndoConfirmButtons>
        </div>
        <div v-else>
          <slot name="default">
            Default Flyout Content
          </slot>
        </div>
      </section>
    </template>
  </b-dropdown>
</template>

<script>
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";

export default {
  name: "FlyoutButton",
  components: {
    UndoConfirmButtons
  },
  props: {
    triggerButtonDisabled: {
      type: Boolean,
      default: false
    },
    triggerButtonType: {
      type: String,
      default: "is-primary"
    },
    triggerButtonSize: {
      type: String,
      default: ""
    },
    triggerButtonText: {
      type: String,
      default: "Open Flyout"
    },
    triggerButtonIcon: {
      type: String,
      default: "folder-open"
    },
    queryStringToggle: {
      type: String,
      default: null
    },
    loginRequired: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "is-bottom-right"
    },
    expanded: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query[this.queryStringToggle]) {
        this.$refs.dropdown.toggle();
      }
    });
  },
  methods: {
    open() {
      if (!this.isActive) this.toggle();
    },
    close() {
      if (this.isActive) this.toggle();
    },
    toggle() {
      this.$refs.dropdown.toggle();
      this.isActive = !this.isActive;
    },
    activeChange(e) {
      this.$emit("active-change", e);
      this.isActive = e;
    },
    loginAndComeBackWithFlyOpen() {
      this.$log.debug("BRB");
      this.$router.push({
        name: "login",
        query: {
          redirectUrl: `${this.$route.fullPath}?${this.queryStringToggle}=1`
        }
      });
    }
  }
};
</script>
