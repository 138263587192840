<template>
  <div
    v-if="!isDangerous || $auth.isAdmin"
    class="submission-card"
    :class="{
      'mt-2': isHeader
    }"
  >
    <SubmissionCardViewHeader
      v-if="isViewHeader && !isEdit"
      :submission="submission"
      @edit="setEdit()"
      @delete="onDelete()"
      @allow="onAllow()"
      @report="onReport()"
      @upvote="onUpvote()"
    />

    <SubmissionCardViewExpanded
      v-if="isViewExpanded && !isEdit"
      :prompt="prompt"
      :submission="submission"
      @edit="setEdit()"
      @delete="onDelete()"
      @allow="onAllow()"
      @report="onReport()"
      @upvote="onUpvote()"
    />

    <SubmissionCardEdit
      v-if="isEdit"
      :prompt="prompt"
      :submission="submission"
      @created="onCreated($event)"
      @updated="onUpdated($event)"
      @cancelled="onCancelled()"
    />
  </div>
</template>

<script>
import {
  UPDATE_SUBMISSION,
  CREATE_SUBMISSION_REACTION,
  DELETE_SUBMISSION_REACTION,
  REPORT_SUBMISSION
} from "@/models/challenges/operations.gql";
import SubmissionCardViewHeader from "@/components/submissionCard/SubmissionCardViewHeader.vue";
import SubmissionCardViewExpanded from "@/components/submissionCard/SubmissionCardViewExpanded.vue";
import SubmissionCardEdit from "@/components/submissionCard/SubmissionCardEdit.vue";

export default {
  name: "SubmissionCardCoordinator",
  components: {
    SubmissionCardViewHeader,
    SubmissionCardViewExpanded,
    SubmissionCardEdit
  },
  props: {
    prompt: {
      type: Object,
      default: null
    },
    // the submission you're showing/editing, null for create UX
    submission: {
      type: Object,
      default: null
    },
    isHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      now: new Date(),
      isViewHeader: false,
      isViewExpanded: false,
      isEdit: false
    };
  },
  computed: {
    isDangerous() {
      if (this.submission && this.submission.reports_aggregate.aggregate.count > 2 && this.submission.allowed_at === null) {
        return true;
      }
      return false;
    }
  },
  created() {
    if (this.isHeader) {
      this.setViewHeader();
    } else {
      if (this.submission) {
        this.setViewExpanded();
      } else {
        // We are creating a new submission, so turn on editing mode
        this.setEdit();
      }
    }
  },
  methods: {
    reset() {
      this.isViewHeader = false;
      this.isViewExpanded = false;
      this.isEdit = false;
    },
    setViewHeader() {
      this.reset();
      this.isViewHeader = true;
    },
    setViewExpanded() {
      this.reset();
      this.isViewExpanded = true;
    },
    setEdit() {
      this.isEdit = true;
    },
    unsetEdit() {
      this.isEdit = false;
    },
    onCreated(e) {
      this.$emit("created", e);
      this.unsetEdit();
    },
    onUpdated(e) {
      this.$emit("updated", e);
      this.unsetEdit();
    },
    onCancelled() {
      this.$emit("cancelled");
      this.unsetEdit();
    },
    onDelete() {
      this.$log.debug("Delete Submission", this.submission.id);
      this.$apollo
        .mutate({
          mutation: UPDATE_SUBMISSION,
          variables: {
            submission_id: this.submission.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.$emit("deleted", result);

          this.$buefy.snackbar.open({
            message: `Submission deleted`,
            type: "is-success"
          });
        });
    },
    onReport() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to report this Submission?",
        onConfirm: () => {
          this.onReportConfirmed();
        }
      });
    },
    onReportConfirmed() {
      this.$log.debug("Report Submission");
      const resolved_route = this.$router.resolve({
        name: this.prompt.challenge === null ? "submission_detail" : "challenge_submission_detail",
        params: {
          challenge_slug: this.prompt.challenge ? this.prompt.challenge.slug : "",
          prompt_slug: this.prompt.challenge ? this.prompt.slug : "",
          submission_id: this.submission.id
        }
      });
      this.$apollo
        .mutate({
          mutation: REPORT_SUBMISSION,
          variables: {
            submission_id: this.submission.id,
            reporting_user_id: this.$auth.user.id,
            reported_route: resolved_route.href
          }
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: `Submission Reported`,
            type: "is-success"
          });
        });
    },
    onUpvote() {
      if (this.submission.current_user_has_reacted) {
        this.$apollo
          .mutate({
            mutation: DELETE_SUBMISSION_REACTION,
            variables: {
              author_id: this.$auth.user.id,
              reaction_id: "upvote",
              submission_id: this.submission.id
            },
            update: cache => {
              cache.evict(cache.identify(this.submission));
              cache.gc();
            }
          })
          .then(() => {
            //this.upvoted = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: CREATE_SUBMISSION_REACTION,
            variables: {
              reaction: {
                submission_id: this.submission.id,
                reaction_id: "upvote"
              }
            },
            update: cache => {
              cache.evict(cache.identify(this.submission));
              cache.gc();
            }
          })
          .then(() => {
            //this.upvoted = true;
          });
      }
    },
    onAllow() {
      this.$log.debug("Allow Submission");
      this.$apollo
        .mutate({
          mutation: UPDATE_SUBMISSION,
          variables: {
            submission_id: this.submission.id,
            changes: {
              allowed_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.$emit("allowed", result);

          this.$buefy.snackbar.open({
            message: `Submission allowed`,
            type: "is-success"
          });
        });
    }
  }
};
</script>
