<template>
  <div class="events-container">
    <div class="event-listing" :class="{ 'featured': event.template.featured_at !== null }">
      <DateSquare
        :d="toLocalDate(event.starts_at || event.scheduled_starts_at)"
        :is-featured="event.template.featured_at !== null"
        :is-cancelled="event.template.cancelled_by_user_at !== null"
        size="medium"
      />
      <div class="event-info">
        <h2>
          {{ event.template | format_title | truncate_render }}
          <badge
            v-if="event.template.featured_at !== null && !suwMobile"
            data-type="featured"
            icon="flag"
            label="Featured"
          />
          <badge
            v-if="durationName === 'extended'"
            data-type="extended"
            icon="arrows-alt-h"
            label="Extended"
          />
          <badge
            v-if="durationName === 'marathon'"
            data-type="marathon"
            icon="running"
            label="Marathon"
          />
          <badge
            v-if="seriesIsUpdated"
            data-type="updated"
            label="Updated"
          />
        </h2>
        <h6>
          Hosted by {{ hostUser | user_name }}
        </h6>
      </div>

      <div class="actions">
        <div class="share-button">
          <CopyUrl :title="event.template | format_title | truncate_render" />
        </div>

        <b-tag type="is-success is-light">
          <b-icon icon="user-check" />
          <span>{{ event.template.members_aggregate.aggregate.count || "0" }}</span>
        </b-tag>

      </div>
    </div>
  </div>
</template>

<script>
import eventSeriesHeaderView from "@/components/eventSeries/EventSeriesHeaderView.js";
import DateSquare from "@/components/dates/DateSquare.vue";
import Badge from "@/components/common/Badge.vue";
import CopyUrl from "@/components/common/CopyUrl.vue";


export default {
  name: "EventSeriesHeaderViewMobile",
  components: {
    DateSquare,
    Badge,
    CopyUrl
  },
  mixins: [eventSeriesHeaderView],
  computed: {
    seriesIsUpdated() {
      //the events in this series contain a venue_id, but we need to check the absence of venue_id, which is contained within the upcoming_events of each event's template
      return (
        this.event.template.upcoming_events.filter(upcomingEvent => {
          return upcomingEvent.starts_at || upcomingEvent.venue_id;
        }).length > 0
      );
    },
    hostUser() {
      if (this.event.template.organizers.length < 1) {
        this.$log.warn("series has no organizers. event_template_id:", this.series.id);
        return null;
      }
      const u = this.event.template.organizers[0].user;
      return u;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.event-listing {
  display: flex;
  flex-direction: row;
  background: white;
  border: 2px solid $border;
  border-radius: 0.5em;
  @extend %elevation-1;
  @include mobile {
    padding: vwl(8px);
  }
  @include portrait {
    padding: vwp(8px);
  }
  @include tablet {
    padding: 1.25rem 1.5rem;
  }
  &.is-active {
    background: $warning-light;
  }
  &.featured {
    @include mobile {
      border: solid $gold 2px;
    }
  }

  h6 {
    color: #7a7a7a;
    font-size: .75rem;
    @include mobile {
      font-size: vwl(10px);
      margin-bottom: vwl(4px);
    }
    @include portrait {
      font-size: vwp(10px);
      margin-bottom: vwp(4px);
    }
    .pill {
      display: inline-flex;
      position: relative;
      @include mobile {
        top: vwl(2px);
      }
      @include portrait {
        top: vwp(2px);
      }
    }
  }

  

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    h6 {
      white-space: nowrap;
    }
  }

  
}
</style>