<template>
  <div :class="{ 'is-deleted': isDeleted, 'post-card-view': !state.isEditing, 'post-card-edit': state.isEditing }" class="is-flex is-flex-direction-column pt-2">
    <PostCardHeader v-if="post" :post="post" />
    <div class="is-flex" :class="{ 'mb-1': suwDesktop }">
      <div v-if="post && depth > -1" class="thread-line mx-2"></div>
      <div class="is-flex is-flex-grow-1 is-flex-direction-column">
        <TipTap
          ref="editor"
          v-model="form.body"
          :class="{ 'is-deleted': isDeleted, 'child-comment elevation-1': !state.isEditing }"
          class="post-editor mb-1"
          :show-edit-controls="state.isEditing"
          :editable="state.isEditing"
          :confirm-enabled="() => !$v.form.$invalid"
          :is-dangerous="isReported && !isAllowed"
          @edit-cancelled="onCancel"
          @edit-confirmed="onConfirm"
        />
        <PostCardTags 
          v-if="!state.isEditing" 
          :post="post" 
          :depth="depth" 
          :max-depth="maxDepth" 
          :expandReplies="state.isShowingReplies" 
          @reply="toggleReplyForm"
          @replies="toggleShowReplies"
          @edit="toggleEditing"
          @reported="onReported"
          @deleted="onDeleted"
          @restored="onRestored"
          @allowed="onAllowed"
        />
        <transition name="slide">
          <!-- Reply Form (PostCard is this file, crazy this works) -->
          <PostCard
            v-if="state.isShowingReplyForm"
            class="my-2 child-comment elevation-1"
            :parent="post"
            :topic="{ id: post.topic_id }"
            @cancel="state.isShowingReplyForm = false"
            @created="state.isShowingReplyForm = false; state.isShowingReplies = true;"
          />
        </transition>
        <transition name="slide">
          <div v-if="state.isShowingReplies">
            <PostCard
              v-for="p in posts"
              :key="p.id"
              :post="p"
              :depth="depth + 1"
              :max-depth="maxDepth"
              expand-replies
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  FORUMS_POST_ADD,
  FORUMS_POST_EDIT,
  LIST_POST_REPLIES
} from "@/models/forums/operations.gql";
import { required } from "vuelidate/lib/validators";
import TipTap from "@/components/forms/TipTap.vue";
import PostCardHeader from "@/components/forums/PostCardHeader.vue";
import PostCardTags from "@/components/forums/PostCardTags.vue";

export default {
  name: "PostCard",
  components: {
    TipTap,
    PostCardHeader,
    PostCardTags
  },
  props: {
    post: {
      // for showing/editing existing posts
      type: Object,
      default: null
    },
    parent: {
      type: Object,
      default: null
    },
    topic: {
      // for replying to existing topics
      type: Object,
      default: null
    },
    expandReplies: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number,
      default: 0
    },
    maxDepth: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      posts: [], // this gets loaded by apollo to find children of this post if the user wants to expand it
      state: {
        isShowingReplies: this.expandReplies,
        isShowingReplyForm: false,
        isEditing: false
      },
      form: {
        id: null,
        body: null,
        parent_id: null,
        topic_id: null
      }
    };
  },
  validations: {
    form: {
      body: {
        required
      }
    }
  },
  computed: {
    isDeleted() {
      if (this.post && this.post.deleted_at) {
        return true;
      }
      return false;
    },
    userCanEdit() {
      return (this.post &&
          !this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.post.author.id) ||
        (!this.isDeleted && (this.$auth.isAdmin || this.$auth.isOrganizer));
    },
    userCanDelete() {
     return (this.post &&
          !this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.post.author.id) ||
        (!this.isDeleted && this.$auth.isAdmin);
    },
    userCanRestore() {
      return (this.post &&
          this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.post.author.id) ||
          (this.isDeleted && this.$auth.isAdmin);
    },
    isReported() {
      if (this.post !== null && this.post.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.post !== null && this.post.allowed_at !== null) {
        return true;
      }
      return false;
    }
  },
  apollo: {
    posts: {
      query: LIST_POST_REPLIES,
      variables() {
        if (this.post) {
          return {
            parent_id: this.post.id
          };
        }

        return null;
      },
      skip() {
        return !this.post || !this.state.isShowingReplies;
      }
    }
  },
  watch: {
    post(val) {
      this.form.body = Object.assign({}, val.body);
    }
  },
  mounted() {
    if (this.topic) {
      this.form.topic_id = this.topic.id;
    }

    if (this.parent) {
      this.form.parent_id = this.parent.id;
    }

    if (this.post) {
      this.form.id = this.post.id;
      this.form.topic_id = this.post.topic_id;
      this.form.parent_id = this.post.id;
      this.form.body = Object.assign({}, this.post.body);
    } else {
      this.state.isEditing = true;
    }
  },
  methods: {
    toggleEditing() {
      this.state.isEditing = !this.state.isEditing;
    },
    toggleReplyForm() {
      this.state.isShowingReplyForm = !this.state.isShowingReplyForm;
    },
    toggleShowReplies() {
      this.state.isShowingReplies = !this.state.isShowingReplies;
    },
    onConfirm(body) {
      Object.assign(this.form, { body });

      if (this.post) {
        this.onUpdate();
      } else {
        this.onCreate();
      }
    },
    onCreate() {
      const { topic_id, parent_id, body } = this.form;
      this.$apollo
        .mutate({
          mutation: FORUMS_POST_ADD,
          variables: {
            topic_id,
            parent_id,
            body
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            if (this.topic !== null) {
              cache.evict({ id: `forums_topics:${this.topic.id}` });
            }
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$refs.editor.clear();
          this.form.id = null;
          this.form.body = null;
          this.$emit("created", result);
          this.$buefy.snackbar.open({
            message: `Post created`,
            type: "is-success"
          });
        });
    },
    onUpdate() {
      const { id, body } = this.form;
      this.$apollo
        .mutate({
          mutation: FORUMS_POST_EDIT,
          variables: {
            post_id: id,
            changes: {
              body
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            if (this.topic !== null) {
              cache.evict({ id: `forums_topics:${this.topic.id}` });
            }
            cache.gc();
            return result;
          }
        })
        .then(({ data: { result } }) => {
          if (this.post) {
            this.state.isEditing = false;
          }
          this.form = {
            ...result
          };
          this.$emit("updated", result);
          this.$buefy.snackbar.open({
            message: `Post updated`,
            type: "is-success"
          });
        });
    },
    onCancel() {
      if (this.post) {
        this.state.isEditing = false;
        this.form = {
          ...this.post
        };
      }
      this.$emit("cancel");
    },
    onReported(result) {
      this.$emit("reported", result);
    },
    onDeleted(result) {
      this.$emit("deleted", result);
    },
    onRestored(result) {
      this.$emit("restored", result);
    },
    onAllowed(result) {
      this.$emit("allowed", result);
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.post-card-view {
  background-color: $white;

  &.is-deleted {
    #{$text-selectors} {
      font-style: italic;
      color: $grey;
    }
  }

  .thread-line {
    border-right: 1px solid $grey400;
  }

  .child-comment {
    //each top level post under topic card doesn't have white container around body
    border: 1px solid #DDD;
    border-radius: 4px;
    background-color: #FFF;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 9px;
    padding-bottom: 6px;
  }
}
.post-card-edit {
  background-color: $white;
  @include mobile {
    padding: 1rem;
    border-radius: 4px;
  }

  .post-editor {
    width: 100%;
    &.is-editing {
      .ProseMirror {
        background-color: $white;
      }
    }
  }
}
.breadcrumb {
  @include mobile {
    font-size: 0.875rem;
    a {
      padding: 0 0.5em;
    }
  }
}
</style>
