var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('Section',[_c('Breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),(_vm.$apollo.queries.challenge.loading)?_c('div',{staticClass:"notification is-info is-light"},[_vm._v(" Loading... ")]):_vm._e(),(_vm.challenge)?[_c('ChallengeCardCoordinator',{staticClass:"card-container challenge-card-view-header",attrs:{"challenge":_vm.challenge,"is-header":""},on:{"accepted":_vm.onAccept}}),(_vm.sortablePrompts)?_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('div',{staticClass:"is-flex is-align-items-center"},[(
                (_vm.$auth.isAuthenticated && _vm.$auth.user.id === _vm.challenge.author.id) || _vm.$auth.isAdmin
              )?_c('b-switch',{staticClass:"ml-2",on:{"input":function (value) { return !value && _vm.resetSortOrder(); }},model:{value:(_vm.isEditingEnabled),callback:function ($$v) {_vm.isEditingEnabled=$$v},expression:"isEditingEnabled"}},[_vm._v(" Reorder Prompts ")]):_vm._e(),(_vm.isEditingEnabled)?_c('b-button',{staticClass:"is-small is-success",attrs:{"label":"Save Order"},on:{"click":_vm.saveSortOrder}}):_vm._e()],1),(
              _vm.$auth.isAuthenticated &&
                (_vm.$auth.isAdmin || _vm.challenge.author.id === _vm.$auth.user.id)
            )?_c('div',{staticClass:"reorder tabs is-danger is-flex is-align-items-center"},[_c('ul',{staticClass:"is-justify-content-end"},[_c('li',{staticClass:"is-flex is-hidden-tablet"},[(_vm.$auth.isAdmin)?_c('a',{staticClass:"button discussions-button",attrs:{"disabled":_vm.isShowingPromptForm},on:{"click":function($event){_vm.isShowingPromptForm = true}}},[_c('b-icon',{attrs:{"icon":"plus-circle"}}),_c('span',{staticClass:"button-label"},[_vm._v("Create New Prompt")])],1):_vm._e()]),_c('li',{staticClass:"is-flex is-hidden-mobile"},[((_vm.$auth.user.id === _vm.challenge.author.id) || _vm.$auth.isAdmin)?_c('b-button',{attrs:{"type":"discussions-button elevation-1 has-text-weight-semibold has-text-info ml-1","label":"Create New Prompt","icon-left":"plus-circle","disabled":_vm.isShowingPromptForm},on:{"click":function($event){_vm.isShowingPromptForm = !_vm.isShowingPromptForm}}}):_vm._e()],1)])]):_vm._e()]),(_vm.isShowingPromptForm)?_c('ChallengePromptCardCoordinator',{attrs:{"prompt":null,"challenge":_vm.challenge},on:{"created":_vm.onCreatePrompt,"updated":function($event){_vm.isShowingPromptForm = false},"cancelled":function($event){_vm.isShowingPromptForm = false}}}):_vm._e(),(_vm.sortablePrompts.length < 1)?_c('EmptyState',[_vm._v(" No prompts yet ")]):_c('DropList',{staticClass:"is-flex-column is-justify-content-space-between",attrs:{"items":_vm.sortablePrompts},on:{"reorder":function($event){return _vm.onReorder($event, _vm.sortablePrompts)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
            var reorder = ref.reorder;
return [_c('Drag',{key:("'drag/" + (item.id)),attrs:{"data":item,"handle":".handle","disabled":!_vm.isEditingEnabled,"drag-image-opacity":1.0},on:{"dragstart":function($event){_vm.isDragging = true},"dragend":function($event){_vm.isDragging = false}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditingEnabled),expression:"isEditingEnabled"}],staticClass:"handle ml-3 mr-2",attrs:{"icon-left":"grip-vertical","type":"success"}}),_c('ChallengePromptCardCoordinator',{attrs:{"is-reordering":reorder,"prompt":item},on:{"updated":_vm.onUpdatePrompt}})],1)]},proxy:true},{key:"drag-image",fn:function(){return [_c('div')]},proxy:true}],null,true)})]}}],null,false,2871993535)})],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }