<template>
  <b-slider
    :value="hoursAndMinutes"
    class="time-slider is-size-7-mobile"
    indicator
    :max="60 * 24 - 15"
    :min="0"
    :step="15"
    :tooltip="false"
    :custom-formatter="format"
    @input="handleInput"
  >
    <b-slider-tick key="8am" :value="60 * 8">8AM</b-slider-tick>
    <b-slider-tick key="noon" :value="60 * 12">Noon</b-slider-tick>
    <b-slider-tick key="6pm" :value="60 * 18">6PM</b-slider-tick>
  </b-slider>
</template>

<script>
import { format } from "date-fns-tz";
export default {
  name: "TimeSlider",
  props: {
    value: {
      type: Date,
      default: () => new Date()
    }
  },
  data() {
    return {
      hoursAndMinutes: this.dateToMinutes(this.value) // noon in minutes
    };
  },
  methods: {
    handleInput(val) {
      this.hoursAndMinutes = val;
      this.$emit("input", this.minutesToDate(val));
    },
    minutesToDate(val) {
      const d = new Date(this.value);
      d.setHours(Math.floor(val / 60));
      d.setMinutes(val % 60);
      return d;
    },
    dateToMinutes(d) {
      return d.getHours() * 60 + d.getMinutes();
    },
    format(val) {
      let tmp = new Date();
      tmp.setHours(Math.floor(val / 60));
      tmp.setMinutes(val % 60);
      let emoji = "🌙";
      if (tmp.getHours() >= 5 && tmp.getHours() <= 9) {
        emoji = "🌅";
      } else if (tmp.getHours() >= 10 && tmp.getHours() <= 17) {
        emoji = "☀️";
      } else if (tmp.getHours() >= 17 && tmp.getHours() <= 20) {
        emoji = "🌇";
      } else if (tmp.getHours() >= 21 && tmp.getHours() <= 23) {
        emoji = "🌃";
      }
      return emoji + " " + format(tmp, "hh:mm a");
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.time-slider {
  @include mobile {
    margin-bottom: 1.5rem !important;
  }
  .b-slider-thumb-wrapper .b-slider-thumb {
    @extend %elevation-2;
    white-space: nowrap;
    background: white;
    cursor: ew-resize;
    &:active {
      transform: scale(1.5) !important;
    }
  }
  &.is-primary .b-slider-fill {
    background-color: transparent !important;
  }
  .b-slider-track {
    background-color: $grey100;
  }
  &.off-schedule {
    .b-slider-track {
      background-color: $info;
    }
  }
}
</style>
