<template>
  <div
    class="challenge-prompt card-container"
    :class="{
      'is-locked': !$auth.isAdmin && isLocked && !isHeader,
      'is-reordering': showReordering,
      'is-yellow-border': isAvailable && isAccepted && !hasSubmitted,
      'is-green-border': isAccepted && hasSubmitted,
      'is-editing': isEdit
    }"
  >
    <ChallengePromptCardViewHeaderLocked
      v-if="isViewHeaderLocked && !isEdit"
      :prompt="prompt"
      @edit="setEdit()"
      @delete="onDelete()"
    />

    <ChallengePromptCardViewHeaderUnlocked
      v-if="isViewHeaderUnlocked && !isEdit"
      :prompt="prompt"
      :challenge-order="challengeOrder"
      :qa-has-submitted="qaHasSubmitted"
      :qa-has-submitted-when="qaHasSubmittedWhen"
      @edit="setEdit()"
      @delete="onDelete()"
    />

    <ChallengePromptCardViewExpandedLocked
      v-if="isViewExpandedLocked && !isEdit"
      :prompt="prompt"
      :challenge-order="challengeOrder"
      :is-reordering="showReordering"
      :qa-is-reordering="qaIsReordering"
      :qa-is-available="qaIsAvailable"
      @edit="setEdit()"
      @delete="onDelete()"
    />

    <ChallengePromptCardViewExpandedUnlocked
      v-if="isViewExpandedUnlocked && !isEdit"
      :prompt="prompt"
      :challenge-order="challengeOrder"
      :is-reordering="showReordering"
      :qa-is-reordering="qaIsReordering"
      :qa-is-accepted="qaIsAccepted"
      :qa-has-submitted="qaHasSubmitted"
      :qa-has-submitted-when="qaHasSubmittedWhen"
      @edit="setEdit()"
      @delete="onDelete()"
    />

    <ChallengePromptCardEdit
      v-if="isEdit"
      :prompt="prompt"
      :challenge-order="challengeOrder"
      :challenge="challenge"
      :qa-is-available="qaIsAvailable"
      :qa-is-accepted="qaIsAccepted"
      :qa-has-submitted="qaHasSubmitted"
      @created="onCreated($event)"
      @updated="onUpdated($event)"
      @cancelled="onCancelled()"
    />
  </div>
</template>

<script>
import { isFuture } from "date-fns";
import { UPDATE_PROMPT } from "@/models/challenges/operations.gql";
import ChallengePromptCardViewHeaderLocked from "@/components/challengePromptCard/ChallengePromptCardViewHeaderLocked.vue";
import ChallengePromptCardViewHeaderUnlocked from "@/components/challengePromptCard/ChallengePromptCardViewHeaderUnlocked.vue";
import ChallengePromptCardViewExpandedLocked from "@/components/challengePromptCard/ChallengePromptCardViewExpandedLocked.vue";
import ChallengePromptCardViewExpandedUnlocked from "@/components/challengePromptCard/ChallengePromptCardViewExpandedUnlocked.vue";
import ChallengePromptCardEdit from "@/components/challengePromptCard/ChallengePromptCardEdit.vue";

export default {
  name: "ChallengePromptCardCoordinator",
  components: {
    ChallengePromptCardViewHeaderLocked,
    ChallengePromptCardViewHeaderUnlocked,
    ChallengePromptCardViewExpandedLocked,
    ChallengePromptCardViewExpandedUnlocked,
    ChallengePromptCardEdit
  },
  props: {
    // this should only be set when prompt is null (create prompt inside a challenge)
    challenge: {
      type: Object,
      default: null
    },
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    isHeader: {
      type: Boolean,
      default: false
    },
    isReordering: {
      type: Boolean,
      default: false
    },
    // values that can be set to simplify human QA of display logic
    qaIsReordering: {
      type: String,
      default: ""
    },
    qaIsAvailable: {
      type: String,
      default: ""
    },
    qaIsAccepted: {
      type: String,
      default: ""
    },
    qaHasSubmitted: {
      type: String,
      default: ""
    },
    qaHasSubmittedWhen: {
      type: String,
      default: ""
    },
    qaEdit: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isViewHeaderLocked: false,
      isViewHeaderUnlocked: false,
      isViewExpandedLocked: false,
      isViewExpandedUnlocked: false,
      isEdit: false
    };
  },
  computed: {
    showReordering() {
      if (!this.qaIsReordering) {
        return this.isReordering;
      }
      return this.qaIsReordering === "y";
    },
    isAvailable() {
      if (!this.qaIsAvailable && this.prompt) {
        return !isFuture(new Date(this.prompt.available_after));
      }
      return this.qaIsAvailable === "y";
    },
    isAccepted() {
      if (!this.qaIsAccepted && this.prompt) {
        return this.prompt.current_user_accepted_at !== null;
      }
      return this.qaIsAccepted === "y";
    },
    hasSubmitted() {
      if (!this.qaHasSubmitted && this.prompt) {
        return this.prompt.current_user_has_submission;
      }
      return this.qaHasSubmitted === "y";
    },
    hasSubmittedWhen() {
      if (!this.qaHasSubmittedWhen && this.prompt) {
        return this.prompt.submissions_aggregate.aggregate.max.updated_at;
      }
      return new Date(this.qaHasSubmittedWhen);
    },
    isLocked() {
      return !this.isAvailable || !this.isAccepted;
    },
    challengeOrder() {
      if (!this.prompt) {
        return null;
      }
      return this.prompt.challenge.prompt_ids.findIndex(id => this.prompt.id === id) + 1;
    }
  },
  created() {
    if (this.qaEdit === "y") {
      this.setEdit();
    } else if (this.isHeader) {
      if (this.isLocked) {
        this.setViewHeaderLocked();
      } else {
        this.setViewHeaderUnlocked();
      }
    } else {
      if (this.prompt) {
        if (this.isLocked) {
          this.setViewExpandedLocked();
        } else {
          this.setViewExpandedUnlocked();
        }
      } else if (this.challenge) {
        // We are creating a new prompt, so turn on editing mode
        this.setEdit();
      }
    }
  },
  methods: {
    reset() {
      this.isViewHeaderLocked = false;
      this.isViewHeaderUnlocked = false;
      this.isViewExpandedLocked = false;
      this.isViewExpandedUnlocked = false;
      this.isEdit = false;
    },
    setViewHeaderLocked() {
      this.reset();
      this.isViewHeaderLocked = true;
    },
    setViewHeaderUnlocked() {
      this.reset();
      this.isViewHeaderUnlocked = true;
    },
    setViewExpandedLocked() {
      this.reset();
      this.isViewExpandedLocked = true;
    },
    setViewExpandedUnlocked() {
      this.reset();
      this.isViewExpandedUnlocked = true;
    },
    setEdit() {
      this.isEdit = true;
    },
    unsetEdit() {
      this.isEdit = false;
    },
    onCreated(e) {
      this.$emit("created", e);
      this.unsetEdit();
    },
    onUpdated(e) {
      this.$emit("updated", e);
      this.unsetEdit();
    },
    onCancelled() {
      this.$emit("cancelled");
      this.unsetEdit();
    },
    onDelete() {
      this.$log.debug("Delete Prompt", this.prompt);
      this.$apollo
        .mutate({
          mutation: UPDATE_PROMPT,
          variables: {
            prompt_id: this.prompt.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.$emit("deleted", result);

          this.$buefy.snackbar.open({
            message: result.title ? `Prompt ${result.title} deleted` : "Prompt deleted",
            type: "is-success"
          });
        });
    }
  }
};
</script>