<template>
  <FlyoutButton
    v-if="event"
    ref="rsvp_flyout"
    :disabled="disabled"
    :trigger-button-type="'is-success'"
    trigger-button-icon="user-check"
    :trigger-button-text="'Join Event'"
    query-string-toggle="rsvp"
    position="is-bottom-right"
    :login-required="true"
    :close-on-click="false"
    :can-close="false"
    :expanded="expanded"
    @active-change="
      active => {
        if (active) {
          startTimer();
        }
      }
    "
  >
    <template #default>
      <b-modal v-model="showTermsModal">
        <TermsAndConditions />
      </b-modal>

      <div>
        <div>
          <h4 class="mb-2">Planning to Attend?</h4>
          <p>
            <b-checkbox v-model="agreedToTerms" class="m-0"></b-checkbox>
            I plan to attend this meeting and I agree to the&nbsp;
            <a @click="showTermsModal = true">Terms and Conditions</a>
          </p>
        </div>
      </div>

      <UndoConfirmButtons
        class="mt-4"
        :confirm-button-type="event.current_user_is_rsvpd ? 'is-danger' : 'is-success'"
        :confirm-enabled="
          (agreedToTerms && timerEnded) || event.current_user_is_rsvpd
        "
        @undo="closeFlyout"
        @confirm="accessEvent()"
      >
        <template #undo>
          <span>Cancel</span>
        </template>
        <template #confirm>
          {{ confirmText }}
        </template>
      </UndoConfirmButtons>
    </template>

    <template #login-required>
      <p class="is-size-4 has-text-weight-semibold">
        An account is required to RSVP to this event.
      </p>
      <p>
        Please login or register for your free account now.
      </p>
    </template>
  </FlyoutButton>
</template>

<script>
import FlyoutButton from "@/components/common/FlyoutButton.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import TermsAndConditions from "@/components/common/TermsAndConditions.vue";

export default {
  name: "EventAccessCard",
  components: {
    FlyoutButton,
    UndoConfirmButtons,
    TermsAndConditions
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timerCount: 0,
    timerEnded: false,
    agreedToTerms: false,
    showTermsModal: false
  }),
  computed: {
    confirmText() {
      let msg = "Confirm";
      return !this.event.current_user_is_rsvpd && this.timerCount > 0 ? `(${this.timerCount}) ${msg}` : msg;
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerEnded = true;
        }
      }
    }
  },
  methods: {
    startTimer() {
      this.timerEnded = false;
      this.timerCount = 5;
    },
    closeFlyout() {
      this.$refs.rsvp_flyout.close();
    },
    accessEvent() {
      this.$emit("eventAccessRequested");
      this.closeFlyout();
    }
  }
};
</script>
