<template>
  <div class="navbar-container">

    <b-navbar is-transparent>
      <template slot="brand">
        <b-navbar-item tag="router-link" to="/">
          <img class="logo" src="/assets/logo.svg" alt="Shut Up &amp; Write!" />
        </b-navbar-item>
      </template>
      <template v-if="!$route.meta.hideMainNavItems" slot="end">
  
        <b-navbar-item tag="router-link" to="/events">
          Events
        </b-navbar-item>
  
        <b-navbar-item tag="router-link" to="/challenges">
          Challenges
        </b-navbar-item>
  
        <!-- #1011 Temporarily Hide Prompts BEGIN
        <b-navbar-item tag="router-link" to="/prompts">
          Prompts
        </b-navbar-item>
        #1011 Temporarily Hide Prompts END -->

        <!-- #602 Temporarily Hide Discussions BEGIN
        <b-navbar-item tag="router-link" :to="{ name: 'forum_categories' }">
          Discussions
        </b-navbar-item> 
        #602 Temporarily Hide Discussions END -->
  
        <b-navbar-item tag="router-link" :to="{ name: 'articles' }">
          Articles
        </b-navbar-item>

        <!-- Store link that opens in a new tab -->
        <b-navbar-item href="https://store.shutupwrite.com/" target="_blank">
          Store
        </b-navbar-item>
  
        <section v-if="$auth.isAuthenticated" class="is-hidden-tablet">
          <b-navbar-item tag="router-link" to="/dashboard">
            Dashboard
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: `dashboard_inbox` }">
            Inbox
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: `dashboard_profile` }">
            Settings
          </b-navbar-item>
          <b-navbar-item
            v-if="$auth.user.username"
            tag="router-link"
            :to="{
              name: `user_profile`,
              params: { username: $auth.user.username }
            }"
          >
            My Public Profile
          </b-navbar-item>
          <b-navbar-item v-if="$auth.isAdmin" tag="router-link" to="/users">
            Users Admin
          </b-navbar-item>
          <b-navbar-item v-if="$auth.isAdmin" tag="router-link" to="/events/admin">
            Events Admin
          </b-navbar-item>
          <b-navbar-item tag="a" @click="logout">
            Log Out
            <span class="has-text-info">
              @{{ $auth.user.username }}
            </span>
          </b-navbar-item>
        </section>
  
        <b-navbar-item v-if="!$auth.loading && !$auth.isAuthenticated" tag="div">
          <a class="button is-primary" @click="$router.push({ name: 'login' })">
            Log In / Sign Up
          </a>
        </b-navbar-item>
        <b-navbar-dropdown
          v-if="$auth.isAuthenticated"
          class="is-hidden-mobile"
          :right="true"
        >
          <template slot="label">
            <section class="is-flex" style="align-items: center;">
              <span class="pr-2 is-info"> @{{ $auth.user.username }} </span>
              <figure class="image is-32x32">
                <Avatar
                  size="medium"
                  v-if="!$auth.loading && $auth.isAuthenticated"
                  :seed-string="$auth.user | user_name"
                  :color="$auth.user.avatar_color"
                />
              </figure>
            </section>
          </template>
          <template slot="default">
            <b-navbar-item tag="div">
              <div class="has-text-centered content is-flex user-info">
                <figure class="image is-64x64 is-inline-block mb-2">
                  <Avatar size="large" :seed-string="$auth.user | user_name" :color="$auth.user.avatar_color" />
                </figure>
                <span class="has-text-weight-bold is-size-5">
                  {{ $auth.user | user_name }}
                </span>
                <router-link
                  v-if="$auth.user.username"
                  :to="{
                    name: `user_profile`,
                    params: { username: $auth.user.username }
                  }"
                  class="is-size-6 is-info"
                >
                  @{{ $auth.user.username }}
                </router-link>
                <div class="user-tags">
                  <div v-if="$auth.isAuthenticated && $auth.isOrganizer" class="control">
                    <badge icon="user-check" label="Organizer" />
                  </div>
                  <div v-if="$auth.isAuthenticated && $auth.isAdmin" class="control">
                    <badge icon="crown" label="Admin" />
                  </div>
                </div>
              </div>
            </b-navbar-item>
            <b-navbar-item tag="router-link" to="/dashboard">
              Dashboard
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name: `dashboard_profile` }">
              Settings
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name: `dashboard_inbox` }">
              Inbox
            </b-navbar-item>
            <b-navbar-item
              v-if="$auth.user.username"
              tag="router-link"
              :to="{
                name: `user_profile`,
                params: { username: $auth.user.username }
              }"
            >
              My Public Profile
            </b-navbar-item>
            <b-navbar-item v-if="$auth.isAdmin" tag="router-link" to="/users">
              Users Admin
            </b-navbar-item>
            <b-navbar-item v-if="$auth.isAdmin" tag="router-link" to="/events/admin">
              Events Admin
            </b-navbar-item>
            <b-navbar-item tag="a" @click="logout">
              Log Out
            </b-navbar-item>
          </template>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import Badge from "@/components/common/Badge.vue";
import Avatar from "@/components/common/Avatar.vue";
export default {
  name: "MainNav",
  components: {
    Badge,
    Avatar
  },
  data() {
    return {
      breadcrumbs: [],
      registration: null,
      //updateExists: false,
      refreshing: false
    };
  },
  watch: {
    $route(newV) {
      if (
        newV.meta &&
        newV.meta.breadcrumbs &&
        newV.meta.breadcrumbs.length > 0
      ) {
        this.breadcrumbs = newV.meta.breadcrumbs;
      }
    }
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUX, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    login() {
      this.$log.info("redirecting to login");
      this.$router.push({ name: "login" });
      // this.$auth.refreshAccess({ redirectUrl: this.$route.fullPath });
    },
    logout() {
      this.$log.info("logging out");
      this.$auth.logout({ redirectLocation: { name: "home" } });
    },
    // refreshApp() {
    //   console.log("activating newest service worker", this.registration);
    //   this.updateExists = false;
    //   if (!this.registration || !this.registration.waiting) return;
    //   this.registration.waiting.postMessage("skipWaiting");
    // },
    showRefreshUX(e) {
      this.registration = e.detail;
      this.$log.info("newer service worker detected. registration:", this.registration);
      //this.updateExists = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.refresh-app {
  font-size: 0.9em !important;
}
.navbar {
  background: #fff;
  @include tablet {
    padding-right: 1rem;
  }
}
.badge {
  top: 0;
  margin: 0 auto;
}
.button {
  @include tablet {
    font-size: 0.875rem;
  }
  @include desktop {
    font-size: 1.05rem;
  }
}
</style>

<style lang="scss">
.navbar {
  border-bottom: 1px solid #dadbe0;
  z-index: auto !important;
  font-size: 1.15em;
  max-width: 3840px;
  margin: 0 auto;
  .navbar-link:not(.is-arrowless) {
    @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
      padding-left: 1rem;
      padding-right: 3rem;
    }
    &::after {
      border-color: $grey600;
    }
  }
  .navbar-item {
    color: $grey600;

    span {
      color: $grey900;
      &.is-info {
        color: $info;
      }
    }

    &:hover, &:focus {
      background: none;
    }

    img {
      max-height: 56px;
      width: 81px;
      @include tablet {
        width: 130px;
      }
      &.logo {
        @include tablet {
          margin: 1em;
        }
      }
    }
  }
  .breadcrumb {
    display: flex;
    align-items: center;
  }
}
.user-info {
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.user-tags {
  flex-wrap: nowrap;
  .control {
    margin: 0.5em 0;
  }
}
</style>
