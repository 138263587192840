import { isDate } from "lodash";
import { formatDistanceToNow, intervalToDuration } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
import { parse } from "postgres-interval";

export const userTimezone = Intl.DateTimeFormat()
  .resolvedOptions()
  .timeZone.trim();

console.log("user TZ", userTimezone);

export function formatUTC(utcDate, fmt) {
  if (!isDate(utcDate)) {
    utcDate = toLocalDate(utcDate);
  }
  return format(utcDate, fmt);
}

export function formatFromNow(utcDate, options = { addSuffix: true }) {
  if (!isDate(utcDate)) {
    try {
      utcDate = toLocalDate(utcDate);
    } catch {
      return "?";
    }
  }
  return formatDistanceToNow(utcDate, options);
}

export function formatFromNowMobile(utcDate, options = { addSuffix: true }) {
  const replacements = [
    [/less than\s*/, "<"],
    [/(?:about|almost)\s*/, "~"],
    [/over\s*/, ">"],
    [/\s*a minute/, "1m"],
    [/\s*minutes?/, "m"],
    [/\s*hours?/, "h"],
    [/\s*days?/, "d"],
    [/\s*months?/, "mo"],
    [/\s*years?/, "y"]
  ];

  const longFormattedDate = formatFromNow(utcDate, options);

  return replacements.reduce((formatted, regReplace) => {
    const [pattern, repl] = regReplace;
    return formatted.replace(pattern, repl);
  }, longFormattedDate);
}

export function formatDurationClock(fromDate, toDate) {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: fromDate,
    end: toDate
  });

  return (
    String(hours + days * 24).padStart(2, 0) +
    ":" +
    String(minutes).padStart(2, 0) +
    ":" +
    String(seconds).padStart(2, 0)
  );
}

export function toLocalDate(utcISOString, timezone = null) {
  return utcToZonedTime(utcISOString, timezone || userTimezone);
}

export function localToUTC(local, timezone = null) {
  return zonedTimeToUtc(local, timezone || userTimezone);
}

export function toUTCDate(utcISOString) {
  const local = toLocalDate(utcISOString);
  return localToUTC(local);
}

export function minutesFromPostgresInterval(interval) {
  const parsed = parse(interval);
  return parsed.days * 60 * 24 + parsed.hours * 60 + parsed.minutes;
}

export function durationNameFromPostgresInterval(interval) {
  const minutes = minutesFromPostgresInterval(interval);
  if (minutes >= 180) {
    return "marathon";
  } else if (minutes > 90) {
    return "extended";
  } else {
    return "standard";
  }
}
