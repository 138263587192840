<template>
  <div>
    <NakedPromptCardEditDesktop 
      v-if="suwDesktop"
      :prompt="prompt" 
      @created="onCreated"
      @updated="onUpdated"
      @cancelled="onCancelled"
    />
    <NakedPromptCardEditMobile 
      v-if="!suwDesktop"
      :prompt="prompt" 
      @created="onCreated"
      @updated="onUpdated"
      @cancelled="onCancelled"
    />
  </div>
</template>

<script>
import NakedPromptCardEditDesktop from "@/components/nakedPromptCard/NakedPromptCardEditDesktop.vue";
import NakedPromptCardEditMobile from "@/components/nakedPromptCard/NakedPromptCardEditMobile.vue";

export default {
  name: "NakedPromptCardEdit",
  components: {
    NakedPromptCardEditDesktop,
    NakedPromptCardEditMobile
  },
  props: {
    prompt: {
      type: Object,
      default: null
    }
  },
  methods: {
    onCreated(result) {
      this.$emit("created", result);
    },
    onUpdated(result) {
      this.$emit("updated", result);
    },
    onCancelled() {
      this.$emit("cancelled");
    }    
  }
};
</script>
