<template>
  <div>
    <EventSeriesEditDesktop 
      v-if="suwDesktop"
      :event="event" 
      @updated="onUpdated"
      @edit-cancelled="onEditCancelled"
    />
    <EventSeriesEditMobile 
      v-if="!suwDesktop"
      :event="event" 
      @updated="onUpdated"
      @edit-cancelled="onEditCancelled"
    />
  </div>
</template>

<script>
import EventSeriesEditDesktop from "@/components/eventSeries/EventSeriesEditDesktop.vue";
import EventSeriesEditMobile from "@/components/eventSeries/EventSeriesEditMobile.vue";

export default {
  name: "EventSeriesEdit",
  components: {
    EventSeriesEditDesktop,
    EventSeriesEditMobile
  },
  props: {
    event: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    onUpdated() {
      this.$emit("updated");
    },    
    onEditCancelled() {
      this.$emit("edit-cancelled");
    },    
  }
}
</script>