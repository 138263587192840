<template>
  <FlyoutButton
    v-if="event"
    ref="rsvp_flyout"
    :trigger-button-type="triggerButtonColor"
    trigger-button-icon="user-check"
    :trigger-button-text="triggerButtonText"
    query-string-toggle="rsvp"
    position="is-bottom-right"
    :login-required="true"
    :close-on-click="false"
    :can-close="false"
    :expanded="expanded"
    @active-change="
      active => {
        if (active) {
          startTimer();
        }
      }
    "
  >
    <template #default>
      <b-modal v-model="showTermsModal">
        <TermsAndConditions />
      </b-modal>

      <div v-if="!event.current_user_is_rsvpd">
        <div>
          <h4 class="mb-2">Planning to Attend?</h4>
          <p>
            <b-checkbox v-model="agreedToTerms" class="m-0"></b-checkbox>
            I plan to attend this meeting and I agree to the&nbsp;
            <a @click="showTermsModal = true">Terms and Conditions</a>
          </p>
        </div>
      </div>
      <div v-else>
        <h4 class="mb-2">Cancel RSVP?</h4>
        <p>
          You will no longer receive updates about this event. You can re-RSVP at
          a future time, however space may be limited.
        </p>
      </div>

      <UndoConfirmButtons
        class="mt-4"
        :confirm-button-type="event.current_user_is_rsvpd ? 'is-danger' : 'is-success'"
        :confirm-enabled="
          (agreedToTerms && timerEnded) || event.current_user_is_rsvpd
        "
        @undo="closeFlyout"
        @confirm="event.current_user_is_rsvpd ? cancelRSVP() : createRSVP()"
      >
        <template #undo>
          <span v-if="event.current_user_is_rsvpd">Leave RSVP as-is</span>
          <span v-else>Cancel</span>
        </template>
        <template #confirm>
          {{ confirmText }}
        </template>
      </UndoConfirmButtons>
    </template>

    <template #login-required>
      <p class="is-size-4 has-text-weight-semibold">
        An account is required to RSVP to this event.
      </p>
      <p>
        Please login or register for your free account now.
      </p>
    </template>
  </FlyoutButton>
</template>

<script>
import { Rsvp, UnRsvp } from "@/models/actions/operations.gql";
import {
  EMPTY_FIELD,
  NOT_EXIST,
  CANCELLED,
  TOO_LATE,
  INSERT_FAILED,
  DELETE_FAILED,
  DEFAULT_FALLBACK,
  getErrorMessage,
  fallbackErrorMessage
} from "@/backendException.js";
import FlyoutButton from "@/components/common/FlyoutButton.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import TermsAndConditions from "@/components/common/TermsAndConditions.vue";

export default {
  name: "RSVPCard",
  components: {
    FlyoutButton,
    UndoConfirmButtons,
    TermsAndConditions
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timerCount: 0,
    timerEnded: false,
    agreedToTerms: false,
    showTermsModal: false,
    rsvpValidationReasonMap: new Map([
      [EMPTY_FIELD, "Required fields must be filled out."],
      [NOT_EXIST, "Event does not exist."],
      [CANCELLED, "Event is cancelled."],
      [TOO_LATE, "Event has already begun."],
      [DEFAULT_FALLBACK, fallbackErrorMessage]
    ]),
    rsvpExecutionReasonMap: new Map([
      [INSERT_FAILED, "RSVP Failed. Please try again."],
      [DEFAULT_FALLBACK, fallbackErrorMessage]
    ]),
    unrsvpValidationReasonMap: new Map([
      [EMPTY_FIELD, "Required fields must be filled out."],
      [NOT_EXIST, "Event does not exist."],
      [CANCELLED, "Event is cancelled."],
      [TOO_LATE, "Event has already ended."],
      [DEFAULT_FALLBACK, fallbackErrorMessage]
    ]),
    unrsvpExecutionReasonMap: new Map([
      [DELETE_FAILED, "un-RSVP Failed. Please try again."],
      [DEFAULT_FALLBACK, fallbackErrorMessage]
    ])
  }),
  computed: {
    triggerButtonText() {
      return this.event.current_user_is_rsvpd ? "You're RSVPd!" : "RSVP to Attend";
    },
    confirmText() {
      let msg = this.event.current_user_is_rsvpd ? "Cancel RSVP" : "RSVP to Attend";
      return !this.event.current_user_is_rsvpd && this.timerCount > 0 ? `(${this.timerCount}) ${msg}` : msg;
    },
    triggerButtonColor() {
      return this.event.current_user_is_rsvpd ? "is-success is-outlined" : "is-success";
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerEnded = true;
        }
      }
    }
  },
  methods: {
    startTimer() {
      this.timerEnded = false;
      this.timerCount = 5;
    },
    closeFlyout() {
      this.$refs.rsvp_flyout.close();
    },
    async cancelRSVP() {
      try {
        const response = await this.$apollo.mutate({
          mutation: UnRsvp,
          variables: {
            eventId: this.event.id,
            userId: this.$auth.user.id
          },
          update: (cache, { data: { result } }) => {
            this.$emit("rsvp-cancelled", result);
            cache.evict(cache.identify(this.event));
            cache.gc();
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          this.$buefy.snackbar.open({
            queue: false,
            message: getErrorMessage(errors, this.unrsvpValidationReasonMap, this.unrsvpExecutionReasonMap),
            position: "is-top",
            type: "is-danger"
          });
        } else {
          this.closeFlyout();
          this.$buefy.snackbar.open({
            message: "RSVP cancelled",
            type: "is-success"
          });
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
      }
    },
    async createRSVP() {
      try {
        const response = await this.$apollo.mutate({
          mutation: Rsvp,
          variables: {
            eventId: this.event.id,
            userId: this.$auth.user.id
          },
          update: (cache, { data: { result } }) => {
            this.$emit("rsvp-created", result);
            cache.evict(cache.identify(this.event));
            cache.gc();
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          this.$buefy.snackbar.open({
            queue: false,
            message: getErrorMessage(errors, this.rsvpValidationReasonMap, this.rsvpExecutionReasonMap),
            position: "is-top",
            type: "is-danger"
          });
        } else {
          this.closeFlyout();
          this.$buefy.snackbar.open({
            message: "RSVP created",
            type: "is-success"
          });
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
      }
    }
  }
};
</script>
