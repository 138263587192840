import { getInstance } from "./index";
import { Logger, INFO } from "@/plugins/logging.js";
import { hasuraClient } from "@/apollo.js";
import gql from "graphql-tag";

const $log = new Logger("GUARD>EVENT_BETA", { level: INFO });

export const eventDetailGuard = async (to, _, next) => {
  const authService = getInstance();

  const fn = async () => {
    var event = await hasuraClient.query({
      query: gql`
        query($event_id: Int!) {
          events_by_pk(id: $event_id) {
            id
            meetup_url
          }
        }
      `,
      variables: {
        event_id: parseInt(to.params.event_id)
      }
    });

    if (event && event.data && event.data.events_by_pk) {
      if (event.data.events_by_pk.meetup_url) {
        $log.info("event not migrated; redirect event to meetup");
        window.open(event.data.events_by_pk.meetup_url, "_blank");
        return;
      } else {
        return next();
      }
    }
    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) return await fn();

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", async loading => {
    if (loading === false) return await fn();
  });
};
