<template>
  <div v-if="!isDangerous || $auth.isAdmin">
    <!-- Topic title and body at head of forums/topic page -->
    <div v-if="isHeader" class="is-topic-display">
      <div class="is-flex-grow-1">
        <div class="is-flex is-justify-content-space-between">
          <div class="is-size-4 has-text-weight-semibold card-title">
            {{ topic.title }}
          </div>
          <div class="is-align-self-center has-text-grey600 ml-2">
            <InlineDate :d="topic.created_at" />
          </div>
        </div>
        <TipTap
          v-if="!state.isEditing"
          v-model="form.body"
          :is-preview="false"
          :is-dangerous="isReported && !isAllowed"
          class="my-1"
        />
      </div>
      <!--right column -->
      <div class="is-topic-meta">
        <div class="is-flex" :class="{ 'mb-2': !suwMobile }">
          <div class="is-flex is-flex-shrink-1 is-align-items-center">
            <div class="has-text-grey600" :class="{ 'mx-1': !suwMobile }">
              Posted By
            </div>
            <UserPill :user="topic ? topic.author : $auth.user" />
          </div>
        </div>
        <div class="is-flex" v-if="isEllipsesEnabled">
          <b-taglist class="is-justify-content-flex-end">
            <b-dropdown 
              ref="dropdown" 
              aria-role="list" 
              position="is-top-left"
            >
              <template #trigger>
                <b-button
                  style="border: none;"
                  type="is-ghost is-small has-text-grey-dark"
                  icon-left="ellipsis-h"
                />
              </template>
              <!-- allow -->
              <b-dropdown-item
                v-if="canShowEllipsesAllow"
                class="p-0 has-text-centered"
              >
                <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllow" />
                <b-button v-else type="is-ghost" label="Allowed" disabled />
              </b-dropdown-item>
              <!-- delete -->
              <b-dropdown-item
                v-if="canShowEllipsesDelete"
                class="p-0 has-text-centered"
              >
                <b-button type="is-ghost" label="Delete" @click="onDelete" />
              </b-dropdown-item>
              <!-- edit -->
              <b-dropdown-item
                v-if="canShowEllipsesEdit"
                class="p-0 mb-1 has-text-centered"
              >
                <b-button type="is-ghost" label="Edit" @click="toggleEditing" />
              </b-dropdown-item>
              <!-- report -->
              <b-dropdown-item
                v-if="canShowEllipsesReport"
                class="p-0 has-text-centered"
              >
                <b-button
                  type="is-ghost"
                  label="Report"
                  @click="confirmReport"
                />
              </b-dropdown-item>
            </b-dropdown>
          </b-taglist>
        </div>
      </div>
    </div>
    <!-- New Topic form with empty fields -->
    <div v-else-if="!topic">
      <b-field
        :type="{ 'is-danger': $v.form.title.$invalid }"
        :message="{ 'Title is required': !$v.form.title.required }"
        label="Post Title"
      >
        <b-input v-model="form.title" placeholder="My Amazing Post" />
      </b-field>
      <TipTap
        v-model="form.body"
        :type="$v.form.body.required ? '' : 'is-danger'"
        :message="$v.form.body.required ? '' : 'Post description is required.'"
        :confirm-enabled="() => !$v.form.$invalid"
        class="my-2"
        :show-edit-controls="true"
        :editable="true"
        @edit-cancelled="onCreateCancelled"
        @edit-confirmed="onCreateConfirmed($event)"
      />
    </div>
    <!-- Topic Cards in list displays -->
    <!-- Not Editing -->
    <div v-else-if="!state.isEditing" class="is-topic-display">
      <div class="is-flex-grow-1">
        <div class="is-flex title-row">
          <div
            class="is-size-4 has-text-weight-semibold card-title is-hover-grow"
            @click="onTopicCardClick()"
          >
            {{ topic.title }}
          </div>
          <div class="is-align-self-center has-text-grey600 ml-2">
            <InlineDate :d="topic.created_at" />
          </div>
        </div>
        <p class="my-1" :class="{ 'is-danger-outline': $auth.isAdmin && isReported && !isAllowed }">
          {{ topic.body_text | truncate(180) }}
        </p>
      </div>
      <!--right column -->
      <div class="is-topic-meta">
        <div class="is-flex">
          <div class="is-flex is-flex-shrink-1 is-align-items-center">
            <div class="has-text-grey600 mx-1 is-hidden-mobile">Posted By</div>
            <UserPill v-if="!suwMobile" :user="topic ? topic.author : $auth.user" />
            <span v-else class="author-tag has-text-grey600">
              {{ topic ? (topic.author | user_name) : ($auth.user | user_name) }}
            </span>
          </div>
        </div>
        <div class="is-flex">
          <b-taglist class="is-justify-content-flex-end is-flex-wrap-nowrap">
            <b-tag type="is-success is-light">
              <b-icon icon="user-check" />
              <span>{{ topic.posts_aggregate.aggregate.author_count }}</span>
            </b-tag>
            <b-tag type="is-info is-light">
              <b-icon icon="file" />
              <span>{{ topic.posts_aggregate.aggregate.count }}</span>
              <span class="sr-only">
                &nbsp;{{ "Comment" | pluralize(topic.posts_aggregate.aggregate.count) }}
              </span>
            </b-tag>
            <b-dropdown 
              v-if="isEllipsesEnabled"
              ref="dropdown" 
              aria-role="list" 
              position="is-top-left"
            >
              <template #trigger>
                <b-button
                  style="border: none;"
                  type="is-ghost is-small has-text-grey-dark"
                  icon-left="ellipsis-h"
                />
              </template>
              <!-- allow -->
              <b-dropdown-item
                v-if="canShowEllipsesAllow"
                class="p-0 has-text-centered"
              >
                <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllow" />
                <b-button v-else type="is-ghost" label="Allowed" disabled />
              </b-dropdown-item>
              <!-- delete -->
              <b-dropdown-item
                v-if="canShowEllipsesDelete"
                class="p-0 has-text-centered"
              >
                <b-button type="is-ghost" label="Delete" @click="onDelete" />
              </b-dropdown-item>
              <!-- edit -->
              <b-dropdown-item
                v-if="canShowEllipsesEdit"
                class="p-0 mb-1 has-text-centered"
              >
                <b-button type="is-ghost" label="Edit" @click="toggleEditing" />
              </b-dropdown-item>
              <!-- report -->
              <b-dropdown-item
                v-if="canShowEllipsesReport"
                class="p-0 has-text-centered"
              >
                <b-button
                  type="is-ghost"
                  label="Report"
                  @click="confirmReport"
                />
              </b-dropdown-item>
            </b-dropdown>
          </b-taglist>
        </div>
      </div>
    </div>
    <!-- Editing -->
    <div v-else>
      <b-field
        :type="{ 'is-danger': $v.form.title.$invalid }"
        :message="{ 'Title is required': !$v.form.title.required }"
        label="Post Title"
      >
        <b-input v-model="form.title" placeholder="My Amazing Post" />
      </b-field>
      <TipTap
        v-show="state.isEditing"
        v-model="form.body"
        :type="$v.form.body.required ? '' : 'is-danger'"
        :message="$v.form.body.required ? '' : 'Post description is required.'"
        :confirm-enabled="() => !$v.form.$invalid"
        :show-edit-controls="state.isEditing"
        :editable="state.isEditing"
        @edit-cancelled="onEditCancelled"
        @edit-confirmed="
          topic ? onEditConfirmed($event) : onCreateConfirmed($event)
        "
      />
    </div>
  </div>
</template>

<script>
import UserPill from "@/components/common/UserPill.vue";
import TipTap from "@/components/forms/TipTap.vue";
import InlineDate from "@/components/common/InlineDate.vue";
import { required } from "vuelidate/lib/validators";

import {
  FORUMS_TOPIC_EDIT,
  FORUMS_TOPIC_ADD,
  REPORT_TOPIC
} from "@/models/forums/operations.gql";

export default {
  name: "TopicCard",
  components: {
    UserPill,
    TipTap,
    InlineDate
  },
  props: {
    topic: {
      type: Object,
      default: null
    },
    isHeader: {
      type: Boolean,
      default: false
    },
    //set this for when topic is empty on new topic
    forum: {
      type: Object,
      default: null
    },
    forumId: {
      type: String,
      default: ""
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      body: {
        required
      }
    }
  },
  data: () => ({
    state: {
      isEditing: false
    },
    form: {
      title: null,
      body: null
    }
  }),
  computed: {
    isDeleted() {
      return this.topic && this.topic.deleted_at ? true : false;
    },
    userCanEdit() {
      return (
        (this.topic &&
          !this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.topic.author.id) ||
        (!this.isDeleted && (this.$auth.isAdmin || this.$auth.isOrganizer))
      );
    },
    userCanDelete() {
      return (
        (this.topic &&
          !this.isDeleted &&
          this.$auth.isAuthenticated &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.topic.author.id) ||
        (!this.isDeleted && this.$auth.isAdmin)
      );
    },
    canShowEllipsesAllow() {
      return this.$auth.isAdmin && this.isReported;
    },
    canShowEllipsesDelete() {
      return (!this.isHeader || this.isReported) && this.userCanDelete;
    },
    canShowEllipsesEdit() {
      return !this.isHeader && this.userCanEdit;
    },
    canShowEllipsesReport() {
      return !this.isHeader;
    },
    isEllipsesEnabled() {
      return this.topic !== null 
        && this.$auth.isAuthenticated
        && (this.canShowEllipsesAllow || this.canShowEllipsesDelete || this.canShowEllipsesEdit || this.canShowEllipsesReport);
    },
    isReported() {
      if (this.topic !== null && this.topic.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.topic !== null && this.topic.allowed_at !== null) {
        return true;
      }
      return false;
    },
    isDangerous() {
      if (this.topic !== null && this.topic.reports_aggregate.aggregate.count > 2 && this.isAllowed) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.topic !== null) {
      this.form.title = this.topic.title;
      this.form.body = { ...this.topic.body };
    } else {
      // topic is null, set ourselves to create mode
      this.$nextTick(() => {
        this.state.isEditing = true;
      });
    }
  },
  methods: {
    onTopicCardClick() {
      this.$router.push({
        name: "forum_topic",
        params: { slug: this.topic.slug || "X", topic_id: this.topic.id }
      });
    },
    onEditCancelled() {
      this.$log.debug("edit cancelled");
      this.form.title = this.topic ? this.topic.title : "";

      this.state.isEditing = false;

      if (this.topic) {
        this.form = {
          ...this.topic
        };
      }
      this.$emit("cancel");
    },
    onCreateCancelled() {
      this.$emit("cancel");
    },
    toggleEditing() {
      this.state.isEditing = !this.state.isEditing;
      this.$refs.dropdown.toggle();
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to delete this post?",
        onConfirm: () => {
          this.onDelete();
        }
      });
    },
    onCreateConfirmed(body) {
      //Using forumId when creating new topic from Events page (discussions)
      var _forumId = this.forum ? this.forum.id : this.forumId;

      this.$apollo
        .mutate({
          mutation: FORUMS_TOPIC_ADD,
          variables: {
            title: this.form.title,
            body: Object.assign({}, body),
            forum_id: _forumId
          },
          update: (cache, { data: { result } }) => {
            this.$log.info(cache, "got result", result);
            cache.evict(cache.identify(result));
            cache.gc();
            return result;
          }
        })
        .then(({ data: { result } }) => {
          this.state.isEditing = false;
          this.$emit("cancel");
          this.$buefy.snackbar.open({
            message: `Post ${result.title} created`,
            type: "is-success"
          });
        });
    },
    onEditConfirmed(body) {
      this.$apollo
        .mutate({
          mutation: FORUMS_TOPIC_EDIT,
          variables: {
            topic_id: this.topic.id,
            changes: {
              title: this.form.title,
              body: Object.assign({}, body)
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.state.isEditing = false;
          this.$buefy.snackbar.open({
            message: result.title ? `Post ${result.title} updated` : "Post updated",
            type: "is-success"
          });
        });
    },
    onDelete() {
      this.$log.debug("Delete Topic", this.form);
      this.$apollo
        .mutate({
          mutation: FORUMS_TOPIC_EDIT,
          variables: {
            topic_id: this.topic.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.isEditing = false;

          this.$emit("deleted", result);

          this.$buefy.snackbar.open({
            message: result.title ? `Post ${result.title} deleted` : "Post deleted",
            type: "is-success"
          });
        });
    },
    confirmReport() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to report this post?",
        onConfirm: () => {
          this.onReport();
        }
      });
    },
    onReport() {
      this.$log.debug("Report Topic");
      const resolved_route = this.$router.resolve({
        name: 'forum_topic',
        params: { 
          slug: this.topic.slug || "X", 
          topic_id: this.topic.id
        }
      });
      this.$apollo
        .mutate({
          mutation: REPORT_TOPIC,
          variables: {
            topic_id: this.topic.id,
            reporting_user_id: this.$auth.user.id,
            reported_route: resolved_route.href
          }
        })
        .then(({ data: { result } }) => {
          this.$buefy.snackbar.open({
            message: `Post Reported`,
            type: "is-success"
          });
        });
    },
    onAllow() {
      this.$log.debug("Allow Topic");
      this.$apollo
        .mutate({
          mutation: FORUMS_TOPIC_EDIT,
          variables: {
            topic_id: this.topic.id,
            changes: {
              allowed_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.isEditing = false;
          this.$emit("allowed", result);

          this.$buefy.snackbar.open({
            message: result.title ? `Topic ${result.title} allowed` : "Topic allowed",
            type: "is-success"
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.is-danger-outline {
  outline: solid 1px $danger;
}
.is-topic-display {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-width: 100%;
  @include mobile {
    flex-direction: column;
  }
  .is-topic-meta {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
      align-items: flex-end;
      row-gap: 1rem;
    }
    @include mobile {
      flex-direction: row;
      align-items: center;
    }
  }
  .tags {
    .tag {
      @include mobile {
        margin-bottom: 0;
      }
      > span {
        display: flex;
        align-items: center;
      }
    }
  }
  .author-tag {
    padding-top: 8px;
  }
}
.title-row {
  @include tablet {
    justify-content: flex-start;
  }
  @include mobile {
    justify-content: space-between;
  }
}
</style>
