<template>
  <div class="undo-confirm-buttons">
    <b-button v-if="undoEnabled" type="is-ghost elevation-1 is-outlined" @click.stop="debouncedOnUndo">
      <slot name="undo">
        Cancel
      </slot>
    </b-button>

    <b-button :type="confirmButtonType" :disabled="!confirmEnabled" type="is-ghost is-success elevation-1 is-outlined confirm-button" @click.stop="debouncedOnConfirm">
      <slot name="confirm">
        Submit
      </slot>
    </b-button>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "UndoConfirmButtons",
  props: {
    undoEnabled: {
      type: Boolean,
      default: true
    },
    confirmEnabled: {
      type: Boolean,
      default: false
    },
    confirmButtonType: {
      type: String,
      default: "is-ghost elevation-1"
    }
  },
  created() {
    this.debouncedOnConfirm = _.debounce(this.onConfirm, 500, {
      leading: true,
      trailing: false
    });
    this.debouncedOnUndo = _.debounce(this.onUndo, 500, {
      leading: true,
      trailing: false
    });
  },
  methods: {
    onConfirm() {
      this.$emit("confirm");
    },
    onUndo() {
      this.$emit("undo");
    }
  }
};
</script>

<style lang="scss">
.undo-confirm-buttons {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  column-gap: 1rem;
  @include mobile {
    justify-content: space-between;
    margin-bottom: 0.5rem;
    > * {
      width: 50%;
    }
  }
}
</style>
