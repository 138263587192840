<template>
  <div
    v-if="!isDangerous || $auth.isAdmin"
    :id="`prompt-${prompt === null ? 'new' : prompt.id}`"
    class="prompt-card is-full-width"
  >
    <NakedPromptCardViewHeader
      v-if="isViewHeader && !isEdit"
      :prompt="prompt"
      @delete="onDelete()"
      @allow="onAllow()"
    />

    <NakedPromptCardViewExpanded
      v-if="isViewExpanded && !isEdit"
      :prompt="prompt"
      @edit="setEdit()"
      @delete="onDelete()"
      @allow="onAllow()"
    />

    <NakedPromptCardEdit
      v-if="isEdit"
      :prompt="prompt"
      @created="onCreated($event)"
      @updated="onUpdated($event)"
      @cancelled="onCancelled()"
    />
  </div>
</template>

<script>
import { UPDATE_PROMPT } from "@/models/challenges/operations.gql";
import NakedPromptCardViewHeader from "@/components/nakedPromptCard/NakedPromptCardViewHeader.vue";
import NakedPromptCardViewExpanded from "@/components/nakedPromptCard/NakedPromptCardViewExpanded.vue";
import NakedPromptCardEdit from "@/components/nakedPromptCard/NakedPromptCardEdit.vue";

export default {
  name: "NakedPromptCardCoordinator",
  components: {
    NakedPromptCardViewHeader,
    NakedPromptCardViewExpanded,
    NakedPromptCardEdit
  },
  props: {
    prompt: {
      type: Object,
      default: null
    },
    isHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isViewHeader: false,
      isViewExpanded: false,
      isEdit: false
    };
  },
  computed: {
    isDangerous() {
      if ((this.prompt?.reports_aggregate?.aggregate.count ?? 0) > 2 && this.prompt.allowed_at === null) {
        return true;
      }
      return false;
    }
  },
  created() {
    if (this.prompt) {
      if (this.isHeader) {
        this.setViewHeader();
      } else {
        this.setViewExpanded();
      }
    } else {
      this.setEdit();
    }
  },
  methods: {
    reset() {
      this.isViewHeader = false;
      this.isViewExpanded = false;
      this.isEdit = false;
    },
    setViewHeader() {
      this.reset();
      this.isViewHeader = true;
    },
    setViewExpanded() {
      this.reset();
      this.isViewExpanded = true;
    },
    setEdit() {
      this.isEdit = true;
    },
    unsetEdit() {
      this.isEdit = false;
    },
    onCreated(e) {
      this.$emit("created", e);
      this.unsetEdit();
    },
    onUpdated(e) {
      this.$emit("updated", e);
      this.unsetEdit();
    },
    onCancelled() {
      this.$emit("cancelled");
      this.unsetEdit();
    },
    onDelete() {
      this.$log.debug("Delete Prompt", this.prompt);
      this.$apollo
        .mutate({
          mutation: UPDATE_PROMPT,
          variables: {
            prompt_id: this.prompt.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.$emit("deleted", result);

          this.$buefy.snackbar.open({
            message: result.title ? `Prompt ${result.title} deleted` : "Prompt deleted",
            type: "is-success"
          });
        });
    },
    onAllow() {
      this.$log.debug("Allow Prompt");
      this.$apollo
        .mutate({
          mutation: UPDATE_PROMPT,
          variables: {
            prompt_id: this.prompt.id,
            changes: {
              allowed_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.isEditing = false;
          this.$emit("allowed", result);

          this.$buefy.snackbar.open({
            message: result.title ? `Prompt ${result.title} allowed` : "Prompt allowed",
            type: "is-success"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.prompt-card {
  p > a {
    color: inherit;
  }
  &.is-full-width {
    width: 100%;
    flex-grow: 1;
  }
  .pill {
    gap: 10px;
  }
}
</style>