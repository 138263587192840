import { LIST_CHALLENGE_PROMPTS, UPDATE_CHALLENGE } from "@/models/challenges/operations.gql";
import { isBefore, isPast } from "date-fns";

const challengeCardViewExpanded = {
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      acceptForm: {
        unlockRate: null
      },
      isBefore,
      prompts: [],
      isFeatured: false
    };
  },
  computed: {
    totalSubmissions() {
      const count = this.challenge.prompts.reduce(
        (agg, prompt) => agg + prompt.submissions_aggregate.aggregate.count,
        0
      );

      return count;
    },
    totalPosts() {
      let count = 0;
      this.challenge.prompts.forEach(prompt => {
        count += prompt.submissions.reduce(
          (agg, submission) => agg + submission.topic.posts_aggregate.aggregate.count,
          0
        );
      });

      return count;
    },
    engagementCount() {
      return this.totalSubmissions + this.totalPosts;
    },
    wasHappeningNow() {
      return isPast(this.availableDate);
    },
    availableDate() {
      if (this.challenge) {
        if (this.challenge.current_user_shifted_start_date) {
          return new Date(this.challenge.current_user_shifted_start_date);
        }
        return new Date(this.challenge.available_after);
      }
      return null;
    },
    userCanEdit() {
      return (
        (!this.isDeleted &&
          !this.isEditing &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.challenge.author.id) ||
        (!this.isDeleted && this.$auth.isAdmin)
      );
    },
    userCanDelete() {
      return (
        (!this.isDeleted &&
          !this.isEditing &&
          this.$auth.isAuthenticated &&
          this.$auth.user.id === this.challenge.author.id) ||
        (!this.isDeleted && this.$auth.isAdmin)
      );
    },
    shiftedStartDate() {
      if (isBefore(this.nowTimer, new Date(this.challenge.available_after))) {
        return this.challenge.available_after;
      }
      return this.nowTimer;
    }
  },
  apollo: {
    prompts: {
      query: LIST_CHALLENGE_PROMPTS,
      variables() {
        return {
          challenge_id: this.challenge.id
        };
      },
      skip() {
        return !this.isFeatured;
      }
    }
  },
  mounted() {
    this.isFeatured = this.challenge.featured_at !== null ? true : false;
    this.form = {
      ...this.challenge,
      available_after: new Date(this.challenge.available_after)
    };
  },
  methods: {
    onChallengeCardClick() {
      this.$router.push({
        name: "challenges_detail",
        params: {
          slug: this.challenge.slug
        }
      });
    },
    onAccept() {
      var challenge_user = {
        shifted_start_date: null,
        challenge_id: this.challenge.id
      };
      this.$emit("accepted", challenge_user);
    },
    onEdit() {
      this.$log.debug("Edit Challenge", this.challenge.id);
      this.$emit("edit");
    },
    onDelete() {
      this.$log.debug("Delete Challenge", this.challenge.id);
      this.$apollo
        .mutate({
          mutation: UPDATE_CHALLENGE,
          variables: {
            challenge_id: this.challenge.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.$emit("deleted", result);

          this.$buefy.snackbar.open({
            message: result.title ? `Challenge ${result.title} deleted` : "Challenge deleted",
            type: "is-success"
          });
        });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to delete this challenge?",
        onConfirm: () => {
          this.onDelete();
        }
      });
    }
  }
};

export default challengeCardViewExpanded;
