<template>
  <svg
    v-if="seedString"
    :width="avatarDiameter"
    :height="avatarDiameter"
    :style="`width: ${avatarDiameter}px; height: ${avatarDiameter}px;`"
    class="avatar elevation-2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
  >
    <circle cx="50%" cy="50%" r="50%" :fill="color" stroke="white" :stroke-width="`${avatarStroke}`" />
    <text x="50%" y="50%" dominant-baseline="central" text-anchor="middle" :style="dynamicTextStyle">
      {{ seedString | initials }}
    </text>
  </svg>
</template>

<script>
// it's easier to center the text on odd values, jus' sayin'
const px_sizes = {
  small: 25,
  medium: 35,
  large: 61,
  huge: 129,
  insane: 257
};

const px_strokes = {
  small: 0,
  medium: 0,
  large: 6,
  huge: 12,
  insane: 16
};

const px_font = {
  small: 11,
  medium: 16,
  large: 26,
  huge: 56,
  insane: 115.65
};

export default {
  name: "Avatar",
  props: {
    seedString: {
      type: String,
      default: () => "not set"
    },
    size: {
      type: String,
      default: () => "small",
      validator: v => Object.keys(px_sizes).includes(v)
    },
    color: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      avatarDiameter: px_sizes[this.size],
      avatarStroke: px_strokes[this.size]
    };
  },
  computed: {
    dynamicTextStyle() {
      return {
        "font-size": `${px_font[this.size]}px`,
        "font-weight": 800
      };
    }
  }
};
</script>

<style lang="scss">
svg.avatar {
  // border: solid 0.1em #FFF;
  border-radius: 50%;
  margin-right: 0.3em;
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  fill-rule: evenodd;
  clip-rule: evenodd;
  text {
    font-family: $family-primary;
    fill: white;
  }
}
svg.avatar.stacked {
  text{
    font-size: 1em !important;
    font-weight: 500 !important;
  }
  margin-right: -15px;
}
</style>
