<template>
  <div class="card">
    <div class="card-info">
      <div class="card-details">
        <h2 class="has-text-grey400">{{ prompt.title }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import challengePromptCardViewHeaderLocked from "@/components/challengePromptCard/ChallengePromptCardViewHeaderLocked.js";

export default {
  name: "ChallengePromptCardViewHeaderLocked",
  mixins: [challengePromptCardViewHeaderLocked],
  props: {
    // the prompt you're showing; required
    prompt: {
      type: Object,
      default: null
    }
  }
};
</script>
