<template>
  <section>
    <div v-if="!hideSort" class="discussions-nav">
      <div class="tabs is-danger is-multiline is-medium">
        <ul class="is-flex-grow-0">
          <li
            v-for="(t, idx) in tabs"
            :key="idx"
            :class="{ 'is-active': activeSort === idx }"
          >
            <a @click="activeSort = idx"
              :class="{ 'button discussions-button': suwMobile }">
              <b-icon :icon="t.icon" :custom-class="t.customClass" />
              <span class="button-label">{{ t.label }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="is-flex is-justify-content-space-between">
        <b-switch
          v-if="$auth.isAuthenticated"
          v-model="onlyShowMine"
          class="ml-2"
          size="is-small"
          type="is-warning"
        >
          My Posts
        </b-switch>
      </div>
      <AuthButton
        class="is-right"
        type="button discussions-button has-text-weight-semibold has-text-info ml-1"
        :label="suwMobile ? '' : 'Create New Post'"
        :icon-left="'plus-circle'"
        :disabled="isShowingAddTopic"
        @click="isShowingAddTopic = true"
      >
        <template #dropdown="{ login }">
          <div
            class="is-flex is-justify-content-center is-align-items-center p-2"
          >
            <p class="mr-2">
              Login Required
            </p>
            <b-button
              label="Sign Up / Log in"
              type="is-primary"
              @click="login"
            />
          </div>
        </template>
      </AuthButton>
    </div>

    <!-- New Topic Form -->
    <TopicCard
      v-if="isShowingAddTopic"
      :forum-id="forumId"
      class="boxed my-2 elevation-1"
      :class="suwMobile ? 'p-2': 'p-3'"
      @cancel="isShowingAddTopic = false"
    />
    <!-- Topics -->
    <TopicCard
      v-if="topics.length > 0"
      v-for="t in topics"
      :key="t.id"
      :topic="t"
      :class="suwMobile ? 'p-2': 'p-3'"
      class="boxed my-2 elevation-1"
    />
    <div v-else class="mt-1">
      <EmptyState>
        No Discussions yet.
      </EmptyState>
    </div>
  </section>
</template>

<script>
import { FORUMS_TOPIC_LIST } from "@/models/forums/operations.gql";
import TopicCard from "@/components/forums/TopicCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import AuthButton from "@/components/common/AuthButton.vue";

export default {
  name: "TopicList",
  components: {
    TopicCard,
    EmptyState,
    AuthButton
  },
  props: {
    hideSort: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 5
    },
    forumId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      topics: [],
      isShowingAddTopic: false,
      onlyShowMine: false,
      activeSort: 0,
      tabs: [
        {
          label: "Latest",
          icon: "exchange-alt",
          customClass: "fa-rotate-90",
          orderBy: { created_at: "desc" }
        },
        {
          label: "Top Posts",
          icon: "star",
          orderBy: [
            { posts_aggregate: { count: "desc" } },
            { created_at: "desc" }
          ]
        }
        // {
        //   label: "My Activity",
        //   icon: "user",
        //   orderBy: {created_at: "asc"},
        // }
      ]
    };
  },
  computed: {
    orderBy() {
      return this.tabs[this.activeSort].orderBy;
    },
    where() {
      let out = {
        deleted_at: { _is_null: true }
      };
      if (this.forumId !== null) {
        out.forum_id = { _eq: this.forumId };
      }
      if (this.onlyShowMine) {
        out.author_id = { _eq: this.$auth.user.id };
      }
      return out;
    }
  },
  apollo: {
    topics: {
      query: FORUMS_TOPIC_LIST,
      variables() {
        return {
          where: this.where,
          orderBy: this.orderBy,
          limit: this.limit
        };
      },
      update({ topics }) {
        return topics;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.discussions-nav {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-grow: 1;
  @include mobile {
    margin-top: 0.75rem;
  }
}
.tabs {
  flex-grow: 1;
  .icon {
    padding: 0;
  }
  @include mobile {
    ul {
      border-bottom-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: stretch;
    }
    li {
      margin-right: 0.5rem;
      display: flex;
      a {
        border-radius: 4px;
        border: solid 1px #ddd;
        margin-bottom: 0;
        span {
          &.button-label {
            font-size: 0;
          }
        }
      }
    }
    .icon {
      &:first-child {
        margin: 0;
      }
    }
  }
}
.is-topic-display {
  .tags {
    .tag {
      margin-bottom: 0;
    }
  }
}
</style>
