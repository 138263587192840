<template>
  <b-tag
    class="clickable-tag has-text-grey-dark p-0"
    :class="{ 'is-enabled': isEnabled, 'is-disabled': isDisabled }"
    :type="type"
  >
    <b-button
      class="clickable-tag-button"
      :class="colorClass"
      ref="clickableButton"
      :type="buttonType"
      :icon-left="iconLeft"
      :icon-right="iconRight"
      :label="label"
      :disabled="isDisabled"
      @click="onClick"
    >
      <slot></slot>
    </b-button>
  </b-tag>
</template>

<script>
export default {
  name: "ClickableTag",
  props: {
    isEnabled: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    buttonType: {
      type: String,
      default: "is-text has-text-grey-dark"
    },
    iconLeft: {
      type: String,
      default: ""
    },
    iconRight: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    colorClass: {
      type: String,
      default: "blue"
    }
  },
  methods: {
    onClick() {
      this.$refs.clickableButton.$el.blur();
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss">
.clickable-tag {
  transition: box-shadow 100ms;
  box-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.25);

  &:hover {
    box-shadow: 0 1px 3px rgba($color: #000000, $alpha: 0.25);
  }

  &:active {
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.25);
  }

  &.is-enabled {
    box-shadow: inset 0 1px 3px rgba($color: #000000, $alpha: 0.25);

    &:hover {
      box-shadow: inset 0 2px 5px rgba($color: #000000, $alpha: 0.25);
    }

    &:active {
      box-shadow: inset 0 3px 6px rgba($color: #000000, $alpha: 0.25);
    }
  }

  &.is-disabled {
    box-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.25);

    &:hover {
      box-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.25);
    }

    &:active {
      box-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.25);
    }
  }

  .clickable-tag-button {
    height: auto;
    font-size: 0.75rem;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .blue {
    &:hover {
      background: #AED8F0 ;
    }
  }

  .yellow {
    &:hover {
      background: #FFEDB6 ;
    }
  }

  .green {
    &:hover {
      background: #BFEBCE;
    }
  }
  
}
</style>
