<template>
  <div class="weekdays">
    <b-button
      v-for="(_, day_of_week) of 7"
      :key="day_of_week"
      :class="{ 'is-primary elevation-2 is-selected': day_of_week === value }"
      @click="onDayClick(day_of_week)"
    >
      {{ labels[day_of_week] }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "WeekdayButtons",
  props: {
    value: {
      type: Number,
      default: 0 // whatever the first label in the labels list is below
    }
  },
  data() {
    return {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    };
  },
  methods: {
    onDayClick(day_of_week) {
      this.$emit("offset", day_of_week - this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.weekdays {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;
  @include desktop {
    column-gap: 1rem;
  }
  
  .button {
    margin: 0em;
    padding-left: 0;
    padding-right: 0;
    flex: 1;
  }
}
</style>
