<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-if="!$apollo.loading && headerCopy" v-html="copyright(headerCopy.copy.html)" />
</template>

<script>
import { HEADERPAGEHEADERCOPY } from "@/models/resources/operations.gql";
import { copyright } from "@/filters.js";

export default {
  name: "HeaderPageHeaderCopy",
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  methods: {
    copyright
  },
  apollo: {
    headerCopy: {
      client: "cms",
      query: HEADERPAGEHEADERCOPY,
      variables() {
        const stage =
          !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts) ? "DRAFT" : "PUBLISHED";
        return {
          stage,
          slug: this.slug
        };
      },
      update({ headerPageHeaderCopy }) {
        //this.$log.debug(headerPageHeaderCopy);
        return headerPageHeaderCopy;
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/mixins';
header {
  h1 {
    color: $gold;
    font-weight: 400;
  }

  p {
    margin: $size-7 0;
    @include mobile {
      margin: vwl(15px) 0;
    }
    @include portrait {
      margin: vwp(15px) 0;
    }
    &, a {
      color: $grey300;
      font-weight: 300;
    }

    strong {
      color: $white;
      font-weight: 500;
      display: block;
    }   
  }
}
</style>