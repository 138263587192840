<template>
  <div class="challenge-editor card">
    <div class="editor-container card-info">
      <div class="card-details">
        <label class="label">Challenge Title (required, be creative)</label>
        <div class="editor-title">
          <b-field
            :type="{ 'is-danger': $v.form.title.$invalid }"
            :message="{ 'Title is required': !$v.form.title.required }"
          >
            <b-input v-model="form.title" placeholder="My Amazing Challenge" />
          </b-field>
          <b-switch v-if="$auth.isAdmin" v-model="isFeatured" class="is-flex is-justify-content-flex-end">
            Featured
          </b-switch>
        </div>
        <TipTap
          v-model="form.body"
          :is-preview="false"
          :show-edit-controls="true"
          :editable="true"
          :confirm-enabled="() => !$v.form.$invalid"
          :type="$v.form.body.required ? '' : 'is-danger'"
          :message="$v.form.body.required ? '' : 'Challenge body is required.'"
          @edit-cancelled="onCancel"
          @edit-confirmed="onConfirm"
          @input="onDraft"
        />
      </div>
      <div class="card-actions">
        <div class="cal">
          <b-field label="Select the first day this challenge will be available" class="m-0">
            <b-datetimepicker v-model="form.available_after" inline placeholder="Click to select...">
              <template #left>
                <b-button type="is-primary" icon-left="clock" @click="form.available_after = new Date()">
                  <span>Now</span>
                </b-button>
              </template>
            </b-datetimepicker>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import challengeCardEdit from "@/components/challengeCard/ChallengeCardEdit.js";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "ChallengeCardEdit",
  components: {
    TipTap
  },
  mixins: [challengeCardEdit],
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  methods: {
    onCreated(result) {
      this.$emit("created", result);
    },
    onUpdated(result) {
      this.$emit("updated", result);
    },
    onCancelled() {
      this.$emit("cancelled");
    }
  }
};
</script>

<style lang="scss" scoped>
.editor-container {
  flex-grow: 1;
}
.editor-title {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 0.75rem;
  .field {
    margin-bottom: 0;
    flex: 1;
  }
  .switch {
    margin-top: 8px;
  }
}
.cal {
  width: 100%;
  @include desktop {
    padding-left: 1rem;
  }
}

</style>