<template>
  <div>
    <h2>Review Your Single Event Changes...</h2>

    <h4 class="mt-2">Starts At</h4>
    <s v-if="eventStartsAtToBeUpdated">
      {{ event.starts_at || event.scheduled_starts_at | format("MMM do hh:mma z") }}
    </s>
    <p>{{ form.starts_at | format("MMM do hh:mma z") }}</p>

    <h4 class="mt-2">Description</h4>
    <TipTap :value="form.description" />

    <h4 class="mt-2">Venue</h4>
    <s>{{ venueStrikeThru }}</s>
    <p>{{ form.venue.street_address }}</p>

    <div v-if="form.venue.find_me">
      <h4 class="mt-2">How can attendees find you?</h4>
      <p>{{ form.venue.find_me }}</p>
    </div>

    <div v-if="form.venue.parking_info">
      <h4 class="mt-2">Parking / Transit Info</h4>
      <p>{{ form.venue.parking_info }}</p>
    </div>

    <div v-if="form.venue.wifi_info">
      <h4 class="mt-2">WiFi Info</h4>
      <p>{{ form.venue.wifi_info }}</p>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { toLocalDate } from "@/dates";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "EventReviewPhysical",
  components: {
    TipTap
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    venueStrikeThru() {
      return this.event.venue.street_address !== this.form.venue.street_address
        ? this.event.venue.street_address
        : "";
    },
    eventStartsAtToBeUpdated() {
      // Only update events.starts_at if these conditions apply
      return (this.event.starts_at === null && !isEqual(toLocalDate(this.form.starts_at), toLocalDate(this.event.scheduled_starts_at)))
        || this.event.starts_at !== null && !isEqual(toLocalDate(this.event.starts_at), toLocalDate(this.form.starts_at));
    }
  }
};
</script>
