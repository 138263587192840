<template>
  <div>
    <HeroDesktop class="is-hidden-mobile" @startTodayClicked="onStartTodayClick" />
    <HeroMobile class="is-hidden-tablet" @startTodayClicked="onStartTodayClick" />
  </div>
</template>

<script>
import HeroDesktop from "@/components/home/HeroDesktop.vue";
import HeroMobile from "@/components/home/HeroMobile.vue";

export default {
  name: "HeroCoordinator",
  components: {
    HeroDesktop,
    HeroMobile
  },
  methods: {
    onStartTodayClick() {
      if (this.$auth.isAuthenticated) {
        this.$router.push({
          name: "events"
        });
      }
      else {
        this.$auth.refreshAccess({ redirectUrl: this.$route.fullPath });
      }
    }
  },
}
</script>
