<template>
  <div class="events-container pt-3 pb-3">
    <slot name="top-corner"></slot>
    <transition v-if="!hideForm" name="slide">
      <keep-alive>
        <!-- VIRTUAL EVENT FORM -->
        <div v-if="form">
          <div v-if="isEditing">
            <b-field
              label="Host City"
              :type="{ 'is-danger': $v.form.host_city.$invalid }"
              class="is-danger"
              :message="{
                'Choosing a Host City is required!': $v.form.host_city.$invalid
              }"
            >
              <GooglePlaces
                :key="`ac-virtual-${autocompleteKey}`"
                :value="form.api_result"
                cities-only
                @updated:place="onPlaceChanged"
              />
            </b-field>
            <h4 class="is-size-6">
              Video Options
            </h4>
            <p class="is-size-6 pb-2">
              Your Shut Up & Write! online event will take place in a video chat environment within the Shut
              Up & Write! website. A link to your video room will be available on your event page five minutes
              before your event's listed start time. If you'd like to use an external video chat service, you
              may add that information by selecting the option below.
            </p>
            <b-field
              label="Meeting URL"
              :type="{ 'is-danger':  $v.form.url.$invalid }"
              :message="{
                'A valid meeting URL is required! ex. http://www.zoom.com': $v.form.url.$invalid
              }"
            >
              <b-input
                v-model="form.url"
                :required="true"
                type="url"
                placeholder="e.g. Zoom, Hangouts, Whereby..."
              />
            </b-field>

            <b-field label="Enter the meeting password or PIN">
              <b-input
                v-model="form.meeting_password"
                placeholder="Password/PIN"
              />
            </b-field>
          </div>
        </div>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import venueCardVirtual from "@/components/venueCard/VenueCardVirtual.js";
import GooglePlaces from "@/components/forms/GooglePlaces.vue";

export default {
  name: "VenueCardVirtualMobile",
  components: {
    GooglePlaces
  },
  mixins: [venueCardVirtual]
};
</script>

<style lang="scss" scoped>
.stupid-super-special-anchor {
  text-decoration: underline !important;
  color: #414141 !important;
}
</style>
