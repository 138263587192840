import Vue from "vue";
import { formatUTC, formatFromNow, formatFromNowMobile, toLocalDate } from "@/dates.js";

const BADD = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
const GOOD = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
const RE_CHAR_REPLACER = new RegExp(BADD.split("").join("|"), "g");
const DEFAULT_DATE_FORMAT = "MMM do, yyyy hh:mm a";

Vue.filter("dump", value => {
  if (!value) return "";
  return JSON.stringify(value, null, 2);
});

Vue.filter("initials", value => {
  if (!value || !value.trim().length) return "?";
  const words = value.trim().match(/\p{L}+|\p{M}+/gmu);
  let initials = "?";
  if (words.length > 0) {
    initials = words
      .reduce((prev, cur, idx) => {
        if (idx === 0 || idx === words.length - 1) {
          prev.push(cur[0]);
        }
        return prev;
      }, [])
      .join("")
      .toUpperCase();
  }
  return initials || "?";
});

Vue.filter("format", (value, fmt) =>
  formatUTC(value, fmt || DEFAULT_DATE_FORMAT)
);
Vue.filter("formatFromNow", (value, options) => formatFromNow(value, options));
Vue.filter("formatFromNowMobile", (value, options) => formatFromNowMobile(value, options));
Vue.filter("toLocalDate", toLocalDate);
Vue.filter("capitalize", value => {
  return value
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
});

export function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
Vue.filter("capitalizeFirstLetter", capitalizeFirstLetter);

export function copyright(value) {
  return value.replaceAll("®", '<sup style="font-size: 25px">®</sup>');
}
Vue.filter("copyright", value => {
  return copyright(value);
});

Vue.filter("truncate", (value, max_length = 75) => {
  value = value.replaceAll("\\n\\n", "  "); // cleanup postgres summarized tiptap bodies
  // if (value.length > max_length) {
  //   return value.trim().substring(0, max_length - 3) + "...";
  // }
  return value.trim();
});

export function truncate_render(value, max_length = 290) {
  value = value.replaceAll("\\n\\n", "  "); // cleanup postgres summarized tiptap bodies
  // const canvas = truncate_render.canvas || (truncate_render.canvas = document.createElement("canvas"));
  // const context = canvas.getContext("2d");
  // var metrics = context.measureText(value);
  // while (metrics.width > max_length) {
  //   value = value.trim();
  //   value = (value.substring(0, value.length - 4) + '...').trim();
  //   metrics = context.measureText(value);
  // }
  return value.trim();
}
Vue.filter("truncate_render", truncate_render);

export function user_name(user) {
  if (user.first_name && user.last_name) {
    if (user.is_last_name_hidden) {
      return `${capitalizeFirstLetter(user.first_name)} ${user.last_name.charAt(0).toUpperCase()}`;
    } else {
      return `${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(user.last_name)}`;
    }
  } else if (user.first_name) {
    return `${capitalizeFirstLetter(user.first_name)}`; //for Meetup.com, really
  } else {
    return "";
  }
}
Vue.filter("user_name", user_name);

export function format_event_title_time(event) {
  let dateToUse = event.starts_at || event.scheduled_starts_at;
  if (!dateToUse) {
    return `format_event_title_time error id: ${event.id}`;
  }
  let result = `${formatUTC(dateToUse, "MMM d, yyyy: h:mmaaa")}`; //Mon FMDD, YYYY: FMHH12am
  return result;
}
Vue.filter("format_event_title_time", format_event_title_time);

export function format_event_title_place(event) {
  let venue = event.venue || event.template.venue;
  if (venue.is_virtual) {
    return "Online"; //currently not used
  } else {
    return `at ${venue.title}`;
  }
}
Vue.filter("format_event_title_place", format_event_title_place);

export function format_event_title_host(event) {
  return `with ${user_name(event.template.organizers[0].user)}`;
}
Vue.filter("format_event_title_host", format_event_title_host);

export function format_event_title(event) {
  let time = format_event_title_time(event);
  let isVirtual = (event.venue && event.venue.is_virtual) || event.template.original_venue_was_virtual;
  if (isVirtual) {
    let host = format_event_title_host(event);
    return `${time} ${host}`;
  } else {
    let place = format_event_title_place(event);
    return `${time} ${place}`;
  }
}
Vue.filter("format_event_title", format_event_title);

export function format_title(event_or_series) {
  if (!event_or_series) return "UNKNOWN";

  //If there is a title set on the series, use it. Only otherwise do we compute a new series title.
  //Events never get custom titles.
  if (event_or_series.template) {
    //events have a .template property
    return format_event_title(event_or_series);
  } else if (event_or_series.series) {
    return event_or_series.series.title;
  } else {
    //series don't, because they are the template, and furthermore always have a title in the database
    return event_or_series.title;
  }
}
Vue.filter("format_title", format_title);

export function format_form_title(form) {
  if (!form) return "UNKNOWN";

  if (form.title) {
    return form.title;
  }

  let title = "Shut Up & Write!";
  let host = user_name(form.organizers[0]);

  if (form.original_venue_was_virtual) {
    title = `${title} Online`;
  } else if (form.venue) {
    title = `${title} at ${form.venue.title}`;
  }
  title = `${title} with ${host}`;

  return title;
}
Vue.filter("format_form_title", format_form_title);

export function slugify(thing) {
  if (thing && thing.upcoming_event && thing.upcoming_event.slug !== null) {
    return thing.upcoming_event.slug;
  }
  if (thing && thing.slug) {
    return thing.slug;
  }
  return format_title(thing)
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(RE_CHAR_REPLACER, c => GOOD.charAt(BADD.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-"); // Replace multiple - with single -
}
Vue.filter("slugify", slugify);