import { REPORT_PROMPT } from "@/models/challenges/operations.gql";

const nakedPromptCardViewExpanded = {
  props: {
    prompt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      promptAggregates: [
        {
          name: "user",
          value: this.prompt.submissions_aggregate.aggregate.user_count,
          type: "is-success is-light",
          icon: "user-check",
          condition: true
        },
        {
          name: "response",
          value: this.prompt.submissions_aggregate.aggregate.count,
          type: "is-info is-light",
          icon: "file",
          condition: true
        },
        {
          name: "reaction",
          value: this.prompt.submissions_aggregate.nodes.reduce(
            (a, b) => a + b.reactions_aggregate.aggregate.count,
            0
          ),
          type: "is-warning is-light",
          icon: "thumbs-up",
          condition: false
        }
      ]
    };
  },
  computed: {
    canShowEllipsesAllow() {
      return this.$auth.isAdmin && this.isReported;
    },
    canShowEllipsesDelete() {
      return this.$auth.user.id === this.prompt.author.id || this.$auth.isAdmin;
    },
    canShowEllipsesEdit() {
      return this.$auth.user.id === this.prompt.author.id || this.$auth.isAdmin;
    },
    isEllipsesEnabled() {
      return this.$auth.isAuthenticated;
    },
    isReported() {
      if (this.prompt.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.prompt !== null && this.prompt.allowed_at !== null) {
        return true;
      }
      return false;
    }
  },
  methods: {
    onPromptCardClick() {
      this.$router.push({
        name: "prompt_detail_naked",
        params: {
          prompt_id: this.prompt.id
        }
      });
    },
    onEditClick() {
      this.$emit("edit");
    },
    onDeleteClick() {
      this.$emit("delete");
    },
    confirmReport() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to report this Prompt?",
        onConfirm: () => {
          this.onReport();
        }
      });
    },
    onReport() {
      this.$log.debug("Report Prompt");
      const resolved_route = this.$router.resolve({
        name: "prompt_detail_naked",
        params: {
          prompt_id: this.prompt.id
        }
      });
      this.$apollo
        .mutate({
          mutation: REPORT_PROMPT,
          variables: {
            prompt_id: this.prompt.id,
            reporting_user_id: this.$auth.user.id,
            reported_route: resolved_route.href
          }
        })
        .then(({ data: { result } }) => {
          this.$buefy.snackbar.open({
            message: `Prompt Reported`,
            type: "is-success"
          });
        });
    },
    onAllowClick() {
      this.$emit("allow");
    }
  }
}

export default nakedPromptCardViewExpanded;