export const isNotDeleted = () => ({
  deleted_at: { _is_null: true }
});

/*
returns direct message topics that involve the passed in `user_id`
*/
export const isDirectMessageTopic = user_id => ({
  forum: {
    DM_binding: {
      _or: [
        { user_id_low: { _eq: user_id } },
        { user_id_high: { _eq: user_id } }
      ]
    }
  }
});
