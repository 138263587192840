<template>
  <h1>Redirecting to Login...</h1>
</template>

<script>
export default {
  name: "LogoutRedirector",
  mounted() {
    this.$auth.$on("ready", this.$auth.logout({ redirectLocation: { name: "home" } }));
  }
};
</script>
