import { render, staticRenderFns } from "./TestimonialCardCarousel.vue?vue&type=template&id=63dc8861&scoped=true&"
import script from "./TestimonialCardCarousel.vue?vue&type=script&lang=js&"
export * from "./TestimonialCardCarousel.vue?vue&type=script&lang=js&"
import style0 from "./TestimonialCardCarousel.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TestimonialCardCarousel.vue?vue&type=style&index=1&id=63dc8861&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63dc8861",
  null
  
)

export default component.exports