<template>
  <Section>
    <EventSeriesCoordinator :loading="loading" :event="event" :is-editing="isEditingSeries" @updated="onSeriesUpdated" @edit-cancelled="onSeriesEditCancelled" />
    <div v-if="event" v-show="!isEditingSeries" class="event-details">
      <div class="events-container">
        <SeriesJoinLeave
          v-if="event.template.cancelled_by_user_at === null"
          :value="event"
          class="is-hidden-tablet"
        />
        <div class="button-group mt-2 mb-2">
          <!-- prev event navigation -->
          <EventNavLink
            :event="event"
            :is-prev="true"
            :class="{
              'elevation-1': suwMobile,
              hidden: !hasPrevEvent
            }"
            class="nav-button prev-button"
          />
  
          <div class="is-flex edit-cancel-buttons" v-if="canDisplayMiddleButtons">
            <b-button 
              class="button has-background-white has-text-info elevation-1"
              :expanded="suwMobile"
              @click="startEditing">
              Edit Series
            </b-button>
  
            <CancelCard
              v-if="canDisplayMiddleButtons"
              :event="event"
              cancel-type="series"
              class="cancel-button"
              :expanded="suwMobile"
            />
          </div>
  
          <!-- next event navigation -->
          <EventNavLink
            :event="event"
            :is-prev="false"
            :class="{
              'elevation-1': suwMobile,
              hidden: !hasNextEvent
            }"
            class="nav-button next-button"
          />
        </div>
      </div>
      <EventBody
        :class="{
          'p-3': !suwMobile,
          'elevation-1': !suwMobile,
          'boxed': !suwMobile
        }"
        :event="event"
        @updated="onEventUpdated"
      />
    </div>
  </Section>
</template>
  
<script>
import Section from "@/components/common/Section.vue";
import EventBody from "@/components/eventBody/EventBody.vue";
import EventSeriesCoordinator from "@/components/eventSeries/EventSeriesCoordinator.vue";
import CancelCard from "@/components/events/CancelCard.vue";
import EventNavLink from "@/components/events/EventNavLink.vue";
import SeriesJoinLeave from "@/components/events/SeriesJoinLeave.vue";

export default {
  name: "EventDetailSectionMain",
  components: {
    Section,
    EventSeriesCoordinator,
    EventBody,
    CancelCard,
    SeriesJoinLeave,
    EventNavLink
  },
  props: {
    event: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isEditingSeries: false
    };
  },
  computed: {
    canDisplayMiddleButtons() {
      return this.event.cancelled_by_user_at === null && this.event.template.cancelled_by_user_at === null && (this.$auth.isAdmin || this.event.current_user_is_organizer);
    },
    hasPrevEvent() {
      return (
        this.event &&
        this.event.other_events &&
        this.event.other_events.findIndex(e => e.id === this.event.id) > 0
      );
    },
    hasNextEvent() {
      return (
        this.event &&
        this.event.other_events &&
        this.event.other_events.findIndex(e => e.id === this.event.id) < this.event.other_events.length - 1
      );
    }
  },
  methods: {
    onSeriesUpdated() {
      this.$emit("seriesUpdated");
    },
    onEventUpdated() {
      this.$emit("eventUpdated");
    },
    onSeriesEditCancelled() {
      this.stopEditing();
    },
    startEditing() {
      this.isEditingSeries = true;
      this.$emit("startEditingSeries");
    },
    stopEditing() {
      this.isEditingSeries = false;
      this.$emit("stopEditingSeries");
    }
  }
};
</script>

<style lang="scss">
@import '../../scss/mixins';
.events-container {
  @include mobile {
    padding: 0 .5em;
  }
}
.join-button {
  @include mobile {
    width: calc(100% - 2px);
    margin: 1rem 1px 0 1px;
  }
  > div {
    width: 100%;
  }
  &.is-disabled {
    opacity: 1;
  }
  .button {
    width: 100%;
    font-weight: 500;
  }
  &.is-disabled {
    .button {
      background-color: #fff;
    }
  }
  .dropdown-menu {
    @include desktop {
      min-width: 320px;
    }
  }
  .dropdown-content {
    padding: 0;
  }
  &.dropdown.is-mobile-modal {
    > .dropdown-menu {
      @include until($desktop) {
        bottom: 25% !important;
      }
      @include mobile {
        max-height: none;
        max-width: none;
        top: 15% !important;
        bottom: 15% !important;
        transform: translate3d(-50%, -15%, 0);
      }
    }
  }
  .is-large.modal-close {
    background-color: #414141;
    @include desktop {
      background-color: transparent;
    }
  }
}
.button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    row-gap: 1rem;
  }

  .nav-button {
    font-weight: 500;
    @include mobile {
      width: calc(50% - 7px);
      justify-content: center;
      background-color: white;
      border: solid 1px #dddddd;
      border-radius: 4px;
    }
    .link-title {
      padding-top: 2px;
    }
  }
  .prev-button {
    @include mobile {
      order: 2;
    }
    @include tablet {
      order: 1;
    }
  }
  .edit-cancel-buttons,
  .join-button {
    @include mobile {
      width: 100%;
      padding: 0 1px;
    }
  }
  .join-button,
  .cancel-button {
    > div, button {
      width: 100%;
    }
  }
  .edit-cancel-buttons {
    // margin: 0 auto;
    justify-content: center;
    @include mobile {
      order: 1;
      // width: 100%;
      justify-content: space-between;
      > * {
        width: calc(50% - 7px);
        display: flex;
      }
    }
    @include tablet {
      order: 2;
      column-gap: 1rem;
    }
    .dropdown-menu {
      width: auto;
    }
  }
  .join-button {
    width: calc(100% - 2px);
    margin-top: 1rem;
    > div {
      width: 100%;
    }
  }
  .cancel-button {
    button {
      background-color: white !important;
    }
  }

  .next-button {
    order: 3;
  }
  .hidden {
    visibility: hidden;
  }
}
.event-container {
  @include tablet {
    .columns {
      margin-bottom: 0;
      .column {
        &:first-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
  .edit-cancel-buttons {
    &.in-person {
      @include desktop {
        justify-content: end;
        position: absolute;
        bottom: 1.5rem;
        left: calc(58.333333% + 20px);
      }
    }
  }
}
</style>
