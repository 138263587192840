<template>
  <main>
    <HeaderLegacy v-if="title" v-bind="$props">
      <slot name="summary" />
    </HeaderLegacy>
    <Header v-else v-bind="$props">
      <slot name="summary" />
    </Header>
    <SectionContainer>
      <slot name="body" />
    </SectionContainer>
  </main>
</template>

<script>
import Header from "@/components/common/Header.vue";
import HeaderLegacy from "@/components/common/HeaderLegacy.vue";
import SectionContainer from "@/components/common/SectionContainer.vue";

export default {
  name: "HeaderPage",
  components: {
    Header,
    HeaderLegacy,
    SectionContainer
  },
  props: {
    title: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    imageClass: {
      type: String,
      default: null
    }
  }
};
</script>
