<template>
  <div>
    <div>
      <div>
        <div
          class="is-size-5 is-hover-grow"
          :class="{ 'is-danger-outline': $auth.isAdmin && isReported && !isAllowed }"
          @click="onPromptCardClick"
        >
          {{ prompt.body_text | truncate(330) }}
        </div>
      </div>
    </div>
    <div class="is-center is-flex is-justify-content-space-between">
      <div class="pill mt-2 is-flex is-justify-content-space-between is-align-items-center">
        <UserPill :user="prompt.author" />
        <InlineDate :d="prompt.created_at" class="has-text-grey400" />
      </div>
      <div class="is-align-self-flex-end">
        <b-taglist>
          <b-tag
            v-for="aggregate in promptAggregates"
            v-show="aggregate.condition"
            :key="aggregate.name"
            class="has-text-grey-dark"
            :type="aggregate.type"
          >
            <b-icon :icon="aggregate.icon" />
            <span>{{ aggregate.value }}</span>
          </b-tag>
          <b-dropdown v-if="isEllipsesEnabled" ref="dropdown" aria-role="list" position="is-top-left">
            <template #trigger>
              <b-button type="is-ghost is-small has-text-grey-dark" icon-left="ellipsis-h" />
            </template>
            <!-- allow -->
            <b-dropdown-item v-if="canShowEllipsesAllow" class="p-0 has-text-centered">
              <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllowClick" />
              <b-button v-else type="is-ghost" label="Allowed" disabled />
            </b-dropdown-item>
            <!-- delete -->
            <b-dropdown-item v-if="canShowEllipsesDelete" class="p-0 has-text-centered">
              <b-button type="is-ghost" label="Delete" @click="onDeleteClick" />
            </b-dropdown-item>
            <!-- edit -->
            <b-dropdown-item v-if="canShowEllipsesEdit" class="p-0 has-text-centered">
              <b-button type="is-ghost" label="Edit" @click="onEditClick" />
            </b-dropdown-item>
            <!-- report -->
            <b-dropdown-item class="p-0 has-text-centered">
              <b-button type="is-ghost" label="Report" @click="confirmReport" />
            </b-dropdown-item>
          </b-dropdown>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
import nakedPromptCardViewExpanded from "@/components/nakedPromptCard/NakedPromptCardViewExpanded.js";
import UserPill from "@/components/common/UserPill.vue";
import InlineDate from "@/components/common/InlineDate.vue";

export default {
  name: "NakedPromptCardViewExpandedDesktop",
  mixins: [nakedPromptCardViewExpanded],
  components: {
    UserPill,
    InlineDate
  },
  props: {
    prompt: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped>
.is-danger-outline {
  outline: solid 1px $danger;
}
</style>
