import { isFuture } from "date-fns";
import { required } from "vuelidate/lib/validators";
import { CREATE_PROMPT, UPDATE_PROMPT, GET_CHALLENGE_PROMPT_BY_DRAFT } from "@/models/challenges/operations.gql";

const challengePromptCardEdit = {
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    // this should only be set when prompt is null (create prompt inside a challenge)
    challenge: {
      type: Object,
      default: null
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    qaIsAvailable: {
      type: String,
      default: ""
    },
    qaIsAccepted: {
      type: String,
      default: ""
    },
    qaHasSubmitted: {
      type: String,
      default: ""
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      body: {
        required
      }
    }
  },
  data() {
    return {
      form: {
        challenge_id: null,
        title: "",
        body: null
      },
      draft: null
    };
  },
  computed: {
    isAccepted() {
      if (!this.qaIsAccepted && this.prompt) {
        return this.prompt.current_user_accepted_at !== null;
      }
      return this.qaIsAccepted === "y";
    },
    hasSubmitted() {
      if (!this.qaHasSubmitted && this.prompt) {
        return this.prompt.current_user_has_submission;
      }
      return this.qaHasSubmitted === "y";
    },
    isAvailable() {
      if (!this.qaIsAvailable && this.prompt) {
        return !isFuture(new Date(this.prompt.available_after));
      }
      return this.qaIsAvailable === "y";
    },
    isEllipsesEnabled() {
      return this.prompt && this.$auth.isAdmin;
    },
    isLocked() {
      if (!this.prompt || !this.prompt.challenge) {
        return false;
      }
      if (!this.isAvailable || !this.isAccepted) {
        return true;
      }
      return false;
    }
  },
  created() {
    if (this.prompt) {
      this.form.id = this.prompt.id;
      this.form.title = this.prompt.title;
      this.form.body = Object.assign({}, this.prompt.body);
    } else {
      this.form.challenge_id = this.challenge.id;
    }
  },
  methods: {
    onCancel() {
      if (this.prompt) {
        this.form = {
          ...this.prompt
        };
      }
      this.$emit("cancelled");
    },
    onDraft(body) {
      if (!this.prompt) {
        //only save NEW prompts as drafts
        Object.assign(this.form, { body });

        if (this.form.id) {
          this.onUpdate(true);
        } else {
          this.onCreate(true);
        }
      }
    },
    onConfirm(body) {
      Object.assign(this.form, { body });

      if (this.form.id) {
        this.onUpdate(false);
      } else {
        this.onCreate(false);
      }
    },
    onCreate(isDraft) {
      const { challenge_id, title, body } = this.form;

      this.$apollo
        .mutate({
          mutation: CREATE_PROMPT,
          variables: {
            prompt: {
              challenge_id,
              title,
              body,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
            if (isDraft) {
              this.form.id = result.id;
            }
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            this.$emit("created", result);
            this.$buefy.snackbar.open({
              message: result.title ? `Prompt ${result.title} created` : "Prompt created",
              type: "is-success"
            });
          }
        });
    },
    onUpdate(isDraft) {
      const { title, body } = this.form;
      const wasDraft = this.prompt ? this.prompt.is_draft : true;
      this.$apollo
        .mutate({
          mutation: UPDATE_PROMPT,
          variables: {
            prompt_id: this.form.id,
            changes: {
              title,
              body,
              is_draft: isDraft
            }
          },
          update: (cache, { data: { result } }) => {
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          if (!isDraft) {
            if (wasDraft) {
              this.$emit("created", result);
            } else {
              this.$emit("updated", result);
            }
            this.$buefy.snackbar.open({
              message: result.title ? `Prompt ${result.title} updated` : "Prompt updated",
              type: "is-success"
            });
          }
        });
    }
  },
  apollo: {
    draft: {
      query: GET_CHALLENGE_PROMPT_BY_DRAFT,
      variables() {
        return {
          author_id: this.$auth.user.id,
          challenge_id: this.form.challenge_id
        };
      },
      update({ drafts }) {
        const draft = drafts ? drafts[0] || null : null;
        if (draft && !this.form.id) {
          this.form.id = draft.id;
          this.form.title = draft.title;
          this.form.body = Object.assign({}, draft.body);
        }
        return draft;
      }
    }
  }
}

export default challengePromptCardEdit;