<template
  :prompt="prompt"
  :challenge-order="challengeOrder"
  :qa-has-submitted="qaHasSubmitted"
  :qa-has-submitted-when="qaHasSubmittedWhen"
>
  <div class="card">
    <div class="challenge-order" :class="hasSubmitted ? 'has-green-text' : 'has-yellow-text'">
      {{ challengeOrder }}
    </div>
    <div class="card-info">
      <div class="card-details">
        <h2>{{ prompt.title }}</h2>
        <TipTap v-model="form.body" />
      </div>
      <div class="card-actions">
        <h6>
          <template v-if="hasSubmitted">
            Completed {{ hasSubmittedWhen | formatFromNow }}
            <b-icon size="is-medium" type="is-success" icon="check-circle" />
          </template>
          <template v-else>
            Unlocked {{ prompt.available_after | formatFromNow }}
            <b-icon size="is-medium" icon="check-circle" />
          </template>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import challengePromptCardViewHeaderUnlocked from "@/components/challengePromptCard/ChallengePromptCardViewHeaderUnlocked.js";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "ChallengePromptCardViewHeaderUnlocked",
  components: {
    TipTap
  },
  mixins: [challengePromptCardViewHeaderUnlocked],
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    // values that can be set to simplify human QA of display logic
    qaHasSubmitted: {
      type: String,
      default: ""
    },
    qaHasSubmittedWhen: {
      type: String,
      default: ""
    }
  }
};
</script>
