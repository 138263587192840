<template>
  <div>
    <div>
      <slot v-if="fakeLoading || $apollo.queries.virtualEventList.loading" name="Loading">
        <div v-for="i in maxEvents" :key="i" class="is-flex line-item">
          <div style="flex-grow: 0;" class="my-2 ml-2">
            <b-skeleton width="120px" height="120px" />
          </div>
          <div style="width: 100%;" class="pl-2 pt-4">
            <b-skeleton size="is-large" :width="rand(40, 70)" />
            <b-skeleton :width="rand(60, 80)" />
            <b-skeleton :width="rand(20, 35)" />
          </div>
        </div>
      </slot>

      <slot v-else-if="totalVirtualEvents > 0">
        <EventSeriesCard
          v-for="event in displayedVirtualEvents"
          :key="event.id"
          :is-active="activeSeriesId === event.series.id"
          :event="event"
          class="mt-2"
        />
        <p
          v-if="
            apportionedViewable > 0 &&
              currentlyShowing < totalVirtualEvents
          "
          class="is-flex is-justify-content-space-around m-3"
        >
          <a
            class="has-text-grey-dark has-text-weight-bold is-size-5"
            @click="currentlyShowing += apportionedViewable"
          >
            Load More
          </a>
        </p>
      </slot>

      <slot v-else name="empty">
        <EmptyState>
          No events found.
        </EmptyState>
      </slot>
    </div>
  </div>
</template>

<script>
import { EVENT_SERIES_LIST_VIRTUAL } from "@/models/series/operations.gql";
import { formatUTC } from "@/dates.js";
import EventSeriesCard from "@/components/eventSeries/EventSeriesCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "EventSeriesListVirtual",
  components: {
    EmptyState,
    EventSeriesCard
  },
  props: {
    where: {
      type: Object,
      default: null
    },
    orderBy: {
      type: Array,
      default: () => [{ starts_at: "asc" }]
    },
    maxEvents: {
      type: Number,
      default: 100
    },
    //Fetch all of the events (up to maxEvents), but only render this many at a time
    apportionedViewable: {
      type: Number,
      default: 0
    },
    lastSearched: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      virtualEventList: [],
      totalVirtualEvents: 0,
      offset: 0,
      currentlyShowing: this.apportionedViewable,
      fakeLoading: false,
      activeSeriesId: null
    };
  },
  computed: {
    since() {
      return this.where.starts_at ? this.where.starts_at._gte : "now";
    },
    displayedVirtualEvents() {
      return this.apportionedViewable > 0 ? this.virtualEventList.slice(0, this.currentlyShowing) : this.virtualEventList;
    }
  },
  apollo: {
    virtualEventList: {
      query: EVENT_SERIES_LIST_VIRTUAL,
      variables() {
        let where = {
          ...this.where, // let the composing view control most of the predicates
          template: {
            ...this.where.template
          }
        };
        return {
          where,
          orderBy: this.orderBy,
          since: this.since
        };
      },
      update({
        events,
        events_aggregate: {
          aggregate: { count }
        }
      }) {
        this.totalVirtualEvents = count;
        const virtualEvents = events.map(queryResult => queryResult.event);
        this.$emit("results:updated", virtualEvents, count);
        this.currentlyShowing = this.apportionedViewable;
        return virtualEvents;
      }
    }
  },
  methods: {
    formatUTC,
    rand(minimum, maximum) {
      const val = minimum + Math.random() * (minimum - maximum);
      return `${val}%`;
    }
  }
};
</script>
