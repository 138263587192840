import { required, requiredIf, url } from "vuelidate/lib/validators";

export default {
  props: {
    value: {
      type: Object,
      default: () => null
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    hideForm: {
      type: Boolean,
      default: false
    },
    showVenueLocation: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    return {
      form: {
        title: {
          required
        },
        api_result: {
          required
        },
        host_city: {
          required
        },
        url: {
          required: requiredIf(function() {
            return this.form.is_virtual;
          }),
          url: function(value) {
            return url(value);
          }
        }
      }
    };
  },
  data() {
    let form = {
      title: null,
      api_result: null,
      // virtual fields
      host_city: null,
      url: null,
      meeting_password: null,
      ...this.value,
      is_virtual: true
    };
    return {
      form,
      autocompleteKey: 0
    };
  },
  computed: {
    directionsURL() {
      if (this.value) {
        return this.value.url;
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        this.$log.info("venue changed", newVal);
        // clear the opposite venue?
        this.$emit("input", newVal);
        this.$emit("invalid", this.$v.$invalid);
      }
    }
  },
  mounted() {
    this.$emit("invalid", this.$v.$invalid);
  },
  methods: {
    async onPlaceChanged(newPlace) {
      //this.$log.info("new place chosen", newPlace);
      this.autocompleteKey = this.autocompleteKey + 1;
      this.form.api_result = newPlace;
      this.form.location = {
        type: "Point",
        coordinates: [
          newPlace.geometry.location.lng(),
          newPlace.geometry.location.lat()
        ]
      };
      this.form.title = newPlace.name;
      this.form.host_city = newPlace.formatted_address;
      this.form.street_address = newPlace.formatted_address;

      this.$emit("input", this.form);
    }
  }
};