<template>
  <Section>
    <div class="events-container">
      <h3>Discussions</h3>
      <TopicList v-if="canAccessTopics" :forum-id="event.template.forum_id" :limit="10" />
      <div v-else>
        <EmptyState v-if="canFollow" class="mb-5">
          Follow this event to see discussions.
        </EmptyState>
        <EmptyState v-else class="mb-5">
          Join this event to see discussions.
        </EmptyState>
      </div>
    </div>
  </Section>
</template>

<script>
import Section from "@/components/common/Section.vue";
import TopicList from "@/components/forums/TopicList.vue";
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "EventDetailSectionDiscussion",
  components: {
    Section,
    TopicList,
    EmptyState
  },
  props: {
    event: {
      type: Object,
      default: () => null
    },
  },
  computed: {
    isEventAuthor() {
      return this.$auth.isAuthenticated && this.event.template.author_user_id === this.$auth.user.id;
    },
    canAccessTopics() {
      return this.event && (this.event.current_user_is_rsvpd || this.event.current_user_is_series_member || this.isEventAuthor)
    },
    canFollow() {
      return this.event && this.event.template.original_venue_was_virtual;
    }
  }
};
</script>
