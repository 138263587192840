<template>
  <div class="featured-card" :class="isFirst ? 'primary' : 'secondary'">
    <div class="is-flex is-justify-content-flex-end is-align-items-flex-start">
      <div class="has-text-grey">
        <span v-if="isFirst">
          Current Challenge
        </span>
        <span v-else-if="isSecond">
          Next Challenge
        </span>
        <span v-else>
          Coming Soon
        </span>
      </div>
    </div>
    <div class="featured-card-content" @click="onChallengeCardClick">
      <div class="featured-card-heading">
        <h3 :class="isFirst ? 'primary' : ''">
          {{ challenge.title }}
        </h3>
        <h4 :class="isFirst ? 'primary' : ''">
          <b-icon icon="stopwatch" class="has-text-primary" />
          <ChallengeStartsOn
            v-if="wasHappeningNow !== true"
            :available-date="availableDate"
            :show-stopwatch="false"
          />
          <template v-else-if="nextPromptDate !== null">
            Next Prompt
            <span v-if="isBefore(new Date(nextPromptDate), addDays(nowTimer, 2))">
              in {{ nextPromptNow() !== true ? formatDurationClock(nowTimer, nextPromptDate) : "00:00:00" }}
            </span>
            <span v-else>on {{ format(new Date(nextPromptDate), "EEEE MM/dd") }}</span>
          </template>
          <template v-else>
            All Prompts Unlocked!
          </template>
        </h4>
      </div>
      <TipTap v-if="isFirst" :value="challenge.body" />
      <div v-if="isFirst" class="progress-rings" :data-prompt-level="prompts.length > 5 ? 'high' : 'low'">
        <template v-for="prompt in prompts">
          <div v-if="prompt.deleted_at == null" :key="prompt.id" class="prompt-progress">
          
            <ProgressRing
              :class="prompt.current_user_has_submission ? 'is-success' : null"
              :radius="prompts.length < 8 ? 54 : 27"
              :stroke="prompts.length < 8 ? 8 : 3"
              :progress="prompt.current_user_has_submission ? 100 : 0"
              :steps="0"
              :dash-spacing="8"
            />
            <span v-if="isBefore(nowTimer, new Date(prompt.available_after))" class="icon prompt-ring lock">
              <object data="/img/icon-padlock.svg"></object>
            </span>
            <b-icon
              v-else-if="prompt.current_user_has_submission" 
              icon="check"
              type="is-success"
              class="prompt-ring checkmark"
            />
            <b-icon v-else icon="check" class="prompt-ring checkmark" style="opacity: 0.5" />
          </div>
        </template>
      </div>
      <div class="challenge-actions" :class="isFirst ? 'primary' : 'secondary'">
        <div class="is-flex">
          <b-button
            class="featured-challenge-button has-text-weight-semibold"
            type="is-outlined"
            label="View Challenge"
            @click="onChallengeCardClick"
          />
        </div>
        <div
          :class="{
            'mb-1': isFirst
          }"
        >
          <b-taglist>
            <b-tag type="is-success is-light has-text-grey-dark">
              <b-icon icon="user-check" />
              <span>{{ challenge.participants_aggregate.aggregate.count }}</span>
            </b-tag>
            <b-tag v-if="isFirst" type="is-info is-light has-text-grey-dark">
              <b-icon icon="file" />
              <!-- trouble with this one in dashboard -->
              <span>{{ engagementCount }}</span>
            </b-tag>
          </b-taglist>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import challengeCardViewFeatured from "./ChallengeCardViewFeatured.js";
import { format, isBefore, addDays } from "date-fns";
import { formatDurationClock } from "@/dates.js";
import TipTap from "@/components/forms/TipTap.vue";
import ProgressRing from "@/components/common/ProgressRing.vue";
import ChallengeStartsOn from "@/components/challengeCard/ChallengeStartsOn.vue";

export default {
  name: "ChallengeCardViewFeaturedDesktop",
  mixins: [challengeCardViewFeatured],
  components: {
    ProgressRing,
    TipTap,
    ChallengeStartsOn
  },
  data() {
    return {
      formatDurationClock,
      format,
      isBefore,
      addDays,
      prompts: []
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.featured-challenge-button {
  color: #ea6ca4;
}
</style>
