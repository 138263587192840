<template>
  <div>
    <EventSeriesCardDesktop
      v-if="!suwMobile"
      :event="event"
      :is-active="isActive"
    />
    <EventSeriesCardMobile
      v-if="suwMobile"
      :event="event"
      :is-active="isActive"
    />
  </div>
</template>

<script>
import EventSeriesCardDesktop from "@/components/eventSeries/EventSeriesCardDesktop.vue";
import EventSeriesCardMobile from "@/components/eventSeries/EventSeriesCardMobile.vue";

export default {
  name: "EventSeriesCard",
  components: {
    EventSeriesCardDesktop,
    EventSeriesCardMobile
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    isActive: {
      // this should get turned on to highlight this event
      type: Boolean,
      default: false
    }
  }
};
</script>