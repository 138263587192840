<template>
  <div>
    <SubmissionCardViewHeaderDesktop 
      v-if="suwDesktop"
      :submission="submission"
      @upvote="onUpvote"
      @delete="onDelete"
      @report="onReport"
      @allow="onAllow"
      @edit="onEdit"
    />
    <SubmissionCardViewHeaderMobile 
      v-if="!suwDesktop"
      :submission="submission"
      @upvote="onUpvote"
      @delete="onDelete"
      @report="onReport"
      @allow="onAllow"
      @edit="onEdit"
    />
  </div>
</template>

<script>
import SubmissionCardViewHeaderDesktop from "@/components/submissionCard/SubmissionCardViewHeaderDesktop.vue";
import SubmissionCardViewHeaderMobile from "@/components/submissionCard/SubmissionCardViewHeaderMobile.vue";

export default {
  name: "SubmissionCardViewHeader",
  components: {
    SubmissionCardViewHeaderDesktop,
    SubmissionCardViewHeaderMobile
  },
  props: {
    submission: {
      type: Object,
      default: null
    }
  },
  methods: {
    onUpvote() {
      this.$emit("upvote");
    },
    onDelete() {
      this.$emit("delete");
    },
    onReport() {
      this.$emit("report");
    },
    onAllow() {
      this.$emit("allow");
    },
    onEdit() {
      this.$emit("edit");
    }
  }
};
</script>
