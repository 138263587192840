<template>
  <div :class="{ 'is-editing': editable, [type]: type, 'is-danger': isDangerous && $auth.isAdmin }">
    <div v-if="editor !== null" v-show="editor !== null && editable" class="wysiwyg">
      <div class="buttons has-addons">
        <b-button
          key="undo"
          :size="buttonSize"
          title="undo"
          icon-left="undo"
          @click="editor.chain().focus().undo().run()"
        />
        <b-button
          key="redo"
          icon-left="redo"
          :size="buttonSize"
          title="redo"
          @click="editor.chain().focus().redo().run()"
        />
        <b-button
          v-for="o in markOptions"
          :key="o.title"
          :size="buttonSize"
          :type="o.buttonType"
          :icon-left="o.icon"
          :title="o.title"
          :active="o.isActive(editor)"
          @click="o.click(editor)"
        />
        <b-button
          key="link"
          icon-left="link"
          title="link"
          :size="buttonSize"
          :class="{ 'is-active': editor.isActive('link') }"
          @click="setLink()"
        />
        <b-button
          key="unlink"
          icon-left="unlink"
          title="unlink"
          :size="buttonSize"
          :disabled="!editor.isActive('link')"
          @click="editor.chain().focus().extendMarkRange('link').unsetLink().run()"
        />
      </div>
      <div class="buttons has-addons">
        <b-button
          key="align left"
          :size="buttonSize"
          icon-left="align-left"
          title="align left"
          :active="editor.isActive({ textAlign: 'left' })"
          @click="editor.chain().focus().setTextAlign('left').run()"
        />
        <b-button
          key="align center"
          :size="buttonSize"
          icon-left="align-center"
          title="align center"
          :active="editor.isActive({ textAlign: 'center' })"
          @click="editor.chain().focus().setTextAlign('center').run()"
        />
        <b-button
          key="heading"
          :size="buttonSize"
          icon-left="h1"
          title="heading"
          :active="editor.isActive('heading', { level: 2 })"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        />
        <b-button
          key="heading2"
          :size="buttonSize"
          icon-left="h2"
          title="heading 2"
          :active="editor.isActive('heading', { level: 3 })"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        />
        <b-button
          key="heading3"
          :size="buttonSize"
          icon-left="h3"
          title="heading 3"
          :active="editor.isActive('heading', { level: 4 })"
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        />
        <b-button
          key="bullet list"
          :size="buttonSize"
          icon-left="list-ul"
          title="bullet list"
          :active="editor.isActive('bulletList')"
          @click="editor.chain().focus().toggleBulletList().run()"
        />
        <b-button
          key="ordered list"
          :size="buttonSize"
          icon-left="list-ol"
          title="ordered list"
          :active="editor.isActive('orderedList')"
          @click="editor.chain().focus().toggleOrderedList().run()"
        />
        <b-button
          key="block quote"
          :size="buttonSize"
          icon-left="quote-left"
          title="block quote"
          :active="editor.isActive('blockquote')"
          @click="editor.chain().focus().toggleBlockquote().run()"
        />
      </div>
    </div>
    <editor-content
      class="content"
      :class="{
        'is-preview': isPreview
      }"
      style="position: relative;"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="true"
      :editor="editor"
    />
    <div class="editor-actions">
      <p class="help" :class="{ [type]: type }">{{ message }}</p>
      <UndoConfirmButtons
        v-if="showEditControls"
        :confirm-enabled="confirmEnabled()"
        :undo-enabled="undoEnabled"
        @undo="onCancelEdit"
        @confirm="onConfirmEdit"
      >
        <template #confirm>
          <slot name="confirm" />
        </template>
      </UndoConfirmButtons>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
// import Typography from "@tiptap/extension-typography";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";

const emptyDocument = {
  type: "doc",
  content: [{ type: "paragraph" }]
};

const markOptions = [
  {
    title: "bold",
    icon: "bold",
    isActive: editor => editor.isActive("bold"),
    click: editor =>
      editor
        .chain()
        .focus()
        .toggleBold()
        .run()
  },
  {
    title: "italic",
    icon: "italic",
    isActive: editor => editor.isActive("italic"),
    click: editor =>
      editor
        .chain()
        .focus()
        .toggleItalic()
        .run()
  },
  {
    title: "underline",
    icon: "underline",
    isActive: editor => editor.isActive("underline"),
    click: editor =>
      editor
        .chain()
        .focus()
        .toggleUnderline()
        .run()
  },
  {
    title: "strikethrough",
    icon: "strikethrough",
    isActive: editor => editor.isActive("strike"),
    click: editor =>
      editor
        .chain()
        .focus()
        .toggleStrike()
        .run()
  }
];

export default {
  name: "TipTap",
  components: {
    EditorContent,
    UndoConfirmButtons
  },
  props: {
    value: {
      type: [Object, String],
      default: () => emptyDocument
    },
    editable: {
      type: Boolean,
      default: false
    },
    showEditControls: {
      type: Boolean,
      default: false
    },
    confirmEnabled: {
      type: Function,
      default: () => false
    },
    undoEnabled: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    editorStyle: {
      type: String,
      default: ""
    },
    isDangerous: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    editor: null,
    emptyDocument,
    markOptions,
    buttonSize: "is-small"
  }),
  watch: {
    showEditControls(newVal) {
      this.$log.info("setting showEditControls", newVal);
      this.$forceUpdate();
    },
    editable(newVal) {
      this.$log.debug("editable is now:", newVal);
      this.editor.setEditable(newVal);
      if (newVal) {
        this.$log.debug("focusing editor");
        this.editor.commands.focus("end");
      }
      this.$forceUpdate();
    },
    value(newVal) {
      const strNew = JSON.stringify(newVal);
      const strOld = JSON.stringify(this.editor.getJSON());
      if (strNew !== strOld && this) {
        this.editor.commands.setContent(newVal, false);
      }
    }
  },
  created() {
    this.debouncedOnTypeStop = _.debounce(this.onTypeStop, 1000);
  },
  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          style: this.editorStyle
        }
      },
      editable: this.editable,
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        //Typography,
        Link.configure({
          autolink: true,
          openOnClick: false,
          linkOnPaste: true,
          validate: href => /^https?:\/\//.test(href)
        }),
        Subscript,
        Superscript,
        TextAlign.configure({
          types: ["heading", "paragraph"]
        })
      ],
      onUpdate: this.debouncedOnTypeStop
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    clear() {
      this.editor.commands.clearContent();
    },
    focus() {
      this.editor.commands.focus();
    },
    onCancelEdit() {
      this.$emit("edit-cancelled");
    },
    onConfirmEdit() {
      this.$emit("edit-confirmed", this.editor.getJSON());
    },
    onTypeStop() {
      const isEmpty = this.editor.state.doc.textContent.length === 0;

      if (isEmpty) {
        this.$emit("input", null);
      } else {
        this.$emit("input", this.editor.getJSON());
      }
    },
    setLink() {
      //from https://tiptap.dev/api/marks/link
      const previousUrl = this.editor.getAttributes("link").href;
      let url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .unsetLink()
          .run();

        return;
      }

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: "_blank" })
        .run();
    }
  }
};
</script>

<style lang="scss" scoped>
.editor {
  height: 19em;
}
.wysiwyg {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include touch {
    justify-content: flex-start;
  }
  .buttons {
    margin-bottom: 0;
  }
}

.is-preview {
  position: relative;
  max-height: 6rem;
  overflow: hidden;
  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    background: linear-gradient(transparent 65%, white);
    box-shadow: inset 0 4rem 6rem rgba(white, 0.25);
  }
}

/* Editor Variants */
.is-danger {
  .content {
    outline: solid 1px $danger;
  }
}

::v-deep {
  /* Basic editor styles */
  .ProseMirror {
    max-height: fit-content;

    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    min-height: 6em;

    &[contenteditable="true"] {
      resize: vertical;
      outline: 1px solid $border;
      padding: 0.25em;
    }
    &[contenteditable="false"] {
      min-height: auto !important;
    }
    &-focused {
      outline: none;
    }

    ::selection {
      color: $dark;
      background: $primary;
    }

    > * + * {
      margin-top: 0.75em;
    }

    & > h1 {
      font-size: 1.5em;
      font-weight: 500;
      margin-bottom: 1em;
      letter-spacing: normal;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    li > p:first-child {
      display: inline;
    }
    
    pre {
      background: rgba(20, 30, 15, 0.8);
      @extend %elevation-1;
      margin: 0.5em auto;
      width: 90%;
      code {
        color: gold;
      }
    }

    blockquote {
      margin: 0.5em auto;
      width: 90%;
      @extend %elevation-1;
      padding-left: 1rem;
      border-top: 1px solid $warning;
      border-left: 16px solid $warning;
      border-right: 1px solid $warning;
      border-bottom: 1px solid $warning;
      background: $warning-light;
    }
  }
  .editor-actions {
    display: flex;
    justify-content: space-between;
    p {
      flex-grow: 1;
    }
    .undo-confirm-buttons {
      width: auto;
    }
  }
}
</style>