<template>
  <b-tooltip
    :label="d | format"
    size="is-large"
    :type="tooltipType"
    position="is-bottom"
  >
    <span class="is-hidden-mobile" style="white-space: nowrap; cursor: default;">
      {{ d | formatFromNow({ addSuffix }) }}
    </span>
    <span class="created-at is-hidden-tablet" style="white-space: nowrap; cursor: default;">
      {{ d | formatFromNowMobile({ addSuffix }) }}
    </span>
  </b-tooltip>
</template>

<script>
export default {
  name: "InlineDate",
  props: {
    d: {
      type: [Date, String],
      default: () => new Date()
    },
    tooltipType: {
      type: String,
      default: "is-info is-light"
    },
    addSuffix: {
      type: Boolean,
      default: true
    }
  }
};
</script>
