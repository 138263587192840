var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container"},[_c('b-navbar',{attrs:{"is-transparent":""}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":"/assets/logo.svg","alt":"Shut Up & Write!"}})])],1),(!_vm.$route.meta.hideMainNavItems)?_c('template',{slot:"end"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/events"}},[_vm._v(" Events ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/challenges"}},[_vm._v(" Challenges ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: 'articles' }}},[_vm._v(" Articles ")]),_c('b-navbar-item',{attrs:{"href":"https://store.shutupwrite.com/","target":"_blank"}},[_vm._v(" Store ")]),(_vm.$auth.isAuthenticated)?_c('section',{staticClass:"is-hidden-tablet"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/dashboard"}},[_vm._v(" Dashboard ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: "dashboard_inbox" }}},[_vm._v(" Inbox ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: "dashboard_profile" }}},[_vm._v(" Settings ")]),(_vm.$auth.user.username)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
            name: "user_profile",
            params: { username: _vm.$auth.user.username }
          }}},[_vm._v(" My Public Profile ")]):_vm._e(),(_vm.$auth.isAdmin)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/users"}},[_vm._v(" Users Admin ")]):_vm._e(),(_vm.$auth.isAdmin)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/events/admin"}},[_vm._v(" Events Admin ")]):_vm._e(),_c('b-navbar-item',{attrs:{"tag":"a"},on:{"click":_vm.logout}},[_vm._v(" Log Out "),_c('span',{staticClass:"has-text-info"},[_vm._v(" @"+_vm._s(_vm.$auth.user.username)+" ")])])],1):_vm._e(),(!_vm.$auth.loading && !_vm.$auth.isAuthenticated)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('a',{staticClass:"button is-primary",on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v(" Log In / Sign Up ")])]):_vm._e(),(_vm.$auth.isAuthenticated)?_c('b-navbar-dropdown',{staticClass:"is-hidden-mobile",attrs:{"right":true}},[_c('template',{slot:"label"},[_c('section',{staticClass:"is-flex",staticStyle:{"align-items":"center"}},[_c('span',{staticClass:"pr-2 is-info"},[_vm._v(" @"+_vm._s(_vm.$auth.user.username)+" ")]),_c('figure',{staticClass:"image is-32x32"},[(!_vm.$auth.loading && _vm.$auth.isAuthenticated)?_c('Avatar',{attrs:{"size":"medium","seed-string":_vm._f("user_name")(_vm.$auth.user),"color":_vm.$auth.user.avatar_color}}):_vm._e()],1)])]),_c('template',{slot:"default"},[_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('div',{staticClass:"has-text-centered content is-flex user-info"},[_c('figure',{staticClass:"image is-64x64 is-inline-block mb-2"},[_c('Avatar',{attrs:{"size":"large","seed-string":_vm._f("user_name")(_vm.$auth.user),"color":_vm.$auth.user.avatar_color}})],1),_c('span',{staticClass:"has-text-weight-bold is-size-5"},[_vm._v(" "+_vm._s(_vm._f("user_name")(_vm.$auth.user))+" ")]),(_vm.$auth.user.username)?_c('router-link',{staticClass:"is-size-6 is-info",attrs:{"to":{
                  name: "user_profile",
                  params: { username: _vm.$auth.user.username }
                }}},[_vm._v(" @"+_vm._s(_vm.$auth.user.username)+" ")]):_vm._e(),_c('div',{staticClass:"user-tags"},[(_vm.$auth.isAuthenticated && _vm.$auth.isOrganizer)?_c('div',{staticClass:"control"},[_c('badge',{attrs:{"icon":"user-check","label":"Organizer"}})],1):_vm._e(),(_vm.$auth.isAuthenticated && _vm.$auth.isAdmin)?_c('div',{staticClass:"control"},[_c('badge',{attrs:{"icon":"crown","label":"Admin"}})],1):_vm._e()])],1)]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/dashboard"}},[_vm._v(" Dashboard ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: "dashboard_profile" }}},[_vm._v(" Settings ")]),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: "dashboard_inbox" }}},[_vm._v(" Inbox ")]),(_vm.$auth.user.username)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":{
              name: "user_profile",
              params: { username: _vm.$auth.user.username }
            }}},[_vm._v(" My Public Profile ")]):_vm._e(),(_vm.$auth.isAdmin)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/users"}},[_vm._v(" Users Admin ")]):_vm._e(),(_vm.$auth.isAdmin)?_c('b-navbar-item',{attrs:{"tag":"router-link","to":"/events/admin"}},[_vm._v(" Events Admin ")]):_vm._e(),_c('b-navbar-item',{attrs:{"tag":"a"},on:{"click":_vm.logout}},[_vm._v(" Log Out ")])],1)],2):_vm._e()],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }