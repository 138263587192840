import { Drag, DropList } from "vue-easy-dnd";

const dragAndDrop = {
  components: {
    Drag,
    DropList
  },
  methods: {
    onReorder(event, items) {
      event.apply(items);
    },
    orderByArray(items, orderedIds, ignoreLength) {
      if (!ignoreLength && items.length !== orderedIds.length) {
        throw new Error(
          "Unable to sort items: item count does not match count of IDs",
          items,
          orderedIds
        );
      }

      if (!ignoreLength) {
        return orderedIds.map(id => items.find(item => item.id === id));
      }
      else {
        return orderedIds.reduce((result, id) => {
          var item = items.find(i => i.id === id);
          if (item) {
            result.push(item);
          }
          return result;
        }, []);
      }
    }
  }
};

export default dragAndDrop;
