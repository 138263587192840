export default {
  installed: false,
  install(Vue) {
    // install guard
    if (this.installed) return;
    this.installed = true;

    Vue.mixin({
      methods: {
        randomID: prefix => {
          return `${prefix}${parseInt(Math.random() * 10000)}`;
        }
      }
    });
  }
};
