export const whereIsOrganizer = ({ id, template }) => ({
  event_organizers: {
    _or: [
      { event_id: { _eq: id } },
      { event_template_id: { _eq: template.id } }
    ]
  }
});

export const whereUsernameMatches = ({ query }) => ({
  username: { _ilike: query === null ? null : `%${query}%` }
});

export const whereFirstNameMatches = ({ query }) => ({
  first_name: { _ilike: query === null ? null : `%${query}%` }
});

export const whereLastNameMatches = ({ query }) => ({
  last_name: { _ilike: query === null ? null : `%${query}%` }
});

export const whereUserMatches = ({ query }) => ({
  _or: [
    whereUsernameMatches({ query }),
    whereFirstNameMatches({ query }),
    whereLastNameMatches({ query })
  ]
});

export const whereUserMatchesWithEmail = ({ query }) => ({
  _or: [
    whereUsernameMatches({ query }),
    whereFirstNameMatches({ query }),
    whereLastNameMatches({ query }),
    { email: { _ilike: query === null ? null : `%${query}%` } }
  ]
});

export const whereUserMatchesWithPostalCode = ({ query }) => ({
  postal_code: { _ilike: query === null ? null : `%${query}%` }
});

export const whereIsNotUser = ({ id }) => ({
  id: { _neq: id }
});

export const roleIn = roles => ({
  role: { _in: roles }
});

export const isSubscribedToEventSeries = ({ id }) => ({
  subscriptions: {
    event_template_id: { _eq: id }
  }
});

export const whereIsSubscribed = () => ({
  is_subscribed: { _eq: true },
  stripe_customer_id: { _is_null: false }
});