<template>
  <div id="toc-container" class="content">
    <h3>Terms of Use</h3>
    <p>
      Last Modified: August 19, 2019
    </p>
    <p>
      Hello fellow writers and people of the internet! We are glad you are here
      and hope you find our Website useful. Shut Up &amp; Write!&reg; is an
      international non-profit organization which provides writers of all genres
      with the accountability, community, and resources they need to get their
      writing done and published. This site is maintained as a service to our
      customers, registered users and fellow writers. Please read the Terms of
      Use carefully before you start to use this website. If you click to accept
      these terms, you acknowledge you have read, understand and agree to be
      bound by the following terms of use. If you do not agree to these terms,
      you should not use this site. Shut Up &amp; Write!&reg; is a community of
      writers all over the world. We also know people will only build community
      if they feel safe and we have drafted some rules and guidelines with this
      idea in mind.
    </p>
    <h4>Acceptance of the Terms of Use</h4>
    <p>
      These terms of use are an agreement between You and Shut Up and
      Write!&reg; (”Company,” “we,” or “us”). Together, with the other documents
      for using this Website (all together, the “Terms of Use” or sometimes just
      “Terms”), these Terms of use expressly reference, control your use of
      shutupwrite.com, including any content, functionality, products, apps,
      technologies, and services of shutupwrite.com (the “Website”).
    </p>
    <p>
      This Website is offered and available to users who are 13 years of age or
      older. You represent that you are of legal age to form a binding contract
      with the Company and meet all of the foregoing eligibility requirements.
      If you do not meet all of these requirements, you must not use the
      Website.
    </p>
    <h4>Changes to the Terms of Use</h4>
    <p>
      We may revise and update these Terms of Use from time to time. All changes
      are effective immediately when we post them. Your continued use of the
      Website following the posting of revised Terms of Use means that you
      accept and agree to the changes.
    </p>
    <h4>Accessing the Website and Account Security</h4>
    <p>
      We reserve the right to withdraw or amend this Website, and any Service or
      material we provide on the Website without notice. You accept this could
      result in all or part of the Website being unavailable. We will not be
      liable if all or any part of the Website is unavailable at any time.
    </p>
    <p>
      To access the Website and some of the resources it offers, you may be
      asked to provide information about yourself. Each time you use the
      Website, you agree that all the information you provide on the Website is
      correct, current, and complete and that you have the right and authority
      to provide that information. You also agree that all information you
      provide to register with this Website is governed by our Privacy Policy.
    </p>
    <p>
      If you choose, or are provided with, a user name, password, or any other
      piece of information as part of our security procedures, you must treat
      such information as confidential. You agree to notify us immediately if
      anyone else gains access to or use of your user name or password or any
      other breach of security. We may disable any user name, password, or other
      identifier, whether chosen by you or provided by us, at any time in our
      sole discretion for any or no reason, including, in our opinion, for a
      violation of these Terms of Use.
    </p>
    <h4>Intellectual Property Rights</h4>
    <p>
      Our Intellectual Property.
    </p>
    <p>
      The Website and its entire contents, features, and functionality are owned
      by the Company, its licensors, or other providers of such material. Any
      ideas, suggestions, and feedback about us, our Website, or our Services
      that you provide to us are entirely voluntary, and you agree that Shut Up
      &amp; Write!&reg; may use such ideas, suggestions, and feedback without
      compensation or obligation to you.
    </p>
    <p>
      These Terms of Use permit you to use the Website for your personal,
      non-commercial use only. You must not reproduce, distribute, modify,
      create derivative works of, publicly display, publicly perform, republish,
      download, store, or transmit any of the material on our Website, except as
      your computer or web browser may automatically cache or store when
      accessing the Website. Any use of the Website not expressly permitted by
      these Terms of Use is a breach of these Terms of Use and could result in
      the termination of your right to use the Website.
    </p>
    <p>
      Your intellectual property.
    </p>
    <p>
      Content you have created belongs to you. We believe you should be free to
      share your content with anyone else, whenever you want. In order for us to
      use your content, including any User Contribution, you will need to give
      us permission to use your content.
    </p>
    <p>
      You agree to grant us a non-exclusive, perpetual, irrevocable,
      transferable, sub-licensable, royalty-free, and worldwide license to use,
      distribute, modify, copy, display, translate, reproduce, perform, modify,
      adapt, prepare derivative works from and otherwise exercise editorial
      functions and disclose to third parties all or a portion of your content.
      These intellectual property policies are intended to protect you, us, and
      our members.
    </p>
    <h4>Prohibited Uses</h4>
    <p>
      You may use the Website only for lawful purposes and in accordance with
      these Terms of Use. You agree not to use the Website:
    </p>
    <p>
      In any way for an illegal purpose. For the purpose of exploiting, harming,
      or attempting to exploit or harm minors in any way by exposing them to
      inappropriate content, asking for personally identifiable information, or
      otherwise. To send, knowingly receive, upload, download, use, or re-use
      any material that does not comply with the Content Standards set out in
      these Terms of Use. To impersonate or attempt to impersonate anyone else
      (including us). To engage in any other conduct that restricts or inhibits
      anyone’s use or enjoyment of the Website, or which, as determined by us,
      may harm the Company or users of the Website, or expose them to liability.
      To use the Website in any manner that could damage, impair or gain
      unauthorized access to the Website or interfere with any other party’s use
      of the Website.
    </p>

    <p></p>
    <h4>User Contributions</h4>
    <p>
      The Website may contain interactive features (“Interactive Services”) that
      allow users to post, submit, publish, display, or transmit to other users
      or other persons (“post”) content or materials (collectively, “User
      Contributions”) on or through the Website. The information you include in
      a User Contribution will be seen by others. All User Contributions must
      comply with our Content Standards.
    </p>
    <p>
      You represent and warrant that:
    </p>
    <p>
      You own or control all rights in and to the User Contributions and have
      the right to grant us, our affiliates and our service providers the
      license described in these Terms. All of your User Contributions comply
      with these Terms. You understand and acknowledge that you are responsible
      for any User Contributions you submit or contribute, and you, not the
      Company, have full responsibility for such content, including its
      legality, reliability, accuracy, and appropriateness. You further
      acknowledge we may exercise the editorial options of your User
      Contribution we mentioned earlier in these Terms, such as decisions
      regarding the publication, withdrawal, postponement, or alteration of
      content (e.g., correcting spelling, removing obscenity, or trimming for
      length), which could result in the removal of a portion or all of your
      User Contributions in our sole discretion.
    </p>
    <h4>Content Standards</h4>
    <p>
      These content standards apply to any and all User Contributions and use of
      Interactive Services. We want Shut Up &amp; Write!&reg; to be a welcoming
      and safe place for our members, guests and fellow writers. User
      Contributions must comply with all applicable federal, state, local, and
      international laws and regulations. Without limiting the foregoing, User
      Contributions must not:
    </p>
    <p>
      Contain any material that is defamatory, obscene, lewd, lascivious,
      filthy, excessively violent, indecent, abusive, offensive, harassing,
      violent, hateful, inflammatory, or otherwise objectionable. Promote
      sexually explicit or pornographic material, violence, or discrimination
      based on race, sex, religion, nationality, disability, sexual orientation,
      or age. Infringe any patent, trademark, trade secret, copyright, or other
      intellectual property or other rights of any other person. Violate the
      legal rights (including the rights of publicity and privacy) of others or
      contain any material that could give rise to any civil or criminal
      liability under applicable laws or regulations or that otherwise may be in
      conflict with these Terms of Use and our Privacy Policy and other
      policies. Be likely to deceive any person. Promote any illegal activity,
      or advocate, promote, or assist any unlawful act. Intentionally or due to
      negligence cause annoyance, inconvenience, or needless anxiety or be
      likely to upset, bully, embarrass, alarm, or annoy any other person.
      Impersonate any person (including us), or misrepresent your identity or
      affiliation with any person or organization. Involve commercial activities
      or sales, such as contests, sweepstakes, and other sales promotions,
      barter, or advertising. Give the impression that they come from or are
      endorsed by us or any other person or entity, if this is not the case.
      Monitoring and Enforcement; Termination
    </p>
    <p>
      If we determine that you or anyone else has violated our terms and
      policies or could create liability for the Company, or for any or no
      reason, we may take a variety of actions to stop the behavior, from asking
      you nicely to knock it off up to banning accounts. If we must, we will
      take appropriate legal action, including referral to or cooperation with
      law enforcement authorities or a court order requesting us to disclose
      identity or other information, for any illegal or unauthorized use of the
      Website. You agree to waive and hold harmless the Company, its affiliates,
      licensees, and service providers from any claims resulting from any action
      from or during such investigations. Please be kind to each other and don’t
      do stupid things on our Website. We want to try to keep ourselves, our
      members, and others safe.
    </p>
    <p>
      Although we may try, we cannot review all material before it is posted on
      the Website, and cannot ensure prompt removal of objectionable material
      after it has been posted. Accordingly, we assume no liability for any
      action or inaction regarding transmissions, communications, or content
      provided by any user or third party. We have no liability or
      responsibility to anyone for performance or nonperformance of the
      activities described in this section.
    </p>
    <h4>Copyright Infringement</h4>
    <p>
      If you believe that any User Contributions violate your copyright, please
      see our Copyright Policy for instructions on sending us a notice of
      copyright infringement. It is the policy of the Company to terminate the
      user accounts of repeat infringers.
    </p>
    <h4>Reliance on Information Posted</h4>
    <p>
      The information presented on or through the Website is made available
      solely for general information purposes. We do not guaranty or warrant the
      accuracy, completeness, or usefulness of this information. Any reliance
      you place on such information is strictly at your own risk. We disclaim
      all liability and responsibility arising from any reliance placed on such
      materials by you or any other visitor to the Website, or by anyone who may
      be informed of any of its contents.
    </p>
    <p>
      This Website may include content provided by third parties, including
      writers like yourself. All statements and/or opinions expressed in these
      materials, and all articles and responses to questions and other content
      are solely the opinions and the responsibility of the person or entity
      providing those materials. We are not responsible, or liable to you or any
      third party, for the content or accuracy of any materials provided by any
      third parties.
    </p>
    <h4>Online Purchases and Other Terms and Conditions</h4>
    <p>
      All purchases through our Website are governed by our Terms of Sale.
    </p>
    <h4>Linking to the Website and Social Media Features</h4>
    <p>
      You may link to our homepage, provided you do so in a way that is fair,
      legal and does not damage our reputation or take advantage of it, but you
      must not establish a link in such a way as to suggest any form of
      association, approval, or endorsement on our part.
    </p>
    <p>
      This Website may provide certain social media features that enable you to:
    </p>
    <p>
      Link from your own or certain third-party websites to certain content on
      this Website. Send emails or other communications with certain content, or
      links to certain content, on this Website. Cause limited portions of
      content on this Website to be displayed or appear to be displayed on your
      own or certain third-party websites. You may use these features solely as
      they are provided by us and solely for the content created by Shut Up and
      Write! ® and otherwise in accordance with any additional terms and
      conditions we provide regarding these features. Subject to the foregoing,
      you must not:
    </p>
    <p>
      Establish a link from any website that is not owned by you. Cause the
      Website or portions of it to be displayed on, or appear to be displayed
      by, any other site, for example, framing, deep linking, or in-line
      linking. Link to any part of the Website other than the homepage.
      Otherwise take any action with respect to the materials on this Website
      that is inconsistent with any other provision of these Terms of Use. The
      website from which you are linking, or on which you make certain content
      accessible, must comply in all respects with the Content Standards set out
      in these Terms of Use. You agree to cooperate with us in causing any
      unauthorized framing or linking immediately to stop. We reserve the right
      to withdraw linking permission without notice.
    </p>
    <h4>Links from the Website</h4>
    <p>
      If our Website contains links to other sites and resources provided by
      third parties, these links are provided for your convenience only. We have
      no control over the contents of those sites or resources and accept no
      responsibility for them or for any loss or damage that may arise from your
      use of them. If you decide to access any of the third-party websites
      linked to this Website, you do so entirely at your own risk and subject to
      the terms and conditions of use for such websites.
    </p>
    <h4>Geographic Restrictions</h4>
    <p>
      The owner of the Website is based in the State of California in the United
      States. We make no claims that the Website or any of its content is
      accessible or appropriate outside of the United States. Access to the
      Website may not be legal by certain persons or in certain countries. Your
      access the Website is on your own initiative and you are responsible for
      your compliance with local laws. To operate our global service, we need to
      store and distribute content and data in places that may be outside your
      country of residence. By opting in to our policies, you acknowledge and
      accept this and can read more in our privacy policy.
    </p>
    <h4>Disclaimer of Warranties</h4>
    <p>
      Although we attempt to make your access to and use of our Website and
      Services safe, do not guarantee that our Website or Services will be free
      of viruses or other destructive code. Your use of the Website, its
      content, and any services or items obtained through the Website, is at
      your own risk. The Website, its content, and any services or items
      obtained through the Website are provided on an “as is” and “as available”
      basis, without warranties of any kind, either express or implied,
      including, but not limited to, implied warranties of merchantability,
      fitness for a particular purpose, and non-infringement. We do not warrant
      that the Website is accurate, complete, reliable, current, or error free.
      To the extent allowed by law, the Company hereby disclaims all warranties,
      whether express, implied, statutory or otherwise. The foregoing does not
      affect any warranties that cannot be excluded or limited under applicable
      law.
    </p>
    <h4>Limitation on Liability</h4>
    <p>
      To the extent provided by law, the Company, its affiliates or their
      licensors, service providers, employees, agents, officers or directors
      will not be liable for damages of any kind, including indirect,
      consequential, exemplary, incidental, special or punitive damages, or lost
      profits, under any legal theory, including contract, tort, negligence,
      strict liability, warranty or otherwise, arising out of or in connection
      with your use, or inability to use, the Website or our Services. To the
      fullest extent provided by law, in no event will the collective liability
      of the company and its subsidiaries and affiliates, and their licensors,
      service providers, employees, agents, officers, and directory, to any
      party (regardless of the form of action, whether in contract, tort or
      otherwise) exceed the greater of $100.00 or the amount you have paid to
      the Company in the previous six months for the applicable content, product
      or service out of which the liability arose. The limitation of liability
      set out above does not apply to liability resulting from our gross
      negligence or willful misconduct or death or bodily injury caused by
      products you purchase through the site. THE FOREGOING DOES NOT AFFECT ANY
      LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>
    <h4>Indemnification</h4>
    <p>
      You agree to defend, indemnify, and hold harmless the Company, its
      employees, and its affiliates from and against any claims, liabilities,
      damages, costs, expenses, or fees (including reasonable attorneys’ fees)
      arising out of or relating to your violation of these Terms of Use, your
      use of the Website, Services or products including, but not limited to,
      your User Contributions, or your violation of applicable laws or
      regulations.
    </p>
    <h4>Governing Law and Jurisdiction</h4>
    <p>
      We want you to enjoy our Website and Services, so if you have an issue or
      dispute, you agree to raise it and try to resolve it with us informally.
      You can contact us with feedback and concerns here or by emailing us at
      info@shutupwrite.com.
    </p>
    <p>
      Any claims arising out of or relating to these Terms or the Services will
      be governed by the laws of the State of California, other than its
      conflict of law rules (whether of California or any other jurisdiction).
      All disputes related to these Terms or the Services will be brought solely
      in the federal or state courts located in San Francisco, California, and
      you consent to personal jurisdiction in these courts. We retain the right
      to bring any suit, action, or proceeding against you for breach of these
      Terms of Use in your country of residence or any other relevant country.
    </p>
    <h4>Arbitration</h4>
    <p>
      At our discretion, we may require you to submit disputes between you and
      us to final and binding arbitration under the Rules of Arbitration of JAMS
      applying California law, unless this would be in conflict with other
      Company policies or legal rules.
    </p>
    <p>
      Arbitration proceedings are ways to resolve disputes without use of the
      court system and you should consider asking a lawyer about this. By
      entering into agreements that require arbitration as the way to resolve
      fee disputes, you give up (waive) your right to go to court to resolve
      those disputes by a judge or jury. These are important rights that should
      not be given up without careful consideration. You acknowledge that you
      have been informed that the grounds for appeal of an arbitration award are
      very limited compared to a court judgment or jury verdict.
    </p>
    <h4>Limitation on Time to File Claims</h4>
    <p>
      Any cause of action or claim you may have arising out of or relating to
      these Terms, the Website or our Services must be commenced within one (1)
      year after the cause of action accrues; otherwise, such cause of action or
      claim is permanently barred.
    </p>
    <h4>Consent to Electronic Communication</h4>
    <p>
      When you visit the Website, chat with us or send email to us, you are
      communicating with us electronically. You agree that we may treat specific
      actions by you, such as checking boxes to receive email updates, sending
      us an email directly or through the shutupwrite.com Website, providing an
      email address in a chat conversation, or leaving a telephone message with
      an email address for customer service to respond, as a request that we
      communicate with you, which will likely be through email. If you request
      we contact you, you authorize us to use email with your personal
      information, which is not always secure and could be read others.
    </p>
    <h4>Legal Equivalency</h4>
    <p>
      This electronic document, along with the other electronic documents,
      policies and guidelines of our Website are signed “writings” and
      electronic records established and maintained in the ordinary course of
      business.
    </p>
    <h4>Waiver and Severability</h4>
    <p>
      Our failure to exercise or enforce any right or provision of these Terms
      will not operate as a waiver of such right or provision. Also, if any
      provision of these Terms is, for any reason, held to be illegal, invalid,
      or unenforceable, the rest of the Terms will remain in effect.
    </p>
    <h4>Entire Agreement</h4>
    <p>
      The Terms of Use, Privacy Policy, Terms of Sale, and other Policies of
      this Website are the entire agreement between you and Shut Up &amp;
      Write!&reg; regarding your use of our Website and Services.
    </p>
    <h4>Your Comments and Concerns</h4>
    <p>
      This website is operated by Shut Up &amp; Write!&reg; of 2261 Market Street #4978,
      San Francisco, CA 94114
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions"
};
</script>
<style lang="scss" scoped>
@import "../../scss/mixins";
#toc-container {
  background-color: #fff;
  padding: 15px 60px 15px 15px;
  margin-bottom: 0px;
  @include desktop {
    padding: 15px;
  }
}
</style>
