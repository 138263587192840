<template>
  <Page>
    <Section>
      <Breadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        v-if="$apollo.queries.challenge.loading"
        class="notification is-info is-light"
      >
        Loading...
      </div>
      <template v-if="challenge">
        <ChallengeCardCoordinator
          :challenge="challenge"
          is-header
          class="card-container challenge-card-view-header"
          @accepted="onAccept"
        />

        <div v-if="sortablePrompts" class="mt-2">
          <div
            class="is-flex is-justify-content-space-between is-align-items-center"
          >
            <div class="is-flex is-align-items-center">
              <b-switch
                v-if="
                  ($auth.isAuthenticated && $auth.user.id === challenge.author.id) || $auth.isAdmin
                "
                v-model="isEditingEnabled"
                class="ml-2"
                @input="value => !value && resetSortOrder()"
              >
                Reorder Prompts
              </b-switch>
              <b-button
                v-if="isEditingEnabled"
                class="is-small is-success"
                label="Save Order"
                @click="saveSortOrder"
              />
            </div>
            <div
              v-if="
                $auth.isAuthenticated &&
                  ($auth.isAdmin || challenge.author.id === $auth.user.id)
              "
              class="reorder tabs is-danger is-flex is-align-items-center"
            >
              <ul class="is-justify-content-end">
                <!-- List Controls - Mobile -->
                <li class="is-flex is-hidden-tablet">
                  <a
                    v-if="$auth.isAdmin"
                    class="button discussions-button"
                    :disabled="isShowingPromptForm"
                    @click="isShowingPromptForm = true"
                  >
                    <b-icon icon="plus-circle" />
                    <span class="button-label">Create New Prompt</span>
                  </a>
                </li>
                <li class="is-flex is-hidden-mobile">
                  <b-button
                    v-if="($auth.user.id === challenge.author.id) || $auth.isAdmin"
                    type="discussions-button elevation-1 has-text-weight-semibold has-text-info ml-1"
                    label="Create New Prompt"
                    icon-left="plus-circle"
                    :disabled="isShowingPromptForm"
                    @click="isShowingPromptForm = !isShowingPromptForm"
                  />
                </li>
              </ul>
            </div>
          </div>

          <!-- NEW PROMPT -->
          <ChallengePromptCardCoordinator
            v-if="isShowingPromptForm"
            :prompt="null"
            :challenge="challenge"
            @created="onCreatePrompt"
            @updated="isShowingPromptForm = false"
            @cancelled="isShowingPromptForm = false"
          />
          <EmptyState v-if="sortablePrompts.length < 1">
            No prompts yet
          </EmptyState>
          <DropList
            v-else
            class="is-flex-column is-justify-content-space-between"
            :items="sortablePrompts"
            @reorder="onReorder($event, sortablePrompts)"
          >
            <template #item="{item, reorder}">
              <Drag
                :key="`'drag/${item.id}`"
                :data="item"
                handle=".handle"
                :disabled="!isEditingEnabled"
                :drag-image-opacity="1.0"
                @dragstart="isDragging = true"
                @dragend="isDragging = false"
              >
                <template #default>
                  <div
                    class="is-flex is-align-items-center is-justify-content-space-between"
                  >
                    <b-button
                      v-show="isEditingEnabled"
                      class="handle ml-3 mr-2"
                      icon-left="grip-vertical"
                      type="success"
                    />
                    <ChallengePromptCardCoordinator
                      :is-reordering="reorder"
                      :prompt="item"
                      @updated="onUpdatePrompt"
                    />
                  </div>
                </template>
                <template #drag-image>
                  <div />
                </template>
              </Drag>
            </template>
          </DropList>
        </div>
      </template>
    </Section>
  </Page>
</template>

<script>
import { toLocalDate } from "@/dates";
import dragAndDrop from "@/mixins/dragAndDrop.js";
import {
  GET_CHALLENGE_BY_SLUG,
  UPDATE_CHALLENGE,
  LIST_CHALLENGE_PROMPTS
} from "@/models/challenges/operations.gql";
import Page from "@/components/common/Page.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import ChallengeCardCoordinator from "@/components/challengeCard/ChallengeCardCoordinator.vue";
import ChallengePromptCardCoordinator from "@/components/challengePromptCard/ChallengePromptCardCoordinator.vue";
import { isNotDeleted, isNotDraft } from "@/models/challenges/predicates.js";
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "ChallengeDetail",
  metaInfo: {
    title: "Writing Challenges - ",
    link: [
      { rel: "canonical", href: "https://www.shutupwrite.com/challenges/" }
    ]
  },
  components: {
    Page,
    Breadcrumbs,
    ChallengeCardCoordinator,
    ChallengePromptCardCoordinator,
    EmptyState
  },
  mixins: [dragAndDrop],
  data: () => ({
    isShowingPromptForm: false,
    scrollToPromptId: null,
    challenge: null,
    sortablePrompts: [],
    isEditingEnabled: false, // can users drag to re-order categories
    toLocalDate
  }),
  computed: {
    breadcrumbs() {
      const result = [];

      result.push({
        to: { name: "challenges" },
        label: "Challenges"
      });

      if (this.challenge) {
        result.push({
          to: {
            name: "challenges_detail",
            params: {
              slug: this.challenge.slug
            }
          },
          label: this.challenge.title
        });
      }
      return result;
    }
  },
  apollo: {
    challenge: {
      query: GET_CHALLENGE_BY_SLUG,
      variables() {
        return {
          slug: this.$route.params.slug,
          expandPrompts: true
        };
      },
      error() {
        this.display404Page();
      },
      update({ challenges }) {
        const c = challenges[0] || null;
        return c || this.display404Page();
      }
    },
    prompts: {
      query: LIST_CHALLENGE_PROMPTS,
      variables() {
        return {
          challenge_id: this.challenge.id,
          where: {
            ...isNotDeleted(),
            ...isNotDraft()
          }
        };
      },
      update({ prompts }) {
        //this.$log.warn("I'm updating the sortable prompts from the server!", prompts);
        this.sortablePrompts = this.orderByArray(
          prompts,
          this.challenge.prompt_ids,
          true
        );
        return prompts;
      },
      skip() {
        return (
          this.challenge === null || this.$apollo.queries.challenge.loading
        );
      }
    }
  },
  methods: {
    onAccept(cache, result) {
      // To whoever sees this, I'm sorry... this handler is from
      // ChallengeCard->ChallengeProgress when the user accepts
      // a challenge, we need to toss our current prompts and go
      // get them with the user's new accepted context
      this.sortablePrompts.forEach(p => cache.evict(cache.identify(p)));
      this.sortablePrompts = [];
      cache.gc();
      this.$apollo.queries.challenge.refresh();
      this.$apollo.queries.prompts.refresh();
    },
    resetSortOrder() {
      this.sortablePrompts = this.orderByArray(
        this.prompts,
        this.challenge.prompt_ids,
        true
      );
      this.isEditingEnabled = false;
    },
    saveSortOrder() {
      let newOrder = this.sortablePrompts.map(p => p.id);
      this.$apollo
        .mutate({
          mutation: UPDATE_CHALLENGE,
          variables: {
            challenge_id: this.challenge.id,
            changes: {
              prompt_ids: `{${newOrder.join(",")}}`
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update on set order", result);
            this.sortablePrompts.forEach(p => cache.evict(cache.identify(p)));
            this.sortablePrompts = [];
            cache.gc();
            this.$apollo.queries.challenge.refresh();
            this.$apollo.queries.prompts.refresh();
          }
        })
        .then(() => {
          this.sortablePrompts = this.orderByArray(this.prompts, newOrder);
          this.isEditingEnabled = false;
          //this.$apollo.queries.prompts.refetch();
          this.$buefy.snackbar.open({
            message: "Prompt Order Updated",
            type: "is-success"
          });
        });
    },
    onCreatePrompt(prompt) {
      this.$log.debug("Prompt Created", prompt);
      this.isShowingPromptForm = false;
    },
    onUpdatePrompt(prompt) {
      this.$log.info("Prompt Updated", prompt);
      this.isShowingPromptForm = false;
    }
  }
};
</script>
