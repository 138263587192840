<template>
  <header :class="imageClass" :style="`${image ? `background-image: url(${image})` : ''}`">
    <div class="container">
      <div class="column">
        <slot />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    image: {
      type: String,
      default: null
    },
    imageClass: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/mixins';
header {
  .truncate-header > & {
    height: auto;
    min-height: 10em;
  }
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
.public-image-limited {
  background-position: 100% 84%;
  background-repeat: no-repeat;
  @include tablet {
    height: 300px;
    background-size: 79%, cover;
  }
}
.laughing-ballcap {
  height: 300px;
  background-size: 130%, cover;
  background-position: -15% 70%;
  background-repeat: no-repeat;
}
.discussion-header {
  height: 300px;
  background-size: cover;
  background-position: 10% 30%;
  background-repeat: no-repeat;
}
</style>
