import { add, getDayOfYear } from "date-fns";
import { toLocalDate } from "@/dates";

const eventCornerEdit = {
  props: {
    value: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    isVirtual: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timeIsOffSchedule() {
      const newHour = this.value.starts_at.getHours();
      const newMinute = this.value.starts_at.getMinutes();
      const scheduledHour = toLocalDate(
        this.event.scheduled_starts_at
      ).getHours();
      const scheduledMinute = toLocalDate(
        this.event.scheduled_starts_at
      ).getMinutes();
      return newHour !== scheduledHour || newMinute !== scheduledMinute;
    },
    dateIsOffSchedule() {
      const newDay = getDayOfYear(this.value.starts_at);
      const scheduledDay = getDayOfYear(this.value.scheduled_starts_at);
      return newDay !== scheduledDay;
    },
    minDate() {
      return new Date();
    },
    maxDate() {
      return add(new Date(toLocalDate(this.event.scheduled_starts_at)), {
        days: 6
      });
    }
  },
  methods: {
    toLocalDate,    
    setVirtual() {
      if (!this.isVirtual) {
        this.$emit("virtualChanged", true);
      }
    },
    setPhysical() {
      if (this.isVirtual) {
        this.$emit("virtualChanged", false);
      }
    },
    handleNewDate(newDate) {
      // we got a new js Date from the datepicker but don't want to lose the time set by the timeslider
      newDate.setHours(this.value.starts_at.getHours());
      newDate.setMinutes(this.value.starts_at.getMinutes());
      this.value.starts_at = newDate;
    }
  }
};

export default eventCornerEdit;