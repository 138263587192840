<template>
  <main>
    <HeroCoordinator />
    <Section class="three-steps">
      <HomeSection>
        <template #title>
          <img src="/img/three-steps.svg" class="onetwothree" />
          <h2>You have the time.</h2>
          <p>
            Commit to writing with others for an hour a week <br class="is-hidden-landscape" />
            and find the accountability, <br class="is-hidden-portrait" />
            focus, and structure <br class="is-hidden-landscape" />
            you need to achieve your writing goals.
          </p>
        </template>
      </HomeSection>
    </Section>
    <Section>
      <ActionCard
        title="Connect with community."
        image-class="home find-an-event-near-you"
        image="/img/hp_find_an_event_near_you.svg"
        button-text="Find an Event Near You"
        button-link="events"
        right
      >
        Join free in-person and<br class="is-hidden-portrait" />
        online events.
      </ActionCard>
    </Section>
    <Section>
      <HomeSection>
        <template #title>
          <h2>
            Every level & every<br class="is-hidden-landscape" />
            genre welcome.
          </h2>
          <p>
            Wherever you are in your process, we've got<br class="is-hidden-landscape" />
            resources to get you started,<br class="is-hidden-portrait" />
            keep you writing,<br class="is-hidden-landscape" />
            and help you stay on track while connecting<br class="is-hidden-landscape" />
            with others.
          </p>
        </template>
      </HomeSection>
    </Section>
    <Section>
      <ActionCard
        title="Challenge yourself."
        image-class="home sign-up-for-a-challenge"
        image="/img/hp_sign_up_for_challenge.svg"
        button-text="Sign Up for a Challenge"
        button-link="challenges"
        left
      >
        Sign up for a Writing Challenge and<br />
        commit to writing every day.
      </ActionCard>
    </Section>
    <!-- #1011 Temporarily Hide Prompts BEGIN
    <Section>
      <ActionCard
        image-class="home explore-writing-prompts"
        image="/img/hp_explore_writing_prompts.svg"
        button-text="Explore Writing Prompts"
        button-link="prompts"
        title="Get Prompted"
        right
      >
        Find inspiration in our free<br class="is-hidden-portrait" />
        library<br class="is-hidden-landscape" />
        of writing prompts.
      </ActionCard>
    </Section>
    #1011 Temporarily Hide Prompts END -->
    <Section>
      <HomeSection>
        <template #title>
          <h2>
            We're fans of working<br class="is-hidden-landscape" /> 
            from home, too.
          </h2>
          <p>
            If you're more comfortable writing at home<br class="is-hidden-landscape" />
            or like your own coffee<br class="is-hidden-portrait" />
            better, find an online<br class="is-hidden-landscape" />
            event. Or sign up to become an organizer &mdash; you<br class="is-hidden-landscape" /> 
            pick the<br class="is-hidden-portrait" />
            place and time, in-person or online.
          </p>
        </template>
      </HomeSection>
    </Section>
    <Section>
      <ActionCard
        image-class="home become-a-host"
        image="/img/hp_become-a-host.svg"
        button-text="Apply to be an Organizer"
        button-link="organizer"
        title="Become an Organizer"
        left
      >
        Start your own event and pick<br />
        the time and place.
      </ActionCard>
    </Section>
    <Section>
      <HomeSection>
        <template #title>
          <h2>No sharing required.</h2>
          <p>
            Hit your flow state without distractions.<br class="is-hidden-landscape" />
            Write for an hour<br class="is-hidden-portrait" />
            and leave, or stick around<br class="is-hidden-landscape" />
            and chat &mdash; the choice is yours!<br class="is-hidden-portrait" />
            No critiques or<br class="is-hidden-landscape" />
            read-alouds, unless you want to share.
          </p>
        </template>
      </HomeSection>
    </Section>
    <Section>
      <HomeSection>
        <template #title>
          <img src="/img/three-plusses.svg" class="threeplusses" />
        </template>
      </HomeSection>
    </Section>
    <TestimonialCardCarousel />
    <Section class="tagline">
      <HomeSection>
        <template #title>
          <h2 class="homesectiontitle has-text-centered has-text-grey800">
            It's time to stop talking about<br class="is-hidden-landscape" />
            that next big<br class="is-hidden-portrait" />
            writing project and Shut Up & Write! it!
          </h2>
        </template>
      </HomeSection>
    </Section>
  </main>
</template>

<script>
import Section from "@/components/common/Section.vue";
import HeroCoordinator from "@/components/home/HeroCoordinator.vue";
import HomeSection from "@/components/home/HomeSection.vue";
import TestimonialCardCarousel from "@/components/home/TestimonialCardCarousel.vue";
import ActionCard from "@/components/common/ActionCard.vue";

export default {
  name: "Home",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Writing Community - ",
    meta: [
      { vmid: "description", name: "description", content: "Free in-person and online writing groups for writers all over the world. Get access to the community and resources you need to be successful." }
    ]
  },
  components: {
    Section,
    HeroCoordinator,
    HomeSection,
    TestimonialCardCarousel,
    ActionCard
  },
  methods: {
    login() {
      this.$log.info("redirecting to login");
      this.$auth.refreshAccess({ redirectUrl: this.$route.fullPath });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.container {
  padding: 0 0.5em;
  @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
    width: 754px;
    margin-left: auto;
    margin-right: auto;
  }
}
.three-steps {
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
.onetwothree,
.threeplusses {
  margin: 15px 0;
  @include mobile {
    margin: vwl(15px) auto;
  }
  @include portrait {
    margin: vwp(15px) auto;
  }
  @include desktop {
    margin: 2rem auto 3rem auto;
    width: 90%;
  }
}
.onetwothree {
  width: 130%;
}
.tagline {
  padding-bottom: 45px;
  @include mobile {
    padding-bottom: vwl(30px);
  }
  @include portrait {
    padding-bottom: vwp(30px);
  }
  h2 {
    line-height: 1.2;
  }
}
</style>
