<template>
  <header class="home">
    <div class="hero">
      <div class="container">
        <h1>
          You want to <span>write.</span><br />
          We're here to help.
        </h1>
        <h2>
          Everyone has a story to tell.<br />
          This is your sign to Shut Up & Write!
        </h2>
        <b-button
          class="hero-cta mt-4"
          type="is-primary is-medium has-text-weight-semibold"
          label="Find an Event Near You"
          @click="$router.push('/events')"
        />
      </div>
    </div>
    <div class="subherobanner">
      <div class="subhero container">
        <p class="has-text-weight-bold">
          Join our thriving community and get your writing done<br class="is-hidden-widescreen" />
          with free<br class="is-flex-widescreen" /> in-person and online writing groups.</p>
        <b-button
          class="subhero-cta"
          type="is-warning is-inverted"
          label="Start Today"
          @click="onStartTodayClick"
        />
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "HeroDesktop",
  methods: {
    onStartTodayClick() {
      this.$emit("startTodayClicked");
    }
  },
}
</script>

<style lang="scss" scoped>
.hero {
  color: white;
  background-image: url(/img/banners/Homepage_Huge2.jpg);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  flex-direction: inherit;
  @include widescreen {
    background-size: cover;
  }
  > div {
    padding: 3em 3em 5em 3em;
    @include desktop {
      padding: 4em 4em 5em 4em;
    }
  }

  h1 {
    color: white;
    font-weight: 300;
    font-size: 60px;
    @include desktop {
      font-size: 72px;
    }
    span {
      color: $gold;
    }
  }
  h2 {
    line-height: 1.4;
    color: white;
    font-weight: 400;
    font-size: 24px;
    margin: 20px 0 0 0;
    @include desktop {
      font-size: 2em;
    }
  }
  .hero-cta {
    min-width: 15em;
  }
}

.subhero {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  align-items: center;
  padding: 2em 3em;
  @include desktop {
    padding: 2.5em 4em;
  }
  p {
    color: #414141;
    font-weight: 700;
    font-size: 1.1rem;
    flex: 1;
    margin: 0;
    @include desktop {
      font-size: 22px;
    }
    @include widescreen {
      font-size: 24px;
    }
  }

  .subhero-cta {
    width: 220px;
  }
}

.subherobanner {
  background: #FFCB37;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
}
</style>
