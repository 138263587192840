<template>
  <div v-if="loading">
    <b-skeleton size="is-small" />
    <div class="is-flex is-justify-content-flex-start">
      <b-skeleton width="80" height="80" class="mr-1" />
      <div class="is-flex is-flex-direction-column is-align-items-stretch">
        <b-skeleton width="100%" size="is-large" />
        <b-skeleton size="is-medium" />
      </div>
    </div>
  </div>
  <EventSeriesEdit v-else-if="isEditing" :event="event" @updated="onUpdated" @edit-cancelled="onEditCancelled" />
  <EventSeriesHeaderView v-else :event="event" />
</template>

<script>
import EventSeriesHeaderView from "@/components/eventSeries/EventSeriesHeaderView.vue";
import EventSeriesEdit from "@/components/eventSeries/EventSeriesEdit.vue";

export default {
  name: "EventSeriesCoordinator",
  components: {
    EventSeriesHeaderView,
    EventSeriesEdit
  },
  props: {
    event: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onUpdated() {
      this.$emit("updated");
    },
    onEditCancelled() {
      this.$emit("edit-cancelled");
    }
  }
};
</script>