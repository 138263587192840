var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{staticClass:"progress-ring",attrs:{"viewBox":_vm.viewBox}},[_c('circle',{staticClass:"progress-steps",class:_vm.steps <= 1 ? 'not-started' : '',style:({
      strokeDasharray: _vm.strokeDasharray,
      transform: _vm.calcRotationOffset(_vm.dashSpacing / 2)
    }),attrs:{"stroke-width":_vm.stroke,"fill":"transparent","r":_vm.normalizedRadius,"cx":_vm.radius,"cy":_vm.radius}}),(!_vm.steps)?_c('circle',{style:({ strokeDashoffset: _vm.strokeDashoffset, transform: 'rotate(-90deg)' }),attrs:{"stroke-dasharray":_vm.circumference + ' ' + _vm.circumference,"fill":"transparent","stroke-width":_vm.stroke,"r":_vm.normalizedRadius,"cx":_vm.radius,"cy":_vm.radius}}):_vm._e(),_vm._l((_vm.stepsCompleted),function(n){return _c('circle',{key:n,staticClass:"progress-completed",style:({
      strokeDashoffset: _vm.strokeDashoffset,
      transform: _vm.calcRotationOffset(
        (n - 1) * (_vm.circumference / _vm.steps) + _vm.dashSpacing / 2
      )
    }),attrs:{"stroke-dasharray":_vm.circumference + ' ' + _vm.circumference,"stroke-width":_vm.stroke,"fill":"transparent","r":_vm.normalizedRadius,"cx":_vm.radius,"cy":_vm.radius}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }