<template>
  <b-notification>Loading...</b-notification>
</template>

<script>
import { EVENTS_LIST } from "@/models/events/operations.gql";
import { slugify } from "@/filters";

export default {
  name: "SeriesLookup",
  props: {
    eventTemplateId: Number,
    required: true
  },
  data() {
    return {
      upcoming_event: null
    };
  },
  apollo: {
    upcoming_event: {
      query: EVENTS_LIST,
      variables() {
        return {
          expand_organizers: false,
          where: {
            event_template_id: { _eq: this.eventTemplateId },
            cancelled_by_user_at: {_is_null: true},
            cancelled_by_materializer_at: {_is_null: true},
            deleted_at: {_is_null: true},
            ends_at: { _gt: "now()" }
          },
          orderBy: {
            scheduled_starts_at: "asc"
          },
          limit: 1,
          offset: 0
        };
      },
      update({ events }) {
        //this.$log.info("done getting events", events);
        this.$router.push({
          name: "event_detail",
          params: {
            slug: slugify(events[0]),
            event_id: events[0].id
          }
        });
      }
    }
  }
};
</script>
