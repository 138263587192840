const nakedPromptCardViewHeader = {
  props: {
    prompt: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        body: null
      }
    };
  },
  computed: {
    canShowEllipsesAllow() {
      return this.$auth.isAdmin && this.isReported;
    },
    canShowEllipsesDelete() {
      return this.$auth.user.id === this.prompt.author.id || this.$auth.isAdmin;
    },
    isEllipsesEnabled() {
      return this.$auth.isAuthenticated && (this.canShowEllipsesAllow || this.canShowEllipsesDelete);
    },
    isReported() {
      if (this.prompt.reports_aggregate.aggregate.count > 0) {
        return true;
      }
      return false;
    },
    isAllowed() {
      if (this.prompt.allowed_at !== null) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.form.body = Object.assign({}, this.prompt.body);
  },
  methods: {
    onDeleteClick() {
      this.$emit("delete");
    },
    onAllowClick() {
      this.$emit("allow");
    }
  }
}

export default nakedPromptCardViewHeader;