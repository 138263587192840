<template>
  <footer>
    <MainFooterDesktop v-if="suwDesktop" />
    <MainFooterMobile v-else />
  </footer>
</template>

<script>
import MainFooterDesktop from "@/components/common/MainFooterDesktop.vue";
import MainFooterMobile from "@/components/common/MainFooterMobile.vue";
export default {
  name: "MainFooterCoordinator",
  components: {
    MainFooterDesktop,
    MainFooterMobile
  }
};
</script>

