<template>
  <div class="create-view pt-4 pb-4">
    <div v-if="isReviewing">
      <h2>Review Your New Series...</h2>
      <h4 class="mt-2">Title</h4>
      <p>{{ form | format_form_title }}</p>

      <h4 class="mt-2">Description</h4>
      <TipTap :value="form.description" />

      <h4 class="mt-2">Hosted By</h4>
      <p>
        <UserPill
          v-for="user in form.organizers"
          :key="user.id"
          :user="user"
          class="mr-1"
        />
      </p>

      <h4 class="mt-2">First Event</h4>
      <p>{{ previewEventStartsAt | format("MMM do hh:mma z") }}</p>

      <h4 class="mt-2">Venue Type</h4>
      <p>{{ form.is_virtual ? "Online" : "In-Person" }}</p>

      <h4 class="mt-2">Duration</h4>
      <p>{{ form.duration_minutes }} minutes</p>

      <h4 v-if="form.is_virtual" class="my-2">Host City</h4>
      <h4 v-else class="my-2">Venue</h4>
      <p>{{ form.venue.street_address }}</p>

      <h4 v-if="form.is_virtual" class="my-2">Meeting URL</h4>
      <p>{{ form.venue.url }}</p>

      <h4 v-if="form.is_virtual" class="my-2">Meeting Password or PIN</h4>
      <p>{{ form.venue.meeting_password }}</p>
      
      <UndoConfirmButtons
        key="reviewSeries"
        class="mt-4"
        :confirm-enabled="!$v.$invalid && !isSaving"
        confirm-button-type="is-success"
        @undo="onReviewUndo"
        @confirm="onReviewConfirm"
      >
        <template #undo>Make Changes</template>
        <template #confirm>
          <span v-if="form.id">Confirm Changes</span>
          <span v-else>Create This Series!</span>
        </template>
      </UndoConfirmButtons>
    </div>
    <div v-else>
      <div class="events-container">
        <div class="mb-2 is-flex is-justify-content-space-between is-align-items-center">
          <div class="is-flex is-align-items-center">
            <h4 v-if="event">Edit Series</h4>
            <h4 v-else>Creating Series</h4>
          </div>
        </div>
        <p v-if="event && isRecurring" class="my-2">
          Anything you change here will change
          <span class="has-text-weight-semibold has-text-danger">all future events</span>
        </p>
        <transition name="slide">
          <div class="my-1">
            <LabeledInput :input="formInputs.title">
              <b-input v-model="form.title" :placeholder="form | format_form_title" />
            </LabeledInput>
          </div>
        </transition>
  
        <div>
          <LabeledInput :input="formInputs.description">
            <TipTap v-model="form.description" :editable="true" />
          </LabeledInput>
          <!-- only allow admins to switch and only during creation -->
          <b-field v-if="event === null" label="Repeats weekly">
            <b-switch v-model="isRecurring" type="is-danger" />
          </b-field>
        </div>
  
        <!-- DAY OF WEEK & DURATION -->
        <div class="my-2">
          <div v-if="isRecurring">
            <LabeledInput :input="formInputs.eachWeek">
              <WeekdayButtons v-model="form.recurrence_dow" @offset="onWeekdayChanged" />
              <p class="mt-1 is-size-6">
                <b-icon icon="info-circle" />
                Next event
                <em>{{ previewEventStartsAt | format("MMM do hh:mma z") }}</em>
              </p>
            </LabeledInput>
            <b-field label="Starting Week">
              <b-datepicker
                v-model="form.start_week_date"
                :min-date="minSeriesStart"
                :unselectable-days-of-week="[1, 2, 3, 4, 5, 6]"
                @input="onStartingWeekChanged"
              />
            </b-field>
          </div>
          <div v-else>
            <b-field label="Start Date &amp; Time">
              <b-datetimepicker
                v-model="form.start_time"
                horizontal-time-picker
                :min-datetime="new Date()"
                :timepicker="{ incrementMinutes: 15 }"
                :class="asf"
                inline
              />
            </b-field>
          </div>
          <div class="column">
            <LabeledInput :input="formInputs.duration">
              <b-input v-model="form.duration_minutes" />
            </LabeledInput>
          </div>
        </div>
  
        <!-- START TIME -->
        <div v-if="isRecurring">
          <LabeledInput :input="formInputs.startTime">
            <TimeSlider v-model="form.start_time" class="mb-5" />
          </LabeledInput>
        </div>
        
        <!-- Organizers & Venue Type -->
        <div class="is-variable is-5">
          <LabeledInput :input="formInputs.hosts">
            <UserSearch
              v-model="form.organizers"
              :allowed-roles="['organizer', 'app_admin']"
            />
          </LabeledInput>
          <LabeledInput :input="formInputs.venue" class="pt-3">
            <div class="is-attached-button-group">
              <b-button
                v-if="!event || !event.template.original_venue_was_virtual"
                :class="{ 'is-primary is-selected': !form.is_virtual }"
                icon-left="coffee"
                icon-pack="fas"
                @click="form.is_virtual = false"
                >In-Person</b-button
              >
              <b-button
                :class="{ 'is-primary is-selected': form.is_virtual }"
                icon-left="laptop"
                icon-pack="fas"
                @click="form.is_virtual = true"
                >Online</b-button
              >
            </div>
          </LabeledInput>
        </div>
        <!-- Venue Details -->
        <label class="label">
          Venue Details
          <b-tooltip type="is-dark" label="TODO: needs copy">
            <b-icon type="is-grey500" icon="question-circle" />
          </b-tooltip>
        </label>
      </div>
      <VenueCardPhysicalMobile
        v-if="!form.is_virtual"
        :value="form.venue"
        :is-editing="true"
        :show-venue-location="event && (event.current_user_is_rsvpd || event.current_user_is_series_member || event.current_user_is_organizer)"
        @input="onVenueChanged"
      />
      <VenueCardVirtualMobile
        v-if="form.is_virtual"
        :value="form.venue"
        :is-editing="true"
        :show-venue-location="event && (event.current_user_is_rsvpd || event.current_user_is_series_member || event.current_user_is_organizer)"
        @input="onVenueChanged"
      />
      <UndoConfirmButtons
        :class="suwMobile ? 'column mt-1' : 'mt-4'"
        :confirm-enabled="!$v.$invalid"
        confirm-button-type="is-success"
        @undo="onSeriesUndo"
        @confirm="onSeriesConfirm"
      >
        <template #undo>Close</template>
        <template v-if="event" #confirm>Review Changes</template>
        <template v-else #confirm>Create This Event Series</template>
      </UndoConfirmButtons>
    </div>
  </div>
</template>

<script>
import eventSeriesEdit from "@/components/eventSeries/EventSeriesEdit.js";
import TimeSlider from "@/components/common/TimeSlider.vue";
import TipTap from "@/components/forms/TipTap.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import UserPill from "@/components/common/UserPill.vue";
import UserSearch from "@/components/forms/UserSearch.vue";
import VenueCardPhysicalMobile from "@/components/venueCard/VenueCardPhysicalMobile.vue";
import VenueCardVirtualMobile from "@/components/venueCard/VenueCardVirtualMobile.vue";
import WeekdayButtons from "@/components/events/WeekdayButtons.vue";
import LabeledInput from "@/components/forms/LabeledInput.vue";

export default {
  name: "EventSeriesEditMobile",
  components: {
    TimeSlider,
    TipTap,
    UndoConfirmButtons,
    UserPill,
    UserSearch,
    VenueCardPhysicalMobile,
    VenueCardVirtualMobile,
    WeekdayButtons,
    LabeledInput
  },
  mixins: [eventSeriesEdit],
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.create-view {
  background-color: #fff;
  padding: 0.5rem;
  @include mobile {
    padding: 0.5rem 0;
  }
}
</style>
