<template>
  <b-button
    v-bind="$attrs"
    :class="`copyable ${cssClass}`"
    :data-clipboard-text="sharableUrl"
    @click="onUrlSave"
  >
    Share
  </b-button>
</template>

<script>
import ClipBoardJS from "clipboard";

export default {
  name: "CopyUrl",
  props: {
    sharableUrl: {
      type: String,
      default: () => window.location.href
    },
    cssClass: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.clipboard = new ClipBoardJS(".copyable");
  },
  beforeDestroy() {
    this.clipboard.destroy();
    this.clipboard = null;
  },
  methods: {
    onUrlSave() {
      if (!this.suwDesktop && navigator.share) {
        navigator
          .share({
            title: this.title ?? "Shut Up & Write!",
            text: "Check this out on Shut Up & Write!",
            url: window.location.href
          })
          //.then(() => console.log('Successful share'))
          .catch(error => console.log("Error sharing", error));
      } else {
        this.$buefy.snackbar.open({
          message: "Copied to clipboard",
          position: "is-top"
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.share-button {
  position: relative;
  @include mobile {
    $size: vwl(20px);
    width: $size;
    height: $size;
  }
  @include portrait {
    $size: vwp(20px);
    width: $size;
    height: $size;
  }
  button {
    border: none;
    background: transparent;
    span {
      background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20167%20191%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M%200%20118.836%20L%2024%20118.836%20L%2024%20166.958%20L%20143%20166.958%20L%20143%20118.836%20L%20167%20118.836%20L%20167%20190.958%20L%200%20190.958%20L%200%20118.836%20Z%22%20fill%3D%22%23414141%22%3E%3C%2Fpath%3E%0A%20%20%3Cpath%20d%3D%22M%20148.027%2064.469%20L%20131.056%2081.439%20L%2095.5%2045.883%20L%2095.5%20131.589%20L%2071.5%20131.589%20L%2071.5%2046.097%20L%2036.571%2081.026%20L%2019.6%2064.056%20L%2083.656%200%20L%20100.627%2016.97%20L%20100.577%2017.019%20L%20148.027%2064.469%20Z%22%20fill%3D%22%23414141%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
      background-size: 70%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: auto !important;
      height: auto !important;
      margin: 0 !important;
    }
    @include mobile {
      padding: 0;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      font-size: 0;
      font-weight: 500;
      display: block;
      border: none;
      span {
        position: absolute;
        padding: 0 !important;
        
        
      }
      svg {
        width: 100% !important;
      }
    }
  }
}
.event-listing {
  .share-button {
    button {
      @include mobile {
        margin-top: vwl(2px);
      }
      @include portrait {
        margin-top: vwp(2px);
      }
      @include tablet {
        margin: 0 !important;
        padding-top: 0;
        padding-bottom: 0;
        height: 24px;
        font-size: 16px;
      }
      span {
        @include tablet {
          background: none;
          position: static;
        }
      }
    }
  }
}
.article-hero {
  .share-button {
    button {
      @include tablet {
        padding-right: 0;
        color: #414141 !important;
        font-size: 14px;
        span {
          padding: 2px 28px 0 0;
          background-size: auto 20px;
          background-position: 95% 15%;
        }
      }
    }
  }
}
</style>
