var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-listing"},[_c('DateSquare',{attrs:{"d":_vm.toLocalDate(_vm.event.starts_at || _vm.event.scheduled_starts_at),"is-featured":_vm.event.template.featured_at !== null,"is-cancelled":_vm.event.template.cancelled_by_user_at !== null}}),_c('div',{staticClass:"event-info",class:{
      cancelled: _vm.event.template.cancelled_by_user_at !== null
    }},[_c('div',{staticClass:"event-title"},[_c('h2',{class:{ 'featured': _vm.event.template.featured_at !== null }},[_c('span',[_vm._v(_vm._s(_vm._f("truncate_render")(_vm._f("format_title")(_vm.event.template))))]),(_vm.event.template.featured_at !== null)?_c('badge',{attrs:{"data-type":"featured","icon":"flag","label":"Featured"}}):_vm._e(),(_vm.durationName === 'extended')?_c('badge',{attrs:{"data-type":"extended","icon":"arrows-alt-h","label":"Extended"}}):_vm._e(),(_vm.durationName === 'marathon')?_c('badge',{attrs:{"data-type":"marathon","icon":"running","label":"Marathon"}}):_vm._e()],1)]),_c('h6',{staticClass:"has-text-grey600"},[_vm._v(" Hosted By "),_vm._l((_vm.event.template.organizers),function(subscription){return _c('UserPill',{key:subscription.user.id,staticClass:"pill",attrs:{"user":subscription.user}})})],2)]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"social buttons"},[_c('div',{staticClass:"share-button"},[(_vm.event.template.cancelled_by_user_at === null)?_c('CopyUrl',{staticClass:"mr-1",attrs:{"size":"10px","type":"has-text-info","sharable-url":_vm.seriesUrl,"title":_vm._f("truncate_render")(_vm._f("format_title")(_vm.event.template))}}):_vm._e()],1),(_vm.event.template.cancelled_by_user_at)?_c('b-tag',{staticClass:"is-danger"},[_vm._v(" CANCELLED ")]):(_vm.event.venue.is_virtual &&
          !_vm.event.template.original_venue_was_virtual)?_c('Pill',{staticClass:"is-success",attrs:{"icon":"laptop","icon-pack":"fas"}},[_vm._v(" Currently Meets Online ")]):_vm._e(),_c('b-tag',{attrs:{"type":"is-success is-light has-text-grey-dark"}},[_c('b-icon',{attrs:{"icon":"user-check"}}),_c('span',[_vm._v(" "+_vm._s(_vm.event.template.members_aggregate.aggregate.count)+" ")])],1)],1),_c('div',{staticClass:"admin buttons"},[(
          _vm.$auth.isAdmin &&
            _vm.event.template.original_venue_was_virtual &&
            _vm.event.template.cancelled_by_user_at === null
        )?_c('FlyoutButton',{ref:"featured_flyout",attrs:{"position":"is-bottom-left","trigger-button-size":"10px","trigger-button-type":"is-success is-outlined","trigger-button-icon":null,"trigger-button-text":_vm.event.template.featured_at ? 'unFeature' : 'Feature'},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FeaturedControl',{attrs:{"event-template":_vm.event.template},on:{"undo":function($event){return _vm.closeFlyout('featured_flyout')},"confirm":function($event){return _vm.closeFlyout('featured_flyout')}}})]},proxy:true}],null,false,3845127428)}):_vm._e(),(_vm.event.template.cancelled_by_user_at === null)?_c('SeriesJoinLeave',{model:{value:(_vm.event),callback:function ($$v) {_vm.event=$$v},expression:"event"}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }