<template>
  <div>
    <NakedPromptCardViewExpandedDesktop 
      v-if="suwDesktop"
      :prompt="prompt" 
      @edit="onEdit"
      @delete="onDelete"
      @allow="onAllow"
    />
    <NakedPromptCardViewExpandedMobile 
      v-if="!suwDesktop"
      :prompt="prompt" 
      @edit="onEdit"
      @delete="onDelete"
      @allow="onAllow"
    />
  </div>
</template>

<script>
import NakedPromptCardViewExpandedDesktop from "@/components/nakedPromptCard/NakedPromptCardViewExpandedDesktop.vue";
import NakedPromptCardViewExpandedMobile from "@/components/nakedPromptCard/NakedPromptCardViewExpandedMobile.vue";

export default {
  name: "NakedPromptCardViewExpanded",
  components: {
    NakedPromptCardViewExpandedDesktop,
    NakedPromptCardViewExpandedMobile
  },
  props: {
    prompt: {
      type: Object,
      default: null
    }
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onDelete() {
      this.$emit("delete");
    },
    onAllow() {
      this.$emit("allow");
    }
  }
};
</script>
