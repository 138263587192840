<template>
  <div>
    <ChallengeCardViewHeader
      v-if="isViewHeader && !isEdit"
      :challenge="challenge"
      @accepted="onAccepted($event)"
    />

    <ChallengeCardViewExpanded
      v-if="isViewExpanded && !isEdit"
      :challenge="challenge"
      @accepted="onAccepted($event)"
      @edit="setEdit()"
    />

    <ChallengeCardViewFeatured
      v-if="isViewFeatured && !isEdit"
      :challenge="challenge"
      :featured-flag="featuredFlag"
    />

    <ChallengeCardEdit
      v-if="isEdit"
      :challenge="challenge"
      @created="onCreated($event)"
      @updated="onUpdated($event)"
      @cancelled="onCancelled()"
    />
  </div>
</template>

<script>
import { AcceptChallenge } from "@/models/actions/operations.gql";
import {
  EMPTY_FIELD,
  NOT_EXIST,
  INVALID_FORMAT,
  NUMBER_TOO_SMALL,
  INSERT_FAILED,
  DEFAULT_FALLBACK,
  getErrorMessage,
  fallbackErrorMessage
} from "@/backendException.js";

import ChallengeCardViewHeader from "@/components/challengeCard/ChallengeCardViewHeader.vue";
import ChallengeCardViewExpanded from "@/components/challengeCard/ChallengeCardViewExpanded.vue";
import ChallengeCardViewFeatured from "@/components/challengeCard/ChallengeCardViewFeatured.vue";
import ChallengeCardEdit from "@/components/challengeCard/ChallengeCardEdit.vue";

export default {
  name: "ChallengeCardCoordinator",
  components: {
    ChallengeCardViewHeader,
    ChallengeCardViewExpanded,
    ChallengeCardViewFeatured,
    ChallengeCardEdit
  },
  props: {
    // this should only be set when prompt is null (create prompt inside a challenge)
    challenge: {
      type: Object,
      default: null
    },
    isHeader: {
      type: Boolean,
      default: false
    },
    featuredFlag: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isViewHeader: false,
      isViewExpanded: false,
      isViewFeatured: false,
      isEdit: false,
      validationReasonMap: new Map([
        [EMPTY_FIELD, "Required fields must be filled out."],
        [NOT_EXIST, "Challenge does not exist."],
        [INVALID_FORMAT, "Challenge Id format is invalid."],
        [NUMBER_TOO_SMALL, "Challenge must start in the future."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap: new Map([
        [INSERT_FAILED, "Accept failed. Please try again."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  created() {
    if (this.challenge) {
      if (this.featuredFlag <= 0) {
        if (this.isHeader) {
          this.setViewHeader();
        } else {
          this.setViewExpanded();
        }
      } else if (this.featuredFlag > 0) {
        this.setViewFeatured();
      }
    } else {
      this.setEdit();
    }
  },
  methods: {
    reset() {
      this.isViewHeader = false;
      this.isViewExpanded = false;
      this.isViewFeatured = false;
      this.isEdit = false;
    },
    setViewHeader() {
      this.reset();
      this.isViewHeader = true;
    },
    setViewExpanded() {
      this.reset();
      this.isViewExpanded = true;
    },
    setViewFeatured() {
      this.reset();
      this.isViewFeatured = true;
    },
    setEdit() {
      this.isEdit = true;
    },
    unsetEdit() {
      this.isEdit = false;
    },
    onCreated(e) {
      this.$emit("created", e);
      this.unsetEdit();
    },
    onUpdated(e) {
      this.$emit("updated", e);
      this.unsetEdit();
    },
    onCancelled() {
      this.$emit("cancelled");
      this.unsetEdit();
    },
    onDelete() {
      this.$emit("deleted");
    },
    async onAccepted(e) {
      try {
        const response = await this.$apollo.mutate({
          mutation: AcceptChallenge,
          variables: {
            challengeId: e.challenge_id,
            userId: this.$auth.user.id,
            shiftedStartDate: e.shifted_start_date
          },
          update: (cache, { data: { result } }) => {
            this.$emit("accepted", cache, result);
            cache.evict(cache.identify(this.challenge));
            cache.gc();
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          this.$buefy.snackbar.open({
            queue: false,
            message: getErrorMessage(errors, this.validationReasonMap, this.executionReasonMap),
            position: "is-top",
            type: "is-danger"
          });
        } else {
          this.$buefy.snackbar.open({
            message: `Challenge ${this.challenge.title} accepted`,
            type: "is-success"
          });
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
      }
    }
  }
};
</script>
