<template>
  <div>
    <EventSeriesHeaderViewDesktop 
      v-if="!suwMobile"
      :event="event" 
    />
    <EventSeriesHeaderViewMobile 
      v-if="suwMobile"
      :event="event" 
    />
  </div>
</template>

<script>
import EventSeriesHeaderViewDesktop from "@/components/eventSeries/EventSeriesHeaderViewDesktop.vue";
import EventSeriesHeaderViewMobile from "@/components/eventSeries/EventSeriesHeaderViewMobile.vue";

export default {
  name: "EventSeriesHeaderView",
  components: {
    EventSeriesHeaderViewDesktop,
    EventSeriesHeaderViewMobile
  },
  props: {
    event: {
      type: Object,
      default: () => null
    }
  }
};
</script>
