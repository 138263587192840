<template>
  <section>
    <!-- use `v-show` vs `v-if`, as removing the element loses the autocomplete bindings -->
    <b-input
      ref="input"
      icon="search-location"
      class="elevation-1"
      autocomplete="off"
      :value="locationQuery"
      :placeholder="citiesOnly ? 'Enter a city or zipcode' : 'Find a venue'"
      @focus="onFocus"
      @blur="onBlur"
    />
  </section>
</template>

<script>
// the `google` object used by this widget is loaded via a script tag in `public/index.html`
/* global google */

export default {
  name: "GooglePlaces",
  props: {
    value: {
      type: Object,
      default: () => null
    },
    citiesOnly: {
      type: Boolean,
      default: false
    },
    showSelectedLocation: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isFocused: false,
      autocomplete: null,
      autocompleteListener: null
    };
  },
  computed: {
    locationQuery() {
      // return what goes in the input field when not focused
      if (this.isFocused) {
        return ""; // blank it out so they can type
      }
      if (this.value !== null) {
        if (!this.citiesOnly && this.value.formatted_address) {
          return `${this.value.name}, ${this.value.formatted_address}`;
        } else if (this.value.formatted_address) {
          return this.value.formatted_address;
        }
      }
      return "";
    }
  },
  beforeDestroy() {
    if (this.autocompleteListener) {
      google.maps.event.removeListener(this.autocompleteListener);
    }
  },
  mounted() {
    this.initAutoComplete();
  },
  methods: {
    onFocus() {
      // since google takes over the input field it does weird crap with our v-model
      // in this case we have to explicitly clear the value of the input in the input
      //this.initAutoComplete();
      this.isFocused = true;
      this.autocomplete.set("place", null);
      // this.autocompleteListener = null;
      //this.$refs.input.$refs.input.value = "";
    },
    onBlur() {
      this.isFocused = false;
      //this.$refs.input.$refs.input.value = q;
      // this.$log.debug('done blur', this.locationQuery);
    },
    initAutoComplete() {
      // below here google comes from the script created in
      const inputEl = this.$refs.input.$refs.input;

      // what we're looking for in autocomplete
      // see https://developers.google.com/places/supported_types?hl=en_US#table3
      let locationTypes = [];
      if (this.citiesOnly) {
        locationTypes = ["(regions)"];
      }

      this.autocomplete = new google.maps.places.Autocomplete(inputEl, {
        types: locationTypes,
        // see https://developers.google.com/maps/documentation/javascript/reference/places-service?hl=en_US#PlaceResult
        fields: [
          "place_id",
          "name",
          "types",
          "formatted_address",
          "geometry",
          "address_components"
        ]
      });

      this.enableEnterKey(inputEl);

      this.autocompleteListener = this.autocomplete.addListener(
        "place_changed",
        this.onPlaceChosen
      );
    },
    onPlaceChosen() {
      // tell our parent there's a new place chosen
      const p = this.autocomplete.getPlace();
      if (p) {
        this.$emit("updated:place", this.autocomplete.getPlace());
      }
    },
    enableEnterKey(input) {
      // slightly modified version of this solution:
      // https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
      // basically simulates arrow down key (to select first result) on Enter key seems most straignforward
      /* Store original event listener */
      const _addEventListener = input.addEventListener;

      const addEventListenerWrapper = (type, listener) => {
        if (type === "keydown") {
          /* Store existing listener function */
          const _listener = listener;
          listener = event => {
            /* Simulate a 'down arrow' keypress if no address has been selected */
            const suggestionSelected = document.getElementsByClassName(
              "pac-item-selected"
            ).length;
            if (event.key === 'Enter' && !suggestionSelected) {
              const e = new KeyboardEvent("keydown", {
                key: "ArrowDown",
                code: "ArrowDown",
                keyCode: 40,
              });
              _listener.apply(input, [e]);
            }
            _listener.apply(input, [event]);
          };
        }
        _addEventListener.apply(input, [type, listener]);
      };
      input.addEventListener = addEventListenerWrapper;
    }
  }
};
</script>
