import { LIST_CHALLENGE_PROMPTS } from "@/models/challenges/operations.gql";
import { isNotDeleted } from "@/models/forums/predicates.js";
import { isPast } from "date-fns";

const challengeCardViewFeatured = {
  props: {
    challenge: {
      type: Object,
      default: null
    },
    featuredFlag: {
      type: Number,
      default: 3
    }
  },
  computed: {
    isFirst() {
      return this.featuredFlag === 1;
    },
    isSecond() {
      return this.featuredFlag === 2;
    },
    totalSubmissions() {
      const count = this.challenge.prompts.reduce(
        (agg, prompt) => agg + prompt.submissions_aggregate.aggregate.count,
        0
      );
      return count;
    },
    totalPosts() {
      let count = 0;
      this.challenge.prompts.forEach(prompt => {
        count += prompt.submissions.reduce(
          (agg, submission) => agg + submission.topic.posts_aggregate.aggregate.count,
          0
        );
      });
      return count;
    },
    engagementCount() {
      return this.totalSubmissions + this.totalPosts;
    },
    wasHappeningNow() {
      return isPast(this.availableDate);
    },
    nextPromptDate() {
      if (this.challenge.next_prompt_available_after) {
        return new Date(this.challenge.next_prompt_available_after);
      }
      return null;
    },
    availableDate() {
      if (this.challenge.current_user_shifted_start_date) {
        return new Date(this.challenge.current_user_shifted_start_date);
      }
      return new Date(this.challenge.available_after);
    }
  },
  apollo: {
    prompts: {
      query: LIST_CHALLENGE_PROMPTS,
      variables() {
        if (this.challenge) {
          return {
            challenge_id: this.challenge.id,
            where: { ...isNotDeleted() }
          };
        }
      }
    }
  },
  methods: {
    onChallengeCardClick() {
      this.$router.push({
        name: "challenges_detail",
        params: {
          slug: this.challenge.slug
        }
      });
    },
    nextPromptNow() {
      return this.nextPromptDate <= this.nowTimer;
    }
  }
};

export default challengeCardViewFeatured;
