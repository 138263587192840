export const defaultEventDescriptionPhysical = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text:
            "Commit to your writing every week. In-person events take place in public spaces where members come together to make progress. No critiques, read-alouds, or distractions: just an hour of focused writing. If you like, stick around to socialize afterwards and make connections with your fellow writers. You've found your writing group - join us!"
        }
      ]
    }
  ]
};

export const defaultEventDescriptionVirtual = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Commit to your writing every week. Online events take place via video conferencing sessions where members come together to make progress. No critiques, read-alouds, or distractions: just an hour of focused writing. If you like, stick around to socialize afterwards and make connections with your fellow writers. You've found your writing group - join us!"
        }
      ]
    }
  ]
};
