const challengePromptCardViewHeaderLocked = {
  props: {
    // the prompt you're showing; required
    prompt: {
      type: Object,
      default: null
    }
  }
}

export default challengePromptCardViewHeaderLocked;