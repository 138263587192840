import { add, set, getDay, nextDay, previousSunday, nextSunday } from "date-fns";
import { rrulestr } from "rrule";
import rrule from "@/rrule.js";
import { toLocalDate, minutesFromPostgresInterval } from "@/dates";
import { maxLength, requiredIf, numeric, required, minValue } from "vuelidate/lib/validators";
import { CREATE_SERIES, UPDATE_SERIES } from "@/models/series/operations.gql";
import {
  defaultEventDescriptionPhysical,
  defaultEventDescriptionVirtual
} from "@/defaultEventDescriptions.js";
import { format_form_title } from "../../filters";

const mustNotBeEmptyArray = value => value && value.length > 0;

export default {
  props: {
    event: {
      type: Object,
      default: () => null
    }
  },
  validations: {
    form: {
      title: {
        maxLength: maxLength(80),
      },
      organizers: {
        mustNotBeEmptyArray
      },
      venue: {
        api_result: {
          required: requiredIf(function () {
            return !this.form.is_virtual;
          })
        },
        url: {
          required: requiredIf(function() {
            return this.form.is_virtual;
          })
        },
        host_city: {
          required: requiredIf(function() {
            return this.form.is_virtual;
          })
        }
      },
      duration_minutes: {
        numeric,
        minValue: minValue(1),
        required: requiredIf(function() {
          return this?.isOverriding;
        })
      },
      description: {
        required
      }
    }
  },
  data() {
    return {
      isSaving: false,
      isReviewing: false,
      isRecurring: this.event ? this.event.is_recurring : true,
      isOverriding: this.event && !this.event.is_recurring,
      form: this.buildFormForEvent(this.event),
      defaultEventDescriptionPhysical,
      defaultEventDescriptionVirtual,
      forcePreviewRefresh: 0,
      prevSunday: previousSunday(new Date()),
      nextSunday: nextSunday(new Date())
    };
  },
  computed: {
    previewEventStartsAt() {
      this.forcePreviewRefresh;
      var today = new Date();
      //this ensures that people do not think that an event can start before the current time in the day
      //ideally this would be generated by rrule, but whatever
      if (today >= this.form.start_time) {
        var prevSunStartTime = this.previewFrom(this.prevSunday, this.form.recurrence_dow, this.form.start_time.getHours(), this.form.start_time.getMinutes());
        var nextSunStartTime = this.previewFrom(this.nextSunday, this.form.recurrence_dow, this.form.start_time.getHours(), this.form.start_time.getMinutes());
        return today <= prevSunStartTime ? prevSunStartTime : nextSunStartTime;
      } else {
        var since = this.prevSunday;
        if (this.form.start_week_date > this.nextSunday) {
          since = this.form.start_week_date;
        }
        return this.previewFrom(since, this.form.recurrence_dow, this.form.start_time.getHours(), this.form.start_time.getMinutes());
      }
    },
    minSeriesStart() {
      return add(this.prevSunday, { days: -1 }); //add -1 so that today is included
    },
    isDefaultDescription() {
      return this.form.description === defaultEventDescriptionPhysical || this.form.description === defaultEventDescriptionVirtual;
    },
    formInputs() {
      return {
        title: {
          label: "Custom Title",
          error: this.$v.form.title.$invalid && "Title is too long"
        },
        description: {
          label: "Description",
          error: this.$v.form.description.$invalid && "Description must not be empty"
        },
        eachWeek: {
          label: "Each week on",
          tooltip: "A new event will be created every week on this day."
        },
        duration: {
          label: "Duration (minutes)",
          tooltip: "How long do your Shut Up & Writes last?",
          error: this.$v.form.duration_minutes.$invalid && "Invalid duration"
        },
        startTime: {
          label: "Start Time",
          tooltip: "What time do your Shut Up & Writes start?"
        },
        hosts: {
          label: "Organizers",
          tooltip: "Who is hosting the event?",
          error: this.$v.form.organizers.$invalid && "Organizer is invalid"
        },
        venue: {
          label: "Venue Type",
          tooltip: "Is this an in-person or online event?"
        }
      };
    }
  },
  watch: {
    event: {
      handler(newVal) {
        this.form = this.buildFormForEvent(newVal);
      }
    }
  },
  methods: {
    toLocalDate,
    previewFrom(d, dow, m, s) {
      // used during create/edit series to figure out when the next actual date is that matches the day of week in the form
      var preview = nextDay(add(d, { days: -1 }), dow);
      preview.setHours(m);
      preview.setMinutes(s);
      return preview;
    },
    onStartingWeekChanged(selectedDate) {
      this.form.start_time.setFullYear(selectedDate.getFullYear());
      this.form.start_time.setMonth(selectedDate.getMonth());
      this.form.start_time.setDate(selectedDate.getDate());
      this.forcePreviewRefresh++;
    },
    buildFormForEvent(event) {
      const defaultStartsAt = add(set(new Date(), { minutes: 0, seconds: 0 }), {
        hours: 1
      });
      let form = {
        start_time: defaultStartsAt,
        start_week_date: previousSunday(this.previewFrom(defaultStartsAt, getDay(defaultStartsAt), defaultStartsAt.getHours(), defaultStartsAt.getMinutes())),
        organizers: [this.$auth.user],
        recurrence_dow: getDay(defaultStartsAt),
        title: null,
        description: defaultEventDescriptionPhysical,
        duration_minutes: 90,
        is_virtual: false,
        venue: {},
        original_venue_title: null,
        original_venue_location: null,
        original_venue_was_virtual: false
      };
      if (event !== null) {
        const is_virtual = event.template.venue.is_virtual;
        const rruleset = rrulestr(event.template.rruleset_text);
        let recurrence_dow = getDay(rruleset.origOptions.dtstart);
        let local_start = toLocalDate(rruleset.origOptions.dtstart);
        form = {
          ...form,
          id: event.template.id,
          title: event.template.title,
          description: event.template.description,
          venue_id: event.template.venue.id,
          venue: event.template.venue,
          original_venue_title: event.template.original_venue_title,
          original_venue_location: event.template.original_venue_location,
          original_venue_was_virtual: event.template.original_venue_was_virtual,
          duration_minutes: minutesFromPostgresInterval(event.duration),
          is_virtual,
          recurrence_dow,
          organizers: event.template.organizers.map(eo => eo.user),
          start_time: local_start, // ensure we don't move the date, and only mess with the hours/minutes/seconds
          start_week_date: previousSunday(this.previewFrom(local_start, getDay(local_start), local_start.getHours(), local_start.getMinutes())),
        };
      }
      if (!form.organizers) {
        form.organizers = [this.$auth.user];
      }
      return form;
    },
    onSeriesUndo() {
      this.isOverriding = false;
      this.$emit("edit-cancelled");
    },
    onVenueChanged(newVenue) {
      // this.$log.info("venue changed, setting originals");
      if (!this.form.id || this.form.original_venue_was_virtual === this.form.is_virtual) {
        // create mode, just set everything based on this venue
        this.form.original_venue_location = newVenue.location;
        this.form.original_venue_title = newVenue.title;
        this.form.original_venue_was_virtual = this.form.is_virtual;
      }
      // persists the is_wherby state between venue changes
      // needed to validate form
      this.form.venue = { ...newVenue };
      if (this.isDefaultDescription) {
        if (this.form.is_virtual) {
          this.form.description = defaultEventDescriptionVirtual;
        } else {
          this.form.description = defaultEventDescriptionPhysical;
        }
      }
    },
    onWeekdayChanged(daysOffset) {
      this.form.start_time = add(this.form.start_time, { days: daysOffset });
      this.form.recurrence_dow = getDay(this.form.start_time);
    },
    onReviewConfirm() {
      if (this.form.id) {
        this.updateSeries();
      } else {
        this.createSeries();
      }
    },
    onReviewUndo() {
      this.isReviewing = false;
    },
    onSeriesConfirm() {
      this.isReviewing = true;
      window.scrollTo(0, 0);
    },
    updateSeries() {
      let rruleset;
      if (this.isRecurring) {
        rruleset = rrule.create_weekly_set(this.form.start_time);
      } else {
        rruleset = rrule.create_one_off_set(this.form.start_time);
      }
      const newHosts = this.form.organizers.map(user => {
        let existing = this.event.template.organizers.find(o => o.user.id === user.id);
        if (existing) {
          return {
            user_id: existing.user.id,
            event_template_id: this.form.id,
            organizer_granted_at: existing.organizer_granted_at,
            organizer_granted_by_id: existing.organizer_granted_by_id
          };
        }
        return {
          user_id: user.id,
          event_template_id: this.form.id,
          organizer_granted_at: "now()",
          organizer_granted_by_id: this.$auth.user.id
        };
      });
      const organizersToRemove = this.event.template.organizers
        .filter(o => !this.form.organizers.find(u => u.id === o.user.id))
        .map(o => o.user.id);
      //this.$log.info("newHosts", newHosts);
      //this.$log.info("organizersToRemove", organizersToRemove);
      const variables = {
        id: this.form.id,
        venue_id: this.form.venue_id,
        organizers: newHosts,
        organizers_to_remove: organizersToRemove,
        series_changes: {
          title: format_form_title(this.form),
          description: this.form.description,
          duration: `${this.form.duration_minutes} minutes`,
          rruleset_text: rruleset.toString(),
          original_venue_title: this.form.original_venue_title,
          original_venue_location: this.form.original_venue_location
        },
        venue_changes: {
          title: this.form.venue.title,
          is_virtual: this.form.venue.is_virtual,
          api_result: this.form.venue.api_result,
          location: this.form.venue.location,
          street_address: this.form.venue.street_address,
          parking_info: this.form.venue.parking_info,
          wifi_info: this.form.venue.wifi_info,
          find_me: this.form.venue.find_me,
          host_city: this.form.venue.host_city,
          url: this.form.venue.url,
          meeting_id: this.form.venue.meeting_id,
          meeting_password: this.form.venue.meeting_password
        }
      };
      this.isSaving = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_SERIES,
          variables,
          update: (
            cache,
            {
              data: {
                host_additions,
                host_removals,
                template_result,
                venue_result
              }
            }
          ) => {
            //this.$log.info("in update of UPDATE_SERIES");
            //this.$log.info("host additions", host_additions.affected_rows);
            //this.$log.info("host removals", host_removals.affected_rows);
            if (template_result !== null) {
              cache.evict(cache.identify(template_result));
            }
            if (venue_result !== null) {
              cache.evict(cache.identify(venue_result));
            }
            return {
              host_additions,
              host_removals,
              template_result,
              venue_result
            };
          }
        })
        .then(({ template_result, venue_result }) => {
          this.isSaving = false;
          if (template_result === null && venue_result === null) {
            // they didn't actually change anything...
          } else {
            this.$log.debug(venue_result);
            this.$buefy.snackbar.open({
              message: `Series Updated`,
              queue: false
            });
          }
          this.$emit("updated");
          this.$router.push({
            name: "series_lookup",
            params: {
              event_template_id: this.form.id
            }
          });
        });
    },
    createSeries() {
      let rruleset;
      //there is no adjusting for daylight savings when we create a series
      if (this.isRecurring) {
        rruleset = rrule.create_weekly_set(this.form.start_time);
      } else {
        rruleset = rrule.create_one_off_set(this.form.start_time);
      }
      const variables = {
        title: format_form_title(this.form),
        description: this.form.description,
        duration: `${this.form.duration_minutes} minutes`,
        rruleset: rruleset.toString(),
        venue: this.form.venue,
        original_venue_title: this.form.original_venue_title,
        original_venue_location: this.form.original_venue_location,
        original_venue_was_virtual: this.form.original_venue_was_virtual,
        organizers: this.form.organizers.map(o => ({
          user_id: o.id,
          organizer_granted_at: "now()",
          organizer_granted_by_id: this.$auth.user.id
        }))
      };
      //this.$log.warn("VARS", variables, this.form);
      this.isSaving = true;
      this.$apollo
        .mutate({
          mutation: CREATE_SERIES,
          variables
        })
        .then(({ data: { result } }) => {
          //this.$log.debug(result);
          //wait a few seconds before declaring the success, to give backend processes time to run and ultimately materialize events and thus provide results on the series_lookup screen
          setTimeout(() => {
            this.isSaving = false;
            this.$buefy.snackbar.open({
              message: `Series Created`,
              queue: false
            });
            this.$router.push({
              name: "series_lookup",
              params: {
                event_template_id: result.id
              }
            });
          }, 3000);
        });
    }
  }
};
