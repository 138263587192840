<template>
  <svg class="progress-ring" :viewBox="viewBox">
    <circle
      :style="{
        strokeDasharray,
        transform: calcRotationOffset(dashSpacing / 2)
      }"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
      :class="steps <= 1 ? 'not-started' : ''"
      class="progress-steps"
    />
    <circle
      v-if="!steps"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset, transform: 'rotate(-90deg)' }"
      fill="transparent"
      :stroke-width="stroke"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
    <circle
      v-for="n in stepsCompleted"
      :key="n"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{
        strokeDashoffset,
        transform: calcRotationOffset(
          (n - 1) * (circumference / steps) + dashSpacing / 2
        )
      }"
      :stroke-width="stroke"
      fill="transparent"
      class="progress-completed"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>

<script>
export default {
  name: "ProgressRing",
  props: {
    radius: {
      type: Number,
      default: 60
    },
    progress: {
      type: Number,
      required: true
    },
    stroke: {
      type: Number,
      default: 2
    },
    steps: {
      type: Number,
      default: 0
    },
    dashSpacing: {
      type: Number,
      default: 2
    }
  },
  data() {
    const normalizedRadius = this.radius - this.stroke / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const diameter = this.radius * 2;
    const viewBox = `0 0 ${diameter} ${diameter}`;

    return {
      normalizedRadius,
      circumference,
      diameter,
      viewBox
    };
  },
  computed: {
    strokeDashoffset() {
      if (!this.steps) {
        return this.circumference - (this.progress / 100) * this.circumference;
      }

      return (
        this.circumference -
        (1 / this.steps) * this.circumference +
        this.dashSpacing
      );
    },
    strokeDasharray() {
      if (!this.steps) {
        return 0;
      }

      return `${(this.circumference - this.steps * this.dashSpacing) /
        this.steps}, ${this.dashSpacing}`;
    },
    stepsCompleted() {
      return Math.round((this.progress / 100) * this.steps);
    }
  },
  methods: {
    calcRotationOffset(arcLength) {
      const rotationAngle =
        -90 + (arcLength / this.normalizedRadius) * (180 / Math.PI);

      return `rotate(${rotationAngle}deg)`;
    }
  }
};
</script>

<style lang="scss">
.progress-ring {
  circle {
    transform-origin: 50% 50%;
    transition: all .25s;
  }

  circle:first-of-type {
    stroke: $grey300;
  }

  circle:not(:first-of-type) {
    stroke: $grey500;
  }

  // &:hover circle:first-of-type {
  //   fill: $grey300;
  //   stroke: transparent;
  // }

  // &:hover circle:not(:first-of-type) {
  //   fill: $grey500;
  //   stroke: transparent;
  // }

  &.is-danger {
    circle:first-of-type {
      stroke: $danger-light;
    }

    circle:not(:first-of-type) {
      stroke: $pink;
    }

    // &:hover circle:first-of-type {
    //   fill: $danger-light;
    //   stroke: transparent;
    // }

    // &:hover circle:not(:first-of-type) {
    //   fill: $danger;
    //   stroke: transparent;
    // }
  }

  &.is-success {
    circle:first-of-type {
      stroke: $success-light;
    }

    circle:not(:first-of-type) {
      stroke: $success-light;
    }

    // &:hover circle:first-of-type {
    //   fill: $success-light;
    //   stroke: transparent;
    // }

    // &:hover circle:not(:first-of-type) {
    //   fill: $success;
    //   stroke: transparent;
    // }
  }

  &.is-info {
    circle:first-of-type {
      stroke: $info-light;
    }

    circle:not(:first-of-type) {
      stroke: $info;
    }

    // &:hover circle:first-of-type {
    //   fill: $info-light;
    //   stroke: transparent;
    // }

    // &:hover circle:not(:first-of-type) {
    //   fill: $info;
    //   stroke: transparent;
    // }
  }
}
</style>
