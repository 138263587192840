<template>
  <section class="container">
    <slot />
  </section>
</template>

<script>
export default {
  name: "SectionContainer"
};
</script>

<style lang="scss" scoped>
section {
  padding: 0 $gap;
  @include mobile {
    padding: 0em 0.5em;
  }

  > section {
    padding: 2em 0 2em 0;
    @include mobile {
      padding: 1.5em 0 1.5em 0;
    }
  }
}
</style>