<template>
  <Page class="main physical">
    <Section>
      <!-- Make into a specific header component? -->
      <div v-if="suwDesktop">
        <h1>
          Find an
          <strong class="nowrap">In-Person</strong>
          Event
        </h1>
        <p>
          Browse our listings to sign up for a regular event that works with your schedule.
        </p>
      </div>

      <EventSeriesListPhysical
        :class="{
          'mt-4': suwDesktop
        }"
        :where="whereClause"
        :max-events="maxEvents"
        :apportioned-viewable="showingAtATime"
        :last-searched="lastSearch"
        @place:updated="onPlaceUpdated"
        @results:updated="onResultsUpdated"
      >
        <template #underMap="{ activeSeriesId, clear }">
          <div class="page-heading is-flex is-justify-content-space-between is-align-items-center">
            <p class="has-text-grey-dark">
              Events are listed for your local time, which is
              {{ formatUTC(nowTimer, "h:mm a") }}. Event listings hosted on Meetup.com are updated every 24 hours.
            </p>
            <div v-if="isChapterMember" class="event-type-dropdown">
              <span v-if="!suwMobile" class="is-hidden-mobile">Chapter</span>
              <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
                <template #trigger>
                  <b-button type="has-text-grey-dark" icon-right="chevron-down">
                    <span v-if="onlyShowChapters">My Chapter</span>
                    <span v-else>None</span>
                  </b-button>
                </template>
                <b-dropdown-item @click="selectMyChapters">
                  My Chapter
                </b-dropdown-item>
                <b-dropdown-item @click="selectNoChapters">
                  None
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="event-type-dropdown">
              <span v-if="!suwMobile" class="is-hidden-mobile">Event Type</span>
              <b-dropdown ref="dropdown" aria-role="list" position="is-bottom-left">
                <template #trigger>
                  <b-button type="has-text-grey-dark" icon-right="chevron-down">
                    <span v-if="onlyShowFeatured">Featured</span>
                    <span v-else-if="onlyShowExtended">Extended</span>
                    <span v-else-if="onlyShowMarathon">Marathon</span>
                    <span v-else-if="suwMobile">Event Type</span>
                    <span v-else>Select</span>
                  </b-button>
                </template>
                <b-dropdown-item @click="selectAny">
                  Any
                </b-dropdown-item>
                <b-dropdown-item @click="selectFeatured">
                  Featured
                </b-dropdown-item>
                <b-dropdown-item @click="selectExtended">
                  Extended
                </b-dropdown-item>
                <b-dropdown-item @click="selectMarathon">
                  Marathon
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-if="activeSeriesId !== null"
                type="is-info is-outlined"
                @click="clear"
              >
                Clear Selection
              </b-button>
            </div>
          </div>
        </template>
      </EventSeriesListPhysical>
    </Section>

    <div class="events-container">
      <div class="boxed mb-4 cantfind">
        <h4 class="has-text-centered has-text-weight-medium">
          Can't find an event near you?
        </h4>
        <p class="has-text-centered has-text-grey">
          Search another location, try an<br class="is-hidden-tablet" />
          online event, or host your own.
        </p>

        <div class="button-row">
          <router-link
            tag="a"
            class="button is-info has-text-weight-bold "
            :to="{ name: 'events_virtual' }"
            exact
          >
          Search Online Events
          </router-link>
          <router-link
            tag="a"
            class="button is-pink has-text-weight-bold"
            :to="{ name: 'host' }"
            exact
          >
            Host an Event
          </router-link>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/common/Page.vue";
import Section from "@/components/common/Section.vue";
import EventSeriesListPhysical from "@/components/eventSeries/EventSeriesListPhysical.vue";
import { startOfDay } from "date-fns";
import { whereIsFeatured, whereIsExtended, whereIsMarathon, whereIsChapter, whereIsNotChapterOnly } from "@/models/series/predicates.js";
import { formatUTC } from "@/dates";

export default {
  name: "EventsPhysical",
  metaInfo: {
    title: "Writing Groups - "
  },
  components: {
    Page,
    Section,
    EventSeriesListPhysical
  },
  data() {
    return {
      searchTargetName: "",
      maxEvents: 100,
      showingAtATime: 10,
      seriesCount: 0,
      onlyShowFeatured: false,
      onlyShowExtended: false,
      onlyShowMarathon: false,
      formatUTC,
      onlyShowChapters: true
    };
  },
  computed: {
    isChapterMember() {
      return this.$auth.user ? this.$auth.user.chapter_members.length > 0 : false;
    },
    whereClause() {
      return {
        template: {
          original_venue_was_virtual: { _eq: false },
          ...(this.onlyShowFeatured ? whereIsFeatured() : {}),
          ...(this.onlyShowExtended ? whereIsExtended() : {}),
          ...(this.onlyShowMarathon ? whereIsMarathon() : {}),
          ...(this.onlyShowChapters && this.isChapterMember ? whereIsChapter(this.$auth.user.chapter_members[0]) : {}), //only works for one chapter
          ...(!this.onlyShowChapters || !this.isChapterMember ? whereIsNotChapterOnly() : {})
        },
        starts_at: {
          _gte: startOfDay(new Date())
        }
      };
    },
    lastSearch() {
      return this.$route.query ? this.$route.query : null;
    }
  },
  mounted() {
    this.onlyShowChapters = this.isChapterMember;
  },
  methods: {
    onPlaceUpdated(place) {
      this.searchTargetName = place.name;
      this.$router.push({
        name: "events_physical",
        query: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
      });
    },
    onResultsUpdated(_series) {
      this.seriesCount = _series.length;
    },
    selectAny() {
      this.onlyShowFeatured = false;
      this.onlyShowExtended = false;
      this.onlyShowMarathon = false;
    },
    selectFeatured() {
      this.onlyShowFeatured = true;
      this.onlyShowExtended = false;
      this.onlyShowMarathon = false;
    },
    selectExtended() {
      this.onlyShowFeatured = false;
      this.onlyShowExtended = true;
      this.onlyShowMarathon = false;
    },
    selectMarathon() {
      this.onlyShowFeatured = false;
      this.onlyShowExtended = false;
      this.onlyShowMarathon = true;
    },
    selectMyChapters() {
      this.onlyShowChapters = true;
    },
    selectNoChapters() {
      this.onlyShowChapters = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.event-type-dropdown {
  > span {
    @include tablet {
      margin-top: 2px;
    }
  }
  > * {
    @include desktop {
      margin-top: 3px;
    }
  }
}
</style>
