<template>
  <div v-if="testimonials" class="container">
    <b-carousel
      class="testimonial is-flex-grow-1"
      :interval="5000"
      :pause-info="true"
      :indicator-inside="false"
      :indicator-background="false"
    >
      <b-carousel-item
        v-for="testimonial in testimonials"
        :key="testimonial.quote"
        class="card has-text-centered"
      >
        <img :src="testimonial.picture" :alt="testimonial.name + 'headshot'" />
        <div class="quote-container">
          <p>
            {{ testimonial.quote }}
          </p>
          <p class="strong">
            {{ testimonial.name }}
          </p>
        </div>
      </b-carousel-item>
    </b-carousel>
  </div>
</template>
<script>
export default {
  data: () => ({
    testimonials: null,
    testimonialData: {
      results: [
        {
          name: {
            first: "Diana",
            last: "W"
          },
          quote:
            "This is the best way to meet fellow writers, get some work done, and just spend an hour on yourself. It's truly an exercise in self-care for the upcoming writer.",
          picture: "/img/testimonials/diana_w.jpg"
        },
        {
          name: {
            first: "Julie",
            last: "H"
          },
          quote:
            "I've finished four books, three now published. I couldn't have done it without the support and schedule that Shut Up & Write! has provided.",
          picture: "/img/testimonials/julie_h.jpg"
        },
        {
          name: {
            first: "Christina",
            last: "H"
          },
          quote:
            "Shut Up & Write! fuels my creativity and productivity and I've met many wonderful people. I get so much more written in these sessions than I do at home.",
          picture: "/img/testimonials/christina_h.jpg"
        },
        {
          name: {
            first: "Diane",
            last: "B"
          },
          quote:
            "I'm getting a lot of writing done... The people are warm and friendly. This is a fabulous experience.",
          picture: "/img/testimonials/diane_b.jpg"
        }
      ]
    }
  }),
  mounted() {
    this.testimonials = this.testimonialData.results.map(
      ({ name, picture, quote }) => ({
        name: `${name.first} ${name.last[0]}.`,
        picture: picture,
        quote: quote
      })
    );
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.testimonial.carousel {
  @include desktop {
    width: 85%;
    margin: 0 auto;
  }
  .carousel-items {
    @extend %elevation-1;
    @include desktop {
      height: 240px;
    }
    @include mobile {
      box-shadow: none !important;
      $size: vwl(80px);
      padding-top: $size / 1.75;
    }
    @include portrait {
      $size: vwp(80px);
      padding-top: $size / 1.75;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../scss/mixins";
.card {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  height: 100%;
  border-radius: 0.625em;
  @include mobile {
    flex-direction: column;
    box-shadow: none;
    $size: vwl(80px);
    padding-top: $size * 0.8;
    padding-bottom: 15%;
  }
  @include portrait {
    $size: vwp(80px);
    padding-top: $size * 0.8;
    padding-bottom: 10%;
  }
  @include tablet {
    padding: 40px 75px 40px 40px;
  }
  @include desktop {
    padding: 3rem 9rem 3rem 3rem;
  }
  @include widescreen {
    padding-right: 15rem;
  }
  &::before,
  &::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2269%22%20height%3D%2264%22%20viewBox%3D%220%200%2099%2094%22%20fill%3D%22%23ffE7A4%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20class%3D%22right-quote%22%3E%3Cpath%20data-v-31ed560f%3D%22%22%20d%3D%22M41.087%20-5.06292e-06L1.58471e-05%20-8.65485e-06L1.20622e-05%2043.2946L13.6957%2043.2946L7.62939e-06%2094L19.5652%2094L41.087%2043.2946L41.087%20-5.06292e-06ZM99%200L57.9131%20-3.59194e-06L57.913%2043.2946L71.6087%2043.2946L57.913%2094L77.4783%2094L99%2043.2946L99%200Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    width: 70px;
    height: 70px;
    @include mobile {
      width: vwl(40px);
      height: vwl(40px);
    }
    @include portrait {
      width: vwp(40px);
      height: vwp(40px);
    }
    @include desktop {
      width: 80px;
      height: 80px;
    }
  }
  &::before {
    top: 45px;
    transform: rotate(180deg);
    left: 155px;
    top: 15px;
    @include mobile {
      left: vwl(25px);
      top: vwl(15px);
    }
    @include portrait {
      left: vwp(25px);
      top: vwp(15px);
    }
    @include desktop {
      left: 165px;
    }
  }
  &::after {
    bottom: 15px;
    right: 15px;
    @include mobile {
      right: vwl(25px);
      bottom: vwl(15px);
    }
    @include portrait {
      right: vwp(25px);
      bottom: vwp(15px);
    }
  }
  img {
    border-radius: 50%;
    border: 8px solid white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    @include mobile {
      position: absolute;
      border: 6px solid white;
      $size: vwl(90px);
      width: $size;
      height: $size;
      top: -$size / 2;
    }
    @include portrait {
      $size: vwp(90px);
      width: $size;
      height: $size;
      top: -($size / 2);
    }
    @include tablet {
      margin-right: 60px;
    }
    @include desktop {
      $size: 136px;
      width: $size;
      height: $size;
      margin-right: 90px;
    }
  }

  .quote-container {
    @include mobile {
      padding: 0 vwl(60px);
      height: vwl(75px);
    }
    @include portrait {
      padding: 0 vwp(30px);
      height: vwp(75px);
    }
    @include tablet {
      text-align: left;
    }
    p {
      font-size: 1.5em;
      line-height: 1.1;
      @include mobile {
        font-size: vwl(11px);
      }
      @include mobile {
        font-size: vwp(12px);
      }
      &.strong {
        font-weight: 700;
        margin-top: 15px;
      }
    }
  }
}
</style>
