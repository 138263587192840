<template>
  <div>
    <NakedPromptCardViewHeaderDesktop 
      v-if="suwDesktop"
      :prompt="prompt" 
      @delete="onDelete"
      @allow="onAllow"
    />
    <NakedPromptCardViewHeaderMobile 
      v-if="!suwDesktop"
      :prompt="prompt" 
      @delete="onDelete"
      @allow="onAllow"
    />
  </div>
</template>

<script>
import NakedPromptCardViewHeaderDesktop from "@/components/nakedPromptCard/NakedPromptCardViewHeaderDesktop.vue";
import NakedPromptCardViewHeaderMobile from "@/components/nakedPromptCard/NakedPromptCardViewHeaderMobile.vue";

export default {
  name: "NakedPromptCardViewHeader",
  components: {
    NakedPromptCardViewHeaderDesktop,
    NakedPromptCardViewHeaderMobile
  },
  props: {
    prompt: {
      type: Object,
      default: null
    }
  },
  methods: {
    onDelete() {
      this.$emit("delete");
    },
    onAllow() {
      this.$emit("allow");
    }
  }
};
</script>
