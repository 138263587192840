<template>
  <div class="featured-card-container">
    <ChallengeCardViewFeaturedDesktop 
      v-if="!suwMobile"
      :challenge="challenge" 
      :featured-flag="featuredFlag" 
    />
    <ChallengeCardViewFeaturedMobile 
      v-if="suwMobile"
      :challenge="challenge" 
      :featured-flag="featuredFlag" 
    />
  </div>
</template>

<script>
import ChallengeCardViewFeaturedDesktop from "@/components/challengeCard/ChallengeCardViewFeaturedDesktop.vue";
import ChallengeCardViewFeaturedMobile from "@/components/challengeCard/ChallengeCardViewFeaturedMobile.vue";

export default {
  name: "ChallengeCardViewFeatured",
  components: {
    ChallengeCardViewFeaturedDesktop,
    ChallengeCardViewFeaturedMobile
  },
  props: {
    challenge: {
      type: Object,
      default: null
    },
    featuredFlag: {
      type: Number,
      default: 3
    }
  }
};
</script>