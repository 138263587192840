import { getInstance } from "./index";
import { Logger, INFO } from "@/plugins/logging.js";

const $log = new Logger("GUARD>ACCOUNT_FINISHED", { level: INFO });

export default (to, _, next) => {
  $log.debug("checking for provisional login");

  const auth = getInstance();

  const fn = () => {
    if (!auth.isAuthenticated) {
      return next();
    } else if (!auth.user.is_provisional_login) {
      return next();
    } else {
      if (to.name === "login") {
        return next();
      }
      if (to.name === "logout") {
        return next();
      }
      if (to.name === "reset_password") {
        return next();
      }
      if (to.name === "reset_password_token") {
        return next();
      }
      if (to.name === "complete_account") {
        return next();
      }
      if (to.name === "complete_account_token") {
        return next();
      }
      next({ name: "login", query: { targetUrl: to.fullPath } });
    }
  };
  // If loading has already finished, check our auth state using `fn()`
  if (!auth.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  auth.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
