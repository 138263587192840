const challengePromptCardViewHeaderUnlocked = {
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    // values that can be set to simplify human QA of display logic
    qaHasSubmitted: {
      type: String,
      default: ""
    },
    qaHasSubmittedWhen: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        body: null
      }
    };
  },
  computed: {
    isBodyVisible() {
      return !this.hasSubmitted;
    },
    hasSubmitted() {
      if (!this.qaHasSubmitted) {
        return this.prompt.current_user_has_submission;
      }
      return this.qaHasSubmitted === "y";
    },
    hasSubmittedWhen() {
      if (!this.qaHasSubmittedWhen) {
        return this.prompt.submissions_aggregate.aggregate.max.updated_at;
      }
      return new Date(this.qaHasSubmittedWhen);
    }
  },
  created() {
    this.form.body = Object.assign({}, this.prompt.body);
  }
  // methods: {
  //   onEditClick() {
  //     this.$emit("edit");
  //   },
  //   onDeleteClick() {
  //     this.$emit("delete");
  //   }
  // }
}

export default challengePromptCardViewHeaderUnlocked;