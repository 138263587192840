<template>
  <main>
    <SectionContainer>
      <slot />
    </SectionContainer>
  </main>
</template>

<script>
import SectionContainer from "@/components/common/SectionContainer.vue";

export default {
  name: "Page",
  components: {
    SectionContainer
  }
};
</script>