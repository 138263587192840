<template>
  <div class="faq elevation-1">
    <div class="is-flex is-align-items-center">
      <img
        v-if="iconUrl"
        class="mr-2 big-icon"
        width="48px"
        height="48px"
        :src="iconUrl"
      />
      <div
        class="right-side is-flex is-flex-direction-column is-justify-content-flex-start"
      >
        <a class="has-text-dark" @click="isOpenState = !isOpenState">
          <div class="header is-align-items-center">
            <h4>
              <slot name="question">Default Question Slot</slot>
            </h4>
            <b-icon
              class="has-text-grey500"
              :class="{ 'is-open': isOpenState }"
              :icon="'chevron-down'"
              @click="isOpenState = !isOpenState"
            />
          </div>
        </a>
        <div
          class="answer"
          :class="{ 'is-open': isOpenState, 'pt-2 pb-2': isOpenState && !suwMobile }"
        >
          <slot name="answer">Default Answer Slot</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordian",
  props: {
    iconUrl: {
      type: String,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpenState: this.isOpen
    };
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.faq {
  .icon {
    transition: .2s;
    transform: rotate(0deg);
    width: 28px;
    @include mobile {
      width: vwl(24px);
    }
    @include portrait {
      width: vwp(24px);
    }
    > svg {
      width: 100%;
    }
    &.is-open {
      transform: rotate(-180deg);
    }
  }
}
</style>

<style lang="scss" scoped>
.faq {
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey300;
  background: white;
  padding: 1rem;
  @include mobile {
    padding: 0.5em;
  }

  .big-icon {
    align-self: flex-start;
  }

  .right-side {
    flex: 1;
    .header {
      display: flex;
      justify-content: space-between;
      h4 {
        @include mobile {
          flex: 1;
        }
      }
    }

    .answer {
      height: 0px;
      transition: all 0.25s;
      overflow: hidden;
      &.is-open {
        min-height: 64px;
        height: auto;
      }
    }
    a {
      -webkit-tap-highlight-color: transparent;
    }
  }
}
</style>
