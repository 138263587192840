<template>
  <FlyoutButton
    class="join-button"
    ref="joinseries_flyout"
    trigger-button-size="28px"
    :trigger-button-disabled="triggerButtonDisabled"
    :trigger-button-type="`is-success ${value.current_user_is_series_member ? 'is-outlined' : ''} ${cssClass}`"
    :trigger-button-text="triggerButtonText"
    trigger-button-icon=""
    query-string-toggle="series"
    position="is-bottom-left"
    :login-required="true"
    :close-on-click="false"
    :can-close="false"
    :expanded="expanded"
    @active-change="
      active => {
        if (active) {
          startTimer();
        }
      }
    "
  >
    <template #default>
      <b-modal v-model="showTermsModal" @click="closeFlyout">
        <TermsAndConditions />
      </b-modal>
      <div v-if="value.current_user_is_series_member" class="content">
        <p class="is-size-4 has-text-weight-semibold">
          <span v-if="value.template.original_venue_was_virtual"
            >Unsubscribe from</span
          >
          <span v-else>Leave</span>
          {{ value | format_title }}?
        </p>
        <p>You will no longer be notified of new events for this series.</p>
      </div>
      <div v-else>
        <div class="is-flex mb-1">
          <b-checkbox v-model="agreedToTerms" class="m-0"> </b-checkbox>
          I agree to the&nbsp;
          <a @click="showTermsModal = true">Terms and Conditions</a>
        </div>

        <p>You will be notified of upcoming events in this series.</p>
      </div>
      <UndoConfirmButtons
        :confirm-button-type="value.current_user_is_series_member ? 'is-danger' : 'is-success'"
        :confirm-enabled="(agreedToTerms && timerEnded) || value.current_user_is_series_member"
        @undo="closeFlyout"
        @confirm="value.current_user_is_series_member ? onLeave() : onJoin()"
      >
        <template #confirm>
          {{ confirmText }}
        </template>
      </UndoConfirmButtons>
    </template>

    <template #login-required>
      <p class="is-size-4 has-text-weight-semibold">
        An account is required to join an event series.
      </p>
      <p>
        Please login or register for your free account now.
      </p>
    </template>
  </FlyoutButton>
</template>

<script>
import { format_title } from "@/filters";
import {
  OWNER_SERIES_JOIN,
  ADMIN_ORGANIZER_SERIES_JOIN,
  SERIES_LEAVE
} from "@/models/series/operations.gql";
import FlyoutButton from "@/components/common/FlyoutButton.vue";
import TermsAndConditions from "@/components/common/TermsAndConditions.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";

export default {
  name: "SeriesJoinLeave",
  components: {
    FlyoutButton,
    TermsAndConditions,
    UndoConfirmButtons
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    cssClass: {
      type: String
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      agreedToTerms: false,
      timerEnded: false,
      showTermsModal: false,
      timerCount: 0
    };
  },
  computed: {
    triggerButtonDisabled() {
      if (this.value.current_user_is_series_member) {
        if (this.value.current_user_is_organizer) {
          // we can't let them leave unless there's another organizer besides them
          return this.value.template.organizers.length < 2;
        }
      }
      return false;
    },
    triggerButtonText() {
      let msg = "Join Series";
      if (this.value.template.original_venue_was_virtual) {
        msg = this.value.current_user_is_series_member
          ? "Followed"
          : "Follow";
      } else {
        msg = this.value.current_user_is_series_member
          ? "Joined"
          : "Join Series";
      }
      return msg;
    },
    confirmText() {
      let msg = "Join Series";
      if (this.value.template.original_venue_was_virtual) {
        msg = this.value.current_user_is_series_member
          ? "Unfollow"
          : "Follow";
      } else {
        msg = this.value.current_user_is_series_member
          ? "Leave Series"
          : "Join Series";
      }
      return !this.value.current_user_is_series_member && this.timerCount > 0
        ? `(${this.timerCount}) ${msg}`
        : msg;
    }
  },
  watch: {
    timerCount: {
      handler(v) {
        if (v > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerEnded = true;
        }
      }
    }
  },
  methods: {
    onJoin() {
      this.closeFlyout();
      this.$apollo
        .mutate({
          mutation:
            this.$auth.isAdmin || this.$auth.isOrganizer
              ? ADMIN_ORGANIZER_SERIES_JOIN
              : OWNER_SERIES_JOIN,
          variables: {
            // because admins can change user_id it must be set when they do it
            ...(this.$auth.isAdmin || this.$auth.isOrganizer
              ? { user_id: this.$auth.user.id }
              : {}),
            event_template_id: this.value.template.id
          },
          update: cache => {
            cache.evict(cache.identify(this.value));
            cache.gc();
          }
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: `You joined ${format_title(this.value)}`,
            type: "is-primary",
            duration: 3000,
            actionText: "undo",
            onAction: this.onLeave
          });
        });
    },
    onLeave() {
      this.closeFlyout();
      this.$apollo
        .mutate({
          mutation: SERIES_LEAVE,
          variables: {
            event_template_id: this.value.template.id,
            user_id: this.$auth.user.id
          },
          update: cache => {
            cache.evict(cache.identify(this.value));
            cache.gc();
          }
        })
        .then(() => {
          this.$buefy.snackbar.open({
            message: `You left ${format_title(this.value)}`,
            type: "is-success",
            actionText: "Undo",
            onAction: this.onJoin
          });
        });
    },
    userClickedTermsLink() {
      this.showTermsModal = true;
    },
    startTimer() {
      this.timerEnded = false;
      this.timerCount = 5;
    },
    closeFlyout() {
      this.$refs.joinseries_flyout.close();
    }
  }
};
</script>
