const challengePromptCardViewExpandedUnlocked = {
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    isReordering: {
      type: Boolean,
      default: false
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    // values that can be set to simplify human QA of display logic
    qaIsReordering: {
      type: String,
      default: ""
    },
    qaIsAccepted: {
      type: String,
      default: ""
    },
    qaHasSubmitted: {
      type: String,
      default: ""
    },
    qaHasSubmittedWhen: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        body: null
      },
      promptAggregates: [
        {
          name: "user",
          value: this.prompt ? this.prompt.submissions_aggregate.aggregate.user_count : 0,
          type: "is-success is-light",
          icon: "user-check",
          condition: true
        },
        {
          name: "response",
          value: this.prompt ? this.prompt.submissions_aggregate.aggregate.count : 0,
          type: "is-info is-light",
          icon: "file",
          condition: true
        },
        {
          name: "reaction",
          value: this.prompt
            ? this.prompt.submissions_aggregate.nodes.reduce(
                (a, b) => a + b.reactions_aggregate.aggregate.count,
                0
              )
            : 0,
          type: "is-warning is-light",
          icon: "thumbs-up",
          condition: this.prompt ? this.prompt.challenge : false
        }
      ]
    };
  },
  computed: {
    showReordering() {
      if (!this.qaIsReordering) {
        return this.isReordering;
      }
      return this.qaIsReordering === "y";
    },
    isAccepted() {
      if (!this.qaIsAccepted) {
        return this.prompt.current_user_accepted_at !== null;
      }
      return this.qaIsAccepted === "y";
    },
    hasSubmitted() {
      if (!this.qaHasSubmitted) {
        return this.prompt.current_user_has_submission;
      }
      return this.qaHasSubmitted === "y";
    },
    hasSubmittedWhen() {
      if (!this.qaHasSubmittedWhen) {
        return this.prompt.submissions_aggregate.aggregate.max.updated_at;
      }
      return new Date(this.qaHasSubmittedWhen);
    },
    isEllipsesEnabled() {
      return this.$auth.isAdmin;
    }
  },
  created() {
    this.form.body = Object.assign({}, this.prompt.body);
  },
  methods: {
    onPromptCardClick() {
      this.$router.push({
        name: "challenges_prompt",
        params: {
          challenge_slug: this.prompt.challenge.slug,
          prompt_slug: this.prompt.slug,
          prompt_id: this.prompt.id
        }
      });
    },
    onEditClick() {
      document.documentElement.classList.remove("is-clipped-touch");
      this.$emit("edit");
    },
    onDeleteClick() {
      this.$emit("delete");
    }
  }
}

export default challengePromptCardViewExpandedUnlocked;