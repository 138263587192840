<template>
  <div class="expanded-card is-flex">
    <div class="is-center is-flex is-flex-direction-column is-flex-grow-1">
      <div class="is-flex is-flex-direction-column">
        <div>
          <div class="is-flex-grow-1">
            <b-input v-model.trim="form.title" class="is-hidden" />
            <TipTap
              v-model="form.body"
              :show-edit-controls="true"
              :editable="true"
              :confirm-enabled="() => !$v.form.$invalid"
              :type="$v.form.body.required ? '' : 'is-danger'"
              :message="$v.form.body.required ? '' : 'Submission body is required.'"
              @edit-cancelled="onCancel"
              @edit-confirmed="onConfirm"
              @input="onDraft"
            >
              <template #confirm>
                <span v-if="submission !== null">
                  Confirm Edit
                </span>
                <span v-else>
                  Submit
                </span>
              </template>
            </TipTap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import submissionCardEdit from "@/components/submissionCard/SubmissionCardEdit.js";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "SubmissionCardEditMobile",
  mixins: [submissionCardEdit],
  components: {
    TipTap
  }
};
</script>
