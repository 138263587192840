<template>
  <div class="unlocked card is-hover-grow">
    <div class="challenge-order" @click="onPromptCardClick">
      {{ challengeOrder }}
    </div>
    <div class="card-info">
      <div class="card-details" @click="onPromptCardClick">
        <h2 v-if="isAccepted">
          {{ prompt.title }}
        </h2>
        <p v-if="!hasSubmitted">
          {{ prompt.body_text | truncate(330) }}
        </p>
        <TipTap v-else v-model="form.body" :is-preview="true" />
      </div>
      <div class="card-actions">
        <h6 v-if="isAccepted" class="timestamp">
          <template v-if="hasSubmitted">
            Completed {{ hasSubmittedWhen | formatFromNow }}
            <b-icon size="is-medium" type="is-success" icon="check-circle" />
          </template>
          <template v-else>
            Unlocked {{ prompt.available_after | formatFromNow }}
            <b-icon size="is-medium" icon="check-circle" />
          </template>
        </h6>
        <b-taglist v-if="!showReordering" class="is-justify-content-flex-end is-flex-wrap-nowrap">
          <b-tag
            v-for="aggregate in promptAggregates"
            v-show="aggregate.condition"
            :key="aggregate.name"
            class="has-text-grey-dark"
            :type="isAccepted ? aggregate.type : ''"
          >
            <b-icon :icon="aggregate.icon" />
            <span>{{ aggregate.value }}</span>
          </b-tag>
          <!-- only admins can create so no moderation or administration needed -->
          <b-dropdown v-if="isEllipsesEnabled" ref="dropdown" aria-role="list" position="is-top-left">
            <template #trigger>
              <b-button type="is-ghost is-small has-text-grey-dark" icon-left="ellipsis-h" />
            </template>
            <!-- delete -->
            <b-dropdown-item
              class="px-0 button is-ghost is-flex is-align-items-center"
              @click="onDeleteClick"
            >
              Delete
            </b-dropdown-item>
            <!-- edit -->
            <b-dropdown-item
              class="px-0 button is-ghost is-flex is-align-items-center"
              @click="onEditClick"
            >
              Edit
            </b-dropdown-item>
          </b-dropdown>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
import challengePromptCardViewExpandedUnlocked from "@/components/challengePromptCard/ChallengePromptCardViewExpandedUnlocked.js";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "ChallengePromptCardViewExpandedUnlocked",
  components: {
    TipTap
  },
  mixins: [challengePromptCardViewExpandedUnlocked],
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    isReordering: {
      type: Boolean,
      default: false
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    // values that can be set to simplify human QA of display logic
    qaIsReordering: {
      type: String,
      default: ""
    },
    qaIsAccepted: {
      type: String,
      default: ""
    },
    qaHasSubmitted: {
      type: String,
      default: ""
    },
    qaHasSubmittedWhen: {
      type: String,
      default: ""
    }
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onDelete() {
      this.$emit("delete");
    }
  }
};
</script>