<template>
  <div v-if="event" class="event-container" :class="{ 'in-person': !isVirtual, 'online': isVirtual, 'is-editing': isEditing }">
    <div>
      <EventReviewPhysical v-if="isReviewing && !isVirtual" :event="event" :form="form" />
      <EventReviewVirtual v-if="isReviewing && isVirtual" :event="event" :form="form" />

      <VenueCardPhysicalDesktop 
        v-if="!suwMobile && !isReviewing && !isVirtual"
        :class="{ 'is-editing': isEditing }"
        ref="venPhysicalDesktop"
        v-model="form.venue"
        :is-editing="isEditing"
        :hide-form="shouldHideVenueForm"
        :show-venue-location="showVenueLocationPhysical"
        @invalid="onInvalidChanged"
      >
        <template #top-corner>
          <EventCornerEditDesktop
            v-if="isEditing" 
            v-model="form" 
            :event="event" 
            :is-virtual="isVirtual" 
            @virtualChanged="onVirtualChanged" 
          />
          <EventCornerViewDesktop 
            v-else
            :event="event" 
          />
        </template>
      </VenueCardPhysicalDesktop>
      <VenueCardPhysicalMobile 
        v-if="suwMobile && !isReviewing && !isVirtual"
        ref="venPhysicalMobile"
        v-model="form.venue"
        :is-editing="isEditing"
        :hide-form="shouldHideVenueForm"
        :show-venue-location="showVenueLocationPhysical"
        @invalid="onInvalidChanged"
      >
        <template #top-corner>
          <EventCornerEditMobile 
            v-if="isEditing" 
            v-model="form" 
            :event="event" 
            :is-virtual="isVirtual" 
            @virtualChanged="onVirtualChanged" 
          />
          <EventCornerViewMobile 
            v-else
            :event="event" 
          />
        </template>
      </VenueCardPhysicalMobile>      
      <VenueCardVirtualDesktop 
        v-if="suwDesktop && !isReviewing && isVirtual"
        ref="venVirtualDesktop"
        v-model="form.venue"
        :is-editing="isEditing"
        :hide-form="shouldHideVenueForm"
        :show-venue-location="showVenueLocationVirtual"
        @invalid="onInvalidChanged"
      >
        <template #top-corner>
          <EventCornerEditDesktop 
            v-if="isEditing" 
            v-model="form" 
            :event="event" 
            :is-virtual="isVirtual" 
            @virtualChanged="onVirtualChanged" 
          />
          <EventCornerViewDesktop 
            v-else
            :event="event"
          />
        </template>
      </VenueCardVirtualDesktop>
      <VenueCardVirtualMobile 
        v-if="!suwDesktop && !isReviewing && isVirtual"
        ref="venVirtualMobile"
        v-model="form.venue"
        :is-editing="isEditing"
        :hide-form="shouldHideVenueForm"
        :show-venue-location="showVenueLocationVirtual"
        @invalid="onInvalidChanged"
      >
        <template #top-corner>
          <EventCornerEditMobile 
            v-if="isEditing" 
            v-model="form" 
            :event="event" 
            :is-virtual="isVirtual" 
            @virtualChanged="onVirtualChanged" 
          />
          <EventCornerViewMobile 
            v-else
            :event="event" 
          />
        </template>
      </VenueCardVirtualMobile>  
      
      <div v-if="event && event.template.original_venue_was_virtual" class="events-container">
        <h3>Event Attendees</h3>
        <Participants class="m-2" :event="event" kind="attendees" />
      </div>

      <UndoConfirmButtons
        v-if="isEditing"
        class="events-container"
        :confirm-enabled="!invalidVenue"
        confirm-button-type="is-success"
        @undo="onUndo"
        @confirm="onConfirm"
      >
        <template #undo>
          <span v-if="isReviewing">Make Changes</span>
          <span v-else>Cancel Changes</span>
        </template>
        <template #confirm>
          <span v-if="isReviewing">Confirm Changes</span>
          <span v-else>Review Changes</span>
        </template>
      </UndoConfirmButtons>
      <div 
        v-else-if="userCanEditEvent"
        class="edit-cancel-buttons button-row"
        :class="{ 'column': suwMobile, 'in-person': !isVirtual }"
      >
        <b-button type="has-text-info elevation-1" @click="isEditing = true">
          Edit Event
        </b-button>
        <CancelCard
          v-if="$auth.isAdmin || event.current_user_is_organizer"
          :event="event"
          cancel-type="event"
          position="is-top-left"
          class="is-danger is-outlined"
        />
      </div>
    </div>
    <EventNavigator :event="event" />
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { toLocalDate } from "@/dates";
import { user_name, truncate_render } from "@/filters.js";
import { UPDATE_SINGLE_EVENT, UPDATE_SINGLE_EVENT_AND_VENUE } from "@/models/events/operations.gql";
import { RAW_EVENT_FIELDS, EVENT_FIELDS } from "@/models/events/fragments.gql";
import { VENUE_FIELDS } from "@/models/venues/fragments.gql";
import { CREATE_VENUE } from "@/models/venues/operations.gql";
import VenueCardPhysicalDesktop from "@/components/venueCard/VenueCardPhysicalDesktop.vue";
import VenueCardPhysicalMobile from "@/components/venueCard/VenueCardPhysicalMobile.vue";
import VenueCardVirtualDesktop from "@/components/venueCard/VenueCardVirtualDesktop.vue";
import VenueCardVirtualMobile from "@/components/venueCard/VenueCardVirtualMobile.vue";
import EventReviewPhysical from "@/components/eventBody/EventReviewPhysical.vue";
import EventReviewVirtual from "@/components/eventBody/EventReviewVirtual.vue";
import EventNavigator from "@/components/events/EventNavigator.vue";
import Participants from "@/components/events/Participants.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import CancelCard from "@/components/events/CancelCard.vue";
import EventCornerEditDesktop from "@/components/eventBody/EventCornerEditDesktop.vue";
import EventCornerEditMobile from "@/components/eventBody/EventCornerEditMobile.vue";
import EventCornerViewDesktop from "@/components/eventBody/EventCornerViewDesktop.vue";
import EventCornerViewMobile from "@/components/eventBody/EventCornerViewMobile.vue";

export default {
  name: "EventBody",
  metaInfo() {
    return this.eventStructuredData;
  },
  components: {
    Participants,
    UndoConfirmButtons,
    VenueCardPhysicalDesktop,
    VenueCardPhysicalMobile,
    VenueCardVirtualDesktop,
    VenueCardVirtualMobile,
    EventReviewPhysical,
    EventReviewVirtual,
    EventNavigator,
    CancelCard,
    EventCornerEditDesktop,
    EventCornerEditMobile,
    EventCornerViewDesktop,
    EventCornerViewMobile
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      isReviewing: false,
      isVirtual: this.event.venue.is_virtual,
      editConfirmEnabled: true,
      form: this.buildForm(this.event),
      invalidVenue: true
    };
  },
  computed: {
    userCanEditEvent() {
      return (
        !this.event.has_occurred &&
        !this.event.deleted_at &&
        !this.event.cancelled_by_user_at &&
        !this.event.cancelled_by_materializer_at &&
        (this.$auth.isAdmin || this.event.current_user_is_organizer)
      );
    },
    shouldHideVenueForm() {
      return false;
    },
    venueStrikeThru() {
      return this.event.venue.street_address !== this.form.venue.street_address
        ? this.event.venue.street_address
        : "";
    },
    eventStartsAtToBeUpdated() {
      // Only update events.starts_at if these conditions apply
      return (this.event.starts_at === null && !isEqual(toLocalDate(this.form.starts_at), toLocalDate(this.event.scheduled_starts_at)))
        || this.event.starts_at !== null && !isEqual(toLocalDate(this.event.starts_at), toLocalDate(this.form.starts_at));
    },
    showVenueLocationPhysical() {
      return this.event.current_user_is_series_member || this.event.current_user_is_organizer;
    },
    showVenueLocationVirtual() {
      return this.event.current_user_is_rsvpd || this.event.current_user_is_organizer;
    },
    eventStructuredData() {
      if (!this.event) {
        return null;
      }
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "Event",
              name: this.event.title,
              startDate: this.event.starts_at || this.event.scheduled_starts_at,
              endDate: this.event.ends_at,
              eventStatus:
                this.event.cancelled_by_materializer_at || this.event.cancelled_by_user_at
                  ? "https://schema.org/EventCancelled"
                  : "https://schema.org/EventScheduled", //EventMovedOnline and EventRescheduled sound fun to implement...but just these two for now
              eventAttendanceMode: this.event.venue.isVirtual
                ? "https://schema.org/OnlineEventAttendanceMode"
                : "https://schema.org/OfflineEventAttendanceMode",
              location: this.event.venue.is_virtual
                ? {
                    "@type": "VirtualLocation",
                    url: this.event.venue.url
                  }
                : {
                    "@type": "Place",
                    //name: //can't do this since sometimes we have just a city name and it's not a city-wide event (and we don't current store the difference)
                    address: {
                      "@type": "PostalAddress",
                      streetAddress: this.event.venue.street_address
                    }
                  },
              description: truncate_render(this.event.template.description_text), //event.description_text is usually blank since it is not actually NULL in the database and doesn't coalesce
              organizer: {
                "@type": "Organization",
                name: user_name(this.event.template.organizers[0].user)
              }
            }
          }
        ]
      };
    }
  },
  watch: {
    event(newEvent) {
      // user probably hit next/prev event arrows, ensure we reset the form to the new event
      this.isEditing = false;
      this.form = this.buildForm(newEvent);
      this.isVirtual = newEvent.venue.is_virtual;
    }
  },
  methods: {
    toLocalDate,
    buildForm(event) {
      const form = {
        description: event.description,
        starts_at: toLocalDate(event.starts_at || event.scheduled_starts_at),
        scheduled_starts_at: toLocalDate(event.scheduled_starts_at),
        venue_id: event.venue_id,
        venue: event.venue,
        template: event.template
      };
      return form;
    },
    prepVenueForm(form, ignoredFields) {
      return Object.fromEntries(
        Object.entries(form).filter(
          ([key]) => ignoredFields.indexOf(key) === -1
        )
      );
    },
    async cloneVenue() {
      /*
      Event editing is confounded with materializer issues. When the materializer makes a new
      event in a series, it leaves `venue_id` on the event NULL. Which means "use the series event"
      when a user wants to override the series venue, we have to make a new venue for that event and
      then update the event's venue_id to point to it. If this has already happened, then we need to
      detect that, and apply changes to both the event and venue as needed.
      */

      // first see if this event has its own venue or is tied to the series venue
      console.log("------ CLONE VENUE ------ this", this);

      return this.$apollo.mutate({
        mutation: CREATE_VENUE,
        variables: {
          venue: this.prepVenueForm(this.form.venue, [
            "__typename",
            "id",
            "created_at",
            "updated_at",
            "deleted_at"
          ])
        },
        error() {
          this.$log.error(
            "couldn't create new venue for event, aborting event edit..."
          );
          return null;
        },
        update: (cache, { data: result }) => {
          this.$log.info("created new venue for 1-off event", result, cache);
          return result;
        }
      });
    },
    async onConfirm() {
      console.log("------ ON CONFIRM ------ this:", this);
      // first see if they've reviewed changes yet, if not, set up review and bail out
      if (!this.isReviewing) {
        this.isReviewing = true;
        this.editConfirmEnabled = true;
        return;
      }
      this.editConfirmEnabled = false;
      // build the arguments for the mutation on the event, but don't include
      // venue_id unless we need to make a new venue for this event (because
      // it doesn't have one)
      const event_changes = {
        description: this.form.description,
        starts_at: this.form.starts_at
      };

      let mutation = UPDATE_SINGLE_EVENT;

      // now we need to check if they changed the venue at all. We don't want to make new venue
      // in the system for non-venue related changes as it will disconnect this event from
      // its parent series' venue.
      if (!isEqual(this.form.venue, this.event.venue) || !isEqual(this.form.venue.is_virtual, this.isVirtual)) {
        this.$log.debug("venue form was changed form:", this.form.venue);
        mutation = UPDATE_SINGLE_EVENT_AND_VENUE;
        // they changed the venue in the form. does this event already have its own venue?
        if (this.event.venue.id === this.event.template.venue.id) {
          this.$log.debug("venue must be cloned");
          // no, we need to create one as a copy of the series venue and bind it to this event
          const { data: { result } } = await this.cloneVenue(this, this.event.template.venue);
          if (result && result.id) {
            event_changes.venue_id = result.id;
            this.$log.warn(
              "cloned series venue as new venue_id",
              event_changes.venue_id
            );
          }
        } else {
          this.$log.debug("venue is already separate from series");
        }
      }

      var v_changes = {
        ...this.form.venue,
        is_virtual: this.isVirtual
      };

      this.$apollo
        .mutate({
          mutation,
          variables: {
            event_id: this.event.id,
            event_changes,
            ...(mutation === UPDATE_SINGLE_EVENT_AND_VENUE
              ? {
                  venue_id: event_changes.venue_id || this.event.venue.id,
                  venue_changes: this.prepVenueForm(v_changes, [
                    "__typename",
                    "id",
                    "created_at",
                    "updated_at"
                  ])
                }
              : {})
          },
          update: (cache, { data }) => {
            if (data.event_result) {
              this.$log.info("event result", data.event_result);

              // update any raw event objects we have
              cache.writeFragment({
                fragment: RAW_EVENT_FIELDS,
                fragmentName: "RAW_EVENT_FIELDS",
                data: data.event_result
              });

              // also update any event_with_computed_fields objects we have for this event (result of get_events call)
              let frag = { ...data.event_result };
              frag.__typename = "event_with_computed_fields";
              cache.writeFragment({
                fragment: EVENT_FIELDS,
                fragmentName: "EVENT_FIELDS",
                data: frag
              });
            }
            if (data.venue_result) {
              this.$log.info("venue result", data.venue_result);
              cache.writeFragment({
                fragment: VENUE_FIELDS,
                fragmentName: "VENUE_FIELDS",
                data: data.venue_result
              });
            }

            // TODO: update any event_with_computed_fields we have
            this.$log.info("in after update");
            if (data.event_result) {
              return data.event_result
            }
          }
        })
        .then(() => {
          this.editConfirmEnabled = true;
          this.isEditing = false;
          this.isReviewing = false;
          this.$emit("updated");
          this.$buefy.snackbar.open({
            message: "Event updated",
            type: "is-success"
          });
        });
    },
    onUndo() {
      if (this.isReviewing) {
        this.isReviewing = false;
        this.editConfirmEnabled = true;
      } else {
        this.isEditing = false;
        this.editConfirmEnabled = true;
        this.isVirtual = this.event.venue.is_virtual;
      }
    },
    onVirtualChanged(yesno) {
      this.isVirtual = yesno;
    },
    onInvalidChanged(yesno) {
      this.invalidVenue = yesno;
    }
  }
};
</script>

<style lang="scss">
.event-container {
  position: relative;
  @include mobile {
    background: #fff;
    margin-top: 1.5rem;
    padding-bottom: .5rem;
  }
  .disabled-overlay {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;
    background: rgba($grey500, 60%);
  }
  .field {
    margin-top: 1.25rem;
    @include mobile {
      margin-top: .50rem;
    }
  }
  &.in-person {
    &.is-editing {
      .undo-confirm-buttons {
        @include desktop {
          position: absolute;
          left: calc(58.333333% + 20px);
          bottom: 1.5rem;
        }
      }
    }
  }
}
.button-row {
  display: flex;
  column-gap: 1rem;
  @include mobile {
    > .button, .dropdown {
      width: 50%;
    }
    .dropdown {
      > div, .button {
        width: 100%;
      }
    }
  }
}
</style>
