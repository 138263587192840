<template>
  <div
    class="elevation-1 boxed seriescard is-hover-grow"
    :class="{
      'is-active': isActive
    }"
    @click="onSeriesCardClick"
  >
    <DateSquare
      :d="toLocalDate(event.starts_at || event.scheduled_starts_at)"
      :is-featured="event.series.featured_at !== null"
      class="mr-3"
      size="medium"
    />
    <div class="event-info">
      <div class="event-title">
        <h2>
          {{ event | format_title | truncate_render }}
          <badge
            v-if="event.series.featured_at !== null"
            data-type="featured"
            icon="flag"
            label="Featured"
          />
          <badge
            v-if="durationName === 'extended'"
            data-type="extended"
            icon="arrows-alt-h"
            label="Extended"
          />
          <badge
            v-if="durationName === 'marathon'"
            data-type="marathon"
            icon="running"
            label="Marathon"
          />
          <badge
            v-if="seriesIsUpdated"
            data-type="updated"
            label="Updated"
          />
        </h2>        
        <div class="is-flex is-align-items-center">
          <div class="is-size-7 has-text-grey">Hosted by</div>
          <UserPill
            v-if="hostUser"
            class="ml-1"
            size="small"
            :user="hostUser"
            :link-to-profile="false"
          />
        </div>
      </div>

      <div class="details">
        <div
          v-if="event.series.venue"
          class="is-flex is-flex-wrap-wrap is-align-items-center is-flex-grow-0"
        >
          <Pill icon="clock" icon-type="is-info" class="mr-2 is-dark">
            {{
              (event.starts_at || event.scheduled_starts_at) | format("h:mmaaa")
            }}
            -
            {{ event.ends_at | format("h:mmaaa") }}
            <span class="tz">
              {{
                (event.starts_at || event.scheduled_starts_at) | format("zzz")
              }}
            </span>
          </Pill>

          <Pill
            class="mr-2"
            :class="{
              'is-success':
                event.series.venue.is_virtual && !event.series.original_venue_was_virtual
            }"
            :icon="event.series.venue.is_virtual ? 'laptop' : 'map-marker-alt'"
            :icon-pack="event.series.venue.is_virtual ? 'fas' : 'far'"
          >
            <span v-if="!event.series.original_venue_was_virtual">
              <span v-if="event.series.venue.is_virtual">
                Currently Meets Online
              </span>
              <span v-else>
                {{ venueCityState }}
              </span>
            </span>
            <span v-else> Online</span>
          </Pill>
           <Pill
            class="mr-2"
            :class="{
              'is-success':
                event.series.venue.is_virtual && !event.series.original_venue_was_virtual
            }"
            icon="globe"
          >
          English
          </Pill>
        </div>
        <b-tag type="is-success is-light">
          <b-icon icon="user-check" />
          <span>{{ event.series.members_aggregate.aggregate.count || "0" }}</span>
        </b-tag>
      </div>
    </div>
  </div>
</template>

<script>
import eventSeriesCard from "@/components/eventSeries/EventSeriesCard.js";
import DateSquare from "@/components/dates/DateSquare.vue";
import Pill from "@/components/common/Pill.vue";
import UserPill from "@/components/common/UserPill.vue";
import Badge from "@/components/common/Badge.vue";

export default {
  name: "EventSeriesCardDesktop",
  components: {
    DateSquare,
    Pill,
    UserPill,
    Badge
  },
  mixins: [eventSeriesCard]
};
</script>

<style lang="scss" scoped>
.date {
  width: 120px;
  height: 120px;
  border-radius: 2px;
  background: $background;
  border: 1px solid $border;
  flex-grow: 0;
  flex-shrink: 0;
}
.seriescard {
  display: flex;
  flex-direction: row;
  @include desktop {
    padding: 1.25rem;
  }
  &.is-active {
    background: $warning-light;
  }
 
  .details {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .pill {
      margin-bottom: 0;
    }
  }
}
.tz {
  padding: 2px;
  font-size: 11px;
  background-color: white;
  border-radius: 5px;
  margin-left: 5px;
}
a {
  color: inherit;
}
</style>
