<template>
  <div>
    <div>
      <TipTap
        v-model="form.body"
        class="is-size-5"
        show-edit-controls
        editable
        :confirm-enabled="() => !$v.form.$invalid"
        :type="$v.form.body.required ? '' : 'is-danger'"
        :message="$v.form.body.required ? '' : 'Prompt body is required.'"
        @edit-cancelled="onCancel"
        @edit-confirmed="onConfirm"
        @input="onDraft"
      >
        <template #confirm>
          <span v-if="prompt !== null">
            Confirm Edit
          </span>
          <span v-else>
            Create Prompt
          </span>
        </template>
      </TipTap>
    </div>

    <div class="is-center is-flex is-justify-content-space-between">
      <div v-if="prompt !== null" class="pill mt-2 is-flex is-justify-content-space-between is-align-items-center">
        <InlineDate :d="prompt.created_at" class="has-text-grey400" />
      </div>
      <div class="is-align-self-flex-end">
      </div>
    </div>
  </div>
</template>

<script>
import nakedPromptCardEdit from "@/components/nakedPromptCard/NakedPromptCardEdit.js";
import InlineDate from "@/components/common/InlineDate.vue";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "NakedPromptCardEditDesktop",
  mixins: [nakedPromptCardEdit],
  components: {
    InlineDate,
    TipTap
  }
};
</script>
