<!-- eslint-disable prettier/prettier -->
<template>
  <section class="section container">
    <div v-if="$apollo.loading">
      Loading...
    </div>
    <div v-else>

      <div v-if="isViewHeaderLocked && !isEdit">
        <div class="level">
          <div class="level-left">
            <a class="a-bold-green" @click="setEdit()">View</a>
            &nbsp;|&nbsp;
            <a class="a-bold-green" @click="setViewExpandedLocked()">Header</a>
            &nbsp;|&nbsp;
            <a class="a-bold-green" @click="setViewHeaderUnlocked()">Locked</a>
          </div>
          <div class="level-right"></div>
        </div>
        <ChallengePromptCardCoordinator
          key="isViewHeaderLocked"
          class="my-2"
          :prompt="prompt"
          is-header
          :challenge-order="0"
          qa-is-available="n"
          qa-is-accepted="n"
        />
      </div>

      <div v-if="isViewHeaderUnlocked && !isEdit">
        <div class="level">
          <div class="level-left">
            <a class="a-bold-green" @click="setEdit()">View</a>
            &nbsp;|&nbsp;
            <a class="a-bold-green" @click="setViewExpandedUnlocked()">Header</a>
            &nbsp;|&nbsp;
            <a class="a-bold-blue" @click="setViewHeaderLocked()">Unlocked</a>
          </div>
          <div class="level-right">
            <a :class="qaNone ? 'a-bold' : 'a-weak'" @click="qaNone = !qaNone; qaHasSubmitted = false">NONE</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaHasSubmitted ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaHasSubmitted = !qaHasSubmitted">qa-has-submitted</a>
            </div>
        </div>
        <ChallengePromptCardCoordinator
          key="isViewHeaderUnlocked"
          class="my-2"
          :prompt="prompt"
          is-header
          qa-is-available="y"
          qa-is-accepted="y"
          :challenge-order="0"
          :qa-has-submitted="!qaNone && qaHasSubmitted ? 'y' : 'n'"
          :qa-has-submitted-when="!qaNone && qaHasSubmitted ? '6/6/2022' : ''"
        />
      </div>      

      <div v-if="isViewExpandedLocked && !isEdit">
        <div class="level">
          <div class="level-left">
            <a class="a-bold-green" @click="setEdit()">View</a>
            &nbsp;|&nbsp;
            <a class="a-bold-blue" @click="setViewHeaderLocked()">Expanded</a>
            &nbsp;|&nbsp;
            <a class="a-bold-green" @click="setViewExpandedUnlocked()">Locked</a>
          </div>
          <div class="level-right">
            <a :class="qaNone ? 'a-bold' : 'a-weak'" @click="qaNone = !qaNone; qaIsReordering = false; qaIsAvailable = false">NONE</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaIsReordering ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaIsReordering = !qaIsReordering">qa-is-reordering</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaIsAvailable ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaIsAvailable = !qaIsAvailable">qa-is-available</a>
          </div>
        </div>
        <ChallengePromptCardCoordinator
          key="isViewExpandedLocked"
          class="my-2"
          :prompt="prompt"
          :challenge-order="0"
          :qa-is-reordering="!qaNone && qaIsReordering ? 'y' : 'n'"
          :qa-is-available="!qaNone && qaIsAvailable ? 'y' : 'n'"
          qa-is-accepted="n"
        />
      </div>

      <div v-if="isViewExpandedUnlocked && !isEdit">
        <div class="level">
          <div class="level-left">
            <a class="a-bold-green" @click="setEdit()">View</a>
            &nbsp;|&nbsp;
            <a class="a-bold-blue" @click="setViewHeaderUnlocked()">Expanded</a>
            &nbsp;|&nbsp;
            <a class="a-bold-blue" @click="setViewExpandedLocked()">Unlocked</a>
          </div>
          <div class="level-right">
            <a :class="qaNone ? 'a-bold' : 'a-weak'" @click="qaNone = !qaNone; qaIsReordering = false; qaHasSubmitted = false">NONE</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaIsReordering ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaIsReordering = !qaIsReordering">qa-is-reordering</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaHasSubmitted ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaHasSubmitted = !qaHasSubmitted">qa-has-submitted</a>
          </div>
        </div>
        <ChallengePromptCardCoordinator
          key="isViewExpandedUnlocked"
          class="my-2"
          :prompt="prompt"
          :challenge-order="0"
          qa-is-available="y"
          qa-is-accepted="y"
          :qa-is-reordering="!qaNone && qaIsReordering ? 'y' : 'n'"
          :qa-has-submitted="!qaNone && qaHasSubmitted ? 'y' : 'n'"
          :qa-has-submitted-when="!qaNone && qaHasSubmitted ? '6/6/2022' : ''"
        />
      </div>

      <div v-if="isEdit">
        <div class="level">
          <div class="level-left">
            <a class="a-bold-blue" @click="unsetEdit()">Edit</a>
          </div>
          <div class="level-right">
            <a :class="qaNone ? 'a-bold' : 'a-weak'" @click="qaNone = !qaNone; qaIsAvailable = false; qaIsAccepted = false; qaHasSubmitted = false">NONE</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaIsAvailable ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaIsAvailable = !qaIsAvailable">qa-is-available</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaIsAccepted ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaIsAccepted = !qaIsAccepted">qa-is-accepted</a>
            &nbsp;|&nbsp;
            <a :class="qaNone ? 'a-weak' : qaHasSubmitted ? 'a-bold' : 'a-strike'" @click="qaNone = false; qaHasSubmitted = !qaHasSubmitted">qa-has-submitted</a>
          </div>
        </div>
        <ChallengePromptCardCoordinator
          key="isEdit"
          class="my-2"
          :prompt="prompt"
          :challenge-order="0"
          qa-edit="y"
          :qa-is-reordering="!qaNone && qaIsReordering ? 'y' : 'n'"
          :qa-is-available="!qaNone && qaIsAvailable ? 'y' : 'n'"
          :qa-is-accepted="!qaNone && qaIsAccepted ? 'y' : 'n'"
          :qa-has-submitted="!qaNone && qaHasSubmitted ? 'y' : 'n'"
        />
      </div>

    </div>
  </section>
</template>

<script>
import { GET_PROMPT_BY_PK } from "@/models/challenges/operations.gql";

import ChallengePromptCardCoordinator from "@/components/challengePromptCard/ChallengePromptCardCoordinator.vue";

export default {
  name: "ChallengePromptDetailQA",
  components: {
    ChallengePromptCardCoordinator
  },
  data() {
    return {
      prompt: null,
      isViewHeaderLocked: false,
      isViewHeaderUnlocked: false,
      isViewExpandedLocked: false,
      isViewExpandedUnlocked: false,
      isEdit: false,
      qaIsAvailable: false,
      qaHasAubmitted: false,
      qaIsReordering: false,
      qaIsAccepted: false,
      qaNone: true
    };
  },
  created() {
    this.setViewHeaderLocked();
  },
  methods: {
    reset() {
      this.isViewHeaderLocked = false;
      this.isViewHeaderUnlocked = false;
      this.isViewExpandedLocked = false;
      this.isViewExpandedUnlocked = false;
      this.isEdit = false;
      this.qaIsAvailable = false;
      this.qaHasSubmitted = false;
      this.qaIsReordering = false;
      this.qaIsAccepted = false;
      this.qaNone = true;
    },
    setViewHeaderLocked() {
      this.$log.info("setViewHeaderLocked");
      this.reset();
      this.isViewHeaderLocked = true;
    },
    setViewHeaderUnlocked() {
      this.$log.info("setViewHeaderUnlocked");
      this.reset();
      this.isViewHeaderUnlocked = true;
    },
    setViewExpandedLocked() {
      this.$log.info("setViewExpandedLocked");
      this.reset();
      this.isViewExpandedLocked = true;
    },
    setViewExpandedUnlocked() {
      this.$log.info("setViewExpandedUnlocked");
      this.reset();
      this.isViewExpandedUnlocked = true;
    },
    setEdit() {
      this.isEdit = true;
    },
    unsetEdit() {
      this.isEdit = false;
    }
  },
  apollo: {
    prompt: {
      query: GET_PROMPT_BY_PK,
      variables() {
        return {
          prompt_id: this.$route.params.prompt_id
        };
      },
      error() {
        this.display404Page();
      },
      update({ prompt }) {
        this.$log.debug("in update", prompt);
        const p = prompt[0] || null;
        return p || this.display404Page();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hr-red {
  background-color: $red;
}
.hr-green {
  background-color: $green;
}
.hr-blue {
  background-color: $blue;
}
.b-red {
  color: $red;
}
.b-green {
  color: $green;
}
.b-blue {
  color: $blue;
}
.a-bold-blue {
  color: $blue;
  font-weight: bolder;
}
.a-bold-green {
  color: $green;
  font-weight: bolder;
}
.a-bold {
  color: $black;
  font-weight: bolder;
}
.a-weak {
  color: $black;
}
.a-strike {
  color: $black;
  text-decoration: line-through;
}
</style>
