<template>
  <div
    v-if="!isHidden"
    v-on-clickaway="hideDropdown"
    class="dropdown"
    :class="{ 'is-active': isActive }"
  >
    <div class="dropdown-trigger">
      <b-button class="auth-button" v-bind="$attrs" @click="handleClick" />
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <slot name="dropdown" :login="login"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

const validateOption = val => ["allow", "disable", "hide"].includes(val);

export default {
  mixins: [clickaway],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    app_admin: {
      type: String,
      validator: validateOption,
      default: "allow"
    },
    organizer: {
      type: String,
      validator: validateOption,
      default: "allow"
    },
    owner: {
      type: String,
      validator: validateOption,
      default: "allow"
    },
    public: {
      type: String,
      validator: validateOption,
      default: "disable"
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    userRole() {
      return this.$auth.isAuthenticated ? this.$auth.user.role : "public";
    },
    isAllowed() {
      return this.$props[this.userRole] === "allow";
    },
    isHidden() {
      return this.$props[this.userRole] === "hide";
    },
    isDisabled() {
      // Disabled means that we will show the user
      // the dropdown when button is clicked
      return this.$props[this.userRole] === "disable";
    }
  },
  methods: {
    hideDropdown() {
      this.isActive = false;
    },
    handleClick(event) {
      if (this.isAllowed && !this.isDisabled) {
        this.$emit("click", event);
      } else {
        this.isActive = !this.isActive;
      }
    },
    login() {
      this.$log.info("redirecting to login");
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  min-width: 400px;
}
// testing rounded border-image
// https://stackoverflow.com/questions/5706963/possible-to-use-border-radius-together-with-a-border-image-which-has-a-gradient
// .auth-button {
//   width: 300px;
//   height: 80px;
//   border: double 1em transparent;
//   border-radius: 30px;
//   background-image: linear-gradient(white, white),
//                     linear-gradient(to right, green, gold);
//   background-origin: border-box;
//   background-clip: content-box, border-box;
// }
</style>
