export const isNotDeleted = () => ({
  deleted_at: { _is_null: true }
});

export const isNotDraft = () => ({
  is_draft: { _eq: false }
});

export const isStandalonePrompt = () => ({
  challenge_id: { _is_null: true }
});

export const whereCurrentUserAuthored = ({ id }) => ({
  author_id: { _eq: id }
});

export const whereCurrentUserHasSubmission = () => ({
  current_user_has_submission: { _eq: true }
});

export const whereCurrentUserHasComment = () => ({
  current_user_has_comment: { _eq: true }
});

export const whereUpdatedInRange = (startDate, endDate) => ({
  updated_at: {
    _gte: startDate,
    _lte: endDate
  }
});

export const isFeatured = () => ({
  featured_at: { _is_null: false },
  deleted_at: { _is_null: true }
});

export const isNotFeatured = () => ({
  featured_at: { _is_null: true },
  deleted_at: { _is_null: true }
});

export const isPreviousChallenge = () => ({
  featured_at: { _is_null: true },
  deleted_at: { _is_null: true },
  available_after: { _lt: "now()" }
});

export const isFutureChallenge = () => ({
  deleted_at: { _is_null: true },
  available_after: { _gt: "now()" }
});

export const isCreatedInPastMonth = () => ({
  deleted_at: { _is_null: true },
  created_at: { _lte: "now()", _gte: "now() - '1 mon'::interval" }
});

