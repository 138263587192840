<template>
  <div class="card is-hover-grow">
    <ChallengeProgress :challenge="challenge" @accepted="onAccept" @click="onChallengeCardClick" />
    <div class="card-info">
      <div class="card-details" @click="onChallengeCardClick">
        <h3>
          {{ challenge.title }}
          <b-tag v-if="challenge.current_user_accepted_at" class="no-text" type="is-success is-light">
            <b-icon icon="check" />
          </b-tag>
        </h3>
        <p>
          {{ challenge.body_text | truncate(180) }}
        </p>
      </div>
      <div class="card-actions">
        <h6 class="timestamp">
          <template v-if="wasHappeningNow">
            {{ format(new Date(challenge.available_after), "EEEE MM/dd/YYY") }}
          </template>
          <ChallengeStartsOn v-else :available-date="availableDate" :show-stopwatch="true" class="stopwatch" />
        </h6>
        <b-taglist>
          <b-tag class="card-participants">
            <b-icon icon="user-check" />
            <span>{{ challenge.participants_aggregate.aggregate.count }}</span>
          </b-tag>
          <b-tag class="card-engagement">
            <b-icon icon="file" />
            <span>{{ engagementCount }}</span>
          </b-tag>
          <b-dropdown
            v-if="userCanDelete || userCanEdit"
            ref="dropdown"
            aria-role="list"
            position="is-top-left"
          >
            <template #trigger>
              <b-button icon-left="ellipsis-h" />
            </template>
            <b-dropdown-item
              v-if="userCanEdit"
              class="button is-ghost is-flex is-align-items-center"
              @click="onEdit"
            >
              Edit
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="userCanDelete" 
              class="button is-ghost is-flex is-align-items-center"
              @click="confirmDelete"
            >
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
import challengeCardViewExpanded from "@/components/challengeCard/ChallengeCardViewExpanded.js";
import { format } from "date-fns";
import ChallengeProgress from "@/components/challengeCard/ChallengeProgress.vue";
import ChallengeStartsOn from "@/components/challengeCard/ChallengeStartsOn.vue";

export default {
  name: "ChallengeCardViewExpanded",
  components: {
    ChallengeProgress,
    ChallengeStartsOn
  },
  mixins: [challengeCardViewExpanded],
  data() {
    return {
      format
    };
  },
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  methods: {
    onAccepted(challenge_user) {
      this.$emit("accepted", challenge_user);
    },
    onEdit() {
      this.$emit("edit");
      document.documentElement.classList.remove("is-clipped-touch");
    }
  },
};
</script>