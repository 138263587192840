<template>
  <div>
    <h4>Edit Event</h4>
    <b-field
      label="Event Date"
      :type="{ 'is-info': dateIsOffSchedule }"
      :message="{ 'you have chosen an off-schedule day': dateIsOffSchedule }"
    >
      <b-datepicker
        :value="value.starts_at"
        :min-date="minDate"
        :max-date="maxDate"
        nearby-selectable-month-days
        @input="handleNewDate"
      />
    </b-field>
    <b-field label="Start Time" :type="{ 'is-info': timeIsOffSchedule }">
      <TimeSlider
        v-model="value.starts_at"
        class="px-5"
        :class="{ 'off-schedule': timeIsOffSchedule }"
      />
      <template #message>
        <span v-if="timeIsOffSchedule">Start time has changed from {{ event.scheduled_starts_at | format }}
        </span>
      </template>
    </b-field>
    <b-field label="Description">
      <TipTap v-model="value.description" editable />
    </b-field>
    <b-field v-if="!value.template.original_venue_was_virtual" label="Venue Type">
      <div class="is-attached-button-group is-justify-content-center">
        <b-button
          :class="{ 'is-primary is-selected': !isVirtual }"
          icon-left="coffee"
          icon-pack="fas"
          @click="setPhysical"
        >
          In-Person
        </b-button>
        <b-button
          :class="{ 'is-primary is-selected': isVirtual }"
          icon-left="laptop"
          icon-pack="fas"
          @click="setVirtual"
        >
          Online
        </b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
import eventCornerEdit from "@/components/eventBody/EventCornerEdit.js";
import TipTap from "@/components/forms/TipTap.vue";
import TimeSlider from "@/components/common/TimeSlider.vue";

export default {
  name: "EventCornerEditDesktop",
  mixins: [eventCornerEdit],
  components: {
    TipTap,
    TimeSlider
  }
};
</script>
