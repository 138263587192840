import { defaultEventDescriptionPhysical, defaultEventDescriptionVirtual } from "@/defaultEventDescriptions.js";
import { addMinutes, parseISO } from "date-fns";

const eventCornerView = {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      requestedEventAccess: false,
      defaultEventDescriptionPhysical,
      defaultEventDescriptionVirtual
    };
  },
  computed: {
    //much of this is duplicated in EventRoom.vue
    nestedEvent() {
      //the get_events() that is used to populate the event in this view DOES return a venue_id, but we need to check the absence of venue_id, which is contained within the upcoming_events of the event's template
      return this.event.template.upcoming_events.filter((evnt) => { 
        return evnt.id == this.event.id;
      });
    },
    isNotCancelled() {
      return (
        this.event &&
        !this.event.cancelled_by_user_at &&
        !this.event.cancelled_by_materializer_at &&
        !this.event.deleted_at
      );
    },
    isVirtual() {
      return this.event && this.event.venue && this.event.venue.is_virtual;
    },
    isRSVPed() {
      return this.isVirtual && (this.event.current_user_is_rsvpd || this.event.current_user_is_organizer);
    },
    isNotRSVPed() {
      return this.isVirtual && !this.event.current_user_is_rsvpd;
    },
    startsAt() {
      if (!this.event) {
        return null;
      }
      return this.event.starts_at || this.event.scheduled_starts_at;
    },
    isShowTime() {
      return this.event && this.nowTimer >= addMinutes(parseISO(this.startsAt), -15);
      //return true; //testing
    },
    isJoinTime() {
      return this.event && this.nowTimer >= addMinutes(parseISO(this.startsAt), -5) && this.nowTimer <= parseISO(this.event.ends_at);
      //return true; //testing
    },
    showMeetingUrl() {
      return (this.isRSVPed || this.requestedEventAccess) && this.isJoinTime;
    },
    showMeetingPassword() {
      return (this.isRSVPed || this.requestedEventAccess) && this.event.venue.meeting_password && this.isJoinTime;
    },
    meetingUrl() {
      return this.event.venue.url;
    }
  }
};

export default eventCornerView;