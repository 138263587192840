<template>
  <section>
    <slot />
  </section>
</template>

<script>
export default {
  name: "Section"
};
</script>