<template>
  <div class="badge is-info">
    <b-icon
      v-if="icon !== null"
      :icon="icon"
      :pack="pack"
      size="is-medium"
    />
    <span v-else class="empty icon"></span>
    <span class="button-label">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: null
    },
    pack: {
      type: String,
      default: "far"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.badge {
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  border-radius: 0.25em;
  background: $primary;
  margin: 0 6px 0 0;
  @include mobile {
    font-size: vwl(9px);
    padding: vwl(1px) vwl(6px);
    margin: vwl(4px) vwl(4px) 0 0;
  }
  @include portrait {
    font-size: vwp(9px);
    padding: vwp(1px) vwp(6px);
    margin: vwp(4px) vwp(4px) 0 0;
  }
  @include tablet {
    position: relative;
    vertical-align: top;
    top: 2px;
  }
  @include desktop {
    font-size: 14px;
    top: 6px;
    padding: 5px 10px;
  }
  &[data-type="extended"] {
    background-color: $blue;
    color: #fff;
    .icon {
      width: 14px;
      @include mobile {
        width: vwl(14px);
      }
      @include portrait {
        width: vwp(14px);
      }
    }
  }
  &[data-type="featured"] {
    background-color: #ffc444;
    color: $grey900;
  }
  &[data-type="marathon"] {
    background-color: #404041;
    color: #ffc444;
  }
  &[data-type="updated"] {
    background-color: $pink;
    color: #fff;
  }

  .icon {
    margin-right: 6px;
    // color: inherit;
    height: 16px;
    width: 12px;
    @include mobile {
      height: vwl(16px);
      width: vwl(12px);
      margin-right: vwl(4px);
    }
    @include portrait {
      height: vwp(16px);
      width: vwp(12px);
      margin-right: vwp(4px);
    }
    &.empty {
      width: 0;
      margin: 0;
    }
  }
  .button-label {
    @include mobile {
      margin: vwl(1px) 0 0 0;
    }
    @include portrait {
      margin: vwp(1px) 0 0 0;
    }
  }
}
</style>
