<template>
  <div class="locked card">
    <div class="challenge-order">
      {{ challengeOrder }}
    </div>
    <div class="card-info">
      <div class="card-details">
        <h2>{{ prompt.title }}</h2>
      </div>
      <div class="card-actions">
        <h6 class="timestamp">
          <template v-if="!isAvailable">
            Unlocks
            <template v-if="isBefore(new Date(prompt.available_after), addDays(nowTimer, 2))">
              in
              {{
                isAvailableInFuture
                  ? formatDurationClock(nowTimer, new Date(prompt.available_after))
                  : "00:00:00"
              }}
              <b-icon
                type="has-text-primary-gradient"
                icon="stopwatch"
                class="is-size-2 has-text-primary-gradient"
              />
            </template>
            <template v-else> on {{ format(new Date(prompt.available_after), "EEEE MM/dd") }} </template>
          </template>
        </h6>
        <b-taglist v-if="!showReordering" class="is-justify-content-flex-end">
          <!-- only admins can create so no moderation or administration needed -->
          <b-dropdown v-if="isEllipsesEnabled" ref="dropdown" aria-role="list" position="is-top-left">
            <template #trigger>
              <b-button type="is-ghost is-small has-text-grey-dark" icon-left="ellipsis-h" />
            </template>
            <!-- delete -->
            <b-dropdown-item
              class="px-0 button is-ghost is-flex is-align-items-center"
              @click="onDeleteClick"
            >
              Delete
            </b-dropdown-item>
            <!-- edit -->
            <b-dropdown-item
              class="px-0 button is-ghost is-flex is-align-items-center"
              @click="onEditClick"
            >
              Edit
            </b-dropdown-item>
          </b-dropdown>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
import challengePromptCardViewExpandedLocked from "@/components/challengePromptCard/ChallengePromptCardViewExpandedLocked.js";
import { format, isBefore, addDays } from "date-fns";
import { formatDurationClock } from "@/dates";

export default {
  name: "ChallengePromptCardViewExpandedLocked",
  mixins: [challengePromptCardViewExpandedLocked],
  data() {
    return {
      formatDurationClock,
      format,
      isBefore,
      addDays
    };
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onDelete() {
      this.$emit("delete");
    }
  }
};
</script>