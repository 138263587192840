<template>
  <span />
</template>

<script>
import {
  LIST_DIRECT_MESSAGES,
  SUBSCRIBE_TO_DIRECT_MESSAGES
} from "@/models/forums/operations.gql";

// this component starts and manages various global subscriptions that are suitable for
// notifications but don't generally return cachable payloads. Use it to tell a user
// that something has happened, but link to where they see the context vs deliver the
// context in the notification
export default {
  name: "GlobalSubscriptions",
  data: () => ({
    unread: 0,
    directMessages: [],
    highWaterMark: new Date().toISOString()
  }),
  apollo: {
    directMessages: {
      query: LIST_DIRECT_MESSAGES,
      variables() {
        return {
          highWaterMark: this.highWaterMark
        };
      },
      // subscribeToMore: {
      //   document: SUBSCRIBE_TO_DIRECT_MESSAGES,
      //   debounce: 1000,
      //   variables() {
      //     return {
      //       highWaterMark: this.highWaterMark
      //     };
      //   },
      //   updateQuery(previousResult, { subscriptionData }) {
      //     this.unread += subscriptionData.data.directMessages.length;
      //     const latest = subscriptionData.data.directMessages.pop();
      //     if (latest === undefined || latest.author.id == this.$auth.user.id) {
      //       // this is either the subscription getting primed with no results (on init)
      //       // or a message that we sent (don't alert the user of their own sends)
      //       // don't move the highWaterMark, no-op
      //       return previousResult;
      //     }
      //     this.highWaterMark = latest.created_at;
      //     this.$log.info(
      //       "updating new highwatermark to",
      //       latest.created_at,
      //       "unread count",
      //       this.unread
      //     );
      //     this.$buefy.snackbar.open({
      //       message: "New direct message received",
      //       actionText: "Go to inbox",
      //       onAction: () => {
      //         this.$router.push({ name: "dashboard_inbox" });
      //       }
      //     });
      //     return previousResult;
      //   }
      // },
      skip() {
        return this.$auth.isLoading || !this.$auth.isAuthenticated;
      }
    }
  }
};
</script>
