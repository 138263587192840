<template>
  <div class="expanded-card is-flex">
    <div class="is-center is-flex is-flex-direction-column is-flex-grow-1">
      <div class="is-flex is-flex-direction-column pb-2">
        <div>
          <div class="is-flex is-align-items-center">
            <div>
              <UserPill :user="submission.author" class="mr-1" />
            </div>
            <span class="has-text-grey">
              <InlineDate :d="submission.created_at" :add-suffix="true" />
            </span>
          </div>
        </div>
        <p class="my-2 has-text-grey700 is-hover-grow pl-2 submission-body" @click="onSubmissionCardClick">
          <TipTap v-model="submission.body" :is-dangerous="isReported && !isAllowed" />
        </p>
        <div class="is-flex">
          <div class="is-flex is-flex-grow-1"></div>
          <b-taglist class="is-flex is-align-items-center">
            <ClickableTag
              class="mb-0"
              color-class="yellow"
              type="is-warning is-light"
              icon-left="thumbs-up"
              :is-enabled="isUpvoted"
              @click="onUpvoteClicked"
            >
              <span>
                {{ `${submission.upvotes.aggregate.count}` }}
                {{ "Like" | pluralize(submission.upvotes.aggregate.count) }}
              </span>
            </ClickableTag>
            <ClickableTag
              class="mb-0"
              color-class="blue"
              type="is-info is-light"
              icon-left="comment-alt"
              :is-disabled="submission.topic.posts_aggregate.aggregate.count < 1"
              :is-enabled="showPosts"
              @click="showPosts = !showPosts"
            >
              <span>
                {{ `${submission.topic.posts_aggregate.aggregate.count}` }}
                {{ "Comment" | pluralize(submission.topic.posts_aggregate.aggregate.count) }}
              </span>
            </ClickableTag>
            <ClickableTag
              class="mb-0"
              color-class="green"
              type="is-success is-light"
              icon-left="comment-alt-plus"
              :is-enabled="showAddComment"
              @click="showAddComment = !showAddComment"
            >
              <span>
                Add Comment
              </span>
            </ClickableTag>
            <b-dropdown v-if="isEllipsesEnabled" ref="dropdown" aria-role="list" position="is-top-left">
              <template #trigger>
                <b-button type="is-ghost is-small has-text-grey-dark" icon-left="ellipsis-h" />
              </template>
              <!-- allow -->
              <b-dropdown-item v-if="canShowEllipsesAllow" class="p-0 has-text-centered">
                <b-button v-if="!isAllowed" type="is-ghost" label="Allow" @click="onAllowClicked" />
                <b-button v-else type="is-ghost" label="Allowed" disabled />
              </b-dropdown-item>
              <!-- delete -->
              <b-dropdown-item v-if="canShowEllipsesDelete" class="p-0 has-text-centered">
                <b-button type="is-ghost" label="Delete" @click="onDeleteClicked" />
              </b-dropdown-item>
              <!-- edit -->
              <b-dropdown-item v-if="canShowEllipsesEdit" class="p-0 has-text-centered">
                <b-button type="is-ghost" label="Edit" @click="onEditClicked" />
              </b-dropdown-item>
              <!-- report -->
              <b-dropdown-item v-if="canShowEllipsesReport" class="p-0 has-text-centered">
                <b-button type="is-ghost" label="Report" @click="onReportClicked" />
              </b-dropdown-item>
            </b-dropdown>
          </b-taglist>
        </div>
      </div>
      <transition name="slide">
        <div v-if="showAddComment" class="expanded-card-expansion">
          <!-- New Post Form -->
          <PostCard
            :post="null"
            :topic="{ id: submission.topic_id }"
            @cancel="showAddComment = false"
            @created="onPostCreated"
          />
        </div>
      </transition>
      <transition name="slide">
        <div v-if="showPosts" class="expanded-card-expansion">
          <!-- Posts List -->
          <div
            v-if="submission.topic.posts_aggregate.aggregate.count > 0"
            class="is-flex is-flex-direction-column"
          >
            <PostCard
              v-for="p in posts"
              :id="p.id"
              :key="p.id"
              :post="p"
              :topic="{ id: submission.topic_id }"
            />
            <!-- <b-button
              type="is-primary is-small"
              label="Show More Comments"
              @click="showMore"
            /> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import submissionCardViewExpanded from "@/components/submissionCard/SubmissionCardViewExpanded.js";
import PostCard from "@/components/forums/PostCard.vue";
import TipTap from "@/components/forms/TipTap.vue";
import UserPill from "@/components/common/UserPill.vue";
import InlineDate from "@/components/common/InlineDate.vue";
import ClickableTag from "@/components/common/ClickableTag.vue";

export default {
  name: "SubmissionCardViewExpandedDesktop",
  components: {
    PostCard,
    TipTap,
    UserPill,
    InlineDate,
    ClickableTag
  },
  mixins: [submissionCardViewExpanded]
};
</script>

<style lang="scss">
.expanded-card-expansion {
  border-top: solid 1px $grey400;
  background-color: rgba($color: $grey100, $alpha: 0.5);
}
.submission-body {
  font-size: 18px;
}
</style>
