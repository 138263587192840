<template>
  <div class="columns is-variable is-5 pb-3">
    <div class="column">
      <slot name="top-corner"></slot>
      <transition v-if="!hideForm" name="slide">
        <keep-alive>
          <!-- VIRTUAL EVENT FORM -->
          <div v-if="form">
            <div v-if="isEditing">
              <b-field
                label="Host City"
                :type="{ 'is-danger': $v.form.host_city.$invalid }"
                class="is-danger"
                :message="{
                  'Choosing a Host City is required!': $v.form.host_city.$invalid
                }"
              >
                <GooglePlaces
                  :key="`ac-virtual-${autocompleteKey}`"
                  :value="form.api_result"
                  cities-only
                  @updated:place="onPlaceChanged"
                />
              </b-field>
              
              <template>
                <b-field
                  label="Meeting URL"
                  :type="{ 'is-danger': $v.form.url.$invalid }"
                  :message="{
                    'A valid meeting URL is required! ex. http://www.zoom.com': $v.form.url.$invalid
                  }"
                >
                  <b-input
                    v-model="form.url"
                    :required="true"
                    type="url"
                    placeholder="e.g. Zoom, Hangouts, Whereby..."
                  />
                </b-field>
                <b-field label="Enter the meeting password or PIN">
                  <b-input v-model="form.meeting_password" placeholder="Password/PIN" />
                </b-field>
              </template>
            </div>
          </div>
        </keep-alive>
      </transition>
    </div>
    <div class="column is-6 is-full-mobile">
      <img src="/img/online_event.svg" />
    </div>
  </div>
</template>

<script>
import venueCardVirtual from "@/components/venueCard/VenueCardVirtual.js";
import GooglePlaces from "@/components/forms/GooglePlaces.vue";

export default {
  name: "VenueCardVirtualDesktop",
  components: {
    GooglePlaces
  },
  mixins: [venueCardVirtual]
};
</script>

<style lang="scss" scoped>
.stupid-super-special-anchor {
  text-decoration: underline !important;
  color: #414141 !important;
}
</style>
