/* global google */
import { required } from "vuelidate/lib/validators";
import { getGPS, DEFAULT_LOCATION } from "@/location.js";

export default {
  props: {
    value: {
      type: Object,
      default: () => null
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    hideForm: {
      type: Boolean,
      default: false
    },
    showVenueLocation: {
      type: Boolean,
      default: false
    }
  },
  validations() {
    return {
      form: {
        title: {
          required
        },
        api_result: {
          required
        }
      }
    }
  },
  data() {
    let form = {
      title: null,
      is_virtual: false,
      street_address: null,
      api_result: null,
      parking_info: null,
      wifi_info: null,
      find_me: null,
      ...this.value
    };
    return {
      form,
      autocompleteKey: 0,
      map: null, // emitted by the gmap component once ready (used for direct calls)
      userLocation: null // will be a google.maps.LatLng object
    };
  },
  computed: {
    directionsURL() {
      if (this.value && this.value.api_result) {
        return `https://www.google.com/maps/dir/?api=1&destination_place_id=${this.value.api_result.place_id}&destination=${this.value.api_result.name}`;
      }
      return null;
    },
    venueIcon() {
      const aspectRatio = 69 / 52; // All svgs in the /img/map folder follow this
      const iconWidth = 35;
      const iconHeight = iconWidth * aspectRatio;
      let iconUrl = "/img/map/SUAW_Yellow1.svg";

      return {
        url: iconUrl,
        fillOpacity: 1.0,
        strokeWeight: 0,
        rotation: 0,
        scaledSize: new google.maps.Size(iconWidth, iconHeight),
        anchor: new google.maps.Point(iconWidth / 2, iconHeight),
      };
    },
    mapOptions() {
      let options = {
        center: DEFAULT_LOCATION,
        zoom: 2
      };
      if (this.form && this.form.api_result) {
        options.center = this.form.api_result.geometry.location;
        options.zoom = 11;
        // this.$log.debug("centering on form venue", options);
      } else if (this.userLocation) {
        options.center = this.userLocation;
        // this.$log.debug("centering on user", options);
      }
      return options;
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        this.$log.info("venue changed", newVal);
        // clear the opposite venue?
        this.$emit("input", newVal);
        this.$emit("invalid", this.$v.$invalid);
      }
    }
  },
  mounted() {
    this.$emit("invalid", this.$v.$invalid);
    //this.$log.info("mounted userLocation", this.userLocation);
    getGPS(position => {
      if (position !== null) {
        //this.$log.info("user location known", this.userLocation);
        if (this.value === null) {
          this.userLocation = position;
          this.mapOptions.center = this.userLocation;
        }
      }
    });
  },
  methods: {
    async onPlaceChanged(newPlace) {
      //this.$log.info("new place chosen", newPlace);
      this.autocompleteKey = this.autocompleteKey + 1;
      this.form.api_result = newPlace;
      this.form.location = {
        type: "Point",
        coordinates: [
          newPlace.geometry.location.lng(),
          newPlace.geometry.location.lat()
        ]
      };
      this.form.title = newPlace.name;
      this.form.host_city = newPlace.formatted_address;
      this.form.street_address = newPlace.formatted_address;

      this.$emit("input", this.form);
    },
    onRecenter() {
      this.map.panTo(this.form.api_result.geometry.location);
    }
  }
};
