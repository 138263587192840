import { isBefore, add, isPast } from "date-fns";

const challengeCardViewHeader = {
  props: {
    challenge: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        body: null,
        available_after: new Date(add(new Date(), { hours: 24 }))
      },
      acceptForm: {
        unlockRate: null
      },
      isBefore
    };
  },
  computed: {
    wasHappeningNow() {
      return isPast(this.availableDate);
    },
    availableDate() {
      if (this.challenge.current_user_shifted_start_date) {
        return new Date(this.challenge.current_user_shifted_start_date);
      }
      return new Date(this.challenge.available_after);
    },
    shiftedStartDate() {
      if (isBefore(this.nowTimer, new Date(this.challenge.available_after))) {
        return this.challenge.available_after;
      }
      return this.nowTimer;
    }
  },
  mounted() {
    this.form = {
      body: this.challenge.body,
      available_after: new Date(this.challenge.available_after)
    };
  },
  methods: {
    onAccept() {
      var challenge_user = {
        shifted_start_date: null,
        challenge_id: this.challenge.id
      };
      this.$emit("accepted", challenge_user);
    },
    loginAndComeBackWithFlyOpen() {
      this.$auth.refreshAccess({ redirectUrl: `${this.$route.fullPath}?${this.queryStringToggle}=1` });
    }
  }
};

export default challengeCardViewHeader;
