import { toLocalDate, durationNameFromPostgresInterval } from "@/dates";
import { format_title } from "@/filters";

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export default {
  props: {
    event: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    seriesUrl() {
      const resolved_route = this.$router.resolve({
        name: "series",
        params: {
          slug: this.$route.params.slug,
          event_template_id: this.event.template.id
        }
      });
      //this.$log.info(resolved_route);
      //this.$log.info(window.location);
      return window.location.origin + resolved_route.href;
    },
    durationName() {
      if (this.event.template.upcoming_events.length > 0) {
        var durationName = durationNameFromPostgresInterval(this.event.template.upcoming_events[0].duration);
        return durationName;
      }
      return "standard";
    }
  },
  mounted() {
    //this.$log.info(format_title(this.event));
    //this.$log.info(getTextWidth(format_title(this.event)));
  },
  methods: {
    toLocalDate,
    format_title,
    closeFlyout(name) {
      const flyout = this.$refs[name];
      if (!flyout) {
        this.$log.warn("tried to close unknown flyout", name, flyout);
        return;
      }
      flyout.toggle();
    }
  }
};