import { toLocalDate, durationNameFromPostgresInterval } from "@/dates";
import { slugify } from "@/filters";

export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    isActive: {
      // this should get turned on to highlight this event
      type: Boolean,
      default: false
    }
  },
  computed: {
    seriesIsUpdated() {
      return this.event.starts_at !== null || this.event.venue_id !== null;
    },
    venueCityState() {
      if (this.event.series.venue.is_virtual) {
        return this.event.series.venue.host_city;
      } else {
        // try to assemble it from address_components
        let addr = this.event.series.venue.street_address.split(",");
        return addr[1] + ", " + addr[2];
      }
    },
    hostUser() {
      if (this.event.series.organizers.length < 1) {
        this.$log.warn(
          "series has no organizers. event_template_id:",
          this.event.series.id
        );
        return null;
      }
      const u = this.event.series.organizers[0].user;
      return u;
    },
    durationName() {
      if (this.event.duration) {
        const durationName = durationNameFromPostgresInterval(this.event.duration);
        return durationName;
      }
      return "standard";
    }
  },
  methods: {
    toLocalDate,
    onSeriesCardClick() {
      this.$router.push({
        name: "event_detail",
        params: {
          slug: slugify(this.event.series),
          event_id: this.event.id
        }
      });
    }
  }
};
