<template>
  <div>
    <label class="label">
      {{ input.label }}
      <b-tooltip
        v-if="input.tooltip"
        type="is-dark"
        :label="input.tooltip"
      >
        <b-icon type="is-grey500" icon="question-circle" />
      </b-tooltip>
    </label>
    <slot></slot>
    <div
      v-if="input.error"
      class="pl-1 has-text-danger mt-1"
      >{{ input.error }}
    </div>
  </div>
</template>
<script>
export default {
  name: "LabeledInput",
  props: {
    input: {
      type: Object,
      required: true,
    }
  }
}
</script>