import { getInstance } from "./index";
import { Logger, DEBUG } from "@/plugins/logging.js";

const $log = new Logger("GUARD>ORGANIZER", { level: DEBUG });

export const organizerGuard = (to, _, next) => {
  $log.debug("route requires user to have 'organizer' role or be an admin");
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (
      authService.isAuthenticated &&
      (authService.isOrganizer || authService.isAdmin)
    ) {
      return next();
    } else {
      console.log("not allowing this user");
      return next(false);
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
