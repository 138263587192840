<template>
  <div>
    <b-button v-if="user" type="is-primary" icon-left="clock" @click="onCheckout">
      <span>Checkout</span>
    </b-button>
    <span v-else>
      No User
    </span>
  </div>
</template>

<script>
import { GET_USER_BY_PK } from "@/models/users/operations.gql";

export default {
  name: "StripeCheckout",
  data() {
    return {
      user: null
    };
  },
  apollo: {
    user: {
      query: GET_USER_BY_PK,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      }
    }
  },
  methods: {
    async onCheckout() {
      //call lambda and redirect to the session.url that is returned
      try {
        const response = await fetch(
          "https://24zda5h2amtbhxtr4prcwvrnae0ezvbh.lambda-url.us-west-1.on.aws/",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({
              env: window.config.STRIPE_LAMBDA_ENV,
              userId: this.user.id,
              userEmail: this.user.email
            })
          }
        );
        //this.$log.info(response);
        if (!response.ok) {
          throw new Error("error calling checkout lambda " + JSON.stringify(response));
        } else {
          var body = await response.json();
          this.$log.info("redirecting to stripe", body.session.url);
          window.open(body.session.url, "_self");
        }
      } catch (e) {
        this.$log.warn("failed to call checkout lambda", e);
      }
    }
  }
};
</script>
