<template>
  <section class="participants" :class="{ 'is-editing': isEditingDM }">
    <div
      class="head"
      :class="{ 'elevation-2': isEditingDM }"
    >
      <div
        v-if="subscription && event && kind === 'members'"
        class="is-flex is-justify-content-space-between is-flex-wrap-wrap"
      >
        <div class="user">
          <UserPill v-if="subscription && subscription.user" :user="subscription.user" size="small" />
          <span v-if="subscription.organizer_granted_at !== null">Organizer</span>
        </div>
        <div class="actions">
          <b-button
            v-if="subscription && subscription.organizer_granted_at !== null && $auth.isAuthenticated && subscription.user.id !== $auth.user.id"
            class="message-button"
            :disabled="isEditingDM"
            icon-left="envelope"
            type="is-info is-small"
            :class="{ 
              'is-inverted': !isEditingDM
            }"
            @click="isEditingDM = true"
          >
            <span class="button-label">Message</span>
          </b-button>
        </div>
        <div class="date">
          <span v-if="subscription.organizer_granted_at !== null">
            <span v-if="subscription.user.id === event.template.author_user_id">
              Created Series <InlineDate :d="event.template.created_at" />
            </span>
            <span v-else>
              Made Organizer
              <InlineDate :d="subscription.organizer_granted_at" />
            </span>
          </span>
          <span v-else>
            Joined Series <InlineDate :d="subscription.created_at" />
          </span>
        </div>
      </div>
      <div
        v-else-if="kind === 'attendees'"
        class="is-flex is-justify-content-space-between is-flex-wrap-wrap"
      >
        <div class="user">
          <UserPill
            v-if="subscription && subscription.user"
            :user="subscription.user"
            size="small"
          />
        </div>
        <div v-if="subscription.rsvped_at" class="date">
          <span>
            RSVPd&nbsp;<span class="is-hidden-mobile">to this event </span>
            <InlineDate :d="subscription.rsvped_at" />
          </span>
        </div>
      </div>
    </div>
    <div v-if="isEditingDM" class="dm-form">
      <h3 class="mb-2">
        Direct message to
        <span class="has-text-grey600">{{
          subscription.user | user_name
        }}</span>
      </h3>
      <p class="is-size-6 my-2">
        They will be notified via email of your new message. To see your direct
        message conversations,
        <router-link :to="{ name: 'dashboard_inbox' }"
          >visit your dashboard inbox</router-link
        >
      </p>

      <b-field label="Subject">
        <b-input v-model="form.subject" />
      </b-field>

      <b-field label="Message">
        <TipTap v-model="form.body" :editable="true" />
      </b-field>

      <UndoConfirmButtons
        :confirm-enabled="confirmEnabled"
        @undo="onCancel"
        @confirm="onConfirm"
      >
        <template #confirm>
          Send Message
        </template>
      </UndoConfirmButtons>
    </div>
  </section>
</template>

<script>
import { UPDATE_TEMPLATE_MEMBER } from "@/models/series/operations.gql";
import {
  GET_DIRECT_MESSAGE_FORUM,
  CREATE_DIRECT_MESSAGE_FORUM,
  FORUMS_TOPIC_ADD
} from "@/models/forums/operations.gql";
import InlineDate from "@/components/common/InlineDate.vue";
import TipTap from "@/components/forms/TipTap.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import UserPill from "@/components/common/UserPill.vue";

export default {
  name: "Participant",
  components: {
    InlineDate,
    TipTap,
    UndoConfirmButtons,
    UserPill
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    kind: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isEditingDM: false,
      forum: null,
      form: {
        subject: null,
        body: null
      }
    };
  },
  computed: {
    confirmEnabled() {
      return (
        this.form.subject !== null &&
        this.form.subject.trim() !== "" &&
        this.form.body !== null &&
        this.form.body !== ""
      );
    }
  },
  apollo: {
    forum: {
      query: GET_DIRECT_MESSAGE_FORUM,
      variables() {
        return {
          my_user_id: this.$auth.user.id,
          other_user_id: this.subscription.user.id
        };
      },
      update({ forums }) {
        const f = forums.length > 0 ? forums[0].forum : null;
        return f;
      },
      skip() {
        return (
          !this.$auth.isAuthenticated ||
          this.subscription.organizer_granted_at === null
        );
      }
    }
  },
  methods: {
    onCancel() {
      this.isEditingDM = false;
      this.form = { subject: null, body: null };
    },
    onConfirm() {
      if (this.forum !== null) {
        this.$log.info("going to re-use forum_id", this.forum);
        this.createTopic();
        return;
      }

      this.$apollo
        .mutate({
          mutation: CREATE_DIRECT_MESSAGE_FORUM,
          variables: {
            other_user_id: this.subscription.user.id
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", cache, result);
            return result;
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.forum = result.forum;
          this.createTopic();
        });
    },
    createTopic() {
      this.$apollo
        .mutate({
          mutation: FORUMS_TOPIC_ADD,
          variables: {
            forum_id: this.forum.id,
            title: this.form.subject || "No subject",
            body: this.form.body
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("topic add in update", cache, result);
            return result;
          }
        })
        .then(result => {
          this.$log.info("topic add in then", result);
          this.onCancel();
          this.$buefy.snackbar.open({
            message: "Message sent!",
            type: "is-success",
            actionText: "Go to inbox",
            onAction: () => {
              this.$router.push({ name: "dashboard_inbox" });
            }
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/mixins.scss";
@import "@/scss/borders.scss";
.participants {
  margin: 0.5em 0;
  @include tablet {
    margin: 0.5em 2em;
  }
  &:first-of-type {
    &:not(:last-of-type) {
      border-bottom: solid 1px #ddd;
    }
  }

  &.is-editing {
    .head {
      border-color: $border;
      background-color: #f1f1f1;
      .user-pill {
        background-color: #fff;
      }
    }
    .dm-form {
      @include tablet {
        border-color: $border;
      }
    }
    .actions {
      button {
        display: none;
      }
    }
  }
  .head {
    font-size: 0.9em;
    border-radius: 1em 1em 0 0;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    position: relative;
    z-index: 1;
    border: 1px solid transparent;
    width: 100%;
    @include mobile {
      padding: 0.75rem 0.5rem 1rem 0.5rem;
    }
    > div {
      @include mobile {
        row-gap: .5rem;
      }
    }
    .user {
      display: flex;
      align-items: center;
      @include mobile {
        flex: 1 0 40%;
      }
      @include tablet {
        flex: 0 0 30%;
      }
      > span {
        display: inline-flex;
        margin: 0 0 0 8px;
        @include tablet {
          margin-top: 0;
        }
      }
    }
    .user-pill {
      background-color: #f5f5f5;
    }
    .date {
      flex: 0 0 27.5%;
      display: flex;
      justify-content: right;
      align-items: center;
      @include mobile {
        padding-left: 0.25rem;
      }
    }
    .actions {
      flex: 0 0 20%;
      text-align: right;
      @include mobile {
        display: flex;
        justify-content: center;
      }
    }
  }
  .dm-form {
    padding: 1.5em;
    position: relative;
    z-index: 0;
    border-radius: 0 0 1em 1em;
    border: 1px solid transparent;
    border-top: 0;
    @include mobile {
      padding: 1em 0.75em 0 1em;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      @include mobile {
        top: 0;
        height: 60px;
        width: 1px;
        background: linear-gradient(to bottom, rgba(241,241,241,1) 0%,rgba(241,241,241,0) 100%);
      }
    }
    &::before {
      left: -1px;
    }
    &::after {
      right: -1px;
    }
    h3 {
      @include mobile {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
