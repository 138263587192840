<template>
  <header
    class="header"
    :class="imageClass"
    :style="`${image ? `background-image: url(${image})` : ''}`"
  >
    <div class="container">
      <div class="column long-title">
        <h1 v-text="title"></h1>
        <!-- <h1>{{ title }}</h1> -->
        <p>
          <slot />
        </p>
      </div>
    </div>
  </header>
</template>
  
<script>
  export default {
    name: "HeaderLegacy",
    props: {
      title: {
        type: String,
        required: true
      },
      image: {
        type: String,
        default: null
      },
      imageClass: {
        type: String,
        default: null
      }
    }
  };
</script>
  
<style lang="scss" scoped>
</style>
  
<style lang="scss" scoped>
  header {
    height: 25vh;
    max-width: 3840px;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    display: flex;
    position: relative;
    align-items: center;
    min-height: 100px;
  
    .truncate-header > & {
      height: auto;
      min-height: 10em;
    }
  
    .column {
      padding: $gap;
    }
  
    h1 {
      color: $gold;
      font-weight: 400;
    }
  
    p {
      margin: $size-7 0 0;
  
      &, a {
        color: $grey300;
        font-weight: 300;
      }
  
      a {
        text-decoration: underline;
      }
  
      strong {
        color: $white;
        font-weight: 500;
        display: block;
      }
    }
  
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .public-image-limited {
    height: 300px;
    background-size: 79%, cover;
    background-position: 100% 84%;
    background-repeat: no-repeat;
  }
  .laughing-ballcap {
    height: 300px;
    background-size: 130%, cover;
    background-position: -15% 70%;
    background-repeat: no-repeat;
  }
  .discussion-header {
    height: 300px;
    background-size: cover;
    background-position: 10% 30%;
    background-repeat: no-repeat;
  }
</style>