<template>
  <Section>
    <div class="events-container">
      <h3>Members</h3>
      <Participants v-if="event" ref="participants" class="my-2" :event="event" kind="members"/>
    </div>
  </Section>
</template>

<script>
import Section from "@/components/common/Section.vue";
import Participants from "@/components/events/Participants.vue";

export default {
  name: "EventDetailSectionMembers",
  components: {
    Section,
    Participants
  },
  props: {
    event: {
      type: Object,
      default: () => null
    },
  },
  methods: {
    refetch() {
      this.$refs.participants.refetch();
    }
  }
};
</script>