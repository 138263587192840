<template>
  <div class="is-flex is-align-items-center mb-1">
    <UserPill class="mr-1" :elevation="1" :user="post !== null ? post.author : $auth.user" />
    <p v-if="post" class="is-size-7 has-text-grey400">
      {{ post.updated_at | formatFromNow }}
    </p>
    <p v-if="post && post.deleted_at" class="is-size-7 has-text-grey400 ml-1">
      (deleted {{ post.deleted_at | formatFromNow }})
    </p>
  </div>
</template>

<script>
import UserPill from "@/components/common/UserPill.vue";

export default {
  name: "PostCardHeader",
  components: {
    UserPill,
  },
  props: {
    post: {
      type: Object,
      default: null
    }
  }
};
</script>
