import { getInstance } from "./index";
import { Logger, INFO } from "@/plugins/logging.js";

const $log = new Logger("GUARD>ADMIN_ONLY", { level: INFO });

export const adminGuard = (to, _, next) => {
  $log.debug("route requires user to have 'app_admin'");
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated && authService.isAdmin) {
      return next();
    } else {
      console.log("not allowing this user");
      return next(false);
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
