import Vue from "vue";
import VueMeta from "vue-meta";
Vue.use(VueMeta);
import App from "./App.vue";
import VuePluralize from "vue-pluralize";
import "./registerServiceWorker";
import router from "./router";
import build_info from "../build-info.json";
// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
// GraphQL API / Apollo Client
import VueApollo from "vue-apollo";
import x5GMaps from "x5-gmaps";
import VueGtag from "vue-gtag";

import { hasuraClient, graphcmsClient } from "./apollo.js";
// Import the plugin here
import { AuthPlugin } from "./auth";
import "@/filters.js";
import random from "@/plugins/random.js";
import { default as log_plugin, Logger, DEBUG } from "@/plugins/logging.js";

const $log = new Logger("main.js", { level: DEBUG });
Vue.use(log_plugin, { level: DEBUG });
Vue.use(random);
Vue.prototype.matchMedia = arg => {
  return window.matchMedia(arg);
};
Vue.use(x5GMaps, {
  key: window.config.GOOGLE_MAPS_KEY,
  libraries: ["places"]
});
Vue.use(VuePluralize);
Vue.use(VueGtag, {
  config: {
    pageTrackerExcludedRoutes: ["challenges_prompt_qa"],
    id: window.config.GOOGLE_ANALYTICS_KEY //for www.shutupwrite.com
  }
});

let dateInterval;

// Global Mixins
Vue.mixin({
  data() {
    const mqMobile = this.matchMedia("(max-width: 768px)");
    const mqTablet = this.matchMedia("(max-width: 1023px)");
    //using 'suw' prefix to avoid conflicts with 3rd party components
    return {
      mqMobile: mqMobile,
      mqTablet: mqTablet,
      suwMobile: mqMobile.matches,
      suwTablet: mqTablet.matches,
      suwDesktop: !mqTablet.matches && !mqMobile.matches,
      nowTimer: new Date()
    };
  },
  created() {
    dateInterval = setInterval(() => (this.nowTimer = new Date()), 1000);
  },
  destroyed() {
    clearInterval(dateInterval);
  },
  mounted() {
    this.mqMobile.addListener(() => {
      this.suwMobile = this.mqMobile.matches;
      this.suwTablet = this.mqTablet.matches;
      this.suwDesktop = !this.suwMobile && !this.suwTablet;
    });
  },
  methods: {
    display404Page() {
      this.$emit("not-found");
    }
  }
});

// icon setup https://www.npmjs.com/package/@fortawesome/vue-fontawesome#recommended
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCoffee, faLaptop } from "@fortawesome/pro-solid-svg-icons";
library.add(faCoffee, faLaptop);
import {
  faStar,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCrown,
  faEdit,
  faSignOutAlt,
  faCalendar,
  faCalendarExclamation,
  faCalendarTimes,
  faCalendarWeek,
  faStopwatch,
  faClock,
  faSearchLocation,
  faUnderline,
  faQuestionCircle,
  faUserCheck,
  faCheck,
  faAngleLeft,
  faAngleRight,
  faExclamationTriangle,
  faInfoCircle,
  faUserFriends,
  faLongArrowAltRight,
  faSearch,
  faArrowUp,
  faArrowDown,
  faPencilAlt,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faBars,
  faEllipsisH,
  faAngleDown,
  faExclamationCircle,
  faExchangeAlt,
  faUser,
  faCommentPlus,
  faCommentAltPlus,
  faCommentAlt,
  faThumbsUp,
  faLock,
  faFile,
  faPlus,
  faGripVertical,
  faCheckCircle,
  faTimesCircle,
  faMapMarkerAlt,
  faFlag,
  faChevronCircleLeft,
  faChevronCircleRight,
  faAt,
  faEnvelope,
  faUserTimes,
  faBan,
  faSparkles,
  faChevronCircleUp,
  faChevronCircleDown,
  faGlobe,
  faStickyNote,
  faShare,
  faRunning,
  faArrowsAltH,
  // WYSIWYG icons
  faBold,
  faItalic,
  faStrikethrough,
  faCode,
  faRemoveFormat,
  faUndo,
  faRedo,
  faMinusSquare,
  faParagraph,
  faHeading,
  faH1,
  faH2,
  faH3,
  faH4,
  faListOl,
  faListUl,
  faPlusCircle,
  faFileCode,
  faQuoteLeft,
  faShareSquare,
  faLink,
  faUnlink,
  faSubscript,
  faSuperscript
} from "@fortawesome/pro-regular-svg-icons";
library.add(
  faStar,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCrown,
  faEdit,
  faSignOutAlt,
  faCalendar,
  faCalendarExclamation,
  faCalendarTimes,
  faCalendarWeek,
  faStopwatch,
  faClock,
  faSearchLocation,
  faUnderline,
  faQuestionCircle,
  faUserCheck,
  faCheck,
  faAngleLeft,
  faAngleRight,
  faExclamationTriangle,
  faInfoCircle,
  faUserFriends,
  faLongArrowAltRight,
  faSearch,
  faArrowUp,
  faArrowDown,
  faPencilAlt,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faBars,
  faEllipsisH,
  faAngleDown,
  faExclamationCircle,
  faExchangeAlt,
  faUser,
  faCommentPlus,
  faCommentAltPlus,
  faCommentAlt,
  faThumbsUp,
  faLock,
  faFile,
  faPlus,
  faGripVertical,
  faCheckCircle,
  faTimesCircle,
  faMapMarkerAlt,
  faFlag,
  faChevronCircleLeft,
  faChevronCircleRight,
  faAt,
  faEnvelope,
  faUserTimes,
  faBan,
  faSparkles,
  faChevronCircleUp,
  faChevronCircleDown,
  faGlobe,
  faStickyNote,
  faShare,
  faRunning,
  faArrowsAltH,
  // WYSIWYG icons
  faBold,
  faItalic,
  faStrikethrough,
  faCode,
  faRemoveFormat,
  faUndo,
  faRedo,
  faMinusSquare,
  faParagraph,
  faHeading,
  faH1,
  faH2,
  faH3,
  faH4,
  faListOl,
  faListUl,
  faPlusCircle,
  faFileCode,
  faQuoteLeft,
  faShareSquare,
  faLink,
  faUnlink,
  faSubscript,
  faSuperscript
);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("VueFontawesome", FontAwesomeIcon); // create component

import Buefy from "buefy";
Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome", // use component we registered above
  defaultIconPack: "far",
  defaultSnackbarDuration: 9000,
  defaultToastDuration: 9000
});

window.build_info = build_info;
//$log.info("WINDOW_CONFIG", window.config);
//$log.info("BUILD_INFO", window.build_info);

// Install the authentication plugin here
Vue.use(AuthPlugin, {
  apolloClient: hasuraClient,
  router: router
});

const apolloProvider = new VueApollo({
  defaultClient: hasuraClient,
  clients: {
    cms: graphcmsClient
  },
  defaultOptions: {
    $query: {
      //loadingKey: "loading",
      // the skip key can take a responsive var from vue, so we wait til the
      // auth system is done "loading" before doing any GQL queries
      skip: vm => vm.$auth.loading // TODO: this has a 60s timeout by default, make it smarter
    }
  }
});
Vue.use(VueApollo);

Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  // errorHandler: (error, vm, info) => {
  //   console.log("vue fucked up", error, vm, info);
  // },
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
