<template>
  <footer>
    <div class="container">
      <div class="columns is-gapless">
        <div class="column is-one-quarter is-flex is-justify-content-space-between center-on-touch">
          <router-link to="/"><BrandCircle /></router-link>
        </div>

        <div class="column is-one-quarter">
          <div class="site-links has-text-weight-bold mb-2">
            <router-link :to="{ name: 'contact' }">
              Contact Us
            </router-link>
          </div>
          <ul>
            <li class="mb-2">
              <p>
                2261 Market Street #4978
                <br />
                San Francisco, CA 94114
              </p>
            </li>
            <li>
              <a href="https://www.facebook.com/shutupnwrite/" target="_blank" rel="noopener noreferrer">
                <img src="/img/facebook.svg" alt="" />
              </a>
              <a href="https://www.instagram.com/shutupwrite/" target="_blank" rel="noopener noreferrer">
                <img src="/img/instagram.svg" alt="" />
              </a>
              <a href="https://twitter.com/shutupwrite" target="_blank" rel="noopener noreferrer">
                <img src="/img/twitter.svg" alt="" />
              </a>
            </li>
          </ul>
        </div>

        <div class="column is-one-quarter">
          <div class="has-text-weight-bold mb-2">Resources</div>
          <ul class="site-links">
            <li><router-link :to="{ name: 'organizer' }">Become an Organizer</router-link></li>
            <li><router-link :to="{ name: 'downloads' }">Downloads</router-link></li>
            <li><a href="https://store.shutupwrite.com/" target="_blank" rel="noopener noreferrer">Store</a></li>
            <li><router-link :to="{ name: 'about' }">About Us</router-link></li>
          </ul>
        </div>


        <div class="column is-one-quarter">
          <div class="has-text-weight-bold mb-2">Support</div>
          <ul class="site-links">
            <li><router-link :to="{ path: '/faq' }">FAQ</router-link></li>
            <li><router-link :to="{ path: '/organizer-handbook' }">Organizer Handbook</router-link></li>
            <li><router-link :to="{ name: 'organizer-faq'}">Organizer FAQ</router-link></li>
            <li><router-link :to="{ name: 'code_of_conduct' }">Code of Conduct</router-link></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="copyright columns is-gapless">
      <div class="column is-6 is-12-mobile">
        &copy; {{ new Date().getFullYear() }} Shut Up & Write! All rights reserved.
      </div>
      <div class="site-links column is-6 is-12-mobile is-flex is-justify-content-flex-end">
        <router-link :to="{ name: 'privacy_policy' }"
          >Your Privacy</router-link
        >
        <router-link :to="{ name: 'tos' }">Terms of Use</router-link>
        <router-link :to="{ name: 'legal' }">Legal</router-link>
      </div>
    </div>
    
  </footer>
</template>

<script>
import BrandCircle from "@/components/common/BrandCircle.vue";
export default {
  name: "MainFooterDesktop",
  components: {BrandCircle}
};
</script>

<style lang="scss">
footer {
  justify-self: flex-end;
  color: $grey900;
  background-color: $gold;

  .container {
    .center-on-touch {
      @include touch {
        padding: 0em 2em !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .columns.is-gapless {
      margin: 4em 0;
      & > .column {
        @include touch {
          padding: 2em 1em !important;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  li {
    padding: .15em 0;
  }

  .copyright {
    border-top: 1px solid $grey900;
    padding: 2em;
    font-size: .9em;

    @include touch {
      flex-direction: row-reverse;
    }
  }

  a {
    display: inline-block;
    color: $grey900;

    img {
      max-width: 1.65em;
    }

    & + a {
      margin-left: 2em;
    }

    &:hover {
      color: $blue;
    }
  }
}
</style>
