export const whereCurrentUserIsOrganizer = ({ id }) => ({
  cancelled_by_materializer_at: { _is_null: true },
  members: {
    user_id: { _eq: id },
    organizer_granted_at: { _is_null: false }
  }
});

export const whereNearGPS = ({ lat, lng, meters }) => ({
  venue: {
    location: {
      _st_d_within: {
        distance: meters,
        from: {
          type: "Point",
          coordinates: [lng, lat]
        }
      }
    }
  }
});

export const whereIsFeatured = () => ({
  featured_at: { _is_null: false }
});

export const whereIsExtended = () => ({
  duration: { _gt: "01:30:00", _lt: "03:00:00" }
});

export const whereIsMarathon = () => ({
  duration: { _gte: "03:00:00" }
});

export const whereIsChapter = ({ chapter_id }) => ({
  chapter_id: { _eq: chapter_id }
});

export const whereIsNotChapterOnly = () => ({
  chapter_id: { _is_null: true }
});
