<template>
  <div class="home container">
    <slot name="title"></slot>
  </div>
</template>

<script>
export default {
  props: {
    hasCards: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";
.container {
  &.home {
    $x: 1rem;
    padding-top: $x;
    padding-bottom: $x;
    text-align: center;
    @include mobile {
      $x: vwl(15px);
      padding-top: $x;
      padding-bottom: $x;
    }
    @include portrait {
      $x: vwp(30px);
      padding-top: $x;
      padding-bottom: $x;
    }
    @include tablet {
      $x: 4rem;
      padding-top: $x;
      padding-bottom: $x;
    }
    @include desktop {
    //   $x: 3.5rem;
    //   padding-top: $x;
    //   padding-bottom: $x;
    // }
    // @include widescreen {
      $x: 5.5rem;
      padding-top: $x;
      padding-bottom: $x;
    }
  }
}
h2 {
  font-weight: 500;
  @include mobile {
    font-size: vwl(20px);
  }
  @include portrait {
    font-size: vwp(20px);
  }
  @include tablet {
    font-size: 2rem;
    margin: 0 0 0.75rem 0;
  }
  @include desktop {
    font-size: 2.5rem;
    margin: 0 0 1.25rem 0;
  }
}
p {
  font-size: 20px;
  line-height: 1.3;
  @include mobile {
    font-size: vwl(13px);
  }
  @include portrait {
    font-size: vwp(13px);
  }
  @include desktop {
    font-size: 1.5rem;
  }
  // @include widescreen {
  //   font-size: 1.75rem;
  //   margin: 1rem 0;
  // }
}
</style>
