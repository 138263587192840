import { isFuture } from "date-fns";

const challengePromptCardViewExpandedLocked = {
  props: {
    // the prompt you're showing/editing, null for create UX
    prompt: {
      type: Object,
      default: null
    },
    isReordering: {
      type: Boolean,
      default: false
    },
    challengeOrder: {
      type: Number,
      default: null
    },
    // values that can be set to simplify human QA of display logic
    qaIsReordering: {
      type: String,
      default: ""
    },
    qaIsAvailable: {
      type: String,
      default: ""
    }
  },
  computed: {
    showReordering() {
      if (!this.qaIsReordering) {
        return this.isReordering;
      }
      return this.qaIsReordering === "y";
    },
    isEllipsesEnabled() {
      return this.$auth.isAdmin;
    },
    isAvailable() {
      if (!this.qaIsAvailable) {
        return !this.isAvailableInFuture;
      }
      return this.qaIsAvailable === "y";
    },
    isAvailableInFuture() {
      return isFuture(new Date(this.prompt.available_after));
    }
  },
  methods: {
    onEditClick() {
      this.$emit("edit");
    },
    onDeleteClick() {
      this.$emit("delete");
    }
  }
}

export default challengePromptCardViewExpandedLocked;