<template>
  <HeaderPage :image="`${suwDesktop ? '/img/banners/Challenges_Huge.jpg' : '/img/banners/Challenges_Mobile.jpg'}`" image-class="challenges page-header">
    <template #summary>
      <HeaderPageHeaderCopy slug="challenges-header" />
    </template>
    <template #body>
      <!-- Challenges List -->
      <Section>
        <!-- List Controls - Desktop -->
        <div class="previous-challenges is-flex is-hidden-mobile is-justify-content-space-between">
          <div class="tabs is-danger mb-0">
            <ul>
              <li
                v-for="(option, index) in sortOptions"
                :key="option.title"
                :class="{ 'is-active': selectedSortOption === index }"
              >
                <a @click="selectedSortOption = index">
                  <b-icon
                    :icon="option.icon"
                    :custom-class="option.customClass"
                  />
                  <span>{{ option.title }}</span>
                </a>
              </li>
            </ul>
          </div>
          <b-button
            v-if="$auth.isAdmin"
            type="discussions-button elevation-1 has-text-weight-semibold has-text-info ml-1"
            label="Create New Challenge"
            icon-left="plus-circle"
            :disabled="isShowingChallengeForm"
            @click="isShowingChallengeForm = !isShowingChallengeForm"
          />
        </div>

        <!-- List Controls - Mobile -->
        <div class="discussions-nav is-hidden-tablet">
          <div class="tabs is-danger is-multiline">
            <ul class="is-flex-grow-0">
              <li
                v-for="(option, index) in sortOptions"
                :key="option.title"
                :class="{ 'is-active': selectedSortOption === index }"
              >
                <a @click="selectedSortOption = index"
                  :class="{ 'button discussions-button': suwMobile }">
                  <b-icon :icon="option.icon" :custom-class="option.customClass" />
                  <span class="button-label">{{ option.label }}</span>
                </a>
              </li>
            </ul>
          </div>

          <b-button
            v-if="$auth.isAdmin"
            type="button discussions-button has-text-weight-semibold has-text-info ml-1"
            icon-left="plus-circle"
            :disabled="isShowingChallengeForm"
            @click="isShowingChallengeForm = true"
          />
        </div>
        <!-- new challenge form -->
        <ChallengeCardCoordinator
          v-if="isShowingChallengeForm"
          class="card-container"
          :challenge="null"
          @created="onCreateChallenge"
          @cancelled="isShowingChallengeForm = false"
        />

        <!-- Challenge Skeletons -->
   
        <template v-if="challenges.length">
          <ChallengeCardCoordinator
            v-for="c in challenges"
            :key="c.id"
            class="challenge card-container"
            :challenge="c"
            @updated="onUpdateChallenge"
          />
        </template>
        <div v-else-if="!isShowingChallengeForm">
          <EmptyState>
            No Challenges Found
          </EmptyState>
        </div>
      </Section>
    </template>
  </HeaderPage>
</template>

<script>
import { isNotDeleted, isNotDraft, isFeatured, isPreviousChallenge } from "@/models/challenges/predicates.js";
import { COMPUTED_CHALLENGES_LIST } from "@/models/challenges/operations.gql";
import HeaderPageHeaderCopy from "@/components/resources/HeaderPageHeaderCopy.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import ChallengeCardCoordinator from "@/components/challengeCard/ChallengeCardCoordinator.vue";
import HeaderPage from "@/components/common/HeaderPage.vue";
import Section from "@/components/common/Section.vue";

export default {
  name: "Challenges",
  metaInfo: {
    title: "Writing Challenges - ",
    meta: [
      { vmid: "description", name: "description", content: "Sign up for five days of prompts designed to take you on a thematic writing journey. Unlock a new prompt each day to share your writing with the community." },
    ],
    link: [
      { rel: "canonical", href: "https://www.shutupwrite.com/challenges/" }
    ]
  },
  components: {
    HeaderPage,
    Section,
    EmptyState,
    ChallengeCardCoordinator,
    HeaderPageHeaderCopy
  },
  data() {
    return {
      isShowingChallengeForm: false,
      challenges: [],
      sortOptions: [
        {
          title: "Latest",
          icon: "exchange-alt",
          customClass: "fa-rotate-90",
          where: {
            ...isPreviousChallenge(),
            ...isNotDraft(),
            ...isNotDeleted()
          },
          orderBy: { available_after: "desc" },
          limit: 10
        },
        {
          title: "Top Challenges",
          icon: "star",
          where: {
            ...isPreviousChallenge(),
            ...isNotDraft(),
            ...isNotDeleted()
          },
          orderBy: { participants_aggregate: { count: "desc" } },
          limit: 10
        }
      ],
      selectedSortOption: 0,
      featured_challenges: []
    };
  },
  mounted() {
    if (this.$auth.isAuthenticated && this.$auth.isAdmin) {
      this.sortOptions.push({
        title: "Admin All Challenges",
        icon: "clock",
        where: {
          ...isNotDeleted(),
          ...isNotDraft()
        },
        orderBy: { created_at: "desc" },
        limit: 999
      });
    }
  },
  apollo: {
    featured_challenges: {
      update: data => data.challenges,
      query: COMPUTED_CHALLENGES_LIST,
      variables() {
        return {
          limit: 10,
          expandPrompts: true,
          where: {
            ...isNotDraft(),
            ...isNotDeleted(),
            ...isFeatured()
          }
        };
      }
    },
    // challenge list at bottom of page
    challenges: {
      query: COMPUTED_CHALLENGES_LIST,
      variables() {
        return {
          expandPrompts: true,
          orderBy: this.sortOptions[this.selectedSortOption].orderBy,
          where: this.sortOptions[this.selectedSortOption].where,
          limit: this.sortOptions[this.selectedSortOption].limit
        };
      }
    }
  },
  methods: {
    onCreateChallenge(challenge) {
      this.$log.debug("Challenge Created", challenge);
      this.isShowingChallengeForm = false;
    },
    onUpdateChallenge(challenge) {
      this.$log.info("Challenge Updated", challenge);
    }
  }
};
</script>
<style lang="scss">
@import "../../scss/mixins";
.page-content.challenges {
  padding-bottom: vwp(32px);
  @include landscape {
    padding-bottom: vwl(32px);
  }
  @include tablet {
    padding-bottom: 3rem;
  }
  .expanded-card {
    display: flex;
    flex-direction: row;
    column-gap: 0.75rem;
    h6 {
      @include mobile {
        text-align: right;
      }
    }
  }
  .event-listing {
    .expanded-card {
      flex-wrap: nowrap;
    }
  }
  .prompt-card {
    > div {
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
      }
    }
    .challenge-actions {
      flex-direction: column;
      align-items: flex-end;
      flex-wrap: nowrap;
      flex-shrink: 0;
      justify-content: space-between;
    }
    h2 + p {
      margin: 0 0 vwp(5px) 0;
    }
  }
  .event-info {
    @include tablet {
      flex-direction: row;
      column-gap: 0.75rem;
    }
  }
}
.challenge-editor {
  .ProseMirror {
    background-color: #fff;
    height: 260px;
  }
}
.challenges {
  .container {
    padding: 3rem 0;
    @include portrait {
      padding: vwp(24px) 0.5em vwp(15px) 0.5em;
    }
    @media (min-width: $portrait) and (max-width: ($tablet - 1px)) {
      padding: vwp(24px) 0.5em vwp(15px) 0.5em;
      margin-left: 10%;
      margin-right: 10%;
    }
    @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
      width: 754px;
      margin-left: auto !important;
      margin-right: auto !important;
      flex-grow: 0;
    }
    @include desktop {
      padding: 3rem 1.25rem;
    }
  }
  .breadcrumbs {
    margin-bottom: 5px;
    + .card-container {
      margin-top: 0;
    }
  }
  .event-listing {
    h6 {
      font-size: 12px;
      margin: 0 0 5px 0;
      @include desktop {
        font-size: 14px;
      }
      .icon {
        height: 12px;
        width: 12px;
        margin: 0 0 0 4px;
        position: relative;
        top: 2px;
        @include desktop {
          height: 20px;
          width: 20px;
          top: 4px;
        }
      }
    }
  }
  .tabs {
    font-size: 1rem;
    @include desktop {
      font-size: 1.25rem;
    }
    ul {
      border-bottom: none;
    }
    a {
      border-bottom-width: 2px;
    }
  }
}
.other-features {
  @include tablet {
    padding: 0 0 0 1.5rem;
  }
  @include mobile {
    margin-top: 1.5rem;
  }
}
.progress-rings {
  display: flex;
  margin: 1rem 0 1.5rem 0;
  padding: 0 0.5rem;
  justify-content: space-around;
  &[data-prompt-level="low"] {
    justify-content: center;
    column-gap: 6%;
    margin-left: auto;
    margin-right: auto;
  }
  &[data-prompt-level="high"] {
    flex-wrap: wrap;
    padding: 0;
    .prompt-progress {
      flex-basis: 12%;
      padding: 0.25rem;
    }
    .prompt-ring {
      top: 0.25rem;
      left: 0.25rem;
      right: 0.25rem;
      bottom: 0.25rem;
    }
  }
  @include mobile {
    margin: vwl(12px) 0 vwl(20px) 0;
  }
  @include portrait {
    margin: vwp(12px) 0 vwp(20px) 0;
  }
  @include desktop {
    margin: 1.5rem 0 2rem 0;
  }
}
.prompt-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 16%;
  flex-shrink: 0;
  position: relative;
  svg {
    width: 100%;
  }
}
.prompt-ring {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  width: auto;
  &.checkmark {
    > svg {
      width: 50% !important;
    }
  }
  &.lock {
    > object {
      width: 30% !important;
    }
  }
}
.challenge-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  &.primary {
    flex-direction: column-reverse;
    .tags {
      margin-bottom: 0.5rem;
    }
  }
  &.secondary {
    @include mobile {
      flex-direction: column-reverse;
    }
    @include tablet {
      justify-content: center;
    }
    .tags {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;

    }
  }
  .tag {
    margin-bottom: 0;
  }
}
.featured-card-container {
  width: 100%;
  height: 100%;
}
.tile {
  @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
    &.is-7 {
      width: 54%;
    }
    &.is-5 {
      width: 46%;
    }
  }
}
.previous-challenges {
  .tabs li, .tabs a {
    height: 100%;
  }
}
.challenge-details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}
.breadcrumbs {
  margin-bottom: 5px;
  + .card-container {
    margin-top: 0;
  }
}
</style>
